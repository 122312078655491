import { Button, Stack, TextField, Typography } from '@mui/material'
import { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { trim } from 'lodash';
import { ApiPaths } from '../../../config'
import { getFormatDate, reorderByPositionAlsoLayer, replaceNullValuesFromObject } from '../../../service/function'
import { userRoleSelector } from '../../../store/selectors/selectors'
import MaskProgress from '../../general/Info/MaskProgress'
import MyTabs from '../MyTabs/MyTabs'
import Loader from '../Loader/Loader'
import { useObjectQuery, usePreparePropsObjectQuery, useUpdateObjectMutation } from '../../../hooks/reactQuery/useObject'
import { ModalContextProvider } from '../../../hooks/useModal/useModal'
import IconBox from '../ForForms/IconBox/IconBox'
import { BoxMarginLeftRight } from '../StyledComponents/BoxMarginLeftRight';
import History from './Tabs/History'
import Tags from './Tabs/Tags'
import Information from './Tabs/Information'
import Display from './Tabs/Display'
import { ModalWindowContext } from '../../../hooks/useModal/ModalWindow';


const ObjectProperty = () => {

    const { objectId } = useContext(ModalWindowContext)
    const { isGeoEditor } = useSelector(userRoleSelector)

    const { data: object, status: objectStatus } = useObjectQuery(objectId)
    const { mutate: updateObject } = useUpdateObjectMutation()
    const { data: preparePropsObject, status: preparePropsObjectStatus } = usePreparePropsObjectQuery(objectId)

    // console.log({object});
    const { control, handleSubmit, watch, setValue, formState: { isSubmitting }, setError } = useForm({
        defaultValues: {
            id: object?.id ? object.id : '',
            name: object?.name ? object.name : '',
            lat: object?.lat ? object.lat : '',
            lon: object?.lon ? object.lon : '',
            tags: object?.tags ? reorderByPositionAlsoLayer({ objectTags: object.tags, layerTags: object.layers?.[0]?.tags }) : [],
            wkt: object?.wkt ? object.wkt : '',
            ...replaceNullValuesFromObject({     // предварительно те поля, значения которых null, undefinded - заменяем на '' - так хочет их получить reacHookForm
                fillColor: object?.fillColor,
                lineWeight: object?.lineWeight,
                lineColor: object?.lineColor
            })
        },

        // при изменении значения objects - срабатывает только свойство 'values'
        values: {
            id: object?.id ? object.id : '',
            name: object.name,
            lat: object.lat,
            lon: object.lon,
            tags: object.tags ? reorderByPositionAlsoLayer({ objectTags: object.tags, layerTags: object.layers?.[0]?.tags }) : [],
            wkt: object.wkt,
            ...replaceNullValuesFromObject({     // предварительно те поля, значения которых null, undefinded - заменяем на '' - так хочет их получить reacHookForm
                fillColor: object?.fillColor,
                lineWeight: object?.lineWeight,
                lineColor: object?.lineColor
            })
        },
        mode: 'onSubmit'
    })

    const onSubmit = async (data) => {

        // удаляем лишнее в tags 
        if (data?.tags) {
            data.tags.map(item => {
                if (!(String(item.id).indexOf('new') < 0 && item.filled)) {
                    delete item['id']
                }
                delete item['draggableId']
                delete item['filled']
                item.type = 1
                return item
            })
        }

        // убираем незаполненные.Если не заполнен только тег - то устанавливаем ему дефолтное значение
        const newTags = [...data.tags
            .filter(tag => {
                if (tag.id) return true
                if (trim(tag.key) === '' && trim(tag.value) === '') return false
                return true
            })
            .map(tag => {
                if (tag.key === '') tag.key = 'Тег'
                return tag
            })
        ]

        try {
            let layerIds = []   // объекты 
            object.layers.forEach(l => layerIds.push(l.id))   // обновляем в store объекты слоев которым был привязан объект

            updateObject({
                data: { ...data, tags: newTags },
                layerIds
            })
        } catch (e) {
            setError("root.serverError", { type: 'submitError', message: e.message })
        }
    }

    if (preparePropsObjectStatus === 'loading' || objectStatus === 'loading') return <Loader />


    const arrTabs = [
        {
            label: 'Информация',
            value: <Information
                reactHookForm={{ watch, control, setValue }}
                object={object}
                preparePropsObject={preparePropsObject}
            />
        },
        {
            label: 'Теги',
            value: <Tags
                editor={preparePropsObject.editor}
                reactHookForm={{ watch, control, setValue, setError }}
            />
        },
        {
            label: 'Отображение',
            value: <Display reactHookForm={{ watch, control, setValue }} object={object} />
        },
        {
            label: 'История',
            value: <History />
        },
    ]


    // if (isGeoEditor) {
    //     arrTabs.push({
    //         label: 'Импорт/Экспорт',
    //         value: <>import body</>
    //     })
    // }

    return (
        <>
            <ModalContextProvider>
                <Stack component="form" method='POST' onSubmit={handleSubmit(onSubmit)} className='overflow'>
                    <Stack spacing={2} className='overflow' >
                        <BoxMarginLeftRight>
                            <Stack spacing={2}>
                                <Stack direction='row' spacing={2}>
                                    <IconBox
                                        iconId={object.iconId}
                                        src={ApiPaths.icon.FROM_OBJECT.replace(":objectId", object.id)}
                                        editor={false}
                                    />

                                    <Controller
                                        name='name'
                                        control={control}
                                        render={({ field }) => <TextField {...field}
                                            disabled={isGeoEditor ? false : true}
                                            fullWidth
                                            label='Название объекта'
                                            variant="outlined"
                                            margin="normal"
                                            style={{ marginTop: '5px' }}
                                        />}
                                    />
                                </Stack>

                                <Stack direction='row' style={{ justifyContent: 'space-between' }}>
                                    <Typography variant='font_12'>Создан: {object.creator} <br /> {getFormatDate(object.createdDateTime)}</Typography>
                                    <Typography variant='font_12'>Последние изменения: {object.editor} <br />{getFormatDate(object.changedDateTime)}</Typography>
                                </Stack>
                            </Stack>

                        </BoxMarginLeftRight>

                        <MyTabs arrTabs={arrTabs} var='var1' />

                        <BoxMarginLeftRight>
                            <Stack spacing={1} direction='row'>
                                <Button type='submit' variant='contained'>Сохранить</Button>
                                {/* <Button variant='outlined'>Определить адрес</Button> */}
                            </Stack>
                        </BoxMarginLeftRight>


                    </Stack>
                </Stack>
            </ModalContextProvider>

            {isSubmitting && <MaskProgress />}
        </>
    )
}

export default ObjectProperty