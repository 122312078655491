import { Link, Stack } from '@mui/material';
import React from 'react';
import HeadTitleBoxTextLayout from '../components/general/HeadToolBar/HeadTitleBoxTextLayout';


const HelpPage = () => {

    return (
        <>
            <HeadTitleBoxTextLayout title='Помощь' />

            <Stack spacing={3} sx={{ mt: '12px', mb: '12px' }}>
                <Link variant='black' href='https://gis76.ru/unsec/docs/user_manual.docx'>Руководство пользователя</Link>
                <Link variant='black' href='https://gis76.ru/unsec/docs/admin.docx'>Руководство администратора</Link>
                <Link variant='black' href='https://gis76.ru/unsec/docs/dev_manual.docx'>Руководство программиста</Link>
                <Link variant='black' href='https://gis76.ru/unsec/docs/memo.docx'>Инструкция редактора мемориальных объектов Ярославской области</Link>
                <Link variant='black' href='https://gis76.ru/new_geo/instruction1.mp4'>Видеоинструкция</Link>
            </Stack>

        </>
    );
};

export default HelpPage;

