import { Autocomplete, Backdrop, Box, CircularProgress, createFilterOptions, IconButton, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from "use-debounce";
import { useMatch } from 'react-router-dom';
import { uniqueId } from 'lodash';
import { ReactComponent as MenuIcon } from "../../../Icons/search/MenuIcon.svg";
import { ReactComponent as SearchIcon } from "../../../Icons/search/SearchIcon.svg";
import { ReactComponent as CloseIcon } from "../../../Icons/search/CloseIcon.svg";
import { ReactComponent as OptionsStreetIcon } from '../../../Icons/search/OptionsStreetIcon.svg';
import BreadCrumbs from '../../general/BreadCrumbs/BreadCrumbs';
import CategoryIcon from '../../general/Category/CategoryIcon';
import { RoutePaths } from '../../../routes';
import { CLOSE_HEIGHT_BOTTOMSHEET } from '../../../config';
import { useSearchLayerQuery, useSearchLocationQuery } from '../../../hooks/reactQuery/useSearch';
import { useBottomSheet } from '../../../hooks/useBottomSheet';
import useNavigate from '../../../hooks/useNavigateWithSearchParams'
import styles from './Search.module.scss'
import { useSearchContext } from '../../../hooks/useSearch';


const Search = ({toggleDrawer}) => {
    
    const {resetSearch, setResetSearch} = useSearchContext() // флаг, определяющий что нужно сбросить состояние Autocomplete

    const { bottomSheetRef } = useBottomSheet()

    const [term, setTerm] = useState('')
    const [key, setKey] = useState('autocompleteKey')
    const [backDrop, setBackDrop] = useState(false)
    const [inputValue, setInputValue] = useState('')

    let isSearchLocationPage = useMatch(RoutePaths.SEARCH_LOCATION)

    const navigate = useNavigate()
    const inputRef = useRef() // для работы с полем ввода textField для мобильных устройств
    const heightBottomSheetRef = useRef() // высота шторки, до нажатия на поиск - запоминаем

    let { data: searchLayersOptions, isFetching: layersIsLoading, isError: isLayerError } = useSearchLayerQuery(term)
    let { data: searchLocationOptions, isFetching: locationIsLoading, isError: isLocationError } = useSearchLocationQuery(term)


    // Обработка ошибок запросов
    if (isLayerError) searchLayersOptions = [{ inputValue, title: 'Поиск слоя - Server Error' }]
    if (isLocationError) searchLocationOptions = [{ inputValue, title: 'Поиск адреса - Server Error' }]

    const isLoading = layersIsLoading || locationIsLoading ? true : false
    const options = [...searchLayersOptions, ...searchLocationOptions]

    // const options = useMemo(() => {
    //     return [...searchLayersOptions, ...searchLocationOptions]
    // }, [searchLayersOptions, searchLocationOptions])

    const filter = createFilterOptions()
    const isEmptyLoadOptions = !isLoading && options.length === 0 && inputValue.length > 1

    // сбрасываю состояние - при клике очистить карту
    useEffect(() => {
        if (resetSearch) {
            setResetSearch(false)
            setKey(uniqueId('autocompleteKey'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetSearch])


    /* Управление отображением: изменение border-radius у поля ввода. Пока сделано на ссылках Ref. */
    const TextFieldRef = useRef(false)
    const setBorderField = (value) => {
        let pos = TextFieldRef.current.className.indexOf('backdrop_active')
        if (value) { // устанавливаем класс для border-radius поля ввода
            if (pos < 0)
                TextFieldRef.current.className = TextFieldRef.current.className + ' backdrop_active'
        } else { // удаляем класс для border-radius поля ввода
            if (pos >= 0)
                TextFieldRef.current.className = TextFieldRef.current.className.replace('backdrop_active', '')
        }
    }

    const debouncedLoadData = useDebouncedCallback((value) => {
        setTerm(value)
    }, 750)

    useEffect(() => {
        debouncedLoadData(inputValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue])



    useEffect(() => {
        if ((options.length > 0 || isEmptyLoadOptions) && backDrop) {
            setBorderField(true)
        } else {
            setBorderField(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, backDrop])

    // Styled 
    const AutocompleteSx = {
        '.MuiAutocomplete-input': {
            ml: '40px',
        },

        '& .MuiAutocomplete-inputRoot': {
            borderRadius: '8px',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.05)',
            height: '48px',
            padding: '0px',
            backgroundColor: '#FFFFFF',
            '&.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
                pl: '4px'
            },
        },
        '& .backdrop_active .MuiAutocomplete-inputRoot': {
            borderRadius: '8px 8px 0px 0px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1);',
        },
        '& .MuiTextField-root': {
            mt: 0,
            mb: 0,
            backgroundColor: 'rgba(0, 0, 0, 0)'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 0
        }
    }

    const searchControlSx = {
        width: '66px',
        position: 'absolute',
        right: '-9px',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }

    const menuControlSx = {
        width: '66px',
        position: 'absolute',
        left: '3px',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }

    return (
        <>
            <Backdrop open={backDrop} variant='mob' />
            <Autocomplete
                // open={true} // если включить, то options после отображения не убираются, что удобно при верстке
                sx={AutocompleteSx}
                key={key}
                freeSolo
                options={options}
                // clearIcon={<SvgIcon sx={{fontSize:'24px'}}><CloseIcon/>1</SvgIcon>}
                clearText='Очистить'
                filterOptions={(options, params) => {   // Если результат ответа пустой - то показываем сообщение 'Ничего не найдено'
                    const filtered = filter(options, params);
                    if (isEmptyLoadOptions) {
                        filtered.push({ inputValue, title: 'Ничего не найдено' })
                    }
                    return filtered
                }}
                onOpen={() => {             // срабатывает каждый раз когда кликаем на поле ввода, переводя фокус на него
                    setBackDrop(true)

                    // управление шторкой. Запоминаем значение высоты до ее сворачивания
                    heightBottomSheetRef.current = bottomSheetRef.current.height
                    bottomSheetRef.current.snapTo(CLOSE_HEIGHT_BOTTOMSHEET)
                }}
                onClose={(e, reason) => {   // срабатывает каждый раз когда убираем фокус с поля ввода
                    // управление шторкой. Возвращаем высоту что была при сворачивании при событиях blur and escape
                    if (reason === 'blur' || reason === 'escape') {
                        bottomSheetRef.current?.snapTo(heightBottomSheetRef.current)
                    }

                    setBackDrop(false)
                    setBorderField(false)
                }}
                onInputChange={(event, inputValue, reason) => {      // срабатывает когда вводим в поле input, а также - когда выбираем значение из списка автозаполнения
                    if (reason === 'input') {
                        setInputValue(inputValue)
                    }
                }}
                onChange={(e, newValue, reason) => {       // срабатывает когда выбираем значение из списка автозаполнения
                    if (reason === 'selectOption') {
                        if ('value' in newValue) { // location
                            let type = newValue.data.city_type && 'city'
                            if (newValue.data.street_type)
                                type = newValue.data.street_type && 'street'
                            else if (newValue.data.house)
                                type = newValue.data.house && 'house'

                            navigate(
                                RoutePaths.SEARCH_LOCATION
                                    .replace(':value', encodeURIComponent(newValue.value))
                                    .replace(':searchLatLon', [newValue.data.geo_lat, newValue.data.geo_lon].join(','))
                                    .replace(':type', type)
                            )
                        } else {                    // layer
                            let layerId = newValue.items.length === 1 ? newValue.items[0].id : newValue.items[newValue.items.length - 1].parent
                            navigate(RoutePaths.LAYER.replace(':layerId', layerId))
                        }
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputRef={inputRef}
                        ref={TextFieldRef}
                        autoFocus={false}
                        disabled={true}
                        fullWidth
                        margin='dense'
                        placeholder="Поиск слоев и адресов"
                        name="searchInput"
                        size="small"
                        className='form_field'
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <IconButton
                                    sx={menuControlSx}
                                    edge='start'
                                    onClick={(e) => {
                                        toggleDrawer()() // переключатель выпадающего слева меню
                                    }}
                                >
                                    <MenuIcon />
                                </IconButton>
                            ),
                            endAdornment: (
                                <>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {inputValue.trim() === ''
                                        ? <IconButton sx={searchControlSx}><SearchIcon /></IconButton>
                                        : <IconButton
                                            sx={searchControlSx}
                                            onClick={() => {
                                                params.InputProps.endAdornment.props.children[0].props.onClick()
                                                setInputValue('')
                                                if (isSearchLocationPage) {
                                                    navigate(RoutePaths.HOME)
                                                }
                                            }}
                                        >
                                            <SvgIcon sx={{fontSize:'24px'}}><CloseIcon/>1</SvgIcon>
                                        </IconButton>
                                    }
                                </>
                            )
                        }}
                    />
                )
                }
                renderOption={(props, option) => {
                    
                    if (!options.length > 0 && inputValue !== '') {
                        inputRef.current.setAttribute('inputmode', 'none')
                        inputRef.current.addEventListener('click', () => inputRef.current.setAttribute('inputmode', ''))
                    }

                    delete props['key']

                    if ('title' in option) {  // обрабатываем опцию 'Ничего не найдено'
                        return (
                            <Box component='li' {...props} onClick={() => { }} className={props.className} key={props.id}>
                                <Box >{option.title}</Box>
                            </Box>
                        )
                    }

                    if ('value' in option) {  // обрабатываем опцию location
                        return (
                            <Stack direction='row' spacing='16px' component='li' {...props} className={props.className + ' ' + styles.location} key={props.id}>
                                <OptionsStreetIcon />
                                <Typography variant='font_14_21' component='div'>{option.value}</Typography>
                            </Stack>
                        )
                    }
                    if ('items' in option) {    // обрабатываем опцию layer
                        option.items.map(item => {
                            return {
                                id: item.id,
                                name: item.name
                            }
                        })

                        return (
                            <Stack {...props} component='li' key={props.id} direction='row' spacing='6px' className={props.className + ' ' + styles.layer}>
                                <CategoryIcon name={option.items[0].name} />

                                <Stack className={styles.box} spacing='4px'>
                                    <Typography variant='font_14_21' component='div'>{option.items[option.items.length - 1].name}</Typography>
                                    <BreadCrumbs className={styles.breadcrumbs} arrBreadCrumbsData={option.items} toSearch />
                                </Stack>
                            </Stack>
                        )
                    }
                }}
                getOptionLabel={(option) => {
                    if (typeof option === "string") return option
                    else return inputValue
                }}
            />
        </>
    )
}

export default Search