import { Button, Stack } from "@mui/material"
import { useContext, useEffect } from "react"
import { useForm } from "react-hook-form"
import PropTypes from 'prop-types'
import MyTabs from "../../../MyTabs/MyTabs"
import { useModal } from "../../../../../hooks/useModal/useModal"
import Loader from "../../../Loader/Loader"
import { useSaveAreaMutation, usePrepareAreaDialogQuery } from "../../../../../hooks/reactQuery/useArea"
import { AreaContext } from './AreaContext'
import General from "./Tabs/General"
import Users from "./Tabs/Users"
import Roles from "./Tabs/Roles"
import { ModalWindowContext } from "../../../../../hooks/useModal/ModalWindow"

const Area = ({ areaId }) => {

    const { openAlert } = useModal()
    const { onClose } = useContext(ModalWindowContext)

    const { mutate: saveArea, status: saveStatus } = useSaveAreaMutation()
    const { data: prepareAreaDialog = {}, isLoading, error } = usePrepareAreaDialogQuery(areaId)

    const { area, command } = prepareAreaDialog //command здесь играет роль areId.

    const { control, handleSubmit, setValue, reset } = useForm({
        defaultValues: {
            'id': '',
            'name': '',
            'userIds': [],
            'roleIds': [],
        },
        mode: 'onSubmit'
    })


    const arrTabs = [
        {
            label: 'Общие',
            value: <General />
        },
        {
            label: 'Пользователи',
            value: <Users />
        },
        {
            label: 'Роли',
            value: <Roles />
        },
    ]

    // После загрузки данных - устанавливаем defaultValues через reset
    useEffect(() => {
        if (area) {
            reset({
                'id': area.id,
                'name': area.name,
                'userIds': area.userIds,
                'roleIds': area.roleIds,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    useEffect(() => {
        if (error) {
            onClose()
            openAlert({
                title: 'Ошибка при загрузке страницы',
                children: error.response.data,
                backdropOpacity: 0.2,
                width: 600
            })
        }
    }, [error, onClose, openAlert])

    const onSubmit = async (data) => {
        saveArea({ areaId: command, data })
    }

    useEffect(() => {
        if (saveStatus === 'success') {
            onClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveStatus])

    if (error) return null
    if (isLoading) return <Loader />

    return (
        <>
            <Stack component="form" method='POST'
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault() }}
                spacing={2} className='overflow'>

                <AreaContext.Provider value={{ prepareAreaDialog, control, setValue }}>
                    <MyTabs arrTabs={arrTabs} var='var1' />
                </AreaContext.Provider>

                <Stack spacing={1} direction='row' variant='bottom_mlr'>
                    <Button type='submit' variant='contained'>Сохранить</Button>
                </Stack>
            </Stack>
        </>
    )
}

Area.propTypes = {
    areaId: PropTypes.any.isRequired,
}

export default Area 