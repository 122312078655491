import React, { useRef, useState } from 'react';
import { Popover, Stack, SvgIcon, Tooltip, useTheme } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useSelector } from 'react-redux'
import Control from '../../cloneOutside/react-leaflet-custom-control'
// import { ReactComponent as EditIcon } from '../../../Icons/desktop/map/EditIcon.svg';
// import { ReactComponent as PrintIcon } from '../../../Icons/desktop/map/PrintIcon.svg';
import { ReactComponent as MapSettingsIcon } from '../../../Icons/desktop/map/MapSettingsIcon.svg';
import { ReactComponent as UserMenuIcon } from '../../../Icons/desktop/map/UserMenuIcon.svg';
// import { ReactComponent as UserAvatar } from '../../../Icons/desktop/UserAvatar.svg'
import UserMenu from '../UserMenu/UserMenu';
import SearchToolBar from '../Search/SearchToolBar';
import { pxToRem } from '../../Style/desktopTheme';
import { isAuthSelector } from '../../../store/selectors/selectors';
import ControlIconButton from './components/ControlIconButton';
import MapSettings from './components/MapSettings';
// import PrintTemplates from './components/PrintTemplates';



const TopControls = (props) => {
    
    let theme = useTheme()
    // console.log({theme});
    const controlRef = useRef()
    const [activeButton, setActiveButton] = useState()
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const [childPopover, setChildPopover] = useState()
    const isAuth = useSelector(isAuthSelector)

    const handleClose = () => {
        setAnchorEl(null)
        setActiveButton(false)
    }

    const openPopover = (event) => {
        // setAnchorEl(event.currentTarget)
        setAnchorEl(controlRef.current)
    }
    const handleClickMapSettings = (event) => {
        setChildPopover(<MapSettings />)
        openPopover(event)
        setActiveButton('mapSettings')
    }

    const handleClickUserMenu = (event) => {
        setChildPopover(<UserMenu popoverClose={handleClose} />)
        openPopover(event)
        setActiveButton('userMenu')
    }

    // const handleClickPrint = (event) => {
    //     setChildPopover(<PrintTemplates popoverClose={handleClose} />)
    //     openPopover(event)
    //     setActiveButton('print')
    // }

    return (
        <>
            <Control position="verticalrighttop" prepend={true}>
                <Stack direction='row' spacing={1.5} ref={controlRef} >

                    <Tooltip title='Настройки карты' arrow placement='bottom'>
                        <ControlIconButton onClick={handleClickMapSettings}>
                            <SvgIcon style={{ fontSize: pxToRem('18px') }} inheritViewBox={true}>
                                <MapSettingsIcon fill={activeButton === 'mapSettings' ? theme.palette.myGreen.main : theme.palette.myBlack.main} />
                            </SvgIcon>
                        </ControlIconButton>
                    </Tooltip>

                    <SearchToolBar/>

                    {/* <ControlIconButton><EditIcon /></ControlIconButton> */}
                    {/* <ControlIconButton onClick={handleClickPrint}><PrintIcon fill={activeButton === 'print' ? theme.palette.myGreen.main : theme.palette.myBlack.main} /></ControlIconButton> */}

                    <Tooltip title='Меню пользователя' arrow placement='bottom'>
                        <ControlIconButton onClick={handleClickUserMenu}>
                            {isAuth
                                ? <SvgIcon style={{ fontSize: pxToRem('24px') }} inheritViewBox>
                                    <PersonOutlineIcon style={{ fill: activeButton === 'userMenu' ? theme.palette.myGreen.main : theme.palette.myBlack.main }} />
                                </SvgIcon>
                                : <SvgIcon style={{ fontSize: pxToRem('18px') }} inheritViewBox><UserMenuIcon fill={activeButton === 'userMenu' ? theme.palette.myGreen.main : theme.palette.myBlack.main} /></SvgIcon>

                            }
                        </ControlIconButton>
                    </Tooltip>
                </Stack>
            </Control>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: -12,
                    horizontal: 'right',
                }}
                slotProps={{
                    paper: {
                        style: {
                            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px -1px 0px 0px rgba(0, 0, 0, 0.02)',
                            borderRadius: '8px',
                        }
                    }
                }}
            >
                {childPopover}
            </Popover>
        </>
    )
}

export default TopControls;