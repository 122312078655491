import { Box, Divider, Stack, Table, TableBody, TableCell, TableRow } from "@mui/material"
import { Fragment, useContext } from "react"
import { ApiPaths, ORIG_ICON_SIZE, ORIG_TREE_ICON_SIZE } from "../../../../../config"
import { useGalleryIconQuery } from "../../../../../hooks/reactQuery/useGallery"
import Loader from "../../../Loader/Loader"
import { GalleryContext } from "../../GalleryContext"



const IconItemInfo = ({ iconId }) => {

    const { params: { oldIcon } } = useContext(GalleryContext)
    const { data, isFetching } = useGalleryIconQuery(iconId)

    if (isFetching) return <Loader />
    return (
        <>
            <Stack variant='mtrbl' >
                <Divider variant='var1' />
                <Box
                    style={{ marginTop: '0px' }}
                    className={'overflow'}
                >
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Id icon</TableCell>
                                <TableCell>{iconId}</TableCell>
                            </TableRow>

                            {!oldIcon &&
                                <>
                                    {data.created &&
                                        <TableRow>
                                            <TableCell>Дата создания</TableCell>
                                            <TableCell>{data.created}</TableCell>
                                        </TableRow>
                                    }

                                    {(data.created_by && data.created_by.fullName) &&
                                        <TableRow>
                                            <TableCell>Создал</TableCell>
                                            <TableCell>{data.created_by.fullName}</TableCell>
                                        </TableRow>
                                    }
                                </>
                            }

                            <TableRow>
                                <TableCell>Иконка слоя</TableCell>
                                <TableCell>
                                    <img alt={ApiPaths.gallery.TREEICON.replace(":iconId", iconId)} src={ApiPaths.gallery.TREEICON.replace(":iconId", iconId)} />
                                </TableCell>
                            </TableRow>

                            {oldIcon
                                ? <TableRow>
                                    <TableCell>Иконка объекта</TableCell>
                                    <TableCell>
                                        <img alt={ApiPaths.gallery.OLD_ICON.replace(":iconId", iconId)} src={ApiPaths.gallery.OLD_ICON.replace(":iconId", iconId)} />
                                    </TableCell>
                                </TableRow>
                                : <>
                                    <TableRow>
                                        <TableCell>Пример иконок объекта</TableCell>
                                        <TableCell>
                                            <Stack spacing={1} direction='row' style={{ alignItems: 'center' }}>
                                                <img alt={ApiPaths.gallery.CIRCLE_ICON.replace(":iconId", iconId)} src={ApiPaths.gallery.CIRCLE_ICON.replace(":iconId", iconId)} />
                                                <img alt={ApiPaths.gallery.DROP_ICON.replace(":iconId", iconId)} src={ApiPaths.gallery.DROP_ICON.replace(":iconId", iconId)} />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>Оригиналы</TableCell>
                                        <TableCell>
                                            <Stack spacing={1} direction='row' style={{ alignItems: 'center' }}>
                                                <img width={ORIG_TREE_ICON_SIZE} height={ORIG_TREE_ICON_SIZE} alt={ApiPaths.gallery.ORIG_TREEICON.replace(":iconId", iconId)} src={ApiPaths.gallery.ORIG_TREEICON.replace(":iconId", iconId)} />
                                                <img width={ORIG_ICON_SIZE} height={ORIG_ICON_SIZE} alt={ApiPaths.gallery.ORIG_ICON.replace(":iconId", iconId)} src={ApiPaths.gallery.ORIG_ICON.replace(":iconId", iconId)} />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                </>
                            }

                            {(data.used_layers && data.used_layers.length > 0) &&
                                <TableRow>
                                    <TableCell>Используется в слоях</TableCell>
                                    <TableCell>
                                        {data.used_layers.map((l, index) => (<Fragment key={'used_layers-' + index}>- {l}<br /></Fragment>))}
                                    </TableCell>
                                </TableRow>
                            }

                            {(data.used_layers_from_object && data.used_layers_from_object.length > 0) &&
                                <TableRow>
                                    <TableCell>Используется у объектов в слоях</TableCell>
                                    <TableCell>
                                        {data.used_layers_from_object.map((l, index) => (<Fragment key={'used_layers_from_object-' + index}>- {l}<br /></Fragment>))}
                                    </TableCell>
                                </TableRow>
                            }


                        </TableBody>
                    </Table>
                </Box>
            </Stack>
        </>
    )
}

export default IconItemInfo