import { Box, IconButton, Stack, SvgIcon, Tooltip } from "@mui/material"
import { styled } from '@mui/material/styles'
import { useMatch } from "react-router-dom";
import { useSelector } from 'react-redux'
import { useContext, useEffect, useState } from "react";
import { ReactComponent as SmallFilterIcon } from '../../../Icons/desktop/bottomToolbar/small/FilterIcon.svg';
import { ReactComponent as SmallClearIcon } from '../../../Icons/desktop/bottomToolbar/small/ClearIcon.svg';
import { ReactComponent as SmallViewSelectedlayersIcon } from '../../../Icons/desktop/bottomToolbar/small/SelectedlayersIcon.svg';
import { ReactComponent as SmallHistoryIcon } from '../../../Icons/desktop/bottomToolbar/small/HistoryIcon.svg';

import { ReactComponent as BigFilterIcon } from '../../../Icons/desktop/bottomToolbar/big/FilterIcon.svg';
import { ReactComponent as BigClearIcon } from '../../../Icons/desktop/bottomToolbar/big/ClearIcon.svg';
import { ReactComponent as BigViewSelectedlayersIcon } from '../../../Icons/desktop/bottomToolbar/big/SelectedlayersIcon.svg';
import { ReactComponent as BigHistoryIcon } from '../../../Icons/desktop/bottomToolbar/big/HistoryIcon.svg';

import { useActions } from "../../../hooks/useActions";
import { RoutePaths } from "../../../routes";
import { layerRecentSelector, selectedLayerIdsSelector, viewSelectedLayersSelector } from "../../../store/selectors/selectors";
import { DrawerContext } from "../SidePanel/SideContextProvider";
import { setCookie } from "../../../service/function";
import useNavigate from '../../../hooks/useNavigateWithSearchParams'
import { pxToRem } from "../../Style/desktopTheme";
import { useClearMap } from "../../../hooks/useClearMap";


const ToolBarButtonBig = styled(IconButton)(
    ({ theme, active }) => {
        return ({
            width: pxToRem('64px'),
            minWidth: pxToRem('64px'),
            fill: active === 'true' ? theme.palette.myBlack.main : theme.palette.myBlack.light,
            color: active === 'true' ? theme.palette.myBlack.main : theme.palette.myBlack.light,
            cursor: active === 'true' ? 'pointer': 'default',
        })
    }
)

const ToolBarButtonSmall = styled(IconButton)(
    ({ theme, active }) => {
        return ({
            width: pxToRem('48px'),
            minWidth: pxToRem('48px'),
            minHeight: pxToRem('48px'),
            fill: active === 'true' ? theme.palette.myBlack.main : theme.palette.myBlack.light,
            color: active === 'true' ? theme.palette.myBlack.main : theme.palette.myBlack.light,
            cursor: active === 'true' ? 'pointer': 'default',
        })
    }
)

const SvgIconBig = ({ children }) => (
    <SvgIcon style={{ fontSize: pxToRem('32px') }}
        inheritViewBox={true}
    >{children}</SvgIcon>
)

const SvgIconSmall = ({ children }) => (
    <SvgIcon style={{ fontSize: pxToRem('24px') }} inheritViewBox={true} >{children}</SvgIcon>
)

const BottomToolBar = () => {

    let navigate = useNavigate();
    let isSearchLocationPage = useMatch(RoutePaths.SEARCH_LOCATION)

    const { mainDrawer, setMainDrawer, } = useContext(DrawerContext)
    const {  setViewSelectedLayers } = useActions()

    const recentLayers = useSelector(layerRecentSelector)
    const viewSelectedLayers = useSelector(viewSelectedLayersSelector)
    const isSelectedLayers = useSelector(selectedLayerIdsSelector).length > 0
    const isActiveClearIcon = isSelectedLayers || isSearchLocationPage

    const [size, setSize] = useState(mainDrawer ? true : false)

    const clearMap = useClearMap()

    const handleClickFilterObjects = () => {
        if (isSelectedLayers) {
            if (!mainDrawer) {
                setMainDrawer(true)
                setCookie('open_cat', true.toString())
            }
            navigate(RoutePaths.FILTER_OBJECTS)
        }
    }

    const handleClickHistoryLayer = () => {
        if (recentLayers.length > 0) {
            navigate(RoutePaths.HISTORY_LAYER)
        }
    }

    useEffect(() => {
        // setTimeout(() => setSize(mainDrawer ? true : false), mainDrawer ? 195 : 100)
        setSize(mainDrawer ? true : false)
    }, [mainDrawer])

    if (!size) {
        return (
            <Box sx={{ padding: pxToRem('12px') }}>
                <Stack spacing={1}>

                    <Tooltip title='Фильтр объектов' arrow placement='right'>
                        <ToolBarButtonSmall
                            onClick={handleClickFilterObjects}
                            active={isSelectedLayers ? 'true' : 'false'}
                        >
                            <SvgIconSmall><SmallFilterIcon /></SvgIconSmall>
                        </ToolBarButtonSmall>
                    </Tooltip>

                    <Tooltip title='Отобразить только выделенные слои' arrow placement='right'>
                        <ToolBarButtonSmall onClick={isSelectedLayers ? () => setViewSelectedLayers(!viewSelectedLayers) : null}
                            active={viewSelectedLayers ? 'true' : 'false'}>
                            <SvgIconSmall><SmallViewSelectedlayersIcon /></SvgIconSmall>
                        </ToolBarButtonSmall>
                    </Tooltip>

                    <ToolBarButtonSmall onClick={handleClickHistoryLayer} active={recentLayers.length > 0 ? 'true' : 'false'}>
                        <SvgIconSmall><SmallHistoryIcon /></SvgIconSmall>
                    </ToolBarButtonSmall>

                    <Tooltip title='Очистить карту' arrow placement='right'>
                        <ToolBarButtonSmall onClick={isActiveClearIcon ? clearMap : null} active={isActiveClearIcon ? 'true' : 'false'}>
                            <SvgIconSmall><SmallClearIcon /></SvgIconSmall>
                        </ToolBarButtonSmall>
                    </Tooltip>
                </Stack>
            </Box>
        )
    } else {
        return (
            <Box sx={{ pt: pxToRem('12px'), pb: pxToRem('12px'), }}>
                <Stack direction='row' spacing={4} style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <ToolBarButtonBig onClick={handleClickHistoryLayer} active={recentLayers.length > 0 ? 'true' : 'false'}>
                        <SvgIconBig><BigHistoryIcon /></SvgIconBig>
                    </ToolBarButtonBig>

                    <Tooltip title='Фильтр объектов' arrow>
                        <ToolBarButtonBig
                            onClick={handleClickFilterObjects}
                            active={isSelectedLayers ? 'true' : 'false'}
                        >
                            <SvgIconBig><BigFilterIcon /></SvgIconBig>
                        </ToolBarButtonBig>
                    </Tooltip>

                    <Tooltip title='Отобразить только выделенные слои' arrow>
                        <ToolBarButtonBig
                            onClick={isSelectedLayers ? () => setViewSelectedLayers(!viewSelectedLayers) : null}
                            active={viewSelectedLayers ? 'true' : 'false'}>
                            <SvgIconBig><BigViewSelectedlayersIcon /></SvgIconBig>
                        </ToolBarButtonBig>
                    </Tooltip>

                    <Tooltip title='Очистить карту' arrow>
                        <ToolBarButtonBig onClick={isActiveClearIcon ? clearMap : null} active={isActiveClearIcon ? 'true' : 'false'}>
                            <SvgIconBig><BigClearIcon /></SvgIconBig>
                        </ToolBarButtonBig>
                    </Tooltip>
                </Stack>
            </Box>
        )
    }
}

export default BottomToolBar