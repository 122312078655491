import { Box, Button, CircularProgress } from "@mui/material"


const LoadingButton = ({isLoading, children, ...props}) => {
    return (
        <Box sx={{
            position: 'relative',
            overflowY: 'hidden'
        }}>
            <Button
                disabled={isLoading}
                {...props}
            >
                {children}
                {isLoading &&
                    <CircularProgress
                        size={24}
                        sx={{
                            color: 'gray',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />}
            </Button>
        </Box>
    )
}

export default LoadingButton