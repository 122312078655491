import { Box, Button, Stack } from "@mui/material"
import { pxToRem } from "../components/Style/desktopTheme"
import HistoryLayer from "../components/general/HistoryLayer/HistoryLayer"
import { useActions } from "../hooks/useActions"
import useDeviceDetect from "../hooks/useDeviceDetect"
import Title from "../components/general/HeadToolBar/Title"
import Header from "../hoc/Header"
import { useNavigate } from "react-router-dom"
import { RoutePaths } from "../routes"
import MapSettings from "../components/mob/Map/MapSettings"
import MobTemplateHeaderToolbar from "../components/general/HeadToolBar/MobTemplateHeaderToolbar"

const wrapHeaderSx = {
    pr: pxToRem('16px'),
    pt: pxToRem('14px'),
    paddingBottom: pxToRem('16px'),
    borderBottom: '1px solid',
    borderColor: '#EFEFF1',
    backgroundColor: 'rgba(239, 239, 241, 0.50)',
}

const HeadHistoryTitleBox = () => {

    const { clearRecentNew, resetSelectedLayerIds } = useActions()
    const handleClearButton = () => {
        clearRecentNew()
        resetSelectedLayerIds()
    }
    return (
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={wrapHeaderSx}>
            <Stack justifyContent='center' alignItems='center'>
                <Box sx={{ ml: pxToRem('16px') }}><Title title='История' /></Box>

                <Box style={{ height: pxToRem('26px') }}></Box>
            </Stack>

            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="text" onClick={handleClearButton}>Очистить</Button>
            </Box>
        </Stack>
    )
}




const MobHistoryLayerPage = () => {
    const navigate = useNavigate()
    return (
        <>
            <Header>
                <MobTemplateHeaderToolbar title={{ name: 'Настройки карты' }} handleClose={() => navigate(RoutePaths.HOME)} />
            </Header>
            <MapSettings />
        </>
    )
}

const DesktopLayerPage = () => {
    return (
        <>
            <HeadHistoryTitleBox />
            <HistoryLayer />
        </>
    )
}


const HistoryLayerPage = () => {

    const { isMobile } = useDeviceDetect()

    return (
        isMobile
            ? <MobHistoryLayerPage />
            : <DesktopLayerPage />
    )
}

export default HistoryLayerPage