import { Button, Stack } from "@mui/material"
import { useContext, useState } from "react"
import { useModal } from "../../../../hooks/useModal/useModal"
import GalleryIcons from "../../Gallery/Tabs/Icons/GalleryIcons"
import BoxImg from "../BoxImg/BoxImg"
import useIconTimeStamp from "../../../../hooks/useIconTimeStamp"
import { BoxMarginLeftRight } from "../../StyledComponents/BoxMarginLeftRight"
import { ModalWindowContext } from "../../../../hooks/useModal/ModalWindow"


const ChoiceIconForm = ({ iconId, updateIconFn }) => {

    const [selectedIconId, setSelectedIconId] = useState(iconId)
    const { onClose } = useContext(ModalWindowContext)

    const props = { selectedIconId, setSelectedIconId }
    const handleClickSave = (iconId) => {
        if (updateIconFn) {
            updateIconFn(iconId)
        }
        onClose()
    }

    return (
        <>
            <GalleryIcons {...props} />
            <BoxMarginLeftRight>
                <Stack spacing={2} direction='row'>
                    <Button onClick={() => handleClickSave(selectedIconId)} variant='contained'>Сохранить</Button>
                    <Button onClick={() => handleClickSave(0)} variant='outlined'>Сбросить</Button>
                </Stack>
            </BoxMarginLeftRight>

        </>
    )
}

const IconBox = ({ iconId, editor, src, updateIconFn }) => {

    const { openWindow } = useModal()
    const timestamp = useIconTimeStamp(iconId)

    const handleClick = (e) => {
        if (!editor) return null;

        openWindow({
            title: 'Галерея иконок',
            children: <ChoiceIconForm iconId={iconId} updateIconFn={updateIconFn} />,
            backdropOpacity: 0.2,
            width: '800px'
        })
    }

    return (
        <BoxImg
            onClick={handleClick}
            src={src + timestamp}
            style={{
                marginTop: '6px',
                cursor: editor ? 'pointer' : 'default',
                opacity: editor ? 1 : 0.5,
            }}
        />
    )
}

export default IconBox