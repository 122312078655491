import { useQueryClient, useIsFetching } from 'react-query';
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux';
import { Box, Checkbox, FormControlLabel, Stack, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as CheckboxActive } from "../../../Icons/desktop/form/CheckboxActive.svg";
import { ReactComponent as ServiceLayerIcon } from "../../../Icons/desktop/layer/ServiceLayerIcon.svg";
import { ReactComponent as OnLayerIcon } from "../../../Icons/desktop/layer/OnLayerIcon.svg";
import MyAlert from '../../general/Info/MyAlert';
import 'react-loading-skeleton/dist/skeleton.css';
import { RoutePaths } from '../../../routes';
import { selectedLayerIdsSelector } from '../../../store/selectors/selectors';
import Link from '../../../hoc/LinkWithSearchParams'
import { pxToRem } from '../../Style/desktopTheme';
import LoadingCheckBox from '../ForForms/LoadingCheckBox';
import LayerMenu from './LayerMenu'


const ItemLayer = ({ metadata, isOnLayer, handleSelectItemLayer }) => {

    const queryClient = useQueryClient()
    const selectedLayerIds = useSelector(selectedLayerIdsSelector)

    const isFetching = useIsFetching({
        queryKey: ['objectsToLayer', String(metadata.id)]
    })

    const queryState = queryClient.getQueryState({
        queryKey: ['objectsToLayer', String(metadata.id)]
    })

    let isLoading = isFetching === 0 ? false : true

    let selected = metadata.typeId === 1
        ? Boolean(queryState?.data) && selectedLayerIds.includes(metadata.id)
        : selectedLayerIds.includes(metadata.id)

    return (
        <>
            <Box sx={{ pt: pxToRem('4px'), pb: pxToRem('4px'), pl: pxToRem('8px'), pr: pxToRem('8px') }}>

                {metadata.isService ?
                    <>
                        <Stack direction='row' justifyContent='space-between' alignItems='start'>

                            <Stack direction='row'>
                                <Link to={RoutePaths.LAYER.replace(':layerId', metadata.id)} >
                                    <Stack direction='row'>
                                        <SvgIcon style={{ fontSize: pxToRem('24px'), margin: pxToRem('8px') }} inheritViewBox={true}>
                                            <ServiceLayerIcon />
                                        </SvgIcon>
                                        <Typography variant='font_24' sx={{ pt: pxToRem('8px') }}>{metadata.name}</Typography>
                                    </Stack>
                                </Link>
                            </Stack>

                            <Stack direction='row' alignItems='center' spacing={0.5} >
                                {isOnLayer
                                    && <Box sx={{ pt: pxToRem('8px') }}>
                                        <SvgIcon style={{ fontSize: pxToRem('20px') }} inheritViewBox={true}>
                                            <OnLayerIcon />
                                        </SvgIcon>
                                    </Box>
                                }

                                <Box sx={{ pt: pxToRem('4px') }}>
                                    <LayerMenu
                                        layerId={metadata.id}
                                        name={metadata.name}
                                        parentId={metadata.parentId}
                                        isService={metadata.isService}
                                        permissions={metadata.permissions}
                                        typeId={metadata.typeId}
                                        heatmap={metadata.heatmap}
                                        haveObjects={metadata.haveObjects} />
                                </Box>
                            </Stack>
                        </Stack>
                    </>
                    :
                    <>
                        <Stack direction='row' justifyContent='space-between' alignItems='start'>
                            {isLoading
                                ? <>
                                    <Stack direction='row'>
                                        <LoadingCheckBox />
                                        <Typography variant='font_24' sx={{ pt: pxToRem('8px') }}>{metadata.name}</Typography>
                                    </Stack>

                                </>
                                : <FormControlLabel
                                    sx={{ ml: '0px' }}
                                    control={
                                        <Checkbox
                                            checkedIcon={<SvgIcon inheritViewBox={true}><CheckboxActive /></SvgIcon>}
                                            onChange={(e) => handleSelectItemLayer(metadata.id, isLoading)} checked={selected}
                                        />
                                    }
                                    label={metadata.name}
                                />
                            }


                            <Box sx={{ pt: pxToRem('4px') }}>
                                {/* <ButtonMenu
                                    layerId={metadata.id}
                                    name={metadata.name}
                                    parentId={metadata.parentId}
                                    isService={metadata.isService}
                                    permissions={metadata.permissions}
                                    typeId={metadata.typeId}
                                    heatmap={metadata.heatmap}
                                    haveObjects={metadata.haveObjects}
                                /> */}
                                <LayerMenu
                                    layerId={metadata.id}
                                    name={metadata.name}
                                    parentId={metadata.parentId}
                                    isService={metadata.isService}
                                    permissions={metadata.permissions}
                                    typeId={metadata.typeId}
                                    heatmap={metadata.heatmap}
                                    haveObjects={metadata.haveObjects} />
                            </Box>

                        </Stack>


                    </>
                }

            </Box>

            {queryState?.error &&
                <Box>
                    <MyAlert severity='error' title='Ошибка' message='Объекты данного слоя не были загружены!' />
                </Box>
            }

        </>
    )
}

ItemLayer.propTypes = {
    metadata: PropTypes.object.isRequired,
    isOnLayer: PropTypes.bool.isRequired,
    handleSelectItemLayer: PropTypes.func.isRequired,
}

export default ItemLayer