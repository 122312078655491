import { Box, Skeleton, Typography } from '@mui/material';
import React, { useState } from 'react';
import TextClamp from 'react-string-clamp';
import PropTypes from 'prop-types'
import useDeviceDetect from '../../../hooks/useDeviceDetect';


const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

const Title = ({ isLoading, title = '', lines = 3 }) => {
    const [expanded, setExpended] = useState(false)
    const {isMobile} = useDeviceDetect()

    return (
        <Box>
            <Typography
                sx={{ cursor: 'default' }}
                component='h1' variant={isMobile ? 'font_20_28': 'font_22_28'} onClick={() => { setExpended(!expanded) }} style={{
                    textAlign: 'left',
                }}>

                {isLoading
                    ? <Skeleton width={150} />
                    : !expanded
                        ? <TextClamp
                            text ={Capitalize(title)}
                            lines={lines}
                            ellipsis='...'
                            splitter=' '
                            punctuation={true}
                        />
                        : Capitalize(title)
                }

            </Typography>
        </Box>
    )
}

export default Title

Title.propTypes = {
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    lines: PropTypes.number,
    className: PropTypes.any,
    style: PropTypes.any,
}