import { Box, Stack } from '@mui/material';
import React from 'react';
// import LoadingIcon from '../../../Icons/LoadingIcon.gif';
import LoadingIcon from '../../../Icons/LoadingIcon.gif';
import { pxToRem } from '../../Style/desktopTheme';

const LoadingCheckBox = () => {
    return <Box>
        <Stack alignItems='center' justifyContent='center' sx={{ padding: pxToRem('11px')}}>
            <img style={{ width: pxToRem('18px'), height: pxToRem('18px') }} align='center' src={LoadingIcon} alt='loading' />
        </Stack>
    </Box>
}

// const LoadingCheckBox = () => {

//     return <Box sx={{ width: '24px', height: '24px' }}><img align='center' width='18px' src={LoadingIcon} alt='loading' /></Box>;
// };

export default LoadingCheckBox;