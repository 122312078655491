import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import React, { useState, useContext } from "react"
import { ImportExportDialogContext } from "../ImportExport";
import api from "../../../../service/http";
import FormContainer from "../../ForForms/FormContainer";
import LoadingButton from "../../ForForms/LoadingButton";


const ExportTab = ({ layerId }) => {

    const dialogContext = useContext(ImportExportDialogContext);

    const [gcs, setGcs] = useState(dialogContext.gcs);
    const [format, setFormat] = useState(dialogContext.format);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    const handleExport = async () => {
        try {
            setIsLoading(true)
            const resp = await api.get(`/integration/export/layer/?id=${layerId}&format=${format}&srs=${gcs}`);
            const json = resp.data;
            if (json.fileName) {
                const respData = await api.get(`/integration/export/layer/${json.fileName}`, { responseType: 'blob' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(respData.data);
                link.setAttribute('download', json.fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setIsLoading(false)
            } else {
                setMessage("Ошибка экспорта:" + resp.statusText);
                setIsLoading(false)
            }
        } catch (e) {
            setMessage("Ошибка экспорта:" + e.message);
            setIsLoading(false)
        }

    }

    const handleChangeGCS = (v) => {
        dialogContext.gcs = v;
        setGcs(v);
    }
    const handleChangeFormat = (v) => {
        dialogContext.format = v;
        setFormat(v);
    }

    return (
        <FormContainer
            action={
                <LoadingButton
                    variant='contained'
                    onClick={handleExport}
                    isLoading={isLoading}
                >Экспортировать</LoadingButton>
            }
            component="form"
        >
            <FormControl fullWidth>
                <InputLabel id="coordinate-system-label">Система координат</InputLabel>
                <Select
                    labelId="coordinate-system-label"
                    id="coordinate-system-select"
                    value={gcs}
                    label="Система координат"
                    onChange={(ev) => handleChangeGCS(ev.target.value)}
                >
                    <MenuItem value="WGS84">World Geodetic System 1984</MenuItem>
                    <MenuItem value="msk_qone1">МСК 76 Зона 1</MenuItem>
                    <MenuItem value="msk_zone2">МСК 76 Зона 2</MenuItem>
                    <MenuItem value="g_gav_yam">УСК Гаврилов-Ям</MenuItem>
                    <MenuItem value="pulkovo42">Пулково 1942</MenuItem>
                    <MenuItem value="pulkovo42_true">Пулково 1942 Лесничества ЯО</MenuItem>
                    <MenuItem value="pulkovo42_4284">Пулково 1942 Адресные планы</MenuItem>
                    <MenuItem value="g_lubim">УСК Любим</MenuItem>
                    <MenuItem value="g_poshehon">УСК Пошехонье</MenuItem>
                    <MenuItem value="g_yglich">УСК Углич</MenuItem>
                    <MenuItem value="g_Pereslavl">УСК Переславль-Залесский</MenuItem>
                    <MenuItem value="g_Prechst">УСК Пречистое</MenuItem>
                    <MenuItem value="g_rostov">УСК Ростов</MenuItem>
                    <MenuItem value="g_yar">УСК Ярославль</MenuItem>
                    <MenuItem value="g_ribinsk">УСК Рыбинск</MenuItem>
                </Select>
            </FormControl>

            <FormControl fullWidth>
                <InputLabel id="format-label">Формат</InputLabel>
                <Select
                    labelId="format-label"
                    id="format-select"
                    value={format}
                    label="Формат"
                    onChange={(ev) => handleChangeFormat(ev.target.value)}
                >
                    <MenuItem value="MIFMID">Экспорт в .MIF/.MID</MenuItem>
                    <MenuItem value="XLS">Экспорт в .XLS</MenuItem>
                    <MenuItem value="CSV">Экспорт в .CSV</MenuItem>
                    <MenuItem value="CSVOpenData">Экспорт в .CSV (открытые данные)</MenuItem>
                    <MenuItem value="TXT">Экспорт в .TXT</MenuItem>
                    <MenuItem value="KML">Экспорт в .KML</MenuItem>
                </Select>
            </FormControl>

            {message && <p>{message}</p>}
        </FormContainer>
    )
}

export default ExportTab;