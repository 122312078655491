import { Stack, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import ZoomControl from "../../general/Map/Control/ZoomControl"
import Control from '../../cloneOutside/react-leaflet-custom-control'
import { editOnMapLayerSelector } from '../../../store/selectors/selectors'
import Ruler from "./components/Ruler";



const RightControls = () => {
    const editOnMapLayer = useSelector(editOnMapLayerSelector)

    return (
        <>
            <Control position={editOnMapLayer ? 'verticalrighttop' : 'verticalrightcenter'} >
                <Stack spacing={2}>
                    <Tooltip title='Линейка' arrow placement='left'>
                        <span>
                            <Ruler />
                        </span>
                    </Tooltip>

                    <ZoomControl />
                </Stack>
            </Control>
        </>
    )
}

export default RightControls