import { Box, Stack, SvgIcon } from '@mui/material';
import React from 'react';
import GeoPortalLogo from "../../../Icons/desktop/logo.png";
import { ReactComponent as LogoIcon } from '../../../Icons/desktop/LogoIcon.svg'
import { RoutePaths } from '../../../routes';
import Link from '../../../hoc/LinkWithSearchParams'
import { pxToRem } from '../../Style/desktopTheme';


const BoxLogo = () => {

    return (
        <Link to={RoutePaths.HOME}>
            <Stack direction='row' spacing={1.5} sx={{ mt: pxToRem('15px') }}>
                <img
                    style={{
                        width: pxToRem('25px'),
                        height: pxToRem('32px')
                    }}
                    src={GeoPortalLogo} alt="Геопортал" />

                <Box sx={{ pt: pxToRem('2px') }}>
                    <SvgIcon
                        style={{ width: pxToRem('148px') }}
                        inheritViewBox={true}
                    >
                        <LogoIcon />
                    </SvgIcon>
                </Box>
            </Stack>
        </Link >
    )
}

export default BoxLogo;