import { Box, Stack, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import styles from './MyTabs.module.scss'

const CustomTabPanel = ({ children, value, index, ...other }) => {
    if (value === index) {
        return (
            <Stack
                id={`tabpanel-${index}`}
                className='overflow'
                style={{
                    margin: 0,
                }}
                {...other}
            >
                {children}
            </Stack>
        )
    }
    return null
}

// setTabsRef используется для программного перехода к нужной вкладке
const MyTabs = ({ arrTabs, setTabsRef, ...props }) => {

    const [tabs, setTabs] = useState(0)
    
    if(setTabsRef) {
        setTabsRef.current = setTabs
    }
     
    const handleChangeTabs = (e, newValue) => {
        setTabs(newValue)
    }

    return (
        <>
            <Box className={styles.wrap_tabs} >
                <Tabs onChange={handleChangeTabs} value={tabs} {...props}>
                    {arrTabs.map((itemTab, index) => {
                        const id = 'tab-' + index
                        return <Tab label={itemTab.label} id={id} key={id} style={{ textTransform: 'none' }} />
                    })}
                </Tabs>
            </Box>

            {arrTabs.map((itemTab, index) => {
                const key = 'tab-' + index
                return <CustomTabPanel value={tabs} index={index} key={key} >
                    {itemTab.value}
                </CustomTabPanel>
            })}
        </>
    )
}

export default MyTabs