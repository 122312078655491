import { Button, Divider, Stack } from "@mui/material"
import PropTypes from 'prop-types'
import TreeListLayersChecked from "../TreeListLayers/TreeListLayersChecked"
import { useForm } from "react-hook-form"
import { BoxMarginLeftRight } from "../StyledComponents/BoxMarginLeftRight"
import { useCopyPropsToLayerMutation } from "../../../hooks/reactQuery/useLayer"


const CopyPropsToLayer = ({ layerId }) => {

    const { mutate: copyPropsToLayer } = useCopyPropsToLayerMutation()
    const { handleSubmit, control, watch } = useForm({
        defaultValues: {
            layerId,
            targetIds: []
        }
    })

    const onSubmit = (data) => {
        copyPropsToLayer({
            layerId: data.layerId,
            targetIds: data.targetIds.join(',')
        })
    }

    return (
        <Stack component='form' method='POST' onSubmit={handleSubmit(onSubmit)} className="overflow" spacing={2}>
            <TreeListLayersChecked {...{
                disabledLayerIds: [layerId],
                control,
            }} />
            <Divider variant='var1' />
            <BoxMarginLeftRight><Button type='submit' variant='contained'
                disabled={watch('targetIds').length === 0}
            >Скопировать</Button></BoxMarginLeftRight>
        </Stack>
    )
}

export default CopyPropsToLayer

CopyPropsToLayer.propTypes = {
    layerId: PropTypes.number.isRequired
}