import React, { createContext, useCallback, useContext, useState } from 'react'
import ModalAlert from './ModalAlert'
import ModalConfirm from './ModalConfirm'
import ModalWindow from './ModalWindow'


const ModalContext = createContext({})

const useDefaultModalLogic = () => {
    const [visible, setVisible] = useState(false);
    const [props, setProps] = useState()

    const openModal = useCallback((props) => {
        setProps(props)
        setVisible(true)
    }, [])

    const closeModal = useCallback(() => {
        setProps(undefined)
        setVisible(false)
    }, [])

    return {
        visible,
        props,
        openModal,
        closeModal,
    }
}

export const useModal = () => useContext(ModalContext)

export const ModalContextProvider = ({ children }) => {

    const {
        visible: alertVisible,
        props: alertProps,
        openModal: openAlert,
        closeModal: closeAlert,
    } = useDefaultModalLogic()

    const {
        visible: confirmVisible,
        props: confirmProps,
        openModal: openConfirm,
        closeModal: closeConfirm,
    } = useDefaultModalLogic()

    const {
        visible: windowVisible,
        props: windowProps,
        openModal: openWindow,
        closeModal: closeWindow,
    } = useDefaultModalLogic()

    const modalContextValue = {
        openAlert,      // alert
        openConfirm,    // confirm
        openWindow,     // alert modal empty
    }

    return (
        <ModalContext.Provider value={modalContextValue}>
            {alertProps && (
                <ModalAlert {...alertProps} visible={alertVisible}
                    onClose={() => {
                        alertProps?.onClose?.()
                        closeAlert()
                    }}
                />
            )}

            {confirmProps && (
                <ModalConfirm {...confirmProps} visible={confirmVisible}
                    onClose={() => {
                        confirmProps?.onClose?.()
                        closeConfirm()
                    }}
                />
            )}

            {windowProps && (
                <ModalWindow {...windowProps} visible={windowVisible}
                    onClose={(event, reason) => {
                        if (windowProps.isCloseBackdropClick && reason === 'backdropClick') {
                            return null
                        }
                        closeWindow(event, reason)
                    }}
                />
            )}

            {children}
        </ModalContext.Provider>
    )
}