import Menu from '@mui/material/Menu';
import React, { useContext, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Box, Button } from '@mui/material';
import { RoutePaths } from '../../../routes';
import { MobBottomSheetContext } from '../BottomSheet/MobBottomSheet';
import { useLayersHierarhyQuery } from '../../../hooks/reactQuery/useLayer';
import { useParams } from 'react-router-dom';
import HeadToolBarLayers from '../../general/HeadToolBar/HeadToolBarLayers';
import Property from './Property';
import Feedback from '../../general/Feedback/Feedback';
import useNavigate from '../../../hooks/useNavigateWithSearchParams'
import MobTemplateHeaderToolbar from '../../general/HeadToolBar/MobTemplateHeaderToolbar';


const LayerMenu = ({ children }) => {

    const { setHeader, setBody } = useContext(MobBottomSheetContext)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const navigate = useNavigate()
    const layerId = Number(useParams().layerId)
    const { data: layerHierarhy, isLoading, error } = useLayersHierarhyQuery({layerId})


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const propList = [
        {
            name: 'Свойства',
            callback: () => {
                handleClose()
                setBody(<Property layerId={layerId} />)
                setHeader(
                    <MobTemplateHeaderToolbar
                        title={{name: 'Свойства'}}
                        handleGoBack={() => {
                            setHeader(<HeadToolBarLayers />)
                            setBody(false)
                        }}
                        handleClose={() => {
                            navigate(RoutePaths.HOME)
                            setBody(false)
                        }}
                        breadcrumbs={{
                            isLoading,
                            error,
                            layerHierarhy
                        }}
                    />
                )
            }
        },
        {
            name: 'Обратная связь',
            callback: () => {
                handleClose()
                setBody(<Feedback layerId={layerId} />)
                setHeader(
                    <MobTemplateHeaderToolbar
                        title={{name:'Обратная связь'}}
                        handleGoBack={() => {
                            setHeader(<HeadToolBarLayers />)
                            setBody(false)
                        }}
                        handleClose={() => {
                            navigate(RoutePaths.HOME)
                            setBody(false)
                        }}
                        breadcrumbs={{
                            isLoading,
                            error,
                            layerHierarhy
                        }}
                    />
                )
            }
        },
    ]

    return (
        <Box>
            <Button variant='icon'
                sx={{ mt: '4px' }}
                onClick={handleClick}>
                {children}
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                autoFocus={true}
            >
                {propList.map((itemProp => (
                    <MenuItem key={itemProp.name}
                        onClick={(e) => itemProp.callback(e)}
                        disabled={itemProp.disabled}
                    >
                        {itemProp.name}
                    </MenuItem>
                )))}
            </Menu>
        </Box>
    );
}

export default LayerMenu;