const restoreRecentLayers = () => {
    try {
        let layers = JSON.parse(localStorage.getItem("recent"))
        if (layers instanceof Array) {
            // очищаем старые recent, т.к. у них другой формат данных
            if(layers[0] && layers[0].category) {
                localStorage.setItem("recent", JSON.stringify([]))
                return []
            }
            return layers
        }
    } catch (e) {
        console.log(e)
    }
    return []
}

const saveRecentLayers = (recentLayers) => {
    localStorage.setItem("recent", JSON.stringify(recentLayers));
}


const initialState = restoreRecentLayers()

export const ADD_RECENT = 'ADD_RECENT';
export const CLEAR_RECENT = 'CLEAR_RECENT';

const addRecent = (state, layer) => {
    let s = [
        ...state.filter(l => l.id !== layer.id),
        layer
    ]
    saveRecentLayers(s);
    return s
}

const clearRecent = () => {
    let s = []
    saveRecentLayers(s)
    return s
}

export default function LayerRecentReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_RECENT:
            return addRecent(state, action.payload)
        case CLEAR_RECENT:
            return clearRecent()
        default:
            return state
    }
}
