import React, { useContext, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, InputLabel, Link, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { LayerPropertyContext } from '../../LayerPropertyContext';
import { replaceNullValuesFromObject } from '../../../../../service/function';
import HeadBoxForm from '../../components/HeadBoxForm';
import PictureField from '../../../ForForms/PictureField';
import MaskProgress from '../../../../general/Info/MaskProgress';
import { useUpdateIconLayerMutation, useUpdateIconShapeLayerMutation, useUpdateLayerMutation, useUpdateTreeIconLayerMutation } from '../../../../../hooks/reactQuery/useLayer';
import ColorTextField from '../../../ForForms/ColorTextField';
import IconBox from '../../../ForForms/IconBox/IconBox';
import { ApiPaths } from '../../../../../config';
import { layerPropVisiblityTypeId, sortedLayerType } from './fieldData';
import FormContainer from '../../../ForForms/FormContainer';



const PropEdit = () => {

    const { mutate: updateTreeIconLayer } = useUpdateTreeIconLayerMutation()
    const { mutate: updateIconLayer } = useUpdateIconLayerMutation()
    const { mutate: updateIconShape } = useUpdateIconShapeLayerMutation()

    const { propsLayer, layer, templates, formStateRef } = useContext(LayerPropertyContext)

    const { mutate: updateLayer } = useUpdateLayerMutation()

    const defaultValues = () => {
        let result = {
            id: layer.id,
            name: layer.name,
            parentId: layer.parentId,
            treeiconId: layer.treeiconId,
            isService: layer.isService,
            position: layer.position,
            typeId: layer.typeId,
        }
        let propVisible = [...new Set([].concat(...Object.values(layerPropVisiblityTypeId)))]

        propVisible.forEach(p => {
            if (p === 'feedbackByObject') {
                result[p] = Boolean(layer[p])
            }

            else {
                result[p] = layer[p]
            }
        })
        if (!layer.iconId || layer.iconId === 0) {
            result.iconShape = 1
        }
        return replaceNullValuesFromObject(result)
    }

    const { control, handleSubmit, watch, reset, register, setValue, getValues, formState: { isSubmitting } } = useForm({
        defaultValues: formStateRef.current['props'] ? formStateRef.current['props'] : defaultValues(),
        mode: 'onSubmit'
    })

    // console.log(defaultValues());

    const onSubmit = async (data) => {
        // Backend хочет видеть значения полей checkbox - как 'on' вместо true. Хотя сам отдает значения в Boolean формате. Поэтому заменяем все true на 'on'
        for (const key in data) {
            if (typeof data[key] === 'boolean' && data[key] === true) {
                data[key] = 'on'
            }
        }
        updateLayer(data)
    }

    // функция, определяющая отображать или нет поле в зависимости от выбранного typeId
    const isVisibleField = (name) => {
        return layerPropVisiblityTypeId[watch('typeId')].includes(name)
    }

    // сохраняем значения полей формы при размонтировании компонента (при переходе на другую вкладку) 
    // Установку при монтировании - см. defaultValues
    useEffect(() => {
        return () => {
            formStateRef.current = {
                ...formStateRef.current,
                'props': getValues(),
            }
        }
    }, [formStateRef, getValues])


    // прослушиваем изменения поля iconShape, и при изменении вызываем updateIconShape
    // + пропускаем начальный рендеринг
    const isInitialMount = useRef(true);
    const watchIconShape = watch('iconShape')
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (layer.iconId > 0) {
                updateIconShape({ layerId: layer.id, iconShape: watchIconShape, iconId: layer.iconId })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchIconShape])

    // при изменении layer - переустанавливаю значения полей (в конкретном случае актуально для iconId), но на всякий случай для всех полей
    useEffect(() => {
        reset(defaultValues())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layer, reset])

    return (
        <>
            <HeadBoxForm {...{
                layer,
                countObjects: propsLayer.spatialDataWeight?.count,
                control,
                updateIconFn: iconId => updateTreeIconLayer({ layerId: layer.id, iconId })
            }} /> 
 
            <FormContainer
                action={<Button type='submit' variant='contained'>Сохранить</Button>}
                component="form"
                method='POST'
                onSubmit={handleSubmit(onSubmit)}
            >
                <Stack direction='row'><Typography variant='font_24' component={FormLabel}>Идентификатор слоя:&nbsp;</Typography>#{layer.id}</Stack>

                <Controller
                    name='isService'
                    control={control}
                    render={({ field }) => <Box><FormControlLabel control={<Checkbox {...field} checked={field.value} />} label='Сервисный слой' /></Box>}
                />

                <Controller
                    name='position'
                    control={control}
                    render={({ field }) => <TextField {...field}
                        type='number'
                        label='Номер для сортировки'
                    />}
                />

                <Controller
                    name='typeId'
                    control={control}
                    render={({ field }) => (
                        <FormControl fullWidth>
                            <InputLabel>Тип слоя</InputLabel>
                            <Select {...field} label='Тип слоя'>
                                {sortedLayerType.map(item => (
                                    <MenuItem key={item[1]} value={item[0]}>{item[1]}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                />

                {isVisibleField('wsdl') &&
                    <Stack direction='row'><Typography variant='font_24' component={FormLabel}>Веб сервис:&nbsp;</Typography><Link href="ws?wsdl" target="_blank">WSDL</Link></Stack>
                }

                {isVisibleField('viewByObject') &&
                    <Controller
                        name='viewByObject'
                        control={control}
                        render={({ field }) => <Box><FormControlLabel control={<Checkbox {...field} checked={field.value} />} label='Отображение по объектам' /></Box>}
                    />
                }

                {isVisibleField('feedbackByObject') &&
                    <Controller
                        name='feedbackByObject'
                        control={control}
                        render={({ field }) => <Box><FormControlLabel control={<Checkbox {...field} checked={field.value} />} label='Обратная связь с автором объектов' /></Box>}
                    />
                }

                {(isVisibleField('iconBgColor') && layer.parentId === null) &&
                    <Controller
                        name='iconBgColor'
                        control={control}
                        render={({ field }) => <ColorTextField {...field} label='Цвет фона иконки Категории' setValue={setValue} />}
                    />
                }

                {(isVisibleField('iconColor') && layer.parentId === null) &&
                    <Controller
                        name='iconColor'
                        control={control}
                        render={({ field }) => <ColorTextField {...field} label='Цвет обводки иконок слоя и объектов' setValue={setValue} />}
                    />
                }

                {(isVisibleField('iconId') && layer.parentId) &&
                    <Stack direction='row' alignItems='center'>
                        <Typography variant='font_24' component={FormLabel}>Иконка объекта точка:&nbsp;</Typography>
                        <IconBox
                            iconId={layer.iconId}
                            src={ApiPaths.icon.FROM_LAYER.replace(":layerId", layer.id)}
                            editor={true}
                            updateIconFn={(iconId) => updateIconLayer({ layerId: layer.id, iconId })}
                        />
                    </Stack>
                }

                {(isVisibleField('iconShape') && layer.parentId) &&
                    <Controller
                        name='iconShape'
                        control={control}
                        render={({ field }) => (
                            <FormControl fullWidth disabled={layer.iconId > 0 ? false : true} >
                                <InputLabel>Тип иконки объекта точка</InputLabel>
                                <Select {...field}
                                    label='Тип иконки объекта точка'
                                    variant="outlined"
                                >
                                    <MenuItem key='circle' value={0}>Круг</MenuItem>
                                    <MenuItem key='drop' value={1}>Капля</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    />
                }

                {isVisibleField('lineColor') &&
                    <Controller
                        name='lineColor'
                        control={control}
                        render={({ field }) => <ColorTextField {...field} label='Цвет границ' setValue={setValue} />}
                    />
                }

                {isVisibleField('lineWeight') &&
                    <Controller
                        name='lineWeight'
                        control={control}
                        render={({ field }) => <TextField {...field}
                            type='number'
                            label='Толщина линии (px)'
                        />}
                    />
                }

                {isVisibleField('fillColor') &&
                    <Controller
                        name='fillColor'
                        control={control}
                        render={({ field }) => <ColorTextField {...field} label='Цвет заливки' setValue={setValue} />}
                    />
                }

                {(isVisibleField('fillImageWidth') && isVisibleField('fillImageHeight')) &&
                    <Stack direction='row' spacing={2}>
                        <Controller
                            name='fillImageWidth'
                            control={control}
                            render={({ field }) => <TextField {...field}
                                type='number'
                                label='Ширина изображения (px)'
                                fullWidth={false}
                            />}
                        />

                        <Controller
                            name='fillImageHeight'
                            control={control}
                            render={({ field }) => <TextField {...field}
                                type='number'
                                label='Высота изображения (px)'
                                fullWidth={false}
                            />}
                        />
                    </Stack>
                }

                {isVisibleField('fillOpacity') &&
                    <Controller
                        name='fillOpacity'
                        control={control}
                        render={({ field }) => <TextField {...field}
                            type='number'
                            label='Прозрачность заливки (от 0% до 100%)'
                        />}
                    />
                }

                {isVisibleField('captionTag') &&
                    <Controller
                        name='captionTag'
                        control={control}
                        render={({ field }) => <TextField {...field}
                            multiline
                            rows={2}
                            label='Шаблон для отображения в названии'
                            helperText={`Используйте названия тегов в скобках: {{name}}`}
                        />}
                    />
                }

                {(isVisibleField('captionSize') && isVisibleField('fontColor') && isVisibleField('fontBold')) &&
                    <Stack direction='row' spacing={2}>
                        <Controller
                            name='captionSize'
                            control={control}
                            render={({ field }) => <TextField {...field}
                                type='number'
                                label='Размер шрифта (px)'
                                fullWidth={false}
                            />}
                        />

                        <Controller
                            name='fontColor'
                            control={control}
                            render={({ field }) => <TextField {...field}
                                label='Цвет шрифта'
                                fullWidth={false}
                            />}
                        />

                        <Controller
                            name='fontBold'
                            control={control}
                            render={({ field }) => <Box><FormControlLabel control={<Checkbox {...field} checked={field.value} />} label='Жирный' /></Box>}
                        />
                    </Stack>
                }

                {(isVisibleField('haloSize') && isVisibleField('haloColor')) &&
                    <Stack direction='row' spacing={2}>
                        <Controller
                            name='haloSize'
                            control={control}
                            render={({ field }) => <TextField {...field}
                                type='number'
                                label='Размер HALO (px)'
                                fullWidth={false}
                            />}
                        />

                        <Controller
                            name='haloColor'
                            control={control}
                            render={({ field }) => <TextField {...field}
                                label='Цвет HALO'
                                fullWidth={false}
                            />}
                        />
                    </Stack>
                }

                {isVisibleField('mobileTmplId') &&
                    <Controller
                        name='mobileTmplId'
                        control={control}
                        render={({ field }) => (
                            <FormControl fullWidth>
                                <InputLabel>Шаблон для &apos;облака&apos;</InputLabel>
                                <Select {...field}
                                    label="Шаблон для 'облака'"
                                    variant="outlined"
                                >
                                    <MenuItem key='none' value={null}>Не выбран</MenuItem>
                                    {templates.map(t => [t.id, t.name]).map(item => (
                                        <MenuItem key={item[1]} value={item[0]}>{item[1]}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />
                }


                {isVisibleField('wmsUrl') &&
                    <Stack direction='row' alignItems='center'>
                        <Typography variant='font_24' component={FormLabel}><>Адрес <Link href="http://www.opengeospatial.org/standards/wfs" target="_blank">WMS</Link> сервиса</>:&nbsp;</Typography>
                        <Controller
                            name='wmsUrl'
                            control={control}
                            render={({ field }) => <TextField {...field}
                                fullWidth
                            />}
                        />
                    </Stack>
                }

                {isVisibleField('tileServiceUrl') &&
                    <Controller
                        name='url'
                        control={control}
                        render={({ field }) => <TextField {...field}
                            label='Адрес тайлового* сервиса'
                            helperText={` *Пример строки подключения:<br/>
                http://tile.host.ru/TileService.ashx?Request=GetTile&LayerName={layername}&apikey={apikey}&z={z}&x={x}&y={y}<br/>
                Обязательные параметры запроса<br/>
                {layername} – цифробуквенный идентификатор слоя. Берется из значения тега {layername} в ответе на запрос
                Request=GetCapabilities, совпадает со служебным полем ID слоя в “личной карте пользователя”.<br/>
                {apikey} – ключ доступа<br/>
                {x}, {y}, {z} – номер тайла`}
                        />}
                    />
                }

                {isVisibleField('esriUrl') &&
                    <Controller
                        name='esriUrl'
                        control={control}
                        render={({ field }) => <TextField {...field}
                            label='Адрес сервиса ArcGis'
                        />}
                    />
                }

                {isVisibleField('isViewObjectsOnArcgis') &&
                    <Controller
                        name='isViewObjectsOnArcgis'
                        control={control}
                        render={({ field }) => <Box><FormControlLabel control={<Checkbox {...field} checked={field.value} />} label='Отображать информацию объектов' /></Box>}
                    />
                }

                {isVisibleField('layerPicture') &&
                    <Controller
                        name='layerPicture'
                        control={control}
                        render={() => {
                            const props = {
                                name: 'layerPicture',
                                label: 'Скан карты (картинка в формате jpg)',
                                register,
                                setValue,
                                pictureUrl: layer.pictureUrl
                            }
                            return <PictureField {...props} />
                        }}
                    />
                }

                {isVisibleField('pictureOpacity') &&
                    <Controller
                        name='pictureOpacity'
                        control={control}
                        render={({ field }) => <TextField {...field}
                            type='number'
                            label='Прозрачность заливки (от 0% до 100%)'
                        />}
                    />
                }

                {isVisibleField('pictureBounds') &&
                    <Controller
                        name='pictureBounds'
                        control={control}
                        render={({ field }) => <TextField {...field}
                            multiline
                            label='Координаты углов картинки (WGS84)'
                        />}
                    />
                }

                {isVisibleField('rnisApiKey') &&
                    <Controller
                        name='rnisApiKey'
                        control={control}
                        render={({ field }) => <TextField {...field}
                            label='RNIS API KEY'
                        />}
                    />
                }

                {isVisibleField('vehicleTailLength') &&
                    <Controller
                        name='vehicleTailLength'
                        control={control}
                        render={({ field }) => <TextField {...field}
                            label='Длина хвоста(точки)'
                        />}
                    />
                }

                {isVisibleField('vehicleTailColor') &&
                    <Controller
                        name='vehicleTailColor'
                        control={control}
                        render={({ field }) => <TextField {...field}
                            label='Цвет хвоста'
                        />}
                    />
                }

                {isVisibleField('vehicleInactivityPeriod') &&
                    <Controller
                        name='vehicleInactivityPeriod'
                        control={control}
                        render={({ field }) => <TextField {...field}
                            label='Период неактивности(часы)'
                        />}
                    />
                }
            </FormContainer>

            {isSubmitting && <MaskProgress />
            }
        </>
    )
}

export default PropEdit