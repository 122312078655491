export const ARCGIS_SET_ERROR = 'ARCGIS_SET_ERROR'
export const ARCGIS_SET_IS_LOADING = 'ARCGIS_SET_IS_LOADING'
export const ARCGIS_SET_DATA = 'ARCGIS_SET_DATA'


const initialState = {
    error: false,
    isLoading: false,
    data: {
        featureCollection: undefined,
        result: undefined,
    },
}

export default function MapArcGisReducer(state = initialState, action) {
    switch (action.type) {
        case ARCGIS_SET_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            }
        case ARCGIS_SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }

        case ARCGIS_SET_DATA:
            return {
                ...state,
                isLoading: false,
                data: {
                    featureCollection: action.payload.featureCollection,
                    result: action.payload.result,
                }
            }
        default:
            return state
    }
}