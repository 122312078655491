import { Box, Skeleton, Typography } from '@mui/material';
import React, { useState } from 'react';
import TextClamp from 'react-string-clamp';
import PropTypes from 'prop-types'
import useDeviceDetect from '../../../hooks/useDeviceDetect';


const Title = ({ isLoading, title = '', lines = 3, className, style }) => {
    const [expanded, setExpended] = useState(false)
    const {isMobile} = useDeviceDetect()

    return (
        <Box className={className} style={style}>
            <Typography component='h1' variant={isMobile ? 'font_20_28': 'font_22_28'} onClick={() => { setExpended(!expanded) }} style={{ textAlign: 'left' }}>

                {isLoading
                    ? <Skeleton width={150} />
                    : !expanded
                        ? <TextClamp
                            text={title}
                            lines={lines}
                            ellipsis='...'
                            splitter=' '
                            punctuation={true}
                        />
                        : title
                }

            </Typography>
        </Box>
    )
}

export default Title

Title.propTypes = {
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    lines: PropTypes.number,
    className: PropTypes.any,
    style: PropTypes.any,
}