import RightControls from './RightControls';
import TopControls from './TopControls';
import RightBottomControls from './RightBottomControls';
import useAddCornersInMap from '../../cloneOutside/react-leaflet-custom-control/useAddCornersInMap'


const MapControl = () => {
    
    useAddCornersInMap()

    return (
        <>
            <TopControls/>
            <RightControls />
            <RightBottomControls />
        </>
    )
}

export default MapControl