export const RoutePaths = {
    HOME: '/',
    LAYER: '/layer/:layerId',
    OBJECT: '/layer/:layerId/object/:objectId',
    // FEEDBACK_OBJECT: '/object/:objectId/feedback', // используется в mob
    SEARCH_LOCATION: '/sl/:value/:searchLatLon/:type',
    PROP_LAYER: '/layer/:layerId/:propName',        // только для mob
    PROP_OBJECT: '/layer/:layerId/object/:objectId/prop',   // только для mob
    IMPORT_EXPORT: '/layer/:layerId/impex',
    TEST:  '/test',
    HISTORY_LAYER: '/historyLayer',
    METADATA_LAYER: '/registry',
    FEEDBACK_PAGE: '/feedback/:type/:id', // используется в desktop
    OLD_MAP_PAGE: '/map',   // путь к странице для редиректа
    FILTER_OBJECTS: '/filterObjects/',  // путь к странице, на которой отображается Фильтр объектов
    FILTER_OBJECTS_DETAIL: '/filterObjects/:layerId/:objectId', // путь к странице на которой отображается Фильтр объектов + детальная информация о объекте
    ARCGIS: '/arcgis/:layerId/:objectId/:lat/:lng',
    
    
    // Mob
    HELP: '/static/help',
    INFO: '/static/info',
    
    
}