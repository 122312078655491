import React, { useEffect, useState } from 'react'
import { IconButton, Paper, TextField } from '@mui/material'
import { useMatch } from 'react-router-dom'
import { Stack } from '@mui/system'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'
import { RoutePaths } from '../../../routes'
import { ReactComponent as FilterIcon } from '../../../Icons/desktop/filterObjects/FilterObjectsIcon.svg'
import { ReactComponent as CloseIcon } from "../../../Icons/search/CloseIcon.svg"
import useNavigate from '../../../hooks/useNavigateWithSearchParams'
import { pxToRem } from '../../Style/desktopTheme'
import { sxPaper, sxTextField } from './sxFilterObjects'
import useSelectedLayers from '../../../hooks/reactQuery/selectorCache/useSelectedLayers'
import useLayerHierarhyList from '../../../hooks/reactQuery/selectorCache/useLayerHierarhyList'
import ItemObjectsToLayer from './ItemObjectsToLayer'


const FilterObjects = ({
    handleClickObject = () => { },
    StackTextFieldSx = { mt: pxToRem('16px'), ml: pxToRem('16px'), mr: pxToRem('16px'), mb: pxToRem('26px') },
}) => {

    const navigate = useNavigate()
    const isFilterObjectDetailPage = useMatch(RoutePaths.FILTER_OBJECTS_DETAIL)
    const selectedLayers = useSelectedLayers()
    const layerHierarhyList = useLayerHierarhyList(selectedLayers.map(l => l.id))
    const [inputValue, setInputValue] = useState('')
    const [term, setTerm] = useState('')

    // Function
    const debouncedSetInputValue = useDebouncedCallback((value) => {
        setTerm(value)
        if (isFilterObjectDetailPage) {
            navigate(RoutePaths.FILTER_OBJECTS)
        }
    }, 500)

    const handleChangeTextField = (value) => {
        setInputValue(value)
    }

    useEffect(() => {
        debouncedSetInputValue(inputValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue])

    return (
        <>
            <Stack sx={StackTextFieldSx}>

                <TextField
                    sx={sxTextField}
                    placeholder="Ключевые слова..."
                    value={inputValue}
                    onChange={(e) => handleChangeTextField(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <>
                                {inputValue.trim() !== '' &&
                                    <IconButton onClick={() => setInputValue('')} >
                                        <CloseIcon fill={'black'} />
                                    </IconButton>
                                }

                                <IconButton><FilterIcon /></IconButton>
                            </>
                        )
                    }}
                />
            </Stack>

            <Paper sx={sxPaper}>
                {selectedLayers.map(l =>
                    <ItemObjectsToLayer
                        {...{
                            layerId: l.id,
                            name: l.name,
                            term,
                            handleClickObject: handleClickObject,
                            layerHierarhyList: layerHierarhyList[l.id],
                        }}
                        key={'iol-' + l.id}
                    />)}
            </Paper>
        </>
    )
}

FilterObjects.propTypes = {
    handleClickObject: PropTypes.func,
    StackTextFieldSx: PropTypes.object,
}

export default FilterObjects