import axios from "axios";
// import _ from "lodash";
import api from "../http";
import {ApiPaths} from "../../config"


export const loadCategory = async () => {
    const result = await api.get(ApiPaths.layer.CATEGORIES)
    return result.data
}

export const loadLayersByParentId = async (layerId) => {
    const result = await api.get(ApiPaths.layer.LAYERS.replace(':layerId', layerId))
    return result.data
}

export const loadLayerHierarhy = async (layerId) => {
    const result = await api.get(ApiPaths.layer.LAYER_HIERARHY.replace(':layerId', layerId))
    return result.data
}

export const loadLayerById = async (layerId) => {
    const result = await api.get(ApiPaths.layer.LAYER.replace(':layerId', layerId))
    let data = {
        [result.data.id]: result.data
    }
    return data // отдаем нормализованные данные
}

export const loadLayerMetadata = async (layerId) => {
    const result = await api.get(ApiPaths.layer.PARENT_METADATA.replace(':parentId', layerId));
    return result.data
}


export const updateLayerMeatadata = async (data) => {
    return await api({
        url: ApiPaths.layer.METADATA_UPDATE,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export const updateLayer = async (data) => {
    let tempData = { ...data }
    let formData = new FormData()
    if (tempData['layerPicture'][0]) {
        formData.append("layerPicture", data['layerPicture'][0])
    }
    delete tempData.layerPicture
    formData.append('properties', new Blob([JSON.stringify(tempData)], { type: "application/json" }));
    return await api({
        url: ApiPaths.layer.UPDATE,
        method: 'post',
        data: formData,
        headers: {
            'contentType': false,
        }
    })
}

export const updateLayerPermissions = async (layerId, data) => {
    return await api({
        url: ApiPaths.layer.PERMISSIONS_UPDATE.replace(':layerId', layerId),
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export const deleteLayer = async (layerId) => {
    return await api({
        url: ApiPaths.layer.DELETE.replace(':layerId', layerId),
        method: 'GET',
    })
}

export const updateLayerTags = async (layerId, data) => {
    return await api({
        url: ApiPaths.layer.TAGS_UPDATE.replace(':layerId', layerId),
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export const loadPreparePropsLayer = async (layerId) => {
    const result = await api.get(ApiPaths.layer.PREPARE_PROPS.replace(":layerId", layerId))
    return result.data
}

export const loadPropsLayer = async (layerId) => {
    const result = await axios.all([
        api.get(ApiPaths.layer.PREPARE_PROPS.replace(":layerId", layerId)),
        api.get(ApiPaths.templates.MOBILE_TEMPLATES),
        api.get(ApiPaths.layer.PERMISSIONS.replace(':layerId', layerId)),
    ])
    return result.map(r => r.data)
}

export const loadAllUsersRoles = async () => {
    const result = await axios.all([
        api.get(ApiPaths.others.PERMISSION_ALL_USERS),
        api.get(ApiPaths.others.PERMISSION_ALL_ROLES),
    ])
    return result.map(r => r.data)
}


export const loadTreeListLayers = async (isIncludeAllLayerTypes=false) => {
    const result = await api.get(ApiPaths.layer.TREE_LIST + '?isIncludeAllLayerTypes='+isIncludeAllLayerTypes)
    return result.data
}

export const updatePictureBoundsLayer = async (layerId, bounds) => {
    const formData = new FormData()
    formData.append('layerId', layerId)
    formData.append('bounds', bounds)
    return await api({
        url: ApiPaths.layer.PICTURE_BOUNDS_UPDATE,
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

export const moveToLayer = async (layerId, parentLayerId) => {
    let formData = new FormData()
    formData.append('layerId', layerId)
    formData.append('parentLayerId', parentLayerId)
    return await api({
        url: ApiPaths.layer.MOVE_TO,
        method: 'post',
        data: formData,
    })
}

export const copyToLayer = async (layerId, parentLayerId) => {
    let formData = new FormData()
    formData.append('layerId', layerId)
    formData.append('parentLayerId', parentLayerId)
    return await api({
        url: ApiPaths.layer.COPY_TO,
        method: 'post',
        data: formData,
    })
}


export const updateLayerHeatMap = async ({layerId, heatmap}) => {
    return await api({
        url: ApiPaths.layer.HEATMAP_UPDATE,
        method: 'post',
        data: {
            id:layerId, 
            heatmap
        },
        headers: {
            'contentType': 'application/json',
        }
    })
}

export const copyPropsToLayer = async ({layerId, targetIds}) => {
    let formData = new FormData()
    formData.append('layerId', layerId)
    formData.append('targetIds', targetIds)
    return await api({
        url: ApiPaths.layer.COPY_PROPS_TO_LAYER,
        method: 'post',
        data: formData,
        // headers: {
        //     'contentType': 'application/json',
        // }
    })
}