import { ErrorBoundary } from 'react-error-boundary'
import DesktopLayer from '../components/desktop/Layer/Layer'
import MobLayer from '../components/mob/Layer/Layer'
import { ErrorFallback } from '../components/desktop/ErrorBoundary/ErrorFallback'
import CheckAccesToLayer from '../hoc/CheckAccesToLayer'
import HeadToolBarLayers from '../components/general/HeadToolBar/HeadToolBarLayers'
import useDeviceDetect from '../hooks/useDeviceDetect'
import Header from '../hoc/Header'


const LayerPage = () => {
    const { isMobile } = useDeviceDetect()
    return (
        <>
            <CheckAccesToLayer>
                <Header>
                    <HeadToolBarLayers />
                </Header>
                
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    {isMobile ? <MobLayer /> : <DesktopLayer />}
                </ErrorBoundary>
            </CheckAccesToLayer>
        </>
    )
}

export default LayerPage