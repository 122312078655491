import axios from "axios"
import { GEOPORTAL_URL } from "../config"
import {refresh} from "./auth"

const api = axios.create({
    withCredentials: true,
    baseURL: GEOPORTAL_URL,
    headers:{
        "X-Requested-With": "XMLHttpRequest"
    }
})

api.interceptors.request.use(config => {
    const token = localStorage.getItem('token')
    if(token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
})

api.interceptors.response.use( config => {return config}, async error => {
    const originalRequest = error.config
    if(error.response.status === 401) {
        if(await refresh()) {
            return api.request(originalRequest)
        }
    } else 
    return Promise.reject(error)
})

export default api