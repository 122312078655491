import { styled, Box, Button, Paper, Table, TableBody, TableCell as MuiTableCell, TableHead, TableRow, Typography, SvgIcon } from "@mui/material"
import { useFieldArray } from "react-hook-form"
import { useSelector } from "react-redux"
import { DOWNLOAD_RESOURCE_PATH } from "../../../../config"
import { TableContainerTags } from "../../StyledComponents/propertyTags"
import { ReactComponent as PlusIcon } from '../../../../Icons/desktop/propLayer/PlusIcon.svg'
import { uploadResource } from "../../../../service/axiosFuncQuery/othersQuery"
import { userRoleSelector } from "../../../../store/selectors/selectors"
import TagsRow from "./components/TagsRow"
import { pxToRem } from "../../../Style/desktopTheme"
import { useEffect, useRef } from "react"
import FormContainer from "../../ForForms/FormContainer"


const TableCellHeadTags = styled(MuiTableCell)({
    paddingLeft: '6px',
    paddingBottom: '7px',
    '&.MuiTableCell-root': {
        borderBottom: 0
    },
})


const Tags = ({ editor, reactHookForm: { control, watch, setValue, setError } }) => {

    const bottomRef = useRef() // для скроллинга вниз после добавления нового элемента
    const { isAdministrator } = useSelector(userRoleSelector)
    const { append, remove } = useFieldArray({ name: 'tags', control })
    const tags = watch('tags')

    const addTag = () => {
        const newTag = { key: '', value: '', position: tags.length, type: 1, id: tags.length }
        append(newTag)
    }

    const uploadFile = (file, index) => {
        uploadResource(file[0])
            .then(response => {
                setValue(`tags.${index}.value`, DOWNLOAD_RESOURCE_PATH + response.data)

            })
            .catch(e => {
                setError(
                    "root.serverError", {
                    type: 'uploadFileError',
                    message: e.message
                })
            })
    }

    const isInitialMount = useRef(true)
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            // scroll вниз после добавления элемента
            bottomRef.current?.scrollIntoView({ behavior: "smooth" })
        }
    }, [tags.length])

    return (
        <FormContainer disablePaddingRight>
            {/*editor*/isAdministrator && <Box><Button style={{ paddingLeft: '16px' }} variant='outlined' onClick={addTag}>
                <SvgIcon sx={{ fontSize: '18px' }}><PlusIcon /></SvgIcon>
                &nbsp;Добавить тег</Button></Box>}

            <Box sx={{ overflow: 'auto', pr: pxToRem('32px') }}>
                <TableContainerTags component={Paper} >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCellHeadTags><Typography variant='font_16_24'></Typography></TableCellHeadTags>
                                <TableCellHeadTags><Typography variant='font_16_24'>Тег</Typography></TableCellHeadTags>
                                <TableCellHeadTags><Typography variant='font_16_24'>Значение</Typography></TableCellHeadTags>
                                <TableCellHeadTags></TableCellHeadTags>
                            </TableRow>
                        </TableHead>



                        <TableBody>
                            {tags.map((item, index) => {
                                const props = {
                                    control,
                                    index,
                                    editor,
                                    remove,
                                    uploadFile,
                                    item
                                }
                                return (
                                    <TagsRow
                                        key={item.id}
                                        {...props}
                                    />
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainerTags>
                <Box ref={bottomRef} />
            </Box>

        </FormContainer>
    )
}

export default Tags