import { useSelector } from 'react-redux'
import { ModalContextProvider } from '../../../hooks/useModal/useModal'
import { isAuthSelector } from '../../../store/selectors/selectors'
import LayerPropertyEditor from './LayerPropertyEditor'
import NotAuthorized from './NotAuthorized'


const LayerProperty = () => {

    const isAuth = useSelector(isAuthSelector)

    if (!isAuth)
        return <NotAuthorized />

    return (
        <ModalContextProvider>
            <LayerPropertyEditor/>
        </ModalContextProvider>
    )
}

export default LayerProperty