import { Box,  Grid, MenuItem, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import CategoryIcon from './CategoryIcon';
import CategoryName from './CategoryName';
import { pxToRem } from '../../Style/desktopTheme';
import styles from './Category.module.scss'


const CategoryBig = ({ category, onLayerIds = [], isLoading, handleClick }) => {

    return (
        <>
            <Box sx={{ pl: pxToRem('16px'), pr: pxToRem('16px'), pt: 0, width:'100%' }}>
                <Grid container
                    columnSpacing={0}
                    rowSpacing={1}
                    columns={4} >
                    {category && category.map(item => (

                        <Grid item key={item.id} xs={1}>
                            <MenuItem
                                onClick={(e) => isLoading ? e.preventDefault() : handleClick(item.id)}
                                disableRipple={isLoading ? true : false}
                                disableGutters
                                component={Stack}
                                spacing={1}
                                alignItems='center'
                                sx={{
                                    maxWidth: pxToRem('88px'),
                                    minWidth: pxToRem('88px'),
                                    whiteSpace: 'normal',
                                    pt: 0,
                                    pl: pxToRem('4px'),
                                    pr: pxToRem('4px'),
                                    '&:hover': {
                                        backgroundColor: '#FAFAFA',
                                        borderRadius: pxToRem('4px'),
                                    },
                                    '.MuiTouchRipple-root': {
                                        mt: 0
                                    },
                                }}>
                                <Box sx={{
                                    width: pxToRem('64px'),
                                    height: pxToRem('64px'),
                                    position: 'relative',

                                }}>
                                    <CategoryIcon name={item.name}
                                        isLoading={isLoading}
                                        style={{
                                            borderRadius: pxToRem('26px'),
                                            height: '100%',
                                        }} />
                                    {(onLayerIds.includes(item.id)) &&
                                        <Box className={styles.on_layer} sx={{
                                            width: pxToRem('15px'),
                                            height: pxToRem('15px'),
                                        }}></Box>
                                    }
                                </Box>
                                <CategoryName name={item.name} isLoading={isLoading}
                                />
                            </MenuItem>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    )
}

export default CategoryBig


CategoryBig.propTypes = {
    category: PropTypes.arrayOf(PropTypes.object).isRequired,
    onLayerIds: PropTypes.arrayOf(PropTypes.number),
    isLoading: PropTypes.bool,
    handleClick: PropTypes.func.isRequired,
}