import { Visibility, VisibilityOff } from "@mui/icons-material"
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, SvgIcon, TextField, Typography } from "@mui/material"
import { useContext, useState } from "react"
import { Controller } from "react-hook-form"
import { UserContext } from "../UserContext"
import { ReactComponent as CheckboxActive } from "../../../../../../Icons/desktop/form/CheckboxActive.svg"
import { pxToRem } from "../../../../../Style/desktopTheme"


const General = () => {

    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const { prepareUserDialog: { user, printApps }, control } = useContext(UserContext)


    return (
        <Stack variant='mtrbl' spacing={2}>
            <Controller
                name='geoEdit'
                control={control}
                render={({ field }) => {
                    return (
                        <FormControlLabel
                            style={{ position: 'relative', left: pxToRem('-12px') }}
                            control={<Checkbox {...field} checkedIcon={<SvgIcon inheritViewBox={true}><CheckboxActive /></SvgIcon>} checked={field.value} />}
                            label='Редактирует на карте' />
                    )
                }}
            />

            {user.id > 10 || !user.id
                ? <Controller
                    name='login'
                    control={control}
                    rules={{ required: "Поле обязательно для заполнения" }}
                    render={({ field, fieldState: { error } }) => <TextField {...field}
                        label='Логин'
                        variant="outlined"
                        error={Boolean(error)}
                        helperText={error && error.message}
                    />
                    }
                />
                : <Box>
                    <Typography variant='font_24'>Логин: &nbsp;</Typography>
                    <Typography variant='font_24'>{user.login}</Typography>
                </Box>
            }


            <Controller
                name='lastName'
                control={control}
                render={({ field }) => <TextField {...field}
                    label='Фамилия'
                    variant="outlined"
                />}
            />

            <Controller
                name='firstName'
                control={control}
                render={({ field }) => <TextField {...field}
                    label='Имя'
                    variant="outlined"
                />}
            />

            <Controller
                name='middleName'
                control={control}
                render={({ field }) => <TextField {...field}
                    label='Отчество'
                    variant="outlined"
                />}
            />

            <Controller
                name='email'
                control={control}
                render={({ field }) => <TextField {...field}
                    label='Email'
                    variant="outlined"
                />}
            />

            <Controller
                name='phone'
                control={control}
                render={({ field }) => <TextField {...field}
                    label='Телефон'
                    variant="outlined"
                />}
            />

            <Controller
                name='organization'
                control={control}
                render={({ field }) => <TextField {...field}
                    label='Наименование организации'
                    variant="outlined"
                />}
            />

            <Controller
                name='printTmpl'
                control={control}
                render={({ field }) => (
                    <FormControl>
                        <InputLabel id='printTmpl'>Шаблон печати</InputLabel>
                        <Select
                            label='Шаблон печати'
                            variant="outlined"
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: '250px'
                                    }
                                }
                            }}
                            {...field}
                        >
                            <MenuItem value=''>не задано</MenuItem>
                            {printApps.map(item =>
                                <MenuItem key={item} value={item}>{item}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                )}
            />

            <Controller
                name='password'
                control={control}
                rules={!user.id ? { required: "Поле обязательно для заполнения" } : {}}
                render={({ field, fieldState: { error } }) => (
                    <FormControl variant="outlined" error={Boolean(error)}>
                        <InputLabel htmlFor="password" error={Boolean(error)}>Пароль</InputLabel>
                        <OutlinedInput
                            {...field}
                            error={Boolean(error)}
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword((show) => !show)}                                    >
                                        <SvgIcon style={{ fontSize: pxToRem('18px') }}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </SvgIcon>
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Пароль"
                        />
                        <FormHelperText error={Boolean(error)}>{error && error.message}</FormHelperText>
                    </FormControl>
                )}
            />

            <Controller
                name='confirmPassword'
                control={control}
                rules={!user.id ? { required: "Поле обязательно для заполнения" } : {}}
                render={({ field, fieldState: { error } }) => (
                    <FormControl variant="outlined" error={Boolean(error)}>
                        <InputLabel htmlFor="confirmPassword" error={Boolean(error)}>Подтверждение пароля</InputLabel>
                        <OutlinedInput
                            {...field}
                            error={Boolean(error)}
                            id="confirmPassword"
                            type={showConfirmPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowConfirmPassword((show) => !show)}>
                                    <SvgIcon style={{ fontSize: pxToRem('18px') }}>{showConfirmPassword ? <VisibilityOff /> : <Visibility />}</SvgIcon>
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Подтверждение пароля"
                        />
                        <FormHelperText error={Boolean(error)}>{error && error.message}</FormHelperText>
                    </FormControl>
                )}
            />

        </Stack>
    )
}

export default General