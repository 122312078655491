import { forwardRef } from "react"
import { Link, useLocation } from "react-router-dom"

const LinkWithSearchParams = forwardRef(
    ({to, ...props}, ref) => {
        const { search } = useLocation()
        return <Link {...props} to={to + search} />
    }

)

export default LinkWithSearchParams