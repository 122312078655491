import { createTheme } from "@mui/material";
import { palette } from './themeSettings'


const color = {
    main: '#333333',
    grayMain: '#E0E0E0',
    grayLight1: '#F5F5F5',
    grayDark: '#CACACA',
    grayLight: '#F6F6F6',
    greenMain: '#27AE60',
    greenDark: '#219653',
}

const defaultFont = {
    color: color.main,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: 'normal'
}

let theme = createTheme({
    palette
})

theme = createTheme(theme, {
    typography: {
        fontFamily: 'Inter',
        base: defaultFont,
        font_11: {
            ...defaultFont,
            fontSize: '11px',
            lineHeight: '13.31px',
            letterSpacing: 'normal'
        },
        font_14_21: {
            fontSize: '14px',
            lineHeight: '21px',
        },
        font_16_22: {
            ...defaultFont,
            lineHeight: '22px',
            letterSpacing: '0.08px'
        },
        font_20_28: {
            ...defaultFont,
            fontWeight: 600,
            lineHeight: '28px',
            fontSize: '20px',
        },
        font_16_normal_008: {
            ...defaultFont,
            fontWeight: 600,
            lineHeight: 'normal',
            letterSpacing: '0.08px',
        },
        font_16_24: {
            ...defaultFont,
            lineHeight: '24px',
        },
        font_10: {
            ...defaultFont,
            fontSize: '10px',
            lineHeight: '12px',
            letterSpacing: '0.33px',
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides:
            {
                a: {
                    color: theme.palette.myBlack.main,
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'underline',
                    }
                }
            },
        },
        MuiLink: {
            variants: [
                {
                    props: { variant: 'black' },
                    style: {
                        ...defaultFont,
                        // color: color.accent,
                        textDecoration: 'none',

                    }
                }
            ]
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                li: {
                    ...defaultFont,
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '0.07px',
                    color: theme.palette.myBlack.main,
                    '& a': {
                        opacity: 0.5,
                        color: theme.palette.myBlack.main,
                        border: 0
                    },
                },
                separator: { 'svg': { stroke: theme.palette.myBlack.main } }
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    ...defaultFont, // DropDown слоев + выпадающее меню пользователя
                    paddingTop: '13px',
                    paddingBottom: '13px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                }
            },
        },
        MuiIconButton: {
            variants: [
                {
                    props: { size: '40' },
                    style: {
                        width: '40px',
                        height: '40px',
                    }
                }
            ]
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    ...defaultFont,
                    lineHeight: '24px',
                    fontWeight: 600,
                    textTransform: 'none',
                }
            },
            variants: [
                {
                    props: { variant: 'greenText' },
                    style: {
                        color: theme.palette.myGreen.main,
                        paddingTop: '7px',
                        paddingBottom: '7px',
                    }
                },
                {
                    props: { variant: 'contained' },
                    style: {
                        '&.MuiButton-root': {
                            ...defaultFont,
                            lineHeight: '24px',
                            textTransform: 'none',
                            color: 'white',
                            background: theme.palette.myGreen.main,
                            paddingTop: '7px',
                            paddingRight: '24px',
                            paddingBottom: '9px',
                            paddingLeft: '24px',
                            borderRadius: '100px',
                            borderColor: theme.palette.myGreen.main,
                        }
                    }
                },
                {
                    props: { variant: 'outlined' },
                    style: {
                        '&.MuiButton-root': {
                            ...defaultFont,
                            fontWeight: 600,
                            lineHeight: '24px',
                            textTransform: 'none',
                            color: theme.palette.myGreen.main,
                            borderRadius: '100px',
                            border: '1px solid rgba(39, 174, 96, 0.50);',
                        }
                    }
                },
                {
                    props: { variant: 'icon' },
                    style: {
                        '&.MuiButton-root': {
                            width: '40px',
                            height: '40px',
                            minWidth: '40px',
                            padding: 0,
                        }
                    }
                },
                {
                    props: { variant: 'icon40' },
                    style: {
                        '&.MuiButton-root': {
                            width: '40px',
                            height: '40px',
                            minWidth: '40px',
                            padding: 0,
                        }
                    }
                }
            ]
        },
        MuiDivider: {
            variants: [
                {
                    props: { variant: 'myModal' },
                    style: {
                        borderBottomColor: 'rgba(0, 0, 0, 0.10);',
                    }
                },
                {
                    props: { variant: 'layer' },
                    style: {
                        borderBottomColor: 'rgba(0, 0, 0, 0.10);',
                        marginTop: '8px',
                        marginBottom: '8px',
                        marginRight: '15px',
                        marginLeft: '15px',
                    }
                }
            ]
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    boxShadow: 'none',
                    borderRadius: '0px 0px 8px 8px',
                },
                listbox: {
                    padding: '5px 0px',
                    maxHeight: '50vh',
                },
            }
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: theme.palette.myGreen.main,
                }
            }
        },
        MuiBackdrop: {
            variants: [
                {
                    props: { variant: 'mob' },
                    style: {
                        background: 'rgba(0, 0, 0, 0.3)',
                    }
                }
            ]
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: '0 1px 3px rgba(0,0,0,.3),0 4px 8px 3px rgba(0,0,0,.05)',
                    borderRadius: '4px',
                }
            }
        },
        MuiStack: {
            variants: [
                {
                    props: { variant: 'noScrollBar' },
                    style: {
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        },

                        '& ::-moz-scrollbar': {
                            display: 'none'
                        },

                        overflow: 'auto',
                        '&-ms-overflow-style': 'none',
                        scrollbarColor: 'transparent transparent',
                        /*just hides the scrollbar for firefox */
                    }
                }
            ]
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    '& .MuiOutlinedInput-input': {
                        ...defaultFont,
                        lineHeight: '24px',
                    },
                    '& label.Mui-focused': {
                        color: theme.palette.myGreen.main,
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: theme.palette.myGray.main,
                        },
                        '&:hover fieldset': {
                            borderColor: theme.palette.myGreen.main,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: theme.palette.myGreen.main,
                        },
                    },
                },
            },
        },
    },
})

export default theme