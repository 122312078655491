import { createContext, useContext, useRef } from "react"

const BottomSheetContext = createContext({})

export const useBottomSheet = () => useContext(BottomSheetContext)

export const BottomSheetProvider = ({ children }) => {
    const bottomSheetRef = useRef()

    return (
        <BottomSheetContext.Provider value={{ bottomSheetRef }}>
            {children}
        </BottomSheetContext.Provider>
    )
}