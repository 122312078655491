import { styled, TableContainer, TableCell } from '@mui/material';


export const TableContainerTags = styled(TableContainer)(
    (theme) => ({
        '&.MuiTableContainer-root': {
            // marginBottom: '16px',
            marginTop: '7px',
            paddingLeft: '8px',
            paddingRight: '8px',
            paddingBottom: '16px',
            boxShadow: 'none',
            backgroundColor: theme.theme.palette.myGray.light,

            '& .MuiTableCell-root': {
                borderBottom: 0,
            }
        }
    })
)

export const TableCellHeadTags = styled(TableCell)({
    paddingBottom: '7px',
    paddingLeft: '6px',
})

export const TableCellTextFieldTags = styled(TableCell)({
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingLeft: '8px',
    paddingRight: '8px',
})

export const TableCellSideTags = styled(TableCell)({
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '4px',
    paddingRight: '4px',
    width: '5%'
})