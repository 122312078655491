import React, { useContext } from 'react';
import { Box, Checkbox, Divider, FormControlLabel } from "@mui/material";
import { useGalleryCategoriesQuery } from '../../../../../hooks/reactQuery/useGallery';
import { sortByName } from '../../../../../service/function';
import Loader from '../../../Loader/Loader'
import { GalleryContext } from '../../GalleryContext';



const CategoryItem = ({ item, setParams }) => {

    const handleChange = (id, checked) => {
        setParams((prevState) => {
            const newTerm = prevState.term
            if (checked) {
                newTerm.push(id)
            } else {
                const index = newTerm.indexOf(id)
                if (index > -1) {
                    newTerm.splice(index, 1)
                }
            }
            const newState = {
                ...prevState,
                term: newTerm
            }
            return newState
        })
    }

    return (
        <Box>
            <FormControlLabel
                label={item.name}
                control={
                    <Checkbox
                        checked={item.checked}
                        onChange={ev => handleChange(item.id, ev.target.checked)} />
                }

            />
        </Box>
    )
}

const Categories = () => {

    const { params: { term, oldIcon }, setParams } = useContext(GalleryContext)
    let { data: categories = [], isFetching } = useGalleryCategoriesQuery({ oldIcon })
    categories = sortByName(categories)

    if (isFetching) return <Loader />
    return (
        <>
            <CategoryItem
                item={{ id: 'unused', name: 'Неиспользуемые', checked: term.indexOf('unused') > -1 ? true : false }}
                setParams={setParams}
                key='unused' />

            <Divider />

            {categories.map(item => <CategoryItem
                item={{
                    ...item,
                    checked: term.indexOf(item.id) > -1 ? true : false
                }}
                setParams={setParams}
                key={item.id}
            />)}
        </>
    )
}

export default Categories