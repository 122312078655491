import { IconButton, Stack, SvgIcon, TextField } from '@mui/material';
import React, { useRef, useState } from 'react';
import ListCheckBox from './ListCheckBox';
import { ReactComponent as SearchIcon } from "../../../../Icons/desktop/form/SearchIcon.svg"
import { ReactComponent as CloseIcon } from "../../../../Icons/desktop/form/DelIcon.svg"
import { pxToRem } from '../../../Style/desktopTheme';
import { objectFilterByValues } from '../../../../service/function';


const WrapFilterList = ({ list, ...properties }) => {

    // filter
    const inputRef = useRef()
    const [term, setTerm] = useState('')    // фильтр
    const filterList = objectFilterByValues(list, term)

    const props = {
        ...properties,
        list: filterList,
    }

    return (
        <Stack variant='mtrbl'>
            <TextField
                inputRef={inputRef}
                fullWidth
                var="small" // my attribute in desktop theme
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <Stack direction='row' spacing={0} >
                            {term.trim() !== '' &&
                                <IconButton onClick={() => {
                                    setTerm('')
                                    inputRef.current.value = ''
                                }}>
                                    <SvgIcon style={{ fontSize: pxToRem('16px') }} inheritViewBox={true}><CloseIcon color="inherit" /></SvgIcon>
                                </IconButton>
                            }

                            <IconButton onClick={() => setTerm(inputRef.current.value)}>
                                <SvgIcon style={{ fontSize: pxToRem('16px') }} inheritViewBox={true}><SearchIcon color="inherit" /></SvgIcon>
                            </IconButton>
                        </Stack>
                    )
                }}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        setTerm(inputRef.current.value)
                        e.preventDefault()
                    }
                }}
            />

            <ListCheckBox {...props} />
        </Stack>
    )
}

export default WrapFilterList