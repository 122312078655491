import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { pxToRem } from '../components/Style/desktopTheme';
import Header from '../hoc/Header'
import useDeviceDetect from '../hooks/useDeviceDetect';
import DesktopTemplateHeaderToolbar from '../components/general/HeadToolBar/DesktopTemplateHeaderToolbar';
import MobTemplateHeaderToolbar from '../components/general/HeadToolBar/MobTemplateHeaderToolbar';


const SearchLocationPage = () => {

    const { isMobile } = useDeviceDetect()
    const { value, searchLatLon } = useParams();
    const title = decodeURIComponent(value)
    const navigate = useNavigate()

    return (
        <>
            <Header>
                {isMobile ? <MobTemplateHeaderToolbar title={{ name: title }} handleClose={() => navigate(-1)} /> : <DesktopTemplateHeaderToolbar handleGoBack={()=> navigate(-1)} title={{ name: title }} />}
            </Header>

            <Box style={{ margin: `${pxToRem('7px')} ${pxToRem('4px')} 0px ${pxToRem('4px')}`, height: '100%', overflow: 'auto', }}>Координаты: {searchLatLon}</Box>
        </>
    )
}

export default SearchLocationPage