import axios from "axios";
import { GEOPORTAL_URL, ApiPaths } from "../config";


export const login = async (username, password) => {
    return await axios.post(GEOPORTAL_URL + ApiPaths.auth.LOGIN, { username, password });
}

export const logout = async () => {
    return axios.get(ApiPaths.auth.LOGOUT)
}

export const refresh = async () => {
    const response = await axios.post(GEOPORTAL_URL + ApiPaths.auth.REFRESH,
        {
            accessToken: localStorage.getItem('token'),
            refreshToken: localStorage.getItem('refresh_token')
        });
    if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('refresh_token', response.data.refresh_token);
        return true;
    }
    return false;
}

export const checkAuth = async () => {
    const token = localStorage.getItem('refresh_token');
    if (!token) {
        return false;
    }
    return refresh();
}