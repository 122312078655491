import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material';
import { pxToRem } from "../../../Style/desktopTheme"


export const ControlIconButton = styled(IconButton)({
    width: pxToRem(40),
    height: pxToRem(40),
    padding: pxToRem('8px'),
    // backgroundColor: props?.active? 'greenyellow' : 'white',
    backgroundColor: 'white',
    borderRadius: pxToRem('8px'),
    boxShadow: `0px ${pxToRem('2px')} ${pxToRem('4px')} 0px rgba(0, 0, 0, 0.20), 0px -1px 0px 0px rgba(0, 0, 0, 0.02)`,
    '&:hover': {
        backgroundColor: 'white',
    },
    '&:active': {
        backgroundColor: '#bfbfbf',
    }
})

export default ControlIconButton
