import { useQueryClient } from "react-query"
import { useLayerByIdQuery } from "../useLayer"
import useInCacheLayers from "./useInCacheLayers"


const useLayerById = (layerId) =>{
    
    const queryClient = useQueryClient()

    const allLayers = useInCacheLayers()
    const layer = allLayers.filter(l => l.id === layerId)[0]

    /* Если запрос useLayerByIdQuery был однажды вызван - enabled=true, то в дальнейшем мы обязаны также выставлять ему enabled=true
    для того чтобы при инвалидации по ключу обновлялись данные в кеше.
    Список активных запросов берем из queryClient.getQueriesData по ключу, и оттуда получаем layerId по которым был вызван useLayerByIdQuery 
    */
    const onQueryLayerIds = queryClient.getQueriesData(['layer']).map(itemQuery => Number(itemQuery[0][1]))
    const enabled = !layer || onQueryLayerIds.includes(Number(layerId))

    useLayerByIdQuery({ layerId, enabled})
    return layer
}

export default useLayerById