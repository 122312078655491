import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userRoleSelector } from '../store/selectors/selectors';
import useLayerHierarhy from './reactQuery/selectorCache/useLayerHierarhy';


// Определяет - может ли пользователь редактировать слой
const useCanEdit = (layerId) => {
    const [canEdit, setCanEdit] = useState(false)
    const { isEditor, isProjectEditor } = useSelector(userRoleSelector)
    const layerHierarhy = useLayerHierarhy(Number(layerId))
    
    const itemLayer = layerHierarhy.filter(l => l.id === Number(layerId))[0]

    useEffect(() => {
        if (isEditor) {
            setCanEdit(true)
        }
        else if (isProjectEditor && itemLayer && itemLayer.permissions !== 1) {
            let parentId = itemLayer.parentId
            while (parentId !== null) {
                // eslint-disable-next-line no-loop-func
                let parentLayer = layerHierarhy.filter(l => l.id === parentId)[0]
                if (!parentLayer) {
                    break;
                }
                if (parentLayer.permissions === 1) {
                    setCanEdit(true)
                    break;
                }
                parentId = parentLayer.parentId
            }
        } else if (!isProjectEditor && itemLayer && itemLayer.permissions === 1) {
            setCanEdit(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditor, isProjectEditor, layerHierarhy])
    return canEdit
}

export default useCanEdit