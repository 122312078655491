import L from 'leaflet';
import React from 'react';
import { useMap } from 'react-leaflet';
import { Box } from '@mui/material';
import icon from '../../../../Icons/map/MyLocationIcon.svg';
import { ReactComponent as LocationIcon } from "../../../../Icons/map/LocationIcon.svg";


const LocationControl = () => {
    const map = useMap();
    const iconMyLocation = new L.Icon({
        iconUrl: icon,
        iconAnchor: null,
        popupAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: new L.Point(32, 32),
    });

    const setMyLocation = (e) => {
        e.preventDefault();
        map.locate({        
            watch: false,
            setView: false,
            enableHighAccuracy: true
        });

        map.on('locationfound', function (ev) {
            L.marker(ev.latlng, {
                icon: iconMyLocation,
            }).addTo(map);

            map.setView(ev.latlng, map.getZoom());
        })

        map.on('locationerror', function (message) {
            console.log(message);
        });
    }

    return (
        <Box component='a' href='' onClick={setMyLocation}>
            <LocationIcon />
        </Box>
    );
};

export default LocationControl;