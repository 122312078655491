import { Stack } from "@mui/material"
import MyTabs from "../MyTabs/MyTabs"
import HeatMap from "./HeatMap"
import { useContext } from "react"
import { ModalWindowContext } from "../../../hooks/useModal/ModalWindow"

const AnaliticSettings = () => {
    const { layerId } = useContext(ModalWindowContext)
    let arrTabs = [
        {
            label: 'Тепловая карта',
            value: <HeatMap layerId={layerId} />
        },
    ]

    return (
        <Stack spacing={2} className='overflow '>
            <MyTabs arrTabs={arrTabs} var='var1' />
        </Stack >
    )
}

export default AnaliticSettings