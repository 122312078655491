import { Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import TreeListLayersPermissions from '../../../../TreeListLayers/TreeListLayersPermissions';
import { RoleContext } from '../RoleContext';

const Layers = () => {

    const { control } = useContext(RoleContext)

    const props = {
        control
    }
    return <Stack
        variant='mtrbl'
        spacing={1}>
        <Typography var='font_16_24'>Выберите слои и режим (чтение/запись)</Typography>
        <TreeListLayersPermissions {...props} />
    </Stack>
}

export default Layers;