import { CircularProgress, Stack } from '@mui/material';
import React from 'react';

const Loader = (props) => {

    return (
        <Stack alignItems='center' justifyContent='center' sx={{minHeight: '60px'}}>
            <CircularProgress
                {...props}
            />
        </Stack>
    )
}

export default Loader;