import { Box, Grid, IconButton, Stack, SvgIcon } from '@mui/material';
import React, { useContext, useState } from 'react';
import { ErrorBoundary } from "react-error-boundary";
// import FilterObjects from '../components/general/FilterObjects/FilterObjects';
import FilterObjects from '../components/general/FilterObjects/FilterObjects';
import Title from '../components/general/Title/Title';
import { DrawerContext } from '../components/desktop/SidePanel/SideContextProvider';
import { getCookie, isBoolean } from '../service/function';
import { ReactComponent as CloseIcon } from "../Icons/desktop/modal/CloseIcon.svg"
import { RoutePaths } from '../routes';
import { ErrorFallback } from '../components/desktop/ErrorBoundary/ErrorFallback';
import Link from '../hoc/LinkWithSearchParams'
import { pxToRem } from '../components/Style/desktopTheme';
import useDeviceDetect from '../hooks/useDeviceDetect';
import Header from '../hoc/Header';
import useNavigate from '../hooks/useNavigateWithSearchParams'
import MobTemplateHeaderToolbar from '../components/general/HeadToolBar/MobTemplateHeaderToolbar';
import ObjectPage from './ObjectPage';



const FilterObjectsPage = () => {
    const { isMobile } = useDeviceDetect()
    return (
        isMobile
            ? <MobFilterObjectsPage />
            : <DesktopFilterObjectsPage />
    )
}


const MobFilterObjectsPage = () => {
    const navigate = useNavigate()
    const [isObjectPage, setIsObjectPage] = useState(false)
    
    const handleClickObject = ({ layerId, objectId }) => {
        navigate(RoutePaths.FILTER_OBJECTS_DETAIL.replace(":layerId", layerId).replace(':objectId', objectId))
        setIsObjectPage(true)
    }
    
    return (
        <>
            {isObjectPage && <ObjectPage handleClose={() => setIsObjectPage(null)} />}

            <Box style={{ display: isObjectPage ? 'none' : 'block' }}>

                {!isObjectPage &&
                    <Header>
                        <MobTemplateHeaderToolbar title={{ name: 'Список объектов' }} handleClose={() => navigate(RoutePaths.HOME)} />
                    </Header>
                }

                <FilterObjects stackTextFieldSx={{ mt: '16px', ml: '9px', mr: '9px', mb: '26px' }} handleClickObject={handleClickObject} />
            </Box>
        </>
    )
}

const DesktopFilterObjectsPage = () => {

    const navigate = useNavigate()
    const { mainDrawer, setMainDrawer, setSecondDrawer } = useContext(DrawerContext)

    const handleClose = () => {
        const open_cat = isBoolean(getCookie('open_cat'))
        if (mainDrawer !== open_cat) {
            setMainDrawer(Boolean(open_cat))
        }
    }

    const handleClickObject = ({ layerId, objectId }) => {
        setSecondDrawer(true)
        navigate(RoutePaths.FILTER_OBJECTS_DETAIL.replace(":layerId", layerId).replace(':objectId', objectId))
    }

    return (
        <>
            <Stack sx={{ mt: pxToRem('24px'), ml: pxToRem('16px'), mr: pxToRem('16px') }} spacing={2}>
                <Grid container direction='row' justifyContent='space-between'>
                    <Grid item><Title title='Список объектов' /></Grid>
                    <Grid item><IconButton component={Link} onClick={handleClose} to={RoutePaths.HOME} sx={{ p: 0 }}  >
                        <SvgIcon style={{ fontSize: pxToRem('32px') }} inheritViewBox><CloseIcon /></SvgIcon>
                    </IconButton></Grid>
                </Grid>
            </Stack>

            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <FilterObjects handleClickObject={handleClickObject} />
            </ErrorBoundary>
        </>
    )
}

export default FilterObjectsPage