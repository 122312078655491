import {MAP_SET_EDIT_ON_MAP, MAP_SET_TILE_LAYER, MAP_HEATMAP_ADD_ID, MAP_HEATMAP_DEL_ID, MAP_SET_WMS_TILE_LAYER, MAP_SET_CHANGE_ICON} from "./index";

export const MapActionCreators = {
    setMapTileLayer: (id) => {
        return {
            type: MAP_SET_TILE_LAYER,
            payload: id
        }
    },
    startMapEdit : (id) => {
      return {
          type: MAP_SET_EDIT_ON_MAP,
          payload: id
      }
    },
    stopMapEdit : () => {
        return {
            type: MAP_SET_EDIT_ON_MAP,
            payload: undefined
        }
    },
    stoppedMapEdit: () => {
        return async (dispatch) => {
            dispatch(MapActionCreators.stopMapEdit())
        }
    },
    heatMapAdd: (layerId) => {
        return {
            type: MAP_HEATMAP_ADD_ID,
            payload: layerId
        }
    },
    heatMapDel: (layerId) => {
        return {
            type: MAP_HEATMAP_DEL_ID,
            payload: layerId
        }
    },
    setMapWmsTileLayer: (id) => {
        return {
            type: MAP_SET_WMS_TILE_LAYER,
            payload: id
        }
    },
    setChangeIcon: (iconId) => {
        return {
            type: MAP_SET_CHANGE_ICON,
            payload: {
                iconId,
                timestamp: new Date().getTime()
            }
        }
    }
}