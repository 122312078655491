import { MapContainer } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";


const LeafletMap = ({ control, children }) => {
    const params = new URLSearchParams(window.location.search)
    const c = params.get('c') ? params.get('c') : false;
    const z = params.get('z') ? params.get('z') : false;

    const center = c ? c.split(',') : [57.624267, 39.889667]
    const zoom = z ? z : 10

    return (
        <MapContainer
            center={center}
            zoom={zoom}
            scrollWheelZoom={true}
            style={{ height: '100vh', width: '100vw', zIndex: 1, }}
            zoomControl={false}
            className="map_container"
            attributionControl={false}
        >
            {control}
            {children}
        </MapContainer>
    )
}

export default LeafletMap