import { Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { useActions } from '../../../hooks/useActions';
import { Tiles } from "../../../store/reducers/map/index";
import { mapSelector } from '../../../store/selectors/selectors';
import HistoryLayer from '../../general/HistoryLayer/HistoryLayer'


const TileItem = ({ name, icon, selected, onClick }) => {
    return (
        <Grid item xs={3} align={"center"}>
            <Box
                onClick={onClick}
                width={71}
                height={71}
                padding={"2px"}
                border={2}
                borderColor={selected ? "#27AE60" : "transparent"}
                style={{
                    boxSizing: 'content-box',
                    borderRadius: 10, marginBottom: '8px'
                }}
            >
                <Box style={{
                    height: 71,
                    width: 71,
                    backgroundImage: `url(${icon})`,
                    borderRadius: "5px"
                }}
                />
            </Box>
            <Typography width={71} color={selected ? "#27AE60" : "inherit"} sx={{ lineHeight: '18px', fontWeight: 600, fontSize: '14px' }}>{name}</Typography>
        </Grid>
    );
};


const MapSettings = () => {
    const settings = useSelector(mapSelector)
    const { setMapTileLayer, clearRecent, resetSelectedLayerIds } = useActions()

    const handleClearButton = () => {
        clearRecent();
        resetSelectedLayerIds();
        console.log("recent cleared");
    }

    
    return (
        <Box style={{
            marginLeft: '15px',
            marginRight: '15px',
            marginTop: '25px',
            letterSpacing: '0.005em',
        }} >
            <Typography sx={{ fontSize: "20px", fontWeight: 600, lineHeight: '28px' }}>Подложки</Typography>
            <Grid container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                rowSpacing={1}
                sx={{ marginTop: '12px' }}
            >
                {Tiles.map(tile => {
                    return (<TileItem
                        key={tile.id}
                        icon={tile.icon}
                        name={tile.name}
                        selected={tile.id === settings.tileLayer.id}
                        onClick={_ => setMapTileLayer(tile.id)}
                    />)
                }
                )}
            </Grid>
            
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: "10px" }}>
                <Typography sx={{ fontSize: "20px", fontWeight: 600, lineHeight: '28px' }}>История</Typography>

                <Typography sx={{ fontSize: "16px", color: '#333333', opacity: 0.5, marginTop: '3px', lineHeight: '20px', letterSpacing: '0.005em' }} onClick={handleClearButton}>Очистить</Typography>
            </div>

            <HistoryLayer/>
        </Box>
    );
};

export default MapSettings;