import PropTypes from 'prop-types'
import $ from "jquery"
import L from "leaflet";
import { useMap } from 'react-leaflet';
import CreateWMS from './CreateWMS';
import CreateArcGIS from './CreateArcGIS';
import CreateWmsWfs from './CreateWmsWfs';
import CreateTile from './CreateTile';
import { IMG_LAYER_PATH } from "../../../config";
import CreateWFS from "./CreateWFS";
import LayerDistortableImage from "./LayerDistortableImage";
import useLayerById from "../../../hooks/reactQuery/selectorCache/useLayerById";


const ItemMapLayer = ({ layerId }) => {
    // console.log("render ItemMapLayer");

    const layer = useLayerById(layerId)

    const map = useMap()

    if (!layer) return null

    if (layer.typeId === 1) { // WFS
        return <CreateWFS layer={layer} />
    }
    if (layer.typeId === 2) { // WMS
        return <CreateWMS wmsUrl={layer.wmsUrl} layerId={layerId} />
    }

    if (layer.typeId === 3) { // TILE
        return <CreateTile url={layer.url} />
    }

    if (layer.typeId === 5) { // ARCGIS
        return <CreateArcGIS url={layer.url} layerId={layer.id} />
    }

    if (layer.typeId === 6) { // WMS_WFS
        return <CreateWmsWfs layerId={layerId} />
    }

    if (layer.typeId === 7) { // Image
        const imageUrl = IMG_LAYER_PATH + layer.pictureUrl
        const b = map.getBounds()

        // если layer.pictureBounds - пустой, то берем след. значения границ
        let imageBounds = [
            [b.getNorthWest().lat, b.getNorthWest().lng],
            [b.getNorthEast().lat, b.getNorthEast().lng],
            [b.getSouthEast().lat, b.getSouthEast().lng],
            [b.getSouthWest().lat, b.getSouthWest().lng]
        ]

        if (layer.pictureBounds) {
            try {
                let bounds = $.parseJSON(layer.pictureBounds)
                if (bounds != null) imageBounds = bounds
            } catch (e) {
                console.log(e)
            }
        }

        return (
            <LayerDistortableImage
                url={imageUrl}
                corners={[
                    new L.latLng(imageBounds[0][0], imageBounds[0][1]),
                    new L.latLng(imageBounds[1][0], imageBounds[1][1]),
                    new L.latLng(imageBounds[3][0], imageBounds[3][1]),
                    new L.latLng(imageBounds[2][0], imageBounds[2][1]),
                ]}
                editable={false}
                selected={false}
                suppressToolbar={false}
                zIndex={200}
                opacity={Number(layer.pictureOpacity) > 0 ? layer.pictureOpacity / 100 : 1}
            />
        )
    }
    return null
}

ItemMapLayer.propTypes = {
    layerId: PropTypes.number.isRequired,
}

export default ItemMapLayer