import { Pagination, PaginationItem, Stack } from '@mui/material';
import React from 'react';
import { ReactComponent as LeftIcon } from '../../../Icons/desktop/pagination/LeftIcon.svg'
import { ReactComponent as RightIcon } from '../../../Icons/desktop/pagination/RightIcon.svg'


const Previous = () => <Stack direction='row' alignItems='center'><LeftIcon style={{ marginRight: '4px' }} />Предыдущие</Stack>
const Next = () => <Stack direction='row' alignItems='center'>Следующие<RightIcon style={{ marginLeft: '4px' }} /></Stack>

const MyPagination = (props) => {

    return (
        <Stack alignItems='center'>
            <Pagination
                {...props}
                renderItem={(item) => (
                    <PaginationItem
                        slots={{
                            previous: Previous,
                            next: Next
                        }}
                        {...item}
                    />
                )}
            />
        </Stack>
    )
}

export default MyPagination