import { Button } from "@mui/material"
import { useContext, useState } from "react"
import TreeListLayers from "../TreeListLayers/TreeListLayers"
import { useCopyToLayerMutation } from "../../../hooks/reactQuery/useLayer"
import { ModalWindowContext } from "../../../hooks/useModal/ModalWindow"
import FormContainer from "../ForForms/FormContainer"


const CopyLayer = ({ layerId }) => {

    const [selectedLayerId, setSelectedLayerId] = useState()
    const { onClose } = useContext(ModalWindowContext)

    const { mutate: moveToLayerQuery } = useCopyToLayerMutation()

    const handleCopyLayer = (parentLayerId) => {
        moveToLayerQuery({ layerId, parentLayerId })
        onClose()
    }

    return (
        <FormContainer
            enableDividerTop
            action={
                <Button variant='contained'
                    onClick={() => handleCopyLayer(selectedLayerId)}
                    disabled={!selectedLayerId}
                >
                    Скопировать в выбранный слой
                </Button>
            }
        >
            <TreeListLayers {...{
                setSelectedLayerId,
                disabledLayerIds: [layerId],
                isDisabledIsNonServiceLayer: true
            }} />
        </FormContainer>
    )
}

export default CopyLayer