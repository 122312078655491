import { pxToRem } from "../../Style/desktopTheme"

// Styled
const sxAccordion = {
    '&.MuiAccordion-root:last-of-type': {
        borderRadius: 0,
    },
    '&.MuiAccordion-root': {
        boxShadow: 'none',
        border: 'none',
        m: 0,
    },
    '&.MuiAccordion-root::before': {
        height: '0px',
        backgroundColor: 'white',
    }    
}

const sxAccordionSummary = {
    flexDirection: 'row-reverse',
    alignItems: 'flex-start',
    borderTop: '1px solid',
    borderColor: 'myGray.main',
    '.MuiAccordionSummary-expandIconWrapper': {
        mt: '12px'
    },
    '.MuiAccordionSummary-content.Mui-expanded': {
        m: '12px 0'
    },
    '&.MuiAccordionSummary-root': {
        pl:pxToRem('16px'),
        pr:pxToRem('16px'),
    }
}

const sxAccordionDetails = {
    backgroundColor: 'myGray.light1',
    pt: 0,
    pb: '7px',
    pl: pxToRem('16px'),
    pr: pxToRem('16px'),
}

const sxListItem = {
    padding: '16px',
    backgroundColor: 'white',
    mt: '8px',
    mb: '8px',
    borderRadius: '12px',
    cursor: 'pointer',
    '&.selected': {
        cursor: 'auto',
        backgroundColor: 'myGreen.main',
        'a: hover': { textDecorationColor: 'white' },
        '.MuiTypography-root': { color: 'white', },
        '.MuiBreadcrumbs-li span': { color: 'white', },
        '.MuiBreadcrumbs-li a': { color: 'rgba(255, 255, 255, 0.60);', },
        '.MuiBreadcrumbs-separator svg': { stroke: 'rgba(255, 255, 255, 0.60);', }
    }
}

const sxTextField = {
    '& .MuiOutlinedInput-root': {
        pr: '4px'
    }
}

const sxPaper = {
    overflow: 'auto',
    boxShadow: 'none',
    borderBottom: '1px solid',
    borderColor: 'myGray.main',
}

export {sxAccordion, sxAccordionSummary, sxAccordionDetails, sxListItem, sxTextField, sxPaper}