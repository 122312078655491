import React, { useState } from 'react'
import { Backdrop, Box, IconButton, Menu, MenuItem, SvgIcon } from '@mui/material'
import { useSelector } from 'react-redux'
import useCanEdit from '../../../hooks/useCanEdit'
import { editOnMapLayerSelector, userRoleSelector } from '../../../store/selectors/selectors'
import { useActions } from "../../../hooks/useActions";
import { useModal } from '../../../hooks/useModal/useModal'
import LayerProperty from '../LayerProperty/LayerProperty'
import { ImportExport } from '../ImportExport/ImportExport'
import Feedback from '../../general/Feedback/Feedback'
import NewLayer from '../LayerProperty/NewLayer'
import { useDelAllObjectsFromLayerMutation } from '../../../hooks/reactQuery/useObject'
import { useDelLayerMutation, useLayersByParentIdQuery } from '../../../hooks/reactQuery/useLayer'
import MoveToLayer from '../ActionToLayer/MoveToLayer'
import { ReactComponent as LayerMenuIcon } from "../../../Icons/desktop/layer/LayerMenuIcon.svg";
import { pxToRem } from '../../Style/desktopTheme'
import AnaliticSettings from '../AnaliticSettings/AnaliticSettings'
import CopyPropsToLayer from '../ActionToLayer/CopyPropsToLayer'
import CopyLayer from '../ActionToLayer/CopyLayer'


export const LayerMenu = (props) => {

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <Box>
                <IconButton size='40' onClick={handleClick}>
                    <SvgIcon style={{ fontSize: pxToRem('32px') }} inheritViewBox={true} ><LayerMenuIcon /></SvgIcon>
                </IconButton>
            </Box>

            {open && <ItemLayerMenu {...props} anchorEl={anchorEl} handleClose={handleClose} />}
        </>
    )
}



const ItemLayerMenu = ({ layerId, name, parentId, isService, permissions, typeId, heatmap, haveObjects, anchorEl, handleClose }) => {

    const [anchorAnalysys, setAnchorAnalysys] = useState(null)
    const open = Boolean(anchorEl)
    const canEdit = useCanEdit(layerId)
    const { isEditor, isProjectEditor, isGeoEditor, isAdministrator } = useSelector(userRoleSelector)
    const editOnMapLayer = useSelector(editOnMapLayerSelector);

    const { openWindow, openConfirm } = useModal()

    const { mutate: delAllObjectsFromLayer } = useDelAllObjectsFromLayerMutation()
    const { mutate: deleteLayer } = useDelLayerMutation()

    const { startMapEdit, selectLayerById, stoppedMapEdit, heatMapAdd, deselectLayerByIds } = useActions()

    // для проверки наличия дочерних слоев у выбранного слоя с isService == true 
    const { data: layersByParent, layersByParentisLoading } = useLayersByParentIdQuery({ layerId, enabled: isService })

    const openNewLayer = (parentId) => {
        openWindow({
            title: 'Новый слой',
            children: <NewLayer {...{ parentId }} />,
            width: 800
        })
    }

    const openProps = (layerId) => {
        openWindow({
            title: 'Свойства слоя',
            children: <LayerProperty />,
            layerId,
            width: 800
        })
    }

    const openImportExport = ({ layerId, tabs }) => {
        openWindow({
            title: 'Импорт, экспорт объектов',
            children: <ImportExport tabs={tabs} layerId={layerId} />,

        })
    }

    const openFeedback = (layerId) => {
        openWindow({
            title: 'Обратная связь',
            children: <Feedback layerId={layerId} />,
            width: 600
        })
    }

    const handleRemoveLayer = (layerId, name, /*parentId*/) => {
        deselectLayerByIds([layerId])
        openConfirm({
            title: 'Удаление слоя',
            children: `Вы действительно желаете удалить слой ${name}?`,
            cancelText: 'Отмена',
            confirmText: 'Применить',
            onConfirm: () => deleteLayer({ layerId }),
        })
    }

    const handleMoveToLayer = (layerId, name) => {
        openWindow({
            title: `Переместить слой "${name}"`,
            children: <MoveToLayer layerId={layerId} />,
            width: 800
        })
    }

    const handleCopyPropsToLayer = (layerId) => {
        openWindow({
            title: 'Скопировать настройки',
            children: <CopyPropsToLayer layerId={layerId} />,
            width: 800
        })
    }

    const removeObjectsToLayer = (layerId, name) => {
        openConfirm({
            title: 'Удалить все объекты',
            children: `Вы действительно желаете удалить все объекты из ${name}?`,
            cancelText: 'Отмена',
            confirmText: 'Применить',
            onConfirm: () => delAllObjectsFromLayer({ layerId }),
        })
        handleClose()
    }

    const handleMapEdit = (id) => {
        if (editOnMapLayer) {
            stoppedMapEdit().then(() => {
                startMapEdit(id)
                selectLayerById(id)
            })
        } else {
            startMapEdit(id)
            selectLayerById(id)
        }
        handleClose()
    }

    const handleAnaliticProps = (layerId, layerName) => {
        openWindow({
            title: `Настройка аналитики для слоя "${layerName}"`,
            children: <AnaliticSettings />,
            layerId,
            width: 800
        })
        handleClose()
    }

    /*** Обработчики пункта меню  - Аналитические карты ***/
    const handleClickAnalysys = (e) => {
        setAnchorAnalysys(e.currentTarget)
    }

    const handleCloseAnalysys = () => {
        setAnchorAnalysys(null)
    }

    const handleHeatMap = (layerId) => {
        heatMapAdd(layerId)
        selectLayerById(layerId)
        handleCloseAnalysys()
        handleClose()
    }

    const handleCopyLayer = (layerId, name) => {
        openWindow({
            title: `Скопировать слой "${name}"`,
            children: <CopyLayer layerId={layerId} />,
            width: 800
        })
    }

    const propList = [
        {
            name: 'Свойства',
            callback: () => {
                handleClose()
                openProps(layerId)

            }
        },
    ]

    if (!isService) {
        propList.push({
            name: 'Обратная связь',
            callback: () => {
                handleClose()
                openFeedback(layerId)
            }
        })
    }


    // Добавляем пункты меню в зависимости от прав
    if (((canEdit || (isProjectEditor && permissions === 1)) || isAdministrator)
        && (typeId === 1 || typeId === 6)
        && (parentId !== null)
        && !isService) {

        propList.unshift({
            name: 'Импорт, экспорт',
            callback: () => {
                handleClose()
                openImportExport({
                    layerId: layerId,
                    tabs: isGeoEditor ? ['import', 'export'] : ['export']
                })
            }
        })
    }

    if (isAdministrator || (isProjectEditor && permissions !== 0)) {
        propList.unshift({
            name: 'Создать слой',
            callback: () => {
                handleClose()
                openNewLayer(layerId)
            }
        })
    }

    if ((canEdit || (isProjectEditor && permissions === 1))
        && (typeId === 1 || typeId === 6 || typeId === 7)
        && (parentId !== null)
        && isGeoEditor
        && !isService) {
        propList.push({
            name: 'Редактировать на карте',
            callback: () => {
                handleMapEdit(layerId);
            }
        })
    }

    if ((canEdit || (isProjectEditor && permissions === 1))
        && (typeId === 1 || typeId === 6)
        && (parentId != null)
        && !isService) {
        propList.push({
            name: 'Редактировать в таблице',
            callback: () => {
                window.open("/mass/edit/" + layerId, 'massedit')
            }
        })
    } else if (!isService) { // Редактирование недоступно, активируем пункт КМ "Просмотр в таблице"
        propList.push({
            name: 'Просмотр в таблице',
            callback: () => {
                window.open("/mass/edit/" + layerId, 'massview')
            }
        })
    }

    // Аналитические карты
    if (((heatmap) && (typeId === 1 || typeId === 6))) {
        propList.push({
            name: <>
                <Box onClick={handleClickAnalysys}>Аналитические карты</Box>
                <Menu
                    anchorEl={anchorAnalysys}
                    open={Boolean(anchorAnalysys)}
                    onClose={handleCloseAnalysys}
                    autoFocus={true}
                >
                    {heatmap && <MenuItem onClick={() => handleHeatMap(layerId)}>Тепловая карта</MenuItem>}
                </Menu>
                <Backdrop open={Boolean(anchorAnalysys)} />
            </>,
            callback: () => { }
        })
    }


    if ((canEdit || (isProjectEditor && permissions === 1)) && (typeId === 1 || typeId === 6) && (parentId != null) && !isService) {
        propList.push({
            name: 'Настройка аналитики',
            callback: () => handleAnaliticProps(layerId, name),
        })
    }


    if (isEditor || (isProjectEditor && canEdit)) {

        if (isService) {

            propList.push({
                name: 'Удалить папку',
                callback: () => handleRemoveLayer(layerId, name, parentId),
                disabled: layersByParent?.length > 0 || layersByParentisLoading
            })
        } else {
            propList.push({
                name: 'Удалить слой без объектов',
                callback: () => handleRemoveLayer(layerId, name, parentId),
                disabled: haveObjects,
            })
        }
    }

    if (isAdministrator && !isService && typeId !== 7) {
        propList.push({
            name: 'Удалить все объекты',
            callback: () => removeObjectsToLayer(layerId, name),
            disabled: !haveObjects,
        })
    }

    if (isEditor || (isProjectEditor && canEdit) || isAdministrator) {
        propList.push({
            name: isService ? 'Переместить папку': 'Переместить слой',
            callback: () => {
                handleClose()
                handleMoveToLayer(layerId, name)
            }
        })
    }

    if (((canEdit || (isProjectEditor && permissions === 1)) || isAdministrator)
        && (typeId === 1 || typeId === 6)
        && (parentId != null)
        && !isService) {
        propList.push({
            name: 'Скопировать настройки',
            callback: () => handleCopyPropsToLayer(layerId),
        })
    }

    if (((canEdit || (isProjectEditor && permissions === 1)) || isAdministrator)) {
        propList.push({
            name: 'Скопировать слой',
            callback: () => handleCopyLayer(layerId, name),
        })
    }

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            autoFocus={true}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            {propList.map((itemProp => <MenuItem key={itemProp.name} onClick={(e) => itemProp.callback(e)} disabled={itemProp.disabled}>{itemProp.name}</MenuItem>))}
        </Menu>
    )
}

export default LayerMenu