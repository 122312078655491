import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types'
import MarkerClusterGroup from 'react-leaflet-cluster';
import { FeatureGroup } from "react-leaflet";
import { CLUSTERING_AMOUNT, MEDIUM_HEIGHT_BOTTOMSHEET } from '../../../config';
import { useObjectsToLayerQuery } from '../../../hooks/reactQuery/useObjectsToLayer';
import CreateObjectsWFS from '../../general/MapLayers/CreateObjectsWFS';
import { RoutePaths } from '../../../routes';
import { DrawerContext } from '../../desktop/SidePanel/SideContextProvider';
import useNavigate from '../../../hooks/useNavigateWithSearchParams'
import { useParams } from 'react-router-dom';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import { useBottomSheet } from '../../../hooks/useBottomSheet';


const WfsGroupMapLayer = ({ layerId }) => {
    const { isMobile } = useDeviceDetect()
    return isMobile ? <MobWfsGroupMapLayer layerId={layerId} /> : <DesktopWfsGroupMapLayer layerId={layerId} />
}

const MobWfsGroupMapLayer = ({ layerId }) => {
    let navigate = useNavigate()
    const { bottomSheetRef } = useBottomSheet()
    const handleClick = (layerId, objectId) => {
        navigate(RoutePaths.OBJECT.replace(':layerId', layerId).replace(':objectId', objectId));
        bottomSheetRef.current.snapTo(MEDIUM_HEIGHT_BOTTOMSHEET)
    }
    return <WfsGroupMapLayerInner layerId={layerId} handleClick={handleClick} />
}


const DesktopWfsGroupMapLayer = ({layerId}) => {
    let navigate = useNavigate()
    const { setSecondDrawer } = useContext(DrawerContext)
    const handleClick = (layerId, objectId) => {
        setSecondDrawer(true)
        navigate(RoutePaths.OBJECT.replace(':layerId', layerId).replace(':objectId', objectId))
    }
    return <WfsGroupMapLayerInner layerId={layerId} handleClick={handleClick} />
}

const WfsGroupMapLayerInner = ({ layerId, handleClick }) => {

    const selectedObjectId = Number(useParams().objectId)
    const { data: objects, status } = useObjectsToLayerQuery(layerId)
    const clusterGroupRef = useRef()
    const CLUSTERING_AMOUNT_NEW = layerId === 4354 ? 25 : CLUSTERING_AMOUNT // временно! по просьбе Оли

    if (status === 'loading') return null

    const props = { layerId, handleClick, clusterGroupRef }
    if ((objects.length > CLUSTERING_AMOUNT_NEW)) {
        return (
            <>
                <MarkerClusterGroup
                    ref={clusterGroupRef}
                    chunkedLoading={true}
                    removeOutsideVisibleBounds={false}
                    maxClusterRadius={40}
                >
                    <CreateObjectsWFS {...props} objects={objects.filter(o => o.id !== selectedObjectId)} />
                </MarkerClusterGroup>

                {/* Выделенный объект - располагаем вне кластера, чтобы корректно отображался маркер при изменении масштаба */}
                <FeatureGroup>
                    <CreateObjectsWFS {...props} objects={objects.filter(o => o.id === selectedObjectId)} />
                </FeatureGroup>
            </>
        )
    } else {
        return (
            <FeatureGroup>
                <CreateObjectsWFS {...props} objects={objects} />
            </FeatureGroup>
        )
    }
}

WfsGroupMapLayer.propTypes = {
    layerId: PropTypes.number.isRequired
}

export default WfsGroupMapLayer




