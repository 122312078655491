import { Navigate, Route, Routes } from "react-router-dom"
import HomePage from "../../../pages/HomePage"
import { RoutePaths } from "../../../routes"
import LayerPage from "../../../pages/LayerPage"
import ObjectPage from "../../../pages/ObjectPage"
import SearchLocationPage from "../../../pages/SearchLocationPage"
import TestPage from "../../../pages/TestPage"
import DesktopMapLayout from "../../desktop/Layout/MapLayout"
import MobMapLayout from "../../mob/Layout/MapLayout"
import MobTextLayout from "../../mob/Layout/TextLayout"
import OldMapPage from "../../../pages/OldMapPage"
import HistoryLayerPage from "../../../pages/HistoryLayerPage"
import useDeviceDetect from "../../../hooks/useDeviceDetect"
import HelpPage from "../../../pages/HelpPage";
import InfoPage from "../../../pages/InfoPage";
import FilterObjectsPage from "../../../pages/FilterObjectsPage"
import ArcGisObjectsPage from "../../../pages/ArcGisObjectsPage"
import ArcGisObjectPage from "../../../pages/ArcGisObjectPage"



/* Использование Layout react router https://it-dev-journal.ru/articles/polnoe-rukovodstvo-po-react-router-v6-opredeleniya-marshrutov */
const AppRouter = () => {
    const { isMobile } = useDeviceDetect()    
    return (
        <Routes>
            <Route path="/" element={isMobile ? <MobMapLayout /> : <DesktopMapLayout />}>
                <Route path={RoutePaths.HOME} element={<HomePage />} />
                <Route path={RoutePaths.LAYER + '/*'} element={<LayerPage />} />
                <Route path={RoutePaths.OBJECT} element={<ObjectPage />} />
                <Route path={RoutePaths.HISTORY_LAYER} element={<HistoryLayerPage />} />
                <Route path={RoutePaths.TEST} element={<TestPage />} />
                <Route path={RoutePaths.SEARCH_LOCATION} element={<SearchLocationPage />} />
                <Route path={RoutePaths.FILTER_OBJECTS} element={isMobile? <FilterObjectsPage/>: null} />   {/*роутинг для отображения Фильтра объектов в MainPanel */}
                <Route path={RoutePaths.FILTER_OBJECTS_DETAIL} element={isMobile? <FilterObjectsPage/>:<ObjectPage />} />  {/*роутинг для отображения Фильтра объектов в MainPanel + Детальная инф о объекте */}
                <Route path={RoutePaths.OLD_MAP_PAGE} element={<OldMapPage />} />

                <Route path={RoutePaths.ARCGIS} element={isMobile? <ArcGisObjectsPage/>: <ArcGisObjectPage/>}/>

                <Route path="*" element={<Navigate to={RoutePaths.HOME} replace />} /> Вместо 404 страницы - мы делаем редирект на HomePage
            </Route>
            <Route path='/static/' element={
                isMobile
                    ? <MobTextLayout />
                    : <Navigate to={RoutePaths.HOME} replace />
            }>
                <Route path={RoutePaths.HELP} element={<HelpPage />} />
                <Route path={RoutePaths.INFO} element={<InfoPage />} />
            </Route>
        </Routes>
    )
}

export default AppRouter