import { useQuery } from "react-query"
import { TIME_UPDATE } from "../../config"
import { loadTreeListLayers } from "../../service/axiosFuncQuery/layerQuery"


// query 
export const useTreeListLayersQuery = (isIncludeAllLayerTypes=false) => {
    return (
        useQuery({
            queryKey: ['treeListLayers'],
            queryFn: () => loadTreeListLayers(isIncludeAllLayerTypes),
            onError: (error) => console.log(error.message),
            staleTime: TIME_UPDATE,
        })
    )
}