import { useState } from 'react';
import { Box, SwipeableDrawer } from '@mui/material';
import MobBottomSheet from '../BottomSheet/MobBottomSheet';
import AppMenu from '../Menu/AppMenu';
import SearchToolBar from '../Search/SearchToolBar';
import LeafletMap from '../../mob/Map/LeafletMap';
import MapControl from '../Map/Control/MapControl';
import MutableTileLayer from '../../general/Map/Leaflet/MutableTileLayer';
import { ModalContextProvider } from '../../../hooks/useModal/useModal';
import { BottomSheetProvider } from '../../../hooks/useBottomSheet';
import LastPages from '../LastPages';
import MapLayers from '../../general/MapLayers/MapLayers';


const MapLayout = () => {

    const drawerWidth = 305;
    const ViewAppMenu = false;
    const [drawerOpen, setDrawerOpen] = useState(ViewAppMenu);

    const toggleDrawer = () => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) { return; }
        setDrawerOpen(!drawerOpen);
    }

    return (
        <>
            <ModalContextProvider>
                <BottomSheetProvider>
                    <LeafletMap control={<MapControl/>} >
                        <MutableTileLayer />
                        <MapLayers />
                        <LastPages />
                    </LeafletMap>

                    <SearchToolBar toggleDrawer={toggleDrawer} />

                    <MobBottomSheet />

                    <SwipeableDrawer
                        anchor={'left'}
                        open={drawerOpen}
                        onClose={toggleDrawer()}
                        onOpen={toggleDrawer()}
                        disableSwipeToOpen={true}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        <Box sx={{ width: drawerWidth, height: '100%' }} role="presentation" onClick={toggleDrawer()} onKeyDown={toggleDrawer()} >
                            <AppMenu />
                        </Box>
                    </SwipeableDrawer>
                </BottomSheetProvider>
            </ModalContextProvider>
        </>
    )
}

export default MapLayout;