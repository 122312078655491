import { Stack, Typography } from "@mui/material"

const MyLabelForm = ({ label, component }) => {
    return (
        <Stack spacing={2}>
            <Typography variant='font_16_24'>{label}:</Typography>
            {component}
        </Stack>
    )
}

export default MyLabelForm