import { Button, Stack } from "@mui/material"
import { useContext, useState } from "react"
import TreeListLayers from "../TreeListLayers/TreeListLayers"
import { useMoveToLayerMutation } from "../../../hooks/reactQuery/useLayer"
import { ModalWindowContext } from "../../../hooks/useModal/ModalWindow"
import FormContainer from "../ForForms/FormContainer"


const MoveToLayer = ({ layerId }) => {

    const [selectedLayerId, setSelectedLayerId] = useState()
    const { onClose } = useContext(ModalWindowContext)

    const { mutate: moveToLayerQuery } = useMoveToLayerMutation()

    const handleMoveToLayer = (parentLayerId) => {
        moveToLayerQuery({ layerId, parentLayerId })
        onClose()
    }

    return (
        <FormContainer
            enableDividerTop
            action={
                <Stack spacing={2} direction='row' sx={{ pl: '32px', pr: '32px' }} >
                    <Button variant='contained' onClick={() => handleMoveToLayer(selectedLayerId)} disabled={!selectedLayerId} title="Test">Переместить</Button>
                    <Button variant='outlined' onClick={() => handleMoveToLayer('')}>Сделать категорией</Button>
                </Stack>
            }
        >
            <TreeListLayers {...{
                setSelectedLayerId,
                disabledLayerIds: [layerId],
                isDisabledIsNonServiceLayer: true
            }} />
        </FormContainer>
    )
}

export default MoveToLayer