import { Box, Divider, Stack } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import BoxLogo from '../BoxLogo/BoxLogo';

const TextLayout = () => {

    return (
        <>
            <Box sx={{ ml: '30px', height: '107px' }}><BoxLogo /></Box>
            <Divider variant='myModal' />

            <Stack spacing={1} sx={{ mt: '13px', ml: '30px', mr: '30px' }}>
                <Outlet />
            </Stack>

        </>
    )
}

export default TextLayout