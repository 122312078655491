import { useParams } from "react-router-dom"
import { useModal } from "../hooks/useModal/useModal"
import { useLayersHierarhyQuery } from "../hooks/reactQuery/useLayer"
import { isAuthSelector } from "../store/selectors/selectors"
import { useSelector } from "react-redux"
import SignIn from "../components/desktop/SignIn/SignIn"
import MyAlert from "../components/general/Info/MyAlert"
import { Link } from "@mui/material"
import { pxToRem } from "../components/Style/desktopTheme"
import useDeviceDetect from "../hooks/useDeviceDetect"


// HOC - проверка доступа к слою. Используем на LayerPage and ObjectPage
const CheckAccesToLayer = ({ children }) => {

    const { isMobile } = useDeviceDetect()
    const { openWindow } = useModal()
    const layerId = Number(useParams().layerId)
    const { error} = useLayersHierarhyQuery({layerId})
    const isAuth = useSelector(isAuthSelector)

    // временная заглушка для isMobil
    if (isMobile) return children

    const openSign = (e) => {
        openWindow({
            title: 'Авторизация',
            children: <SignIn />,
            width: pxToRem('560px'),
            center: true,
        })
        e.preventDefault()
    }

    if (error?.response?.data === 'Access is denied') {
        const message = <>У Вас нет прав на просмотр данного слоя.  {(!isAuth && !isMobile) && <>Пожалуйста <Link href='#' onClick={(e) => openSign(e)}>авторизуйтесь</Link></>}</>
        return <MyAlert severity='info' message={message} />
    }

    return children
}

export default CheckAccesToLayer