import React, { useContext, useState } from 'react';
import Grid from "@mui/material/Grid";
import { Icon, Menu, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import Loader from '../../../Loader/Loader';
import { useGalleryIconsQuery, useRemoveGalleryIconMutation } from '../../../../../hooks/reactQuery/useGallery';
import { useModal } from '../../../../../hooks/useModal/useModal';
import { GalleryContext } from '../../GalleryContext';
import IconItemInfo from './IconItemInfo';
import IconItem from "./IconItem";
import EditItem from './EditItem';


const MenuItemInfo = ({ iconId, handleClose }) => {

    const { openWindow } = useModal()

    const handleClick = (e) => {
        openWindow({
            title: 'Информация icon ' + iconId,
            children: <IconItemInfo iconId={iconId} />,
            backdropOpacity: 0.2,
            width: '50%'
        })

        if (handleClose) {
            handleClose()
        }
    }

    return (
        <MenuItem onClick={handleClick}>
            <Icon color='action'><InfoIcon /></Icon>&nbsp;Информация
        </MenuItem>
    )
}

const MenuItemEdit = ({ iconId, handleClose }) => {
    
    const { openWindow } = useModal()

    const handleClick = (e) => {
        openWindow({
            title: 'Редактирование иконки ' + iconId,
            children: <EditItem iconId={iconId}/>,
            backdropOpacity: 0.2,
            width: '50%'
        })

        if (handleClose) {
            handleClose()
        }
    }

    return (
        <MenuItem onClick={handleClick}>
            <Icon color='action'><EditIcon /></Icon>&nbsp;Редактировать
        </MenuItem>
    )
}



const IconItemList = () => {

    const { openConfirm } = useModal()
    const { params: { term, oldIcon } } = useContext(GalleryContext)
    const { data: icons = [], isFetching } = useGalleryIconsQuery({ term: term.toString(), oldIcon })
    const { mutate: removeIcon } = useRemoveGalleryIconMutation()

    const [menu, setMenu] = useState({ anchorEl: null, activeIconId: null })
    const open = Boolean(menu.anchorEl)

    const handleClickMenu = (e, iconId) => {
        setMenu({ anchorEl: e.currentTarget, activeIconId: iconId })
    }

    const handleClose = () => {
        setMenu({ anchorEl: null, activeIconId: null })
    }

    const handleDelete = () => {
        openConfirm({
            title: 'Сообщение',
            children: 'Вы действительно желаете удалить IconPack ' + menu.activeIconId + '?',
            cancelText: 'Отмена',
            confirmText: 'Удалить',
            onConfirm: () => removeIcon(menu.activeIconId),
        })
        handleClose()
    }

    if (isFetching) return <Loader />
    return (
        <>
            <Grid container spacing={2} sx={{ pl: '12px' }} >
                {icons.map(i =>
                    <Grid item key={i}>
                        <IconItem iconId={i} handleClickMenu={handleClickMenu} activeIconId={menu.activeIconId} />
                    </Grid>
                )}
            </Grid>

            <Menu
                anchorEl={menu.anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItemInfo iconId={menu.activeIconId} handleClose={handleClose} />
                <MenuItemEdit iconId={menu.activeIconId} handleClose={handleClose} />
                <MenuItem onClick={handleDelete}><Icon color='action'><DeleteIcon /></Icon>&nbsp;Удалить</MenuItem>
            </Menu>
        </>
    )
}

export default IconItemList