import { Alert, CircularProgress, IconButton, Stack, styled, Typography } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import React, { createContext, useContext, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import SouthEastOutlinedIcon from '@mui/icons-material/SouthEastOutlined';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { ReactComponent as LayerIcon } from "../../../Icons/layer/ServiceLayerIcon.svg";
import { ReactComponent as ExpandIcon } from '../../../Icons/desktop/treeListLayers/ExpandIcon.svg'
import { ReactComponent as CollapseIcon } from '../../../Icons/desktop/treeListLayers/CollapseIcon.svg'
import { useTreeListLayersQuery } from '../../../hooks/reactQuery/useTreeListLayers';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';


const StyledCheckbox = styled(Checkbox)({
    padding: '0px',
    '& .MuiSvgIcon-root': { fontSize: 20 }
})

const StyledTreeItem = styled(TreeItem)({
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px solid #D9D9D9;`,
    },
})


export const TreeListContext = createContext()
const WrapContext = createContext()

const getNormalizedLayers = (treeList) => {
    // console.log("getNormalizedLayers");
    let normalizedLayers = {}
    const getLayers = (layers) => {
        layers.forEach(l => {
            normalizedLayers = {
                ...normalizedLayers,
                [l.id]: {
                    id: l.id,
                    parentId: l.parentId,
                    name: l.text
                }
            }
            getLayers(l.children)
        })
    }
    getLayers(treeList)
    return normalizedLayers
}


const CountChildrenSelected = ({ id, selectedIds }) => {

    const { normalizedLayers } = useContext(WrapContext)

    const getCounts = (selectedIds, normalizedLayers) => {
        // console.log({selectedIds, normalizedLayers});

        const getParentIds = (id, parentIds) => {
            // if(normalizedLayers[id] === undefined) {
            //     console.log({id});
            // }
            const parentId = normalizedLayers[id]?.parentId
            
            if (parentId) {
                return getParentIds(parentId, [...parentIds, parentId])
            }
            return parentIds
        }

        const hierarhyLayerIds = {}
        selectedIds.forEach(id => {
            const parentIds = getParentIds(id, [])
            if (parentIds.length > 0) {
                hierarhyLayerIds[id] = parentIds
            }
        })

        let counts = {}
        Object.values(hierarhyLayerIds).forEach(ids => {
            ids.forEach(id => {
                counts = {
                    ...counts,
                    [id]: (counts[id] || 0) + 1
                }
            })
        })
        return counts
    }

    const counts = getCounts(selectedIds, normalizedLayers)
    return counts[id] ? `- (${counts[id]})` : ''
}


const ItemLabel = ({ id, parentId, isService, text, children }) => {

    const { activeNodes, field } = useContext(TreeListContext)

    let selectedIds = []
    Object.keys(field.value).forEach(key => {
        if (field.value[key].r || field.value[key].w) {
            selectedIds.push(Number(key))
        }
    })

    //get checked
    const readChecked = Object.keys(field.value).map(Number).includes(id) && field.value[id].r === true
    const writeChecked = Object.keys(field.value).map(Number).includes(id) && field.value[id].w === true

    const handleChangeReadCheckBox = (e, checked) => {
        field.onChange({
            ...field.value,
            [id]: {
                'r': checked,
                'w': field.value[id]?.w ? true : false
            }
        })
    }

    const handleChangeWriteCheckBox = (e, checked) => {
        field.onChange({
            ...field.value,
            [id]: {
                'r': checked ? true : field.value[id]?.r ? true : false,
                'w': checked,
            }
        })
    }

    const handleApplyChildren = (e) => {
        let newValue = {}
        const parentLayerPermission = field.value[id] ? field.value[id] : { 'r': false, 'w': false }

        children.forEach(item => {
            newValue = {
                ...newValue,
                [item.id]: parentLayerPermission
            }
        })
        field.onChange({
            ...field.value,
            ...newValue,
        })
        if (activeNodes.current.includes(id)) {
            e.stopPropagation()
        }
    }

    return <Stack direction='row' alignItems='center' spacing={1.5} >
        <Stack direction='row'>
            <StyledCheckbox {...field} title='Чтение'
                checked={readChecked}
                onChange={handleChangeReadCheckBox}
                onClick={(e) => e.stopPropagation()}
            />
            <StyledCheckbox {...field} title='Запись'
                checked={writeChecked}
                onChange={handleChangeWriteCheckBox}
                onClick={(e) => e.stopPropagation()}
            />

            {children.length > 0 && <IconButton onClick={handleApplyChildren} size='small' sx={{ p: 0 }} title='Применить к дочерним элементам'><SouthEastOutlinedIcon /></IconButton>}
        </Stack>

        {(parentId && !isService)
            ? <WysiwygIcon />
            : <LayerIcon />
        }
        <Typography var={12}>
            {text}
            <CountChildrenSelected {...{ id, selectedIds }} />
        </Typography>
    </Stack>
}



const ItemLayer = ({ treeList }) => {

    return treeList.map(l => {
        const key = 'layer-' + l.id
        return (
            <StyledTreeItem
                key={key}
                itemId={l.id.toString()}
                label={<ItemLabel {...l} />}
            >
                {l.children.length > 0 && <ItemLayer treeList={l.children} />}
            </StyledTreeItem>
        )
    })
}


const TreeListLayersPermissions = ({ control, ...props }) => {

    const { data: treeList, isLoading, error } = useTreeListLayersQuery(true)
    const activeNodes = useRef([])

    if (isLoading) {
        return (
            <Stack alignItems='center' justifyContent='center'>
                <CircularProgress />
            </Stack>
        )
    }

    if (error) {
        return <Alert severity="error">{error.message}</Alert>
    }

    const normalizedLayers = { normalizedLayers: getNormalizedLayers(treeList) }  // формируем нормализованный список слоев из treeList

    return (
        <WrapContext.Provider value={normalizedLayers}>
            <Stack className='overflow'>
                <SimpleTreeView
                    {...props}
                    slots={{
                        collapseIcon: CollapseIcon,
                        expandIcon: ExpandIcon,
                    }}
                    onExpandedItemsChange={(e, nodes) => {
                        activeNodes.current = nodes.map(Number)
                    }}
                >
                    <Controller
                        name='assignedlayers'
                        control={control}
                        render={({ field }) => (
                            <TreeListContext.Provider value={{ activeNodes, field }}>
                                <ItemLayer treeList={treeList} />
                            </TreeListContext.Provider>
                        )}
                    />
                </SimpleTreeView >
            </Stack>
        </WrapContext.Provider>
    )
}

TreeListLayersPermissions.propTypes = {
    control: PropTypes.object.isRequired,
}

ItemLayer.propTypes = {
    treeList: PropTypes.array.isRequired,
}

ItemLabel.propTypes = {
    id: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
}

export default TreeListLayersPermissions