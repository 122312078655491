import { TileLayer } from 'react-leaflet/TileLayer'

 const CreateTile = (props) => {
    
 return (
    <TileLayer 
        url={props.url}
        zIndex={500}
    />
 )
};

export default CreateTile;