import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from "leaflet";
import { ReactLeafletDistortableImage } from '../../cloneOutside/@diwotech/react-leaflet-distortable-imageoverlay/dist';


const LayerDistortableImage = (props) => {

    // добавляем оп-цию прозрачности
    const map = useMap()

    useEffect(() => {
        if (props.opacity) {
            map.eachLayer(l => {
                if (l._url === props.url) {
                    let e = l.getElement();
                    L.DomUtil.setOpacity(e, props.opacity)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map])

    return (
        <ReactLeafletDistortableImage {...props} />
    )
}

export default LayerDistortableImage