import { useSelector } from "react-redux"
import { selectedLayerIdsSelector } from "../../../store/selectors/selectors"
import { useQueries } from "react-query"
import { loadLayerHierarhy } from "../../../service/axiosFuncQuery/layerQuery"
import { getLayerHierarhyFn } from "../../../store/selectors/functions"
import { TIME_UPDATE } from "../../../config"
import useInCacheLayers from "./useInCacheLayers"

const useOnLayerIds = () => {
    const selectedLayerIds = useSelector(selectedLayerIdsSelector)
    const allLayersNormalized = useInCacheLayers({ normalize: true })

    let result = []
    let noFindDataSelectedLayerIds = [] // массив selectedLayerIds по которым данные не найдены в кеше - по ним будем делать запросы
    selectedLayerIds.forEach(id => {
        const ids = getLayerHierarhyFn(id, allLayersNormalized).map(l => l.id)
        if (ids.length > 0) {
            result = [...result, ...ids]
        } else {
            noFindDataSelectedLayerIds.push(id)
        }

    })
    result = [...new Set(result)]

    useQueries(noFindDataSelectedLayerIds.map(id => ({
        queryKey: ['layerHierarhy', String(id)],
        queryFn: () => loadLayerHierarhy(id),
        staleTime: TIME_UPDATE,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            if (error.response.data === 'Access is denied' || failureCount === 2) {
                return false
            }
            return true
        }
    })))

    return result
}

export default useOnLayerIds