import { Box, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone";
import { isEmpty } from "lodash";
import { ImportExportDialogContext } from "../ImportExport";
import api from "../../../../service/http";
import { useQueryClient } from "react-query";
import FormContainer from "../../ForForms/FormContainer";
import LoadingButton from "../../ForForms/LoadingButton";


function ImportDropzone({ onSelect }) {
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            onSelect(file);
            // const reader = new FileReader()
            //
            // reader.onabort = () => console.log('file reading was aborted')
            // reader.onerror = () => console.log('file reading has failed')
            // reader.onload = () => {
            //     // Do whatever you want with the file contents
            //     const binaryStr = reader.result
            //     console.log(binaryStr)
            // }
            // reader.readAsArrayBuffer(file)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    return (

        <div {...getRootProps()} style={{ border: "1px dashed blue", padding: 20 }}>
            <input {...getInputProps()} />
            Перетащите файл сюда или нажмите чтобы выбрать
        </div>
    )
}

const ImportTab = ({ layerId }) => {

    const queryClient = useQueryClient()
    const dialogContext = useContext(ImportExportDialogContext);
    const [file, setFile] = useState(dialogContext.file);
    const [gcs, setGcs] = useState(dialogContext.gcs);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState({});
    const [isLoading, setIsLoading] = useState(false)


    const sleep = async (millis) => {
        return new Promise((resolve => {
            setTimeout(resolve, millis);
        }));
    }

    const getStatus = async () => {
        const resp = await api.get('/integration/import/layerstatus', {});
        return resp.data;
    }
    useEffect(() => {
        let ignore = false;
        const fetchStatus = async () => {
            await sleep(500);
            let status = await getStatus();
            if (!ignore) {
                setStatus(status);
            }
        }

        if (!isEmpty(status)) {
            if (status.totalErrors > 0) {
                setMessage(`При загрузке возникли ошибки. Загружено ${status.loaded} объектов.`);
                setIsLoading(false)
            } else if (status.total === 0) {
                setMessage(``);
            } else if (status.total === status.loaded) {
                queryClient.invalidateQueries(['objectsToLayer', String(layerId)])
                queryClient.invalidateQueries(['category'])
                queryClient.invalidateQueries(['layerHierarhy'])
                queryClient.invalidateQueries(['layersByParentId'])
                queryClient.invalidateQueries(['layer'])
                setMessage(`Загрузка завершена. Загружено ${status.loaded} объектов.`);
                setIsLoading(false)
            } else if (status.total > status.loaded) {
                setMessage(`Загружено ${status.loaded} из ${status.total}`);
                fetchStatus().then();
            }
        }

        return () => {
            ignore = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    const handleImport = async () => {
        const formData = new FormData();
        formData.append("id", layerId);
        formData.append("ref", gcs);
        formData.append("file", file);
        setIsLoading(true)
        try {
            const resp = await api.post('/integration/import/layer', formData);
            setStatus(resp.data);
        } catch (e) {
            setMessage("Ошибка импорта:" + e.message);
            setIsLoading(false)
        }

    }

    const handleChangeGCS = (v) => {
        dialogContext.gcs = v;
        setGcs(v);
    }
    const handleSelectFile = (v) => {
        dialogContext.file = v;
        setFile(v);
    }

    return (
        <FormContainer
            action={
                file &&
                <Stack spacing={1}>
                    <Box>{file.name}</Box>
                    {/* <Box> */}
                        <LoadingButton
                            variant={"contained"}
                            onClick={handleImport}
                            isLoading={isLoading}
                        >Импортировать</LoadingButton>
                    {/* </Box> */}
                </Stack>
            }
            component="form"
        >
            <FormControl fullWidth>
                <InputLabel id="coordinate-system-label">Система координат</InputLabel>
                <Select
                    labelId="coordinate-system-label"
                    id="coordinate-system-select"
                    value={gcs}
                    label="Система координат"
                    onChange={(ev) => handleChangeGCS(ev.target.value)}
                >
                    <MenuItem value="WGS84">World Geodetic System 1984</MenuItem>
                    <MenuItem value="msk_qone1">МСК 76 Зона 1</MenuItem>
                    <MenuItem value="msk_zone2">МСК 76 Зона 2</MenuItem>
                    <MenuItem value="g_gav_yam">УСК Гаврилов-Ям</MenuItem>
                    <MenuItem value="pulkovo42">Пулково 1942</MenuItem>
                    <MenuItem value="pulkovo42_true">Пулково 1942 Лесничества ЯО</MenuItem>
                    <MenuItem value="pulkovo42_4284">Пулково 1942 Адресные планы</MenuItem>
                    <MenuItem value="g_lubim">УСК Любим</MenuItem>
                    <MenuItem value="g_poshehon">УСК Пошехонье</MenuItem>
                    <MenuItem value="g_yglich">УСК Углич</MenuItem>
                    <MenuItem value="g_Pereslavl">УСК Переславль-Залесский</MenuItem>
                    <MenuItem value="g_Prechst">УСК Пречистое</MenuItem>
                    <MenuItem value="g_rostov">УСК Ростов</MenuItem>
                    <MenuItem value="g_yar">УСК Ярославль</MenuItem>
                    <MenuItem value="g_ribinsk">УСК Рыбинск</MenuItem>
                </Select>
            </FormControl>

            <ImportDropzone onSelect={handleSelectFile} />

            {message && <p>{message}</p>}
        </FormContainer>
    )
}

export default ImportTab