import { Box, Button, Divider, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ApiPaths, ORIG_ICON_SIZE, ORIG_TREE_ICON_SIZE } from '../../../../../config';
import { useAddGalleryIconMutation, useUpdateGalleryIconMutation, useUploadGalleryIconMutation } from '../../../../../hooks/reactQuery/useGallery';
import PictureField from '../../../ForForms/PictureField';
import Loader from '../../../Loader/Loader';
import { ModalWindowContext } from '../../../../../hooks/useModal/ModalWindow';


const EditItem = ({ iconId }) => {
    
    const { onClose } = useContext(ModalWindowContext)

    const { mutate: uploadGalleryIcon, data: uploadIconResult, isLoading } = useUploadGalleryIconMutation()
    const { mutate: addGalleryIcon } = useAddGalleryIconMutation()
    const { mutate: updateGalleryIcon } = useUpdateGalleryIconMutation()

    const { handleSubmit, watch, register, setValue } = useForm()

    let tempDir = uploadIconResult ? uploadIconResult.data : null

    const origTreeIconData = watch('origTreeIcon')
    const origIconData = watch('origIcon')

    useEffect(() => {
        if (origTreeIconData && origIconData && origTreeIconData.length > 0 && origIconData.length > 0) {
            handleSubmit(onSubmit)()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [origTreeIconData, origIconData])

    const onSubmit = (data) => {
        uploadGalleryIcon({ origIcon: data.origIcon[0], origTreeIcon: data.origTreeIcon[0] })
    }

    const handleSaveIcon = () => {
        addGalleryIcon(tempDir)
        onClose()
    }

    const handleUpdateIcon = () => {
        updateGalleryIcon({ tempDir, iconId })
        onClose()
    }

    return (
        <>
            <Stack
                variant='mtrbl'
                className={'overflow'} component="form" method='POST' onSubmit={handleSubmit(onSubmit)} spacing={2}>
                <PictureField
                    name='origTreeIcon'
                    label={`Иконка слоя (${ORIG_TREE_ICON_SIZE}*${ORIG_TREE_ICON_SIZE} .svg)`}
                    register={register}
                    setValue={setValue}
                    accept='.svg'
                />

                <PictureField
                    name='origIcon'
                    label={`Иконка объекта (${ORIG_ICON_SIZE}*${ORIG_ICON_SIZE} .svg)`}
                    register={register}
                    setValue={setValue}
                    accept='.svg'
                />

                {tempDir &&
                    <>
                        <Divider />
                        <Typography variant='font_16_24'>Примеры сгенерированных иконок</Typography>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Иконка слоя</TableCell>
                                    <TableCell>
                                        <img
                                            alt={ApiPaths.gallery.TEMP_DIR_TREEICON.replace(":tempDir", tempDir)}
                                            src={ApiPaths.gallery.TEMP_DIR_TREEICON.replace(":tempDir", tempDir)} />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Варианты иконок объекта</TableCell>
                                    <TableCell>
                                        <Stack spacing={1} direction='row' style={{ alignItems: 'center' }}>
                                            <img 
                                                alt={ApiPaths.gallery.TEMP_DIR_DROPICON.replace(":tempDir", tempDir)}
                                                src={ApiPaths.gallery.TEMP_DIR_DROPICON.replace(":tempDir", tempDir)} />

                                            <img 
                                                alt={ApiPaths.gallery.TEMP_DIR_CIRCLEICON.replace(":tempDir", tempDir)}
                                                src={ApiPaths.gallery.TEMP_DIR_CIRCLEICON.replace(":tempDir", tempDir)} />
                                        </Stack>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Оригиналы</TableCell>
                                    <TableCell>
                                        <Stack spacing={1} direction='row' style={{ alignItems: 'center' }}>
                                            <img width={ORIG_TREE_ICON_SIZE} height={ORIG_TREE_ICON_SIZE}
                                                alt={ApiPaths.gallery.TEMP_DIR_ORIG_TREEICON.replace(":tempDir", tempDir)}
                                                src={ApiPaths.gallery.TEMP_DIR_ORIG_TREEICON.replace(":tempDir", tempDir)} />

                                            <img width={ORIG_ICON_SIZE} height={ORIG_ICON_SIZE}
                                                alt={ApiPaths.gallery.TEMP_DIR_ORIG_ICON.replace(":tempDir", tempDir)}
                                                src={ApiPaths.gallery.TEMP_DIR_ORIG_ICON.replace(":tempDir", tempDir)} />
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Box><Button onClick={() => { iconId ? handleUpdateIcon() : handleSaveIcon() }} variant='contained'>Сохранить</Button></Box>
                    </>
                }
            </Stack>

            {isLoading && <Loader />}
        </>
    )
}

export default EditItem