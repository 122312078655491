import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Skeleton from 'react-loading-skeleton'
import { SvgIcon } from '@mui/material';
import { ReactComponent as SeparatorIcon } from "../../../Icons/SeparatorIcon.svg";
import { RoutePaths } from '../../../routes';
import 'react-loading-skeleton/dist/skeleton.css'
import MyAlert from '../../general/Info/MyAlert';
import Link from '../../../hoc/LinkWithSearchParams'
import { pxToRem } from "../../Style/desktopTheme"


const BreadCrumbs = ({ isLoading, error, layerHierarhy, isSearch, isHideLastElement, ...props }) => {

    // layerHierarhy[
    //  {id:, name},
    // ]  - массив родительских слоев

    let lastCrumbs = [];
    let breadcrumbs = [];

    if (!isSearch)
        breadcrumbs.push(<Link to={RoutePaths.HOME} key="breadcrumb-0" >Категории</Link>);

    if (layerHierarhy) {
        layerHierarhy.forEach((item, index) => {
            if (index < layerHierarhy.length - 1) {
                breadcrumbs.push(
                    <Link to={RoutePaths.LAYER.replace(':layerId', item.id)} key={'breadcrumb-' + item.id} >
                        {item.name}
                    </Link>)
            }
        });

        if (!isSearch) {
            lastCrumbs = layerHierarhy[layerHierarhy.length - 1];
            breadcrumbs.push(<span key='breadcrumb-last'>{lastCrumbs?.name}</span>);
        }
    }

    if (error) {
        return <MyAlert severity='error' title='Ошибка' message={error.message} />
    }

    if (isHideLastElement) {
        delete breadcrumbs[breadcrumbs.length - 1]
    }

    if (breadcrumbs.length > 0) {
        return (
            <Breadcrumbs
                separator={<SvgIcon inheritViewBox={true} sx={{ width: pxToRem('5px'), height: pxToRem('7px'), }}><SeparatorIcon /></SvgIcon>}
                {...props}
            >
                {isLoading
                    ? <Skeleton width={pxToRem(200)} />
                    : breadcrumbs
                }
            </Breadcrumbs>
        )
    } else return null
}

export default BreadCrumbs;