import { Autocomplete, Box, Button, FormControlLabel, IconButton, Radio, RadioGroup, Stack, SvgIcon, Table, TableBody, TextField } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import CircularProgress from '@mui/material/CircularProgress';
import { useForm, Controller, useFieldArray } from "react-hook-form"
import MaskProgress from "../../../../general/Info/MaskProgress"
import { ReactComponent as PlusIcon } from '../../../../../Icons/desktop/propLayer/PlusIcon.svg'
import { ReactComponent as AddIcon } from '../../../../../Icons/desktop/propLayer/AddIcon.svg'
import { ReactComponent as DelIcon } from '../../../../../Icons/desktop/propLayer/DelIcon.svg'
import { LayerPropertyContext } from "../../LayerPropertyContext";
import { useUpdateLayerPermissionsMutation } from "../../../../../hooks/reactQuery/useLayer";
import { pxToRem } from "../../../../Style/desktopTheme";
import Row from "./Row";
import FormContainer from "../../../ForForms/FormContainer";


const sortByName = (arrData) => {
    return arrData.sort((a, b) => a.name.localeCompare(b.name))
}

const Permissions = () => {

    const { propsLayer: { editor }, layer, formStateRef, allUsers = [], allRoles = [], permissionLayer } = useContext(LayerPropertyContext)

    const { mutate: updateLayerPermissions } = useUpdateLayerPermissionsMutation()

    const [autoCompleteValue, setAutoCompleteValue] = useState(false)
    const [permissionValue, setPermissionValue] = useState(0)

    const [addUser, setAddUser] = useState()
    const [addRole, setAddRole] = useState()


    /* used react-hook-form dynamical fields */
    const { control, handleSubmit, watch, setValue, getValues, formState: { isLoading, isSubmitting } } = useForm({
        defaultValues: formStateRef.current['access']
            ? formStateRef.current['access']
            : async () => {
                // Убираем из allUsers и allRoles тех, кто уже выбран
                const selectedUserIds = permissionLayer.layerUsers.map(u => u.id)
                    .concat(permissionLayer.projectUsers.map(u => u.id))

                const filterAllUsers = [...allUsers]
                    .filter(u => !selectedUserIds.includes(u.id))
                    .map(u => ({ id: u.id, type: 'TYPE_USER', name: u.login, comment: u.fullName, permission: 0 }))

                const selectedRolesIds = permissionLayer.layerRoles.map(u => u.id)
                const filterAllRoles = [...allRoles]
                    .filter(u => !selectedRolesIds.includes(u.id))
                    .map(r => ({ id: r.id, type: 'TYPE_ROLE', name: r.name, comment: r.comment, permission: 0 }))

                return {
                    allUsers: sortByName(filterAllUsers),
                    allRoles: filterAllRoles,
                    permission: [...sortByName(permissionLayer.projectUsers), ...sortByName(permissionLayer.layerUsers), ...sortByName(permissionLayer.layerRoles)],
                    permissionMode: permissionLayer.permissionMode
                }
            },
        mode: 'onSubmit'
    })

    const { append, remove } = useFieldArray({ name: 'permission', control })
    const permission = watch('permission')

    const onSubmit = async (data) => {
        updateLayerPermissions({ layerId: layer.id, data: data.permission })
    }

    const addPermission = () => {
        if (autoCompleteValue) {
            // удаляем из allUsers
            if (autoCompleteValue.type === 'TYPE_USER') {
                setValue('allUsers', [
                    ...watch('allUsers').filter(u => u.id !== autoCompleteValue.id),
                ])
                setAddUser(false) // закрываем панель добавления
            }

            // удаляем из allRoles
            if (autoCompleteValue.type === 'TYPE_ROLE') {
                setValue('allRoles', [
                    ...watch('allRoles').filter(u => u.id !== autoCompleteValue.id),
                ])
                setAddRole(false) // закрываем панель добавления
            }

            // добавляем в permission
            append({
                ...autoCompleteValue,
                permission: permissionValue
            })

            // сбрасываем permissionValue
            if (permissionValue !== 0) {
                setPermissionValue(0)
            }
        }
    }

    const removePermission = (index) => {
        const perm = watch('permission')[index]

        // добавляем в allUsers
        if (perm.type === 'TYPE_USER') {
            setValue('allUsers', sortByName([
                ...watch('allUsers'),
                perm
            ]))
        }

        // добавляем в allRoles
        if (perm.type === 'TYPE_ROLE') {
            setValue('allRoles', sortByName([
                ...watch('allRoles'),
                perm
            ]))
        }

        // удаляем из permission
        remove(index)
    }

    // сохраняем значения полей формы при размонтировании компонента (при переходе на другую вкладку) 
    // Установку при монтировании - см. defaultValues
    useEffect(() => {
        return () => {
            if (!isLoading) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                formStateRef.current['access'] = getValues()
            }
        }
    }, [formStateRef, getValues, isLoading])


    // Styled
    const StackSx = {
        pt: '18px', pb: '18px', pl: '16px', pr: '0px',
        backgroundColor: 'myGray.light',
        justifyContent: 'space-between',
        alignItems: 'center'
    }

    return (
        <>
            <FormContainer
                action={
                    editor && <>
                        <Controller
                            name='send'
                            control={control}
                            disabled={isSubmitting}
                            render={({ field }) =>
                                <Button
                                    type='submit'
                                    variant="contained"
                                    onClick={handleSubmit(onSubmit)} {...field}
                                >Сохранить</Button>
                            }
                        />
                    </>
                }
            >
                {editor &&
                    <Stack direction='row' spacing={2}>
                        <Button variant="outlined" onClick={() => setAddUser(true)}>
                            <SvgIcon style={{ fontSize: pxToRem('18px') }} inheritViewBox><PlusIcon /></SvgIcon>
                            &nbsp;Добавить пользователя</Button>
                        <Button variant="outlined" onClick={() => setAddRole(true)}>
                            <SvgIcon style={{ fontSize: pxToRem('18px') }} inheritViewBox><PlusIcon /></SvgIcon>
                            &nbsp;Добавить роль</Button>
                    </Stack>
                }


                {(addUser || addRole) &&
                    <Stack direction='row' spacing={1} sx={StackSx} >
                        <Autocomplete
                            fullWidth
                            loading={isLoading}
                            options={addUser
                                ? watch('allUsers') ? watch('allUsers') : []
                                : watch('allRoles') ? watch('allRoles') : []
                            }
                            renderOption={(props, option) => (
                                <Box component='li' {...props}>
                                    <Box >{option.name}</Box>
                                </Box>
                            )}
                            getOptionLabel={option => option.name}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={addUser ? 'Пользователи' : 'Роли'}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        )
                                    }}
                                />
                            )}
                            onChange={(e, value, reason) => {
                                if (reason === 'selectOption') {
                                    setAutoCompleteValue(value)
                                }
                            }}
                        />

                        <RadioGroup
                            onChange={(e, value) => setPermissionValue(Number(value))}
                            defaultValue={0}
                            sx={{ pl: '8px', }} row name="permission">
                            <Stack direction='row'>
                                <FormControlLabel value={1} control={<Radio size="small" />} label="Запись" />
                                <FormControlLabel value={0} control={<Radio size="small" />} label="Чтение" />
                            </Stack>
                        </RadioGroup>

                        <Stack direction='row'>
                            <Box><IconButton onClick={() => addPermission()}>
                                <SvgIcon style={{ fontSize: pxToRem('32px') }} inheritViewBox><AddIcon /></SvgIcon>
                            </IconButton></Box>

                            <Box><IconButton onClick={() => {
                                if (addUser) setAddUser(false)
                                if (addRole) setAddRole(false)
                            }}>
                                <SvgIcon style={{ fontSize: pxToRem('32px') }} inheritViewBox><DelIcon fill="rgba(51, 51, 51, 0.4)" /></SvgIcon>
                            </IconButton></Box>
                        </Stack>
                    </Stack>
                }

                <Box component="form" method='POST' onSubmit={handleSubmit(onSubmit)}>
                    <Table>
                        <TableBody>
                            {!isLoading && permission.map((perm, index) => {
                                const props = {
                                    ...perm,
                                    index,
                                    setValue,
                                    removePermission,
                                    permissionMode: watch('permissionMode'),
                                    editor,
                                }
                                return <Row {...props} key={'perm-' + index} />
                            })}
                        </TableBody>
                    </Table>
                </Box>
            </FormContainer>

            {isSubmitting && <MaskProgress />}
        </>
    )
}

export default Permissions