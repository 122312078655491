import { Box, Stack } from "@mui/material"
import Title from "./Title";
import CloseButton from "./CloseButton";
import GoBackButton from "./GoBackButton";
import PropTypes from 'prop-types'
import BreadCrumbs from "./BreadCrumbs";


export const MobTemplateHeaderToolbar = ({ title, handleGoBack, handleClose, breadcrumbs }) => {
    return (
        <>
            <Stack direction='row' sx={{
                mt: '14px',
                pl: '4px',
                pr: '4px',
                width: '100%',
                justifyContent: 'space-between'
            }}>
                <Stack direction='row'>
                    {handleGoBack && <GoBackButton handleClick={handleGoBack} />}
                    <Box sx={{ mt: '6px' }}><Title lines={2} title={title.name} isLoading={title.isLoading} /></Box>
                </Stack>

                {handleClose && <CloseButton handleClick={handleClose} />}
            </Stack>


            {breadcrumbs && <Box sx={{
                ml: '15px', height: '40px', overflowX: 'hidden', overflowY: 'hidden',
            }}>
                <BreadCrumbs {...breadcrumbs} sx={{
                    position: 'relative',
                    '& .MuiBreadcrumbs-li': {
                        lineHeight: '40px',
                    },
                    '.MuiBreadcrumbs-ol': {
                        position: 'absolute',
                        flexWrap: 'unset',
                        left: 0,
                        width: '100%',
                        whiteSpace: 'nowrap',
                    },
                }} />
            </Box>}
        </>
    )
}

export default MobTemplateHeaderToolbar

MobTemplateHeaderToolbar.propTypes = {
    title: PropTypes.object.isRequired,
    handleClose: PropTypes.func,
    handleGoBack: PropTypes.func,
    breadcrumbs: PropTypes.object,
}