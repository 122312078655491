import { themeHtmlFontSize } from "../components/Style/desktopTheme"
import { TIME_UPDATE } from "../config"
import $ from "jquery"

// Из массива слоев делает объект, ключи которого - layerId
export const arrayToObjectLayers = (arrLayers) => {
    let layers = {}
    for (let i = 0; i < arrLayers.length; i++) {
        layers[arrLayers[i].id] = arrLayers[i]
    }
    return layers
}

// Фильтр объекта по ключам
// export const objectFilterByKeys = (object, arrKeys) => {
//     return Object.keys(object)
//         .filter(key => arrKeys.includes(key))
//         .reduce((obj, key) => {
//             obj[key] = object[key]
//             return obj
//         }, {})
// }

// Фильтр объекта по значению
export const objectFilterByValues = (object, term) => {
    const res = {}

    if (!object) return object
    if (term.trim() === '') return object
    
    const keys = Object.keys(object)
    const values = Object.values(object)
    values
        .filter((value, index) => {
            if (value.toLowerCase().indexOf(term.toLowerCase()) >= 0) {
                res[keys[index]] = value
            }
        })
    return res

    // return Object.keys(object)
    //     .filter(key => arrKeys.includes(key))
    //     .reduce((obj, key) => {
    //         obj[key] = object[key]
    //         return obj
    //     }, {})
}


// проверяет вхождение одного массива в другой
export const contains = (where, what) => {
    for (var i = 0; i < what.length; i++) {
        if (where.indexOf(what[i]) === -1) {
            return false;
        }
    }
    return true;
}

// возвращает Set - список уникальных Id (для преобразования родительских id выделенных слоев из того вида, что хранится в store в итоговый Set)
// objectToArray = {
//     id: {
//         parentIds: [id1, id2, ...]
//     },
//     id: {
//         parentIds: [id1, id2, ...]
//     },
// }
export const uniqueObjectValues = (objectToArray) => {
    const set = new Set();
    if (objectToArray) {
        Object.values(objectToArray).forEach(item => {
            item.parentIds.forEach(item => set.add(item));
        })
    }
    return set;
}

export const getFormatDate = (arrDate) => {
    if (!arrDate) return null
    if (arrDate) {
        let mm = arrDate[1];
        let dd = arrDate[2];
        let hh = arrDate[3];
        let min = arrDate[4];

        if (arrDate[1] < 10) mm = '0' + mm;
        if (arrDate[2] < 10) dd = '0' + dd;
        if (arrDate[3] < 10) hh = '0' + hh;
        if (arrDate[4] < 10) min = '0' + min;

        return dd + '.' + mm + '.' + arrDate[0] + ' ' + hh + ':' + min;
    }
}

export const getChangeObjectsDate = (spatialDataWeight) => {
    if (!spatialDataWeight) return null

    let maxDate = null
    const changed = getFormatDate(spatialDataWeight.changed)
    const created = getFormatDate(spatialDataWeight.created)

    const changedDate = spatialDataWeight.changed ? new Date(spatialDataWeight.changed[0], spatialDataWeight.changed[1], spatialDataWeight.changed[2], spatialDataWeight.changed[3], spatialDataWeight.changed[4]).getTime() : '-'
    const createdDate = spatialDataWeight.created ? new Date(spatialDataWeight.created[0], spatialDataWeight.created[1], spatialDataWeight.created[2], spatialDataWeight.created[3], spatialDataWeight.created[4]).getTime() : '-'

    if (changed == null) {
        maxDate = created
    }
    if (changed != null && created != null) {
        maxDate = changedDate > createdDate ? changed : created
    }
    return maxDate
}

export const getFormatDateMs = (ms) => {
    const date = new Date(ms)
    var options = {
        day: 'numeric',
        year: 'numeric',
        month: 'long',
    }
    return date.toLocaleString(date, options)
}

export const rotateCoordinates = (arr) => {
    for (var i in arr) {
        if (typeof arr[i][0] === "object") {
            arr[i] = rotateCoordinates(arr[i]);
            continue;
        }
        arr[i] = [arr[i][1], arr[i][0]];
    }
    return arr;
};

export const isUpdate = (requestTime) => {
    if (!requestTime) return true
    const itemTime = new Date().getTime()
    if (itemTime - requestTime > TIME_UPDATE)
        return true
    else return false
}

export const sortByName = (arrData) => {
    return arrData.sort((a, b) => a.name.localeCompare(b.name))
}

export const replaceNullValuesFromObject = (obj) => {
    for (const key of Object.keys(obj)) {
        if (obj[key] === null || obj[key] === undefined) {
            obj[key] = ''
        }
    }
    return obj
}

// Возращает правильные окончания в зависимости от кол-ва
export const morph = (int, array) => {
    return (array = array || ['объект', 'объекта', 'объектов']) && array[(int % 100 > 4 && int % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(int % 10 < 5) ? int % 10 : 5]];
}


// применяется для пересортировки при DragOnDrop
export const reorderByPosition = (arr, startIndex, endIndex) => {
    if (arr.length === 0) return arr

    const result = [...arr]
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((item, index) => {
        item.position = index
        return item
    })
    return result
}

export const reorderByPositionAlsoLayer = ({ objectTags, layerTags }) => {
    // console.log("dispatch reorderByPositionAlsoLayer", {objectTags, layerTags});

    if (!layerTags || (layerTags && layerTags.length === 0)) {
        return sortByPosition([...objectTags])
    }

    const reorderByPositionObjectTags = sortByPosition([...objectTags])
    const reorderByPositionLayerTags = sortByPosition([...layerTags])

    let newObjectTags = []
    let oldObjectTags = [...reorderByPositionObjectTags]

    reorderByPositionLayerTags.forEach((tag, index) => {
        const findIndex = oldObjectTags.findIndex(t => t.key === tag.key)
        const findTag = oldObjectTags[findIndex]
        findTag.position = index
        newObjectTags.push(findTag)
        oldObjectTags.splice(findIndex, 1)
    })

    // все что не найдено в слоях - добавляем в конец
    if (oldObjectTags.length > 0) {
        oldObjectTags.forEach(tag => {
            newObjectTags.push({
                ...tag,
                position: newObjectTags.length
            })
        })
    }
    // console.log("newObjectTags", newObjectTags);
    return newObjectTags
}

export const sortByPosition = (arr) => {
    return arr.sort((a, b) => a.position - b.position)
}


export const prepareAssignedlayers = (layerPerms) => {
    let assignedlayers = {}
    layerPerms.forEach((item) => {
        assignedlayers = {
            ...assignedlayers,
            [item.layerId]: {
                'r': item.permissions === 1 || item.permissions === 0,
                'w': item.permissions === 1
            }
        }
    })
    return assignedlayers
}

export const isBoolean = (v) => {
    if (v === 'true') return true
    if (v === 'false') return false
}


// возвращает куки с указанным name,
// или undefined, если ничего не найдено
export const getCookie = (name) => {
    let matches = document.cookie.match(new RegExp(
        //eslint-disable-next-line
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const setCookie = (name, value, options = {}) => {
    options = {
        path: '/',
        // при необходимости добавьте другие значения по умолчанию
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }
    document.cookie = updatedCookie;
}


// функция для вычисления значений (например width, height ...) после отработки медиа-запросов - относительно fontSize тега html
// val - исходное значение (в px)
export const sizeScaleFromHtml = (val) => {
    const htmlFontSize = Number($('html').css("font-size").replace('px', ''))
    return Number(val) * htmlFontSize / themeHtmlFontSize
}