import { IconButton, SvgIcon, TableRow, TextField } from "@mui/material"
import ReactFileReader from "react-file-reader"
import { Controller } from "react-hook-form"
import { ReactComponent as DelIcon } from '../../../../../Icons/desktop/propLayer/DelIcon.svg'
import { ReactComponent as UploadIcon } from '../../../../../Icons/desktop/propLayer/UploadIcon.svg'
import { TableCellSideTags, TableCellTextFieldTags } from "../../../StyledComponents/propertyTags"
import { pxToRem } from "../../../../Style/desktopTheme"


const TagsRow = ({ index, control, remove, editor, uploadFile, innerRef, item, ...props }) => (
    <TableRow {...props} ref={innerRef}>
        <TableCellSideTags align='center'>{index + 1}</TableCellSideTags>
        <TableCellTextFieldTags>
            <Controller
                name={`tags.${index}.key`}
                control={control}
                render={({ field }) => {
                    return <TextField {...field} placeholder="Тег" disabled={item.type !== 1} size="small" fullWidth />
                }}
            />
        </TableCellTextFieldTags>
        <TableCellTextFieldTags>
            <Controller
                name={`tags.${index}.value`}
                control={control}
                render={({ field }) => <TextField {...field} placeholder="Значение"
                    InputProps={{
                        endAdornment: (
                            <ReactFileReader fileTypes={['.jpg', '.png', '.bmp', '.gif', '.tiff', '.jpeg', '.rar', '.zip', '.jar', '.gz', '.7z', '.doc', '.docx']} handleFiles={(file) => uploadFile(file, index)}>
                                <IconButton variant="var1" sx={{ ml: pxToRem('8px'), mr: pxToRem('8px') }}>
                                    <SvgIcon sx={{ fontSize: pxToRem('24px') }}><UploadIcon /></SvgIcon><input type='file' hidden />
                                </IconButton>
                            </ReactFileReader>
                        )
                    }}
                    size="small"
                    fullWidth
                />}
            />
        </TableCellTextFieldTags>
        <TableCellSideTags align='center'>
            {editor && item.type === 1
                ? <IconButton variant="var1" onClick={() => remove && remove(index)}>
                    <SvgIcon sx={{fontSize: pxToRem('32px')}}><DelIcon fill="#FC2D29"/></SvgIcon>
                </IconButton>
                : <DelIcon fill="rgba(51, 51, 51, 0.4)" />
            }
        </TableCellSideTags>
    </TableRow>
)

export default TagsRow
