import { useQuery } from 'react-query'
import { SEARCH_TIME_UPDATE } from '../../config';
import { loadSearchLayers, loadSearchLocation } from '../../service/axiosFuncQuery/searchQuery';


export const useSearchLayerQuery = (term='') => {

    return (
        useQuery({
            queryKey: ['searchLayers', term],
            queryFn: () => loadSearchLayers(term),
            onError: (error) => console.log(error.message),
            placeholderData: [],
            staleTime: SEARCH_TIME_UPDATE,
            keepPreviousData: true, // показывать предыдущие данные до загрузки новых
        })
    )
}


export const useSearchLocationQuery = (term = '') => {

    return (
        useQuery({
            queryKey: ['searchLocation', term],
            queryFn: () => loadSearchLocation(term),
            onError: (error) => console.log(error.message),
            placeholderData: [],
            staleTime: SEARCH_TIME_UPDATE,
            keepPreviousData: true,
        })
    )
}