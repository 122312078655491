import { IconButton, Popover, TextField } from "@mui/material";
import ColorizeIcon from '@mui/icons-material/Colorize';
import { forwardRef, useState } from "react";
import { ThemeProvider } from "@mui/styles";
import { ColorBox } from "../../cloneOutside/material-ui-color";
import desktopTheme from "../../Style/desktopTheme";


const ColorTextField = forwardRef(({ setValue, ...props }, ref) => {

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const changeColor = (color) => {
        const colorValue = color.raw.length === 4 ? `rgba(${color.raw[0]},${color.raw[1]},${color.raw[2]},${color.raw[3]})` : `#${color.hex}`
        setValue(props.name, colorValue)
        handleClose()
    }

    return (
        <>
            <TextField
                ref={ref}
                {...props}
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={handleClick}>
                            <ColorizeIcon fontSize="small" sx={{ color: 'rgba(51, 51, 51, 1)' }} />
                        </IconButton>
                    )
                }}
            />

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <ThemeProvider theme={desktopTheme}>
                    <ColorBox
                        defaultValue={props.value ? props.value : '#000'}
                        deferred={true}
                        onChange={changeColor}
                    />
                </ThemeProvider>
            </Popover>
        </>
    )
})

export default ColorTextField