import { Autocomplete, Box, Button, Link, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import React, { useContext, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import CreatePoint from '../../../general/MapLayers/CreatePoint';
import { getFormatDate } from '../../../../service/function';
import { ApiPaths, GEOPORTAL_URL } from '../../../../config';
import CreateShape from '../../../general/MapLayers/CreateShape';
import { useRevertObjectMutation } from '../../../../hooks/reactQuery/useObject';
import { useObjectRevisionsQuery } from '../../../../hooks/reactQuery/useObject';
import Loader from '../../Loader/Loader'
import MaskProgress from '../../../general/Info/MaskProgress';
import { ModalWindowContext } from '../../../../hooks/useModal/ModalWindow';
import FormContainer from '../../ForForms/FormContainer';


const History = () => {

   const { objectId } = useContext(ModalWindowContext)
   const { mutate: revertToObject } = useRevertObjectMutation()

   // For Autocomplete controlled
   const [value, setValue] = useState(false)
   const [inputValue, setInputValue] = useState('')

   const [viewCoordinate, setViewCoordinate] = useState()
   const { data: prepareHistory = {}, isLoading, isFetching } = useObjectRevisionsQuery({ objectId, revId: value?.revId })

   const revertTo = async (objectId, revId, layerIds) => {
      try {
         revertToObject({ objectId, revId, layerIds })
         setValue(false)
         setInputValue('')
      } catch (e) {
         console.log(e)
      }
   }

   if (isLoading) {
      return <Loader />
   }
   else {
      const { history, portalObject, revert } = prepareHistory
      const layer = portalObject.layers?.[0]
      const layerIds = portalObject.layers.map(l => l.id)

      let iconUrl, lineColor, lineWeight, fillColor, fillOpacity = null

      if (!layer.viewByObject) {
         // берем данные из слоя
         iconUrl = GEOPORTAL_URL + ApiPaths.icon.FROM_LAYER.replace(':layerId', layer.id)
         lineColor = layer.lineColor ? layer.lineColor : null
         lineWeight = layer.lineWeight !== null ? layer.lineWeight : null
         fillColor = layer.fillColor ? layer.fillColor : null
         fillOpacity = layer.fillOpacity !== null ? layer.fillOpacity : null
      } else {
         // берем данные из объекта
         if (portalObject.iconId) iconUrl = GEOPORTAL_URL + ApiPaths.icon.FROM_OBJECT.replace(':objectId', portalObject.id)
         if (portalObject.lineColor) lineColor = portalObject.lineColor;
         if (portalObject.lineWeight) lineWeight = portalObject.lineWeight;
         if (portalObject.fillColor) fillColor = portalObject.fillColor;
         if (portalObject.fillOpacity) fillOpacity = portalObject.fillOpacity;

      }

      return (
         <>
            <MaskProgress open={isFetching} />

            <FormContainer>
               <Stack direction='row' spacing={2} alignItems='center'>
                  <Autocomplete
                     fullWidth
                     noOptionsText='Нет доступных ревизий'
                     value={!value ? history?.[0] : value}
                     onChange={(e, value, reason) => {
                        if (reason === 'selectOption') {
                           setValue(value)
                        }
                     }}
                     inputValue={inputValue}
                     onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                     }}
                     disableClearable
                     loading={isLoading}
                     options={history}
                     isOptionEqualToValue={(option, value) => option.revId === value.revId}
                     renderOption={(props, option) => {
                        return (
                           <Box component='li' {...props} key={option.revId}>
                              <Box >{`revId: {${option.revId}} - `} {getFormatDate(option.revDate)} - {option.updatedBy}</Box>
                           </Box>
                        )
                     }}
                     getOptionLabel={option => `revId: {${option.revId}} - ` + getFormatDate(option.revDate) + ' - ' + option.updatedBy}
                     renderInput={params => (<TextField {...params}
                        label={'Ревизия'}
                     />)}
                  />

                  {revert && <Box><Button onClick={() => revertTo(portalObject.id, value.revId, layerIds)} style={{ whiteSpace: 'nowrap' }} variant='outlined'>Откатить к ревизии {value.revId}</Button></Box>}
               </Stack>

               <Stack direction='row' spacing={2} >
                  <Box style={{ tableLayout: 'fixed', wordBreak: 'break-word' }}>
                     <Table>
                        <TableBody>
                           <TableRow>
                              <TableCell sx={{ width: '30%' }}>Наименование</TableCell>
                              <TableCell>{portalObject.name}</TableCell>
                           </TableRow>

                           <TableRow>
                              <TableCell>Координаты</TableCell>
                              <TableCell>
                                 {portalObject.wkt.indexOf('POINT') >= 0
                                    ? portalObject.lat + ' ' + portalObject.lon
                                    : viewCoordinate
                                       ? portalObject.wkt
                                       : <Link href="#" onClick={(e) => { setViewCoordinate(true); e.preventDefault() }} >Показать</Link>
                                 }
                              </TableCell>
                           </TableRow>

                           <TableRow>
                              <TableCell>Создан</TableCell>
                              <TableCell>{portalObject.creator} {getFormatDate(portalObject.createdDateTime)}</TableCell>
                           </TableRow>

                           <TableRow>
                              <TableCell>Ревизия</TableCell>
                              <TableCell>{portalObject.editor} {getFormatDate(portalObject.changedDateTime)}</TableCell>
                           </TableRow>
                        </TableBody>
                     </Table>

                     {portalObject.tags.length > 0 &&
                        <Table>
                           <TableHead>
                              <TableRow>
                                 <TableCell>Тег</TableCell>
                                 <TableCell>Значение</TableCell>
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {portalObject.tags.map(t => (
                                 <TableRow key={t.id}>
                                    <TableCell>{t.key}</TableCell>
                                    <TableCell>{t.value}</TableCell>
                                 </TableRow>
                              ))}
                           </TableBody>
                        </Table>
                     }
                  </Box>

                  <Paper elevation={1} style={{ width: 250, height: 250 }} >
                     <MapContainer
                        center={[portalObject.lat, portalObject.lon]}
                        zoom={13}
                        scrollWheelZoom={false}
                        style={{ width: '250px', height: '250px', zIndex: 1 }}
                        attributionControl={false}
                     >
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                        {portalObject.wkt.indexOf('POINT') >= 0
                           ? <CreatePoint item={portalObject} iconUrl={iconUrl} iconShape={layer.iconShape} selected={false} />
                           : <CreateShape
                              lineColor={lineColor}
                              lineWeight={lineWeight}
                              fillColor={fillColor}
                              fillOpacity={fillOpacity}
                              item={portalObject} // {id, geoJSON, lineColor, lineWeight, fillColor, fillOpacity, objectGeomType}
                              isNoPaddingSelected={true}
                              selected={true}
                           />
                        }

                     </MapContainer>
                  </Paper>

               </Stack>
            </FormContainer>
         </>
      )
   }
}

export default History