import { useLocation, useNavigate } from "react-router-dom"

 const useNavigateWithSearchParams = () => {
    const {search} = useLocation()
    const navigate = useNavigate()

    const navigateWithSearchParams = (url) => {
        navigate(url + search)
    }
    return navigateWithSearchParams
}

export default useNavigateWithSearchParams