import PropTypes from 'prop-types'


const SelectedIcon = ({ iconShape, src }) => {
    // iconShape = 1 || null (unset) - drop
    // iconShape = 0 - circle
    // const color = '#EA6C56'
    const color = '#F73B00'
    const cx = iconShape === 1 ? 17 : 14
    const cy = iconShape === 1 ? 17 : 14
    return (
        <svg width="34" height="43" viewBox="0 0 34 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.3332 17.0515C32.3332 28.7555 16.9999 41.1282 16.9999 41.1282C16.9999 41.1282 1.66653 27.7523 1.66653 17.0515C1.66653 8.55643 8.53187 1.66915 16.9999 1.66915C25.4679 1.66915 32.3332 8.55643 32.3332 17.0515Z" fill={color} stroke="white" strokeLinejoin="bevel" />
            <path d="M16.9999 41.1282L16.5624 41.6329L16.9832 42L17.4177 41.6493L16.9999 41.1282ZM16.9999 41.1282C17.4177 41.6494 17.4175 41.6495 17.4177 41.6493L17.4189 41.6484L17.4215 41.6463L17.4307 41.6388L17.4655 41.6104C17.4959 41.5855 17.5403 41.549 17.5977 41.5011C17.7128 41.4054 17.8804 41.2643 18.0931 41.0811C18.5183 40.7148 19.1239 40.1798 19.85 39.5016C21.3012 38.1459 23.2377 36.214 25.176 33.9102C29.0223 29.3386 33 23.1593 33 17.0512C33 8.18624 25.8367 1 17 1C8.16333 1 1 8.18624 1 17.0512C1 19.8777 2.00927 22.8307 3.47167 25.6372C4.93767 28.4506 6.8844 31.1649 8.8182 33.5263C10.7537 35.8901 12.6873 37.914 14.1362 39.3465C14.8611 40.0632 15.4657 40.6329 15.89 41.0242C16.1021 41.2198 16.2693 41.3709 16.3838 41.4735C16.4411 41.5248 16.4853 41.564 16.5154 41.5906L16.5499 41.621L16.559 41.629L16.5615 41.6312L16.5622 41.6318C16.5624 41.632 16.5625 41.6321 17 41.1274L16.9999 41.1282Z" fill={color} />
            <path d="M16.9999 41.1282L16.5624 41.6329L16.9832 42L17.4177 41.6493M16.9999 41.1282L17.4177 41.6493M16.9999 41.1282C17.4177 41.6494 17.4175 41.6495 17.4177 41.6493M16.9999 41.1282L17 41.1274C16.5625 41.6321 16.5624 41.632 16.5622 41.6318L16.5615 41.6312L16.559 41.629L16.5499 41.621L16.5154 41.5906C16.4853 41.564 16.4411 41.5248 16.3838 41.4735C16.2693 41.3709 16.1021 41.2198 15.89 41.0242C15.4657 40.6329 14.8611 40.0632 14.1362 39.3465C12.6873 37.914 10.7537 35.8901 8.8182 33.5263C6.8844 31.1649 4.93767 28.4506 3.47167 25.6372C2.00927 22.8307 1 19.8777 1 17.0512C1 8.18624 8.16333 1 17 1C25.8367 1 33 8.18624 33 17.0512C33 23.1593 29.0223 29.3386 25.176 33.9102C23.2377 36.214 21.3012 38.1459 19.85 39.5016C19.1239 40.1798 18.5183 40.7148 18.0931 41.0811C17.8804 41.2643 17.7128 41.4054 17.5977 41.5011C17.5403 41.549 17.4959 41.5855 17.4655 41.6104L17.4307 41.6388L17.4215 41.6463L17.4189 41.6484L17.4177 41.6493" stroke="white" strokeWidth="1.64516" strokeLinejoin="bevel" />
            <path d="M16.9999 29.0899C23.6273 29.0899 28.9999 23.7001 28.9999 17.0515C28.9999 10.4029 23.6273 5.01312 16.9999 5.01312C10.3724 5.01312 4.99986 10.4029 4.99986 17.0515C4.99986 23.7001 10.3724 29.0899 16.9999 29.0899Z" fill="white" />

            {src &&
                <foreignObject x='5' y='5' width='24' height='24'>
                    <div style={{ position: 'relative', width: '24px', height: '24px' }}>
                        <svg style={{ width: '24px', height: '24px' }}>
                            <defs>
                                <clipPath id="clipping">
                                    <circle cx={cx} cy={cy} r="9.5" />
                                </clipPath>
                            </defs>
                        </svg>

                        <img style={{
                            clipPath: 'url(#clipping)',
                            position: 'absolute',
                            transform: 'translate(-50%, -50%)',
                            top: iconShape === 1 ? '16px' : '50%',
                            left: '50%',
                        }}
                            src={src}
                            alt='selectedicon'
                        />
                    </div>
                </foreignObject>
            }
        </svg>
    )
}

SelectedIcon.propTypes = {
    iconShape: PropTypes.number,
    src: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]).isRequired,
}

export default SelectedIcon