import React, { useEffect, useRef, useState } from 'react';
import { FeatureGroup } from "react-leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import "./leaflet.draw.css";
import "./additional_leaflet.draw.css";
import iconSVG from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from "leaflet";
import drawLocales from 'leaflet-draw-locales'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useMap } from 'react-leaflet';
import { EditControl } from "../../../cloneOutside/react-leaflet-draw/src/index";
import { ApiPaths, GEOPORTAL_URL } from '../../../../config';
import { useActions } from '../../../../hooks/useActions';
import { useCreateGeometryObjectMutation, useDelObjectsFromLayerMutation, useUpdateGeometryObjectMutation } from '../../../../hooks/reactQuery/useObject';
import { useObjectsToLayerQuery } from '../../../../hooks/reactQuery/useObjectsToLayer';
import { useModal } from '../../../../hooks/useModal/useModal';
import { morph } from '../../../../service/function';
import CreateShape from './CreateShape';
import CustomLeafletButton from "./CustomLeafletButton";


/* Логика: 
   Основной слой <FeatureGroup key='fg_draw'> - на нем находятся все объекты слоя -  drawObjects. На этом слое можно рисовать новые объекты, удалять. Но редактирование отключено.
   При клике на объект - он перемещается на доп. слой <FeatureGroup key='fg_edit' и включается режим редактирования. После завершения редактирования - объект перемещается с этого слоя обратно.
*/

const locale = drawLocales('ru')
L.drawLocal = locale

function DrawControl({ layer }) {

    const { openConfirm } = useModal()

    const { mutate: createGeometryObject, error: createError } = useCreateGeometryObjectMutation()
    const { mutate: updateGeometryObject } = useUpdateGeometryObjectMutation()
    const { mutate: deleteGeometryObject } = useDelObjectsFromLayerMutation()

    const { data: objects } = useObjectsToLayerQuery(layer.id)

    const [newObject, setNewObject] = useState();
    const [name, setName] = useState("");
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false)
    const { stopMapEdit, stoppedMapEdit, startMapEdit, selectLayerById } = useActions();
    const map = useMap()


    const [mapObjects, setMapObjects] = useState({
        drawObjects: [],    // объекты основного слоя - на нем можно рисовать
        editObjects: []     // объекты доп. слоя - только для редактирования
    })
    const [drawMode, setDrawMode] = useState(false) // режим когда на основном слое включаем рисование или удаление

    const controlEditRef = useRef()     // ref на панель Edit, чтобы могли вызывать на ней события
    const initialLoadObjects = useRef(false)  // чтобы обновлять key (setKey) только при изменении objects (когда они перезагружены)
    const tempEditObjectRef = useRef(false) // ref для временного сохранения объекта типа MultiLineString. Когда редактируем объект такого типа - то удаляем его с карты, и вместо него создаем featureGroup состоящуюиз примитивов этого объекта

    useEffect(() => {
        if (drawMode && controlEditRef.current) {
            setEdit(false)
        }
    }, [drawMode])

    useEffect(() => {
        if (
            (!edit && controlEditRef.current) || (drawMode && controlEditRef.current)) {
            controlEditRef.current?._toolbars?.edit?._modes?.edit?.handler?.disable()
        }
    }, [edit, drawMode])

    useEffect(() => {
        if (objects) {
            initialLoadObjects.current = true
            setMapObjects({ drawObjects: objects, editObjects: [] })
        }

        if (newObject) {
            newObject.remove(map)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objects])

    // включение редактирования при клике на объект
    useEffect(() => {
        const editObjects = mapObjects.editObjects
        if (editObjects.length > 0) {
            controlEditRef.current._toolbars.edit._modes.edit.handler.enable()
        }
    }, [mapObjects.editObjects])

    // передаем в каждый объект и вызываем при клике на него
    const handleDrawObjectClick = (e, o) => {
        if (edit && mapObjects.editObjects.length === 0) {
            setMapObjects(v => {
                let newEditObjects = []
                // если тип === MultiLineString, то разбиваем его на примитивные объекты и объединяем их в группу.
                // т.к. leaflet.Draw умеет работать только с примитивными объектами
                if (o.objectGeomType === 'MultiLineString') {
                    const geoJSON = JSON.parse(o.geoJSON)
                    geoJSON.coordinates.forEach((coord, index) => {
                        newEditObjects.push({
                            id: o.id + '-' + index,
                            objectGeomType: 'LineString',
                            geoJSON: JSON.stringify({ type: 'LineString', coordinates: coord })
                        })
                    })
                } else {
                    newEditObjects = [o]
                }
                tempEditObjectRef.current = o       // временно сохраняем объект в Ref
                // console.log({o});

                return ({
                    drawObjects: v.drawObjects.filter(l => l.id !== o.id),
                    editObjects: newEditObjects
                })
            })
        }
    }

    if (createError && newObject) {
        newObject.remove()
    }

    let icon;

    if (layer?.iconId) {
        icon = L.icon({
            iconUrl: GEOPORTAL_URL + ApiPaths.icon.FROM_LAYER.replace(':layerId', layer.id),
            iconSize: [28, 28],
            iconAnchor: [14, 14],
        });
    } else {
        icon = L.icon({
            iconUrl: iconSVG,
            shadowUrl: iconShadow,
            iconSize: [25, 41],
            iconAnchor: [13, 41],
        });
    }

    const clearDrawFeatchureGroup = (layer) => {
        // leaflet draw размещает только что созданные объекты на свою featchureGroups. И ее используют оба компонента - и который для draw и который для edit. 
        // и после попытки создать объект, даже если затем нажали Отмена - нужно очистить данную featureGroups - иначе он выделит два объекта - тот на который мы кликнули и тот что хотели создать
        map.eachLayer(l => {
            if (l.hasLayer && l.hasLayer(layer) && l.options.id !== 'draw') {
                l.clearLayers()
            }
        })
    }

    const createObject = async () => {
        const geoJson = newObject.toGeoJSON();
        createGeometryObject({
            type: 'marker',
            layerId: layer.id,
            name,
            geoJson,
        })
        setOpen(false)
        clearDrawFeatchureGroup(newObject)
    }

    const handleCreate = async (layer) => {
        setNewObject(layer);
        setName("");
        setOpen(true);
    }

    

    const handleClose = () => {
        clearDrawFeatchureGroup(newObject)
        setOpen(false)
        setEdit(false)
    }

    const handleUpdateGeometry = async (layers) => {
        const data = []
        layers.eachLayer((layer) => {

            if (String(layer.options.id).indexOf('-') > 0) {    // MultiLineString
                const [id, index] = layer.options.id.split('-').map(Number)
                const coordinatesItemObject = layer.toGeoJSON().geometry.coordinates

                if (id === tempEditObjectRef.current.id) {
                    // обновляем координаты у временно сохраненного в ref объекта типа MultiLineString
                    let geoJSON = JSON.parse(tempEditObjectRef.current.geoJSON)
                    geoJSON.coordinates[index] = coordinatesItemObject
                    tempEditObjectRef.current.geoJSON = JSON.stringify(geoJSON)
                }

            } else {                                            // примитивный объект - LineString, ...
                tempEditObjectRef.current.geoJSON = JSON.stringify(layer.toGeoJSON().geometry)
                data.push({
                    id: layer.options.id,
                    geojson: JSON.stringify(layer.toGeoJSON())
                })
            }
        })

        if (tempEditObjectRef.current.objectGeomType === "MultiLineString") {
            let newGeoJSON = {
                type: 'Feature',
                properties: {},
                geometry: JSON.parse(tempEditObjectRef.current.geoJSON),
            }
            data.push({
                id: tempEditObjectRef.current.id,
                geojson: JSON.stringify(newGeoJSON)
            })
        }

        updateGeometryObject({
            layerId: layer.id,
            data
        })
    }

    const handleEditStop = () => {
        setMapObjects(v => {

            if (v.editObjects.length === 0) {
                return v
            }

            let editObject = Object.assign(tempEditObjectRef.current)

            // меняем слой редактируемого объекта
            let hasObjectIndex = false
            v.drawObjects.forEach((o, index) => {
                if (o.id === editObject.id) {
                    hasObjectIndex = index
                }
            })

            let newDrawObjects = [...v.drawObjects]
            if (hasObjectIndex) {
                newDrawObjects[hasObjectIndex] = editObject
            }
            else {
                newDrawObjects = [...newDrawObjects, editObject]
            }

            return {
                drawObjects: newDrawObjects,
                editObjects: []
            }
        })
    }

    const handleDeleteObjects = async (layers) => {
        const objectIds = [];
        layers.eachLayer((layer) => {
            objectIds.push(layer.options.id)
        })

        if (objectIds.length === 0) return null

        openConfirm({
            title: 'Подтверждение',
            children: `Вы действительно желаете удалить ${objectIds.length} ${morph(objectIds.length)}?`,
            cancelText: 'Отмена',
            confirmText: 'Удалить',
            onConfirm: () => deleteGeometryObject({
                layerId: layer.id,
                objectIds
            }),
            onCancel: () => {
                // Т.к. восстановить удаленные объекты стандартными способами не получилось (через controlDrawRef.current?._toolbars?.edit?._modes?.remove?.handler?.revertLayers())
                // то просто выключаем/включаем режим редактирования на карте
                stoppedMapEdit().then(() => {
                    startMapEdit(layer.id)
                    selectLayerById(layer.id)
                })
            }
        })
    }

    const handleStopMapEdit = () => {
        stopMapEdit()
        controlEditRef.current = false
        setEdit(false)
        setMapObjects({
            drawObjects: [],
            editObjects: []
        })
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Новый объект</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Название"
                        fullWidth
                        variant={"outlined"}
                        value={name}
                        onChange={e => { setName(e.target.value); }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={createObject}>Создать</Button>
                    <Button variant={"outlined"} onClick={handleClose}>Отмена</Button>
                </DialogActions>
            </Dialog>



            <FeatureGroup key='fg_draw' id={'draw'} >
                <EditControl
                    key='ec_draw'
                    position="verticalrightcenter"
                    draw={{
                        marker: { icon: icon },
                        circle: false,
                        circlemarker: false,
                        polygon: { allowIntersection: false },
                    }}
                    onCreated={(e) => {
                        handleCreate(e.layer).then()
                    }}
                    onDeleted={(e) => {
                        handleDeleteObjects(e.layers).then()
                    }}

                    onDeleteStart={() => setDrawMode(true)}
                    onDeleteStop={() => setDrawMode(false)}
                    onDrawStart={() => setDrawMode(true)}
                    onDrawStop={() => setDrawMode(false)}

                    edit={{
                        edit: false,
                    }}
                />
                <CreateShape
                    icon={icon}
                    handleClick={handleDrawObjectClick}
                    objects={mapObjects.drawObjects}
                />
                <CustomLeafletButton
                    position="verticalrightcenter"
                    title="Закончить редактировние"
                    class={"leaflet-draw-edit-close"}
                    onClick={handleStopMapEdit}
                />

                {!drawMode &&
                    <CustomLeafletButton
                        key={'be_' + edit.toString()}
                        position={"verticalrightbottom"}
                        title={edit ? 'Выключить режим редактирования' : 'Включить режим редактирования'}
                        class={edit ? 'leaflet-draw-edit-edit active' : 'leaflet-draw-edit-edit'}
                        onClick={() => setEdit(!edit)}
                    />
                }
            </FeatureGroup>


            <FeatureGroup key='fg_edit'>
                <EditControl
                    key='ec_edit'
                    controlRef={controlEditRef}
                    position="verticalrightcenter"
                    draw={{
                        polygon: false,
                        polyline: false,
                        circle: false,
                        marker: false,
                        rectangle: false,
                        circlemarker: false,
                    }}
                    onEdited={(e) => handleUpdateGeometry(e.layers).then()}
                    onEditStop={() => handleEditStop()}
                    edit={{
                        remove: false,
                        edit: mapObjects.editObjects.length > 0 ? true : false,
                    }}
                />

                <CreateShape
                    icon={icon}
                    handleClick={handleDrawObjectClick}
                    objects={mapObjects.editObjects}
                />
            </FeatureGroup>
        </>
    )
}

export default DrawControl