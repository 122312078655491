import { Checkbox, FormControl, FormControlLabel, FormGroup, ListItemButton, SvgIcon } from "@mui/material"
import { Controller } from "react-hook-form"
import { VariableSizeList } from 'react-window';
import PropTypes from 'prop-types'
import { ReactComponent as CheckboxActive } from "../../../../Icons/desktop/form/CheckboxActive.svg"
import { sizeScaleFromHtml } from "../../../../service/function";


const sortList = (list, checkedIds) => {
    let notCheckedResult = []
    let checkedResult = []

    const res = Object.entries(list).sort((a, b) => a[1].localeCompare(b[1]))

    res.forEach(item => {
        if (checkedIds.includes(Number(item[0]))) {
            checkedResult = [               // not checked
                ...checkedResult,
                {
                    id: item[0],
                    label: item[1]
                }
            ]
        } else {
            notCheckedResult = [            // checked
                ...notCheckedResult,
                {
                    id: item[0],
                    label: item[1]
                }
            ]
        }
    })

    return [...checkedResult, ...notCheckedResult] // merge checked and 'not checked'
}

const RenderRow = ({ index, style, data: { field, list } }) => {

    const itemId = Number(list[index].id)
    return (
        <ListItemButton style={style} sx={{ pt: 0, pb: 0 }} key={index}>
            <FormControlLabel
                label={list[index].label}
                control={<Checkbox
                    {...field}
                    value={itemId}
                    checked={field.value.some((id) => id === itemId)}
                    checkedIcon={<SvgIcon inheritViewBox={true}><CheckboxActive /></SvgIcon>}
                    onChange={(event, checked) => {
                        if (checked) {
                            field.onChange([
                                ...field.value,
                                Number(event.target.value)
                            ]);
                        } else {
                            field.onChange(
                                field.value.filter(
                                    (value) => value !== Number(event.target.value)
                                )
                            );
                        }
                    }}
                />}
            />
        </ListItemButton>
    )
}

RenderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object,
    data: PropTypes.shape({
        field: PropTypes.object,
        list: PropTypes.array,
    })
}


const ListCheckBox = ({ nameFields, list, checkedIds, disabled = false, control, setValue }) => {

    const sortedList = sortList(list, checkedIds)

    const rowHeights = sortedList.map((item) => {
        const countRow = Math.ceil(item.label.length / 48)
        if (countRow > 1) {
            return sizeScaleFromHtml(countRow * 15 + 40)
        } else {
            return sizeScaleFromHtml(45)
        }
    })

    const getItemSize = (index) => rowHeights[index]

    return (
        <>
            <FormControlLabel
                label="Выбрать все записи"
                disabled={disabled}
                control={
                    <Checkbox
                        checkedIcon={<SvgIcon inheritViewBox={true}><CheckboxActive /></SvgIcon>}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setValue(nameFields, sortedList.map(item => Number(item.id)))
                            } else {
                                setValue(nameFields, [])
                            }
                        }}
                    />
                }
            />

            <FormControl component="fieldset" variant="standard">
                <FormGroup>

                    <Controller
                        name={nameFields}
                        control={control}
                        disabled={disabled}
                        render={({ field }) => (
                            <VariableSizeList
                                height={sizeScaleFromHtml(400)}
                                itemSize={getItemSize}
                                itemCount={Object.keys(list).length}
                                itemData={{ field, list: sortedList }}
                                overscanCount={5}
                            >
                                {RenderRow}
                            </VariableSizeList >
                        )}
                    />
                </FormGroup>
            </FormControl>
        </>
    )
}

ListCheckBox.propTypes = {
    nameFields: PropTypes.string.isRequired,
    list: PropTypes.object.isRequired,
    checkedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    control: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}


export default ListCheckBox