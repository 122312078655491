import { QueryClient, QueryClientProvider } from 'react-query'
import { CssBaseline, ThemeProvider } from '@mui/material';
import desktopTheme from './components/Style/desktopTheme';
import mobTheme from './components/Style/mobTheme';
import { SnackbarProvider } from 'notistack';
import './css/App.scss'
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './components/general/Router/AppRouter';
import useDeviceDetect from './hooks/useDeviceDetect';
import { usePreloadLayersQuery } from './hooks/reactQuery/usePreloadLayers';
import useAppSearchParams from './hooks/useAppSearchParams';
import { MaskContextProvider } from './hooks/useMask';
import { ModalContextProvider } from './hooks/useModal/useModal';
import { ReactQueryDevtools } from 'react-query/devtools'
import store from './store'
import { Provider as ReduxProvider } from "react-redux";
import { SearchProvider } from './hooks/useSearch';


export const queryClient = new QueryClient()

// Выносим хуки в отдельный компонент, чтобы не не было лишних рендеров MapLayout
const URLPreload = () => {
    usePreloadLayersQuery()
    return null
}

const AppSearch = () => {
    useAppSearchParams()
    return null
}


function App() {
    // console.log("Render App");

    const { isMobile } = useDeviceDetect()
    let Theme = isMobile ? mobTheme : desktopTheme;
    return (
        <QueryClientProvider client={queryClient}>

            <ReduxProvider store={store}>
                <ThemeProvider theme={Theme}>
                    <SnackbarProvider maxSnack={5} preventDuplicate={true}>
                        <MaskContextProvider>
                            <SearchProvider>

                                <CssBaseline />
                                <BrowserRouter basename={process.env.PUBLIC_URL}>
                                    <ModalContextProvider>
                                        <URLPreload />
                                        <AppSearch />
                                        <AppRouter />
                                    </ModalContextProvider>
                                </BrowserRouter>

                            </SearchProvider>
                        </MaskContextProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </ReduxProvider>

            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider >
    )
}

export default App