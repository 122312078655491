import { useEffect, useRef, useState } from "react";
import { DynamicMapLayer } from "react-esri-leaflet";
import { useMap, useMapEvents } from "react-leaflet";
import L from "leaflet";
import { useActions } from "../../../hooks/useActions";
import { RoutePaths } from "../../../routes";
import { useParams, useNavigate, useMatch } from "react-router-dom";
import { mapArcGisSelector } from "../../../store/selectors/selectors";
import { useSelector } from "react-redux";
import useLayerById from "../../../hooks/reactQuery/selectorCache/useLayerById";
import CustomLeafletButton from "../../desktop/EditMapLayers/DrawControl/CustomLeafletButton";


const LoaderArcGis = ({ dynamicMapLayerRef, title }) => {
    const [isLoading, setIsLoading] = useState()

    useEffect(() => {
        dynamicMapLayerRef.current
            .on('loading', () => {
                console.log("loading");
                setIsLoading(true)
            })

        dynamicMapLayerRef.current
            .on('load', () => {
                console.log("load");
                setIsLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {isLoading &&
                <CustomLeafletButton
                    position="verticalrightcenter"
                    title={`Загрузка слоя "${title}"`}
                    class="leaflet-loading-button"
                    onClick={() => { }}
                />
            }
        </>
    )
}

const CreateArcGIS = ({ url, layerId }) => {
    const dynamicMapLayerRef = useRef()
    const layer = useLayerById(layerId)

    return (
        <>
            <LoaderArcGis dynamicMapLayerRef={dynamicMapLayerRef} title={layer.name} />

            {layer?.isViewObjectsOnArcgis && <OnEventAndIdentifyFeatures layerId={layerId} dynamicMapLayerRef={dynamicMapLayerRef} />}

            <DynamicMapLayer
                ref={dynamicMapLayerRef}
                url={url}
                transparent={true}
                useCors={false}
                format={'image/png'}
                returnGeometry={false}
            />
        </>
    )
}

export default CreateArcGIS

// выносим данный функционал отдельно, т.к. navigate от react router вызывает лишний ререндер
const OnEventAndIdentifyFeatures = ({ layerId, dynamicMapLayerRef }) => {
    const map = useMap()
    const lat = Number(useParams().lat)
    const lng = Number(useParams().lng)
    const urlObjectId = Number(useParams().objectId)

    const { ArcGisSetIsLoading, ArcGisSetError, ArcGisSetData } = useActions()
    const navigate = useNavigate()
    const isArcGisPage = useMatch(RoutePaths.ARCGIS)


    useMapEvents({
        click: (e) => {
            ArcGisSetIsLoading(true)

            dynamicMapLayerRef.current
                .identify()
                .on(map)
                .at(e.latlng)
                .run(function (error, featureCollection, response) {
                    if (error) return ArcGisSetError(error)
                    if (featureCollection?.features.length === 0) {
                        ArcGisSetIsLoading(false)
                        return null
                    }

                    ArcGisSetData(featureCollection, response?.results)
                    navigate(
                        RoutePaths.ARCGIS
                            .replace(':layerId', layerId)
                            .replace(':objectId', e.urlObjectId ? e.urlObjectId : featureCollection?.features?.[0]?.id)
                            .replace(':lat', e.latlng.lat)
                            .replace(':lng', e.latlng.lng)
                    )
                })
        },
    })

    // вызываем событие клик по карте при загрузке с соотв. параметрами url - lat, lng
    useEffect(() => {
        if (!lat || !lng) return

        map.fireEvent('click', {
            latlng: L.latLng(lat, lng),
            urlObjectId
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {isArcGisPage && <IdentifiedFeature />}
        </>
    )
}

const IdentifiedFeature = () => {

    const map = useMap()
    const objectId = Number(useParams().objectId)
    const identifiedFeatureRef = useRef()
    const { data } = useSelector(mapArcGisSelector)

    useEffect(() => {
        if (identifiedFeatureRef.current) {
            map.removeLayer(identifiedFeatureRef.current)
        }

        const feature = data?.featureCollection?.features?.filter(o => Number(o.id) === objectId)[0]
        identifiedFeatureRef.current = L.geoJSON(feature).addTo(map);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objectId])

    // clear feature layer in map where - unmount
    useEffect(() => {
        return () => {
            // remove identifiedFeature layer in map
            if (identifiedFeatureRef.current) {
                map.removeLayer(identifiedFeatureRef.current)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return null
}