import { SYSTEM_SET_LAST_PAGE, SYSTEM_SET_VIEW_SELECTED_LAYERS, SYSTEM_RESET } from "./index"

export const SystemActionCreators = {
    setLastPage: (path) => {
        return {
            type: SYSTEM_SET_LAST_PAGE,
            payload: path
        }
    },
    setViewSelectedLayers: (viewSelectedLayers) => {
        return {
            type: SYSTEM_SET_VIEW_SELECTED_LAYERS,
            payload: viewSelectedLayers
        }
    },
    reset: () => {
        return {
            type: SYSTEM_RESET,
        }
    }
}