import { useEffect, useState } from "react";
import { MOBILE_WIDTH } from "../config";


const useDeviceDetect = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_WIDTH)

    useEffect(() => {
        // Отслеживаем изменение размера экрана здесь, моментально реагируя на любые его изменения
        const handleResize = () => setIsMobile(window.innerWidth < MOBILE_WIDTH)
        window.addEventListener('resize', handleResize)
        // Непременно удаляем обработчик, чтобы предотвратить утечку памяти
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return { isMobile }
}

export default useDeviceDetect