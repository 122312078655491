import { useSelector } from "react-redux"
import { selectedLayerIdsSelector } from "../../../store/selectors/selectors"
import useInCacheLayers from "./useInCacheLayers"
import { sortByName } from "../../../service/function"


const useSelectedLayers = () => {
    const allLayers = useInCacheLayers()
    const selectedLayerIds = useSelector(selectedLayerIdsSelector)
    let results = []

    selectedLayerIds.forEach(layerId => {
        results = [
            ...results, 
            ...allLayers.filter(l=>l.id === layerId)
        ]
    })
    results = sortByName(results)
    return results
}

export default useSelectedLayers