import { Card, CardContent, CardHeader, CardMedia, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useContext } from "react";
import { ApiPaths } from '../../../../../config';
import { GalleryContext } from "../../GalleryContext";
import useIconTimeStamp from "../../../../../hooks/useIconTimeStamp";


const IconItem = ({ iconId, handleClickMenu, activeIconId }) => {

    const { selectedIconId, setSelectedIconId } = useContext(GalleryContext)
    const timestamp = useIconTimeStamp(iconId)
    
    return (
        <Card
            onClick={() => {
                if (setSelectedIconId) {
                    setSelectedIconId(Number(iconId))
                }
            }}
            variant={"outlined"}
            sx={{
                width: "120px", height: '122px',
                '&.MuiCard-root:hover, &.MuiCard-root.active': {
                    backgroundColor: 'myGray.light1'
                }
            }}
            className={(activeIconId === iconId) || (selectedIconId === Number(iconId)) ? 'active' : ''}
        >
            <CardHeader
                action={
                    <IconButton size={"small"} onClick={(e) => handleClickMenu(e, iconId)}>
                        <MoreVertIcon />
                    </IconButton>
                }
                title={<Typography variant='font_14_20'>{`icon ${iconId}`}</Typography>}
            />

            <CardContent style={{ paddingTop: 0, paddingLeft: '12px' }}>
                <CardMedia
                    component="img"
                    sx={{ width: 'auto' }}
                    image={ApiPaths.gallery.TREEICON.replace(":iconId", iconId) + timestamp}
                    alt={`icon ${iconId}`}
                />
            </CardContent>
        </Card>
    )
}

export default IconItem