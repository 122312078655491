import React, { useEffect, useState } from 'react';
import {  Checkbox, FormControlLabel, SvgIcon } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ReactComponent as CheckBoxAllIconSvg } from '../../../Icons/desktop/form/CheckBoxAllIconSvg.svg'
import { pxToRem } from '../../Style/desktopTheme';
// import LoadingCheckBox from './LoadingCheckBox';


const AllSelectedCheckbox = ({ selected, isLoading, objectsIsLoading, handleAllSelectLayer }) => {
    // props.selected
    // props.isLoading - загрузка самого компонента(страницы) - для отображения Skeleton
    // props.objectsIsLoading - загрузка объектов слоя
    // props.handleAllSelectLayer()

    const [checkAll, setCheckAll] = useState(selected)


    const handleCheckAll = () => {

        if (!objectsIsLoading) {
            setCheckAll(v => !v)
            handleAllSelectLayer()
        }
    }

    useEffect(() => {
        setCheckAll(selected)
    }, [selected])

    return (
        <>
            {isLoading
                ? <Skeleton width={100} style={{marginLeft: pxToRem('12px'), marginTop: pxToRem('10px'), marginBottom: pxToRem('10px')}} />
                : <FormControlLabel
                    sx={{ ml: '0px', alignItems: 'start', '& .MuiFormControlLabel-label': { pt: pxToRem('8px') } }}
                    control={
                        <Checkbox
                            style={{ padding: pxToRem('8px') }}
                            icon={<SvgIcon inheritViewBox={true} sx={{ fill: 'myGray.dark' }} ><CheckBoxAllIconSvg /></SvgIcon>}
                            checkedIcon={<SvgIcon inheritViewBox={true} sx={{ fill: 'myGreen.main' }} ><CheckBoxAllIconSvg /></SvgIcon>}
                            checked={checkAll}
                            onChange={handleCheckAll}
                        />
                    }
                    label='Выбрать все'
                />
            }
        </>
    )
}

export default AllSelectedCheckbox