import { Box, Button, Container, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, SvgIcon, TextField } from '@mui/material'
import { useForm, Controller } from "react-hook-form"
import React, { useContext, useEffect, useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useSelector } from "react-redux"
import { useActions } from '../../../hooks/useActions'
import { authSelector } from '../../../store/selectors/selectors'
import { useQueryClient } from 'react-query'
import { pxToRem } from '../../Style/desktopTheme'
import { ModalWindowContext } from '../../../hooks/useModal/ModalWindow'


const SignIn = () => {

    const queryClient = useQueryClient()

    const { onClose } = useContext(ModalWindowContext)
    const { login } = useActions()
    const { error: responseError, isAuth } = useSelector(authSelector)

    const [showPassword, setShowPassword] = useState(false)
    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            login: '',
            password: '',
        },
        mode: 'onSubmit'
    })

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const onSubmit = async (data) => {
        const resetQuery = () => queryClient.resetQueries() // инвалидация всех запросов
        login(data.login, data.password, resetQuery)
    }

    useEffect(() => {
        if (isAuth) {
            onClose({isAuth})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth])
    return (
        <>
            <Container>
                <Box component="form" method='POST' onSubmit={handleSubmit(onSubmit)}>
                    {responseError && <FormHelperText error >{responseError}</FormHelperText>}

                    <Controller
                        name="login"
                        control={control}
                        rules={{ required: 'Поле обязательно для заполнения' }}
                        render={({ field }) => <TextField {...field}
                            margin="normal"
                            fullWidth
                            label="Логин"
                            variant="outlined"
                            autoFocus
                            error={errors?.login?.message ? true : false}
                            helperText={errors?.login?.message ? errors?.login?.message : false}
                        />}
                    />

                    <Controller
                        name='password'
                        control={control}
                        rules={{ required: 'Поле обязательно для заполнения' }}
                        render={({ field }) => {
                            return (
                                <FormControl style={{ marginTop: pxToRem('16px'), marginBottom: pxToRem('16px') }} variant="outlined" fullWidth {...field}>
                                    <InputLabel htmlFor="outlined-adornment-password">Пароль</InputLabel>
                                    <OutlinedInput
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    // edge="end"
                                                >
                                                    <SvgIcon style={{fontSize: pxToRem('24px')}}>
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </SvgIcon>
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                        error={errors?.password?.message ? true : false}
                                    />
                                    {errors?.password &&
                                        <FormHelperText error>{errors?.password?.message ? errors?.password?.message : false}</FormHelperText>}

                                </FormControl>
                            )
                        }}
                    />

                    <Controller
                        name='send'
                        control={control}
                        render={({ field }) => <Button
                            type='submit'
                            variant="contained"
                            onClick={handleSubmit(onSubmit)} {...field}
                        >Войти</Button>}
                    />

                </Box>
            </Container>
        </>
    )
}

export default SignIn