import { Marker, Polygon, Polyline } from "react-leaflet";
import { rotateCoordinates } from "../../../../service/function";



const CreateShape = ({ objects, icon, handleClick }) => {

    return objects.map(item => {
        // eslint-disable-next-line default-case
        switch (item.objectGeomType) {
            case 'Point':
                
                const geoJSON3 = JSON.parse(item.geoJSON);
                // console.log({item, gseoJSON3});
                return <Marker
                    position={[geoJSON3.coordinates[1], geoJSON3.coordinates[0]]}
                    icon={icon}
                    id={item.id}
                    editing={true}
                    key={'o_' + item.id}
                    eventHandlers={{
                        click: (e) => {
                            handleClick(e, item)
                        },
                    }}
                />
            case 'Polygon':
            case 'MultiPolygon':
                const geoJSON = JSON.parse(item.geoJSON);
                return <Polygon
                    positions={rotateCoordinates(geoJSON.coordinates)}
                    id={item.id}
                    key={'o_' + item.id}
                    eventHandlers={{
                        click: (e) => {
                            handleClick(e, item)
                        },
                    }}
                />
            case 'LineString':
            case 'MultiLineString':
                const geoJSON1 = JSON.parse(item.geoJSON);
                const positions = rotateCoordinates(geoJSON1.coordinates)
                return <Polyline
                    positions={positions}
                    id={item.id}
                    key={'o_' + item.id}
                    eventHandlers={{
                        click: (e) => {
                            handleClick(e, item)
                        },
                    }}
                />
        }
        return null
    })
}

export default CreateShape