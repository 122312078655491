import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMap } from 'react-leaflet';
import L from "leaflet";
import { ReactComponent as RulerIcon } from '../../../../Icons/map/RulerIcon.svg';
import ControlIconButton from './ControlIconButton';
import { useTheme } from '@mui/material';


const calculateDistance = (firstMarker, lastMarker) => {
    const EARTH_RADIUS_KM = 6371;
    const TO_RADIAN = Math.PI / 180;
    const deltaF = (lastMarker.lat - firstMarker.lat) * TO_RADIAN;
    const deltaL = (lastMarker.lng - firstMarker.lng) * TO_RADIAN;
    const angle =
        Math.sin(deltaF / 2) * Math.sin(deltaF / 2) +
        Math.cos(firstMarker.lat * TO_RADIAN) *
        Math.cos(lastMarker.lat * TO_RADIAN) *
        Math.sin(deltaL / 2) *
        Math.sin(deltaL / 2);
    const distance =
        2 * Math.atan2(Math.sqrt(angle), Math.sqrt(1 - angle)) * EARTH_RADIUS_KM;

    return distance;
}

const Ruler = () => {
    const theme = useTheme()
    let markersRef = useRef([])
    let totalDistanceRef = useRef(0)
    let clickCountRef = useRef(0)

    const map = useMap()
    const layerRef = useRef(L.layerGroup().addTo(map))
    const [active, setActive] = useState(false)
    const container = map.getContainer()
    const isInitialMount = useRef(true)

    const handleClickRullerButton = () => {
        setActive(v => !v)
    }

    const handleMapClick = (e) => {
        clickCountRef.current++;

        const circleMarker = L.circleMarker(e.latlng, {
            color: 'red',
            radius: 2,
        });

        if (markersRef.current.length < 1) {
            markersRef.current.push(e.latlng);
            circleMarker.addTo(layerRef.current);
        } else {
            markersRef.current.push(e.latlng);
            const currentDistance = calculateDistance(
                markersRef.current[markersRef.current.length - 2],
                markersRef.current[markersRef.current.length - 1]
            );
            totalDistanceRef.current += currentDistance;
            const text =
                totalDistanceRef.current > 1
                    ? `+${currentDistance.toFixed(2)}km<br/>${totalDistanceRef.current.toFixed(2)} km`
                    : `distance : ${(totalDistanceRef.current * 1000).toFixed(2)} m`;

            circleMarker
                .addTo(layerRef.current)
                .bindTooltip(text, { permanent: true, className: 'result-tooltip' });

            L.polyline(markersRef.current, {
                color: 'red',
                weight: 2,
                dashArray: '1,5',
                smoothFactor: 1,
            }).addTo(layerRef.current);
        }
    }

    const handleMapClickMemo = useCallback((e) => handleMapClick(e), [])

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            // Your useEffect code here to be run on update
            if (active) {
                container.style.cursor = 'crosshair'
                map.on('click', handleMapClickMemo)
            } else {
                container.style.cursor = ''
                clickCountRef.current = 0
                markersRef.current.length = 0
                totalDistanceRef.current = 0
                layerRef.current.clearLayers()
                map.off('click', handleMapClickMemo)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, active])

    const delta = useRef(500)
    const lastKeypressTimeRef = useRef(0)

    const doDoubleKeypress = () => {
        container.style.cursor = ''
        layerRef.current.clearLayers()
        setActive(false)
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            var thisKeypressTime = new Date()
            if (thisKeypressTime - lastKeypressTimeRef.current <= delta.current) {
                doDoubleKeypress()
                thisKeypressTime = 0;
            }
            lastKeypressTimeRef.current = thisKeypressTime

            clickCountRef.current = 0
            markersRef.current.length = 0
            totalDistanceRef.current = 0
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);


    return (
        <ControlIconButton onClick={handleClickRullerButton}>
            <RulerIcon fill={active ? theme.palette.myGreen.main : theme.palette.myBlack.main} />
        </ControlIconButton>
    )
}

export default Ruler