import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { IconButton } from '@mui/material';
import Link from '../../../hoc/LinkWithSearchParams'


const MenuListItem = ({ path, text, icons, className, callback }) => {
    
    const handleClick = () => {
        if (callback) {
            callback()
        }
        if (!path) return false
    }

    return (
        <Link
            to={path}
            onClick={handleClick}
        >
            <ListItem key={text} disablePadding className={className} >
                <ListItemButton className='ListItemButton'>
                    <IconButton sx={{ width: '40px', height: '40px' }}>{icons}</IconButton>
                    <ListItemText className='ListItemText' primary={text} />
                </ListItemButton>
            </ListItem>
        </Link>
    );
}

export default MenuListItem;