import { Box, Divider } from '@mui/material';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useActions } from '../../../hooks/useActions';
import MyAlert from '../../general/Info/MyAlert';
import { contains } from '../../../service/function';
import { selectedLayerIdsSelector, viewSelectedLayersSelector } from '../../../store/selectors/selectors';
import { RoutePaths } from '../../../routes';
import { useLayersByParentIdQuery } from '../../../hooks/reactQuery/useLayer';
import useNavigate from '../../../hooks/useNavigateWithSearchParams'
import ItemLayer from '../../desktop/Layer/ItemLayer';
import AllSelectedCheckbox from '../../desktop/ForForms/AllSelectedCheckbox'
import { pxToRem } from '../../Style/desktopTheme';
import ItemLayerSkeleton from './ItemLayerSkeleton';
import { useEffect } from 'react';
import useOnLayerIds from '../../../hooks/reactQuery/selectorCache/useOnLayerIds';



const Layer = () => {

    const layerId = Number(useParams().layerId)
    const onLayerIds = useOnLayerIds()
    const {data: layers=[], isLoading, error} = useLayersByParentIdQuery({layerId})
    const selectedLayerIds = useSelector(selectedLayerIdsSelector)
    const viewSelectedLayers = useSelector(viewSelectedLayersSelector)
    const navigate = useNavigate()
    

    let nonServiceLayerIds = []              // Массив с Id НЕ сервисных слоев т.е. у которых свойство isService = false
    let isLoadingObjects = false               // флаг, отражающий что идет загрузка объектов слоя
    let isAllSelected = false;                   // флаг, отражающий, что выделены все слои на странице (не сервисные слои)

    const { selectLayerById, deselectLayerByIds } = useActions();

    const filterLayers = viewSelectedLayers ? layers.filter(l => onLayerIds.indexOf(l.id) >= 0) : layers

    useEffect(() => {
        if (layers.length > 0 && filterLayers.length === 0) {
            navigate(RoutePaths.HOME)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layers, filterLayers])

    /* Для склелетона, когда isLoading=true */
    let arrLayers = isLoading ? Array.from({ length: 7 }, (v, i) => i).map((i, index) => ({ id: `skl-${index}` })) : filterLayers;

    // Формируем arrNoServiseLayersId 
    if (arrLayers && arrLayers.length > 0) {
        arrLayers.forEach((item) => {
            if (item.isService === false) {
                nonServiceLayerIds.push(item.id);
            }
        })
    }


    isAllSelected = selectedLayerIds.length > 0 ? contains(selectedLayerIds, nonServiceLayerIds) : false;


    const handleSelectItemLayer = (layerId, isLoading) => {
        if (!isLoading) {
            if (selectedLayerIds.includes(layerId)) {
                deselectLayerByIds([layerId])
            } else {
                selectLayerById(layerId);
            }
        } else {
            console.log("Дождитесь окончания загрузки данных");
        }
    }

    const handleAllSelectLayer = () => {
        if (isAllSelected) {
            deselectLayerByIds(nonServiceLayerIds)
        } else {
            // получаем массив оставшихся, не выделенных элементов
            const arrRemainingSelectLayerId = nonServiceLayerIds.filter(i => !selectedLayerIds.includes(i))
            if (arrRemainingSelectLayerId.length > 0) {
                arrRemainingSelectLayerId.forEach(id => selectLayerById(id))
            }
        }
    }

    if (error) {
        console.log({ error });
        return <MyAlert severity='error' title='Ошибка' message={error.message} />
    }

    if (_.isEmpty(layers) && !isLoading) {
        // return <MyAlert severity='info' message='Нет доступных слоев' />
        return null
    }
    return (
        <Box sx={{ mt: pxToRem('8px'), mb: pxToRem('8px'), overflow: 'auto' }}>

            {(nonServiceLayerIds.length > 0 || isLoading) &&    // Если присутствует НЕ сервисный слой - отображаем чекбокс "Выбрать все"
                <>
                    <Box sx={{ pt: pxToRem('4px'), pb: pxToRem('4px'), pl: pxToRem('8px'), pr: pxToRem('8px') }}>
                        <AllSelectedCheckbox
                            handleAllSelectLayer={() => handleAllSelectLayer()}
                            selected={isAllSelected}
                            objectsIsLoading={isLoadingObjects}
                            isLoading={isLoading}
                        />
                    </Box>
                    <Divider variant='var1' sx={{ mt: pxToRem('8px'), mb: pxToRem('8px') }} />
                </>
            }

            {arrLayers && arrLayers.map((item) => {
                if (isLoading) {
                    return <ItemLayerSkeleton key={item.id} />
                } else {
                    let props = {
                        metadata: item,
                        isOnLayer: onLayerIds.includes(item.id),
                        handleSelectItemLayer,
                    }
                    return <ItemLayer {...props} key={`l-${item.id}`} />
                }
            })}
        </Box>
    )
}

export default Layer;
