import React, { useContext } from 'react'
import MuiDrawer from '@mui/material/Drawer'
import { Box, Divider, styled } from '@mui/material'
import BottomToolBar from '../BottomToolbar/BottomToolBar'
import { DrawerContext } from '../SidePanel/SideContextProvider'
import { CLOSE_WIDTH_MAIN_PANEL, OPEN_WIDTH_MAIN_PANEL } from '../../../config'
import { pxToRem } from '../../Style/desktopTheme'


// Styled
const openedMixin = (theme) => ({
    width: pxToRem(OPEN_WIDTH_MAIN_PANEL),
    // transition: theme.transitions.create('width', {
    //     easing: theme.transitions.easing.sharp,
    //     duration: durationOpen,
    // }),
    overflowX: 'hidden',
})

const closedMixin = (theme) => {
    return ({
        // transition: theme.transitions.create('width', {
        //     easing: theme.transitions.easing.sharp,
        //     duration: durationClose,
        // }),
        overflowX: 'hidden',
        width: pxToRem(CLOSE_WIDTH_MAIN_PANEL),
    })
}

const MainDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => {
        return ({
            zIndex: 1000,
            width: `${OPEN_WIDTH_MAIN_PANEL}px`,
            flexShrink: 0,
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
            '.MuiDrawer-paper': {
                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px -1px 0px 0px rgba(0, 0, 0, 0.02)',
                border: '0px',
                overflowY: 'hidden',
                borderRadius: '0px',
                flexShrink: 0,
            }
        })
    }
)


const MainPanel = ({ children }) => {

    const { mainDrawer } = useContext(DrawerContext)
    
    return (
        <MainDrawer
            variant="permanent"
            anchor={'left'}
            open={mainDrawer}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                {children}

                <Box sx={{ mt: 'auto' }}>
                    <Divider style={{ marginTop: pxToRem('12px') }} />
                    <BottomToolBar/>
                </Box>
            </Box>
        </MainDrawer>
    )
}

export default MainPanel