import { useMatch } from "react-router-dom";
import { useActions } from "./useActions";
import { useSearchContext } from "./useSearch";
import { RoutePaths } from "../routes";
import useNavigate from "./useNavigateWithSearchParams";


export const useClearMap = () => {

    let navigate = useNavigate()
    const { clearSelectedLayerIds } = useActions()
    const {setResetSearch} = useSearchContext()
    let isSearchLocationPage = useMatch(RoutePaths.SEARCH_LOCATION)

    let isFilterObjectPage = useMatch(RoutePaths.FILTER_OBJECTS)
    let isFilterObjectDetailPage = useMatch(RoutePaths.FILTER_OBJECTS_DETAIL)
    const isFilterObject = isFilterObjectPage || isFilterObjectDetailPage

    const isArcGisPage = useMatch(RoutePaths.ARCGIS)

    const clearMap = () => {
        clearSelectedLayerIds()

        if (isSearchLocationPage) {
            setResetSearch(true)
        }

        if(isFilterObject || isArcGisPage || isSearchLocationPage) {
            navigate(RoutePaths.HOME)
        }
    }
    return clearMap
}
