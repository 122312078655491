import { CircularProgress, Stack, styled, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import PropTypes from 'prop-types'
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { ReactComponent as CollapseIcon } from '../../../Icons/desktop/treeListLayers/CollapseIcon.svg'
import { ReactComponent as ExpandIcon } from '../../../Icons/desktop/treeListLayers/ExpandIcon.svg'
import { ReactComponent as LayerIcon } from "../../../Icons/layer/ServiceLayerIcon.svg";
import { useTreeListLayersQuery } from '../../../hooks/reactQuery/useTreeListLayers'
import { SimpleTreeView} from '@mui/x-tree-view';

const getIsServiceLayerIds = (treeList) => {
    let resultIds = []
    const getLayers = (layers) => {
        layers.forEach(l => {
            if (l.parentId === null || l.isService) {
                resultIds = [
                    ...resultIds,
                    l.id
                ]
            }
            getLayers(l.children)
        })
    }
    if (!treeList) return []
    getLayers(treeList)
    return resultIds
}


const ItemLabel = ({ name, selected, isService, parentId }) => {
    return (
        <Stack direction='row' alignItems='center' spacing={1.5} >
            {(parentId && !isService)
                ? <WysiwygIcon />
                : <LayerIcon />
            }
            <Typography
                style={selected ? { fontWeight: 600 } : {}}
                var={12}>{name}</Typography>
        </Stack>
    )
}


const StyledTreeItem = styled(TreeItem)({
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px solid #D9D9D9;`,
    },
})


const ItemLayer = ({ treeList, selectedLayerIds, disabledLayerIds, isDisabledIsNonServiceLayer }) => {
    return treeList.map(l => {
        const key = 'layer-' + l.id
        return (
            <StyledTreeItem
                key={key}
                itemId={l.id.toString()}
                isservice={l.isService.toString()}
                label={
                    <ItemLabel
                        name={l.text}
                        selected={selectedLayerIds.includes(l.id)}
                        isService={l.isService}
                        parentId={l.parentId} />
                }
                disabled={disabledLayerIds.includes(l.id) || (isDisabledIsNonServiceLayer && l.parentId && !l.isService)}
            >
                {l.children.length > 0 && <ItemLayer treeList={l.children} selectedLayerIds={selectedLayerIds} disabledLayerIds={disabledLayerIds} isDisabledIsNonServiceLayer={isDisabledIsNonServiceLayer} />}
            </StyledTreeItem>
        )
    })
}


const TreeListLayers = ({ setSelectedLayerId, selectedLayerIds = [], disabledLayerIds = [], isDisabledIsNonServiceLayer = false, isDisableSelectionServiceLayer= false, ...props }) => {
    
    const { data: treeList, isLoading } = useTreeListLayersQuery()
    const isServiceLayerIds = useMemo(() => getIsServiceLayerIds(treeList), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading])

    if (isLoading) {
        return (
            <Stack alignItems='center' justifyContent='center'>
                <CircularProgress />
            </Stack>
        )
    }

    return (
        <Stack className='overflow'>
            <SimpleTreeView
                {...props}
                slots={{
                    collapseIcon: CollapseIcon,
                    expandIcon: ExpandIcon,
                }}
                onSelectedItemsChange={(_, nodeIds) => {
                    const selectId = Number(nodeIds)
                    if (isServiceLayerIds.includes(selectId) && isDisableSelectionServiceLayer) {
                        setSelectedLayerId(false)
                    } else {
                        setSelectedLayerId(selectId)
                    }
                }}
            >
                <ItemLayer {...{ treeList, selectedLayerIds, disabledLayerIds, isDisabledIsNonServiceLayer: isDisabledIsNonServiceLayer }} />
            </SimpleTreeView>
        </Stack>
    )
}

TreeListLayers.propTypes = {
    setSelectedLayerId: PropTypes.func.isRequired,          // fn - set selected layerId
    selectedLayerIds: PropTypes.arrayOf(PropTypes.number),  // selected layerIds
    disabledLayerIds: PropTypes.arrayOf(PropTypes.number)   // disabled layerIds
}

ItemLayer.propTypes = {
    treeList: PropTypes.array.isRequired,
    selectedLayerIds: PropTypes.array,
    disabledLayerIds: PropTypes.array,
}

ItemLabel.propTypes = {
    name: PropTypes.string.isRequired,
    selected: PropTypes.bool
}

export default TreeListLayers