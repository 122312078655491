import { Box, Stack, useTheme } from "@mui/material"
import Title from "./Title";
import GoBackButton from "./GoBackButton";
import PropTypes from 'prop-types'
import BreadCrumbs from "./BreadCrumbs";
import { pxToRem } from "../../Style/desktopTheme";
import LayerMenu from "../../desktop/Layer/LayerMenu";



const DesktopTemplateHeaderToolbar = ({ title, handleGoBack, breadcrumbs, isShowLayerMenu = true }) => {

    const theme = useTheme()
    const itemLayer = breadcrumbs?.layerHierarhy?.[breadcrumbs.layerHierarhy.length - 1]
    return (
        <>
            <Box sx={{
                backgroundColor: 'rgba(239, 239, 241, 0.50)',
                borderBottom: `1px solid ${theme.palette.myBlack.dark}`,
                pt: pxToRem('8px'),
                pb: pxToRem('16px'),
            }}>
                <Stack direction='row' sx={{
                    pl: pxToRem('4px'),
                    pr: pxToRem('4px'),
                    width: '100%',
                    justifyContent: 'space-between',

                }}>
                    <Stack direction='row'>
                        {handleGoBack && <GoBackButton handleClick={handleGoBack} />}
                        <Box sx={{ mt: pxToRem('6px') }}><Title lines={3} title={title.name} isLoading={title.isLoading} /></Box>
                    </Stack>

                    {(isShowLayerMenu && breadcrumbs && breadcrumbs.layerHierarhy && breadcrumbs.layerHierarhy.length > 0) &&
                        <LayerMenu
                            layerId={itemLayer.id}
                            name={itemLayer.name}
                            parentId={itemLayer.parentId}
                            isService={itemLayer.isService}
                            permissions={itemLayer.permissions}
                            typeId={itemLayer.typeId}
                            heatmap={itemLayer.heatmap}
                            haveObjects={itemLayer.haveObjects}
                        />
                    }

                </Stack>


                {breadcrumbs && <Box sx={{
                    ml: pxToRem('16px'),
                }}>
                    <BreadCrumbs {...breadcrumbs} />
                </Box>}
            </Box>
        </>
    )
}

export default DesktopTemplateHeaderToolbar

DesktopTemplateHeaderToolbar.propTypes = {
    title: PropTypes.object.isRequired,
    handleClose: PropTypes.func,
    handleGoBack: PropTypes.func,
    breadcrumbs: PropTypes.object,
}