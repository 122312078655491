import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import { TIME_UPDATE } from '../../config'
import {
    loadPropsLayer, deleteLayer, loadCategory, loadLayerById, updateLayerPermissions, updateLayerTags, updateLayerMeatadata, updateLayer, loadLayerMetadata,
    updatePictureBoundsLayer, loadAllUsersRoles, moveToLayer, loadPreparePropsLayer, loadLayersByParentId, loadLayerHierarhy,
    updateLayerHeatMap,
    copyPropsToLayer,
    copyToLayer
} from '../../service/axiosFuncQuery/layerQuery'
import { useActions } from '../useActions'
import { useMask } from '../useMask'
import CloseAction from '../../components/general/SnackBar/CloseAction';
import { updateTreeIconLayer, updateIconLayer, updateIconShapeLayer, } from '../../service/axiosFuncQuery/galleryQuery';


// query 
export const useCategoryQuery = () => {
    return (
        useQuery({
            queryKey: ['category'],
            queryFn: () => loadCategory(),
            onError: (e) => console.log(e.message),
            staleTime: TIME_UPDATE,
            keepPreviousData: true,
            refetchOnWindowFocus: false
        })
    )
}

export const useLayersByParentIdQuery = ({layerId, enabled = true}) => {
    return (
        useQuery({
            queryKey: ['layersByParentId', String(layerId)],
            queryFn: () => loadLayersByParentId(layerId),
            onError: (e) => console.log(e.message),
            staleTime: TIME_UPDATE,
            keepPreviousData: true,
            enabled: Boolean(layerId) && enabled,
        })
    )
}

export const useLayersHierarhyQuery = ({ layerId, enabled = Boolean(layerId) }) => {
    return (
        useQuery({
            queryKey: ['layerHierarhy', String(layerId)],
            queryFn: () => loadLayerHierarhy(layerId),
            staleTime: TIME_UPDATE,
            keepPreviousData: true,
            enabled,
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                if (error.response.data === 'Access is denied' || failureCount === 2) {
                    return false
                }
                return true
            }
        })
    )
}


export const useLoadRecentLayerQuery = (layerId) => {
    return (
        useQuery({
            queryKey: ['layerHierarhy', String(layerId)],
            queryFn: () => loadLayerHierarhy(layerId),
            onError: (e) => {
                console.log(e)
            },
            staleTime: TIME_UPDATE,
            enabled: false,  // для запуска используем refetch
            retry: (failureCount, error) => {
                if (error?.response?.data === 'Access is denied' || failureCount === 2) {
                    return false
                }
                return true
            }
        })
    )
}

export const useLayerByIdQuery = ({ layerId, enabled = Boolean(layerId) }) => {
    return (
        useQuery({
            queryKey: ['layer', String(layerId)],
            queryFn: () => loadLayerById(layerId),
            onError: (e) => console.log(e.message),
            staleTime: TIME_UPDATE,
            keepPreviousData: true,
            enabled
        })
    )
}

export const usePreparePropsLayerQuery = ({ layerId }) => {
    return (
        useQuery({
            queryKey: ['preparePropsLayer', String(layerId)],
            queryFn: () => loadPreparePropsLayer(layerId),
            onError: (e) => console.log(e.message),
            staleTime: TIME_UPDATE,
        })
    )
}

export const usePropsLayerQuery = ({ layerId }) => {
    return (
        useQuery({
            queryKey: ['propsLayer', String(layerId)],
            queryFn: () => loadPropsLayer(layerId),
            onError: (e) => console.log(e.message),
            staleTime: TIME_UPDATE,
        })
    )
}

export const useAllUsersRolesQuery = (editor) => {
    return (
        useQuery({
            queryKey: ['allUsersRoles'],
            queryFn: () => loadAllUsersRoles(),
            onError: (e) => console.log(e.message),
            staleTime: TIME_UPDATE,
            enabled: editor,
        })
    )
}


// mutation
export const useLayerMetadataMutation = () => {
    const { showMask, hideMask } = useMask()
    return (
        useMutation({
            mutationFn: (layerId) => loadLayerMetadata(layerId),
            onMutate: () => showMask(),
            onSuccess: () => hideMask(),
            onError: (e) => {
                hideMask()
                console.log(e)
            },
        })
    )
}


export const useUpdateLayerMutation = () => {
    const { showMask, hideMask } = useMask()
    const queryClient = useQueryClient()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: (data) => updateLayer(data),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                hideMask()
                queryClient.invalidateQueries(['category'])
                queryClient.invalidateQueries(['layerHierarhy'])
                queryClient.invalidateQueries(['layersByParentId'])
                queryClient.invalidateQueries(['layer'])
                enqueueSnackbar(`Свойства слоя #${variables.id} сохранены`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (e) => {
                hideMask()
                const message = `Ошибка. Слой не сохранен ${e.message}`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
        })
    )
}


export const useUpdateLayerMetadataMutation = () => {
    const { showMask, hideMask } = useMask()
    const queryClient = useQueryClient()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: (data) => updateLayerMeatadata(data),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                hideMask()
                queryClient.invalidateQueries(['category'])
                queryClient.invalidateQueries(['layerHierarhy'])
                queryClient.invalidateQueries(['layersByParentId'])
                queryClient.invalidateQueries(['layer'])
                enqueueSnackbar(variables.id > 0 ? `Метаданные слоя #${variables.id} сохранены` : 'Новый слой создан', { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (e, variables) => {
                console.log(e)
                hideMask()
                const message = (variables.id > 0 ? `Ошибка. Метаданные слоя #${variables.id} не сохранены. Ошибка: ` : 'Новый слой не создан. Ошибка: ') + e.message
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
        })
    )
}


export const useUpdateLayerPermissionsMutation = () => {
    const { showMask, hideMask } = useMask()
    const queryClient = useQueryClient()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ layerId, data }) => updateLayerPermissions(layerId, data),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                hideMask()
                queryClient.invalidateQueries(['category'])
                queryClient.invalidateQueries(['layerHierarhy'])
                queryClient.invalidateQueries(['layersByParentId'])
                queryClient.invalidateQueries(['layer'])
                queryClient.invalidateQueries(['propsLayer'])
                enqueueSnackbar(`Права доступа слоя #${variables.layerId} сохранены`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (_, variables) => {
                hideMask()
                const message = `Ошибка. Права доступа слоя #${variables.layerId} не сохранены`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
        })
    )
}


export const useUpdateLayerTagsMutation = () => {
    const { showMask, hideMask } = useMask()
    const queryClient = useQueryClient()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ layerId, data }) => updateLayerTags(layerId, data),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                hideMask()
                queryClient.invalidateQueries(['category'])
                queryClient.invalidateQueries(['layerHierarhy'])
                queryClient.invalidateQueries(['layersByParentId'])
                queryClient.invalidateQueries(['layer'])
                queryClient.invalidateQueries(['object'])
                enqueueSnackbar(`Обязательные теги слоя #${variables.layerId} сохранены`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (_, variables) => {
                hideMask()
                const message = `Ошибка. Обязательные теги слоя #${variables.layerId} не сохранены`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
        })
    )
}


export const useDelLayerMutation = () => {
    const { showMask, hideMask } = useMask()
    const queryClient = useQueryClient()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ layerId }) => deleteLayer(layerId),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                hideMask()
                queryClient.invalidateQueries(['category'])
                queryClient.invalidateQueries(['layerHierarhy'])
                queryClient.invalidateQueries(['layersByParentId'])
                queryClient.invalidateQueries(['layer'])
                enqueueSnackbar(`Cлой #${variables.layerId} удален`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (e, variables) => {
                hideMask()
                const message = `Ошибка. Слой #${variables.layerId} не удален. ${e.response.data}`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
        })
    )
}


export const useUpdatePictureBoundsLayerMutation = () => {
    const queryClient = useQueryClient()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ layerId, bounds }) => updatePictureBoundsLayer(layerId, bounds),
            onSuccess: (_, variables) => {
                queryClient.invalidateQueries(['layer'])
                queryClient.invalidateQueries(['layersByParentId'])
                enqueueSnackbar(`Границы ImageOverlay слоя #${variables.layerId} сохранены`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (_, variables) => {
                const message = `Ошибка. Границы ImageOverlay слоя #${variables.layerId} не сохранены`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
        })
    )
}

// treeicon, icon

export const useUpdateTreeIconLayerMutation = () => {
    const { setChangeIcon } = useActions()
    const queryClient = useQueryClient()
    const { hideMask, showMask } = useMask()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ layerId, iconId }) => updateTreeIconLayer(layerId, iconId),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                setChangeIcon(variables.iconId)
                queryClient.invalidateQueries(['layerHierarhy'])
                queryClient.invalidateQueries(['layersByParentId'])
                queryClient.invalidateQueries(['layer'])
                queryClient.invalidateQueries({ queryKey: ['galleryIcons'] })
                hideMask()
                enqueueSnackbar(`Иконка слоя и объекта на свойствах слоя #${variables.layerId} изменена`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (_, variables) => {
                hideMask()
                const message = `Ошибка. Иконка слоя и объекта на свойствах слоя #${variables.layerId} не изменена`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
        })
    )
}


export const useUpdateIconLayerMutation = () => {
    const { setChangeIcon } = useActions()
    const queryClient = useQueryClient()
    const { hideMask, showMask } = useMask()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ layerId, iconId }) => updateIconLayer(layerId, iconId),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                setChangeIcon(variables.iconId)
                queryClient.invalidateQueries(['layerHierarhy'])
                queryClient.invalidateQueries(['layersByParentId'])
                queryClient.invalidateQueries(['layer'])
                queryClient.invalidateQueries({ queryKey: ['galleryIcons'] })
                queryClient.invalidateQueries({ queryKey: ['galleryCategories'] })
                queryClient.invalidateQueries({ queryKey: ['galleryIconInfo'] })
                hideMask()
                enqueueSnackbar(`Иконка объекта в свойствах слоя #${variables.layerId} изменена`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (_, variables) => {
                hideMask()
                const message = `Ошибка. Иконка объекта в свойствах слоя #${variables.layerId} не изменена`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
        })
    )
}


export const useUpdateIconShapeLayerMutation = () => {
    const { setChangeIcon } = useActions()
    const queryClient = useQueryClient()
    const { hideMask, showMask } = useMask()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ layerId, iconShape }) => updateIconShapeLayer(layerId, iconShape /*, iconId*/),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                queryClient.invalidateQueries(['layerHierarhy'])
                queryClient.invalidateQueries(['layersByParentId'])
                queryClient.invalidateQueries(['layer'])
                setChangeIcon(variables.iconId)
                hideMask()
                enqueueSnackbar(`Тип иконки объекта в свойствах слоя #${variables.layerId} изменен`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (_, variables) => {
                hideMask()
                const message = `Ошибка. Тип иконки объекта в свойствах слоя #${variables.layerId} не изменен`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
        })
    )
}

export const useMoveToLayerMutation = () => {
    const { showMask, hideMask } = useMask()
    const queryClient = useQueryClient()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ layerId, parentLayerId }) => moveToLayer(layerId, parentLayerId),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                hideMask()
                queryClient.invalidateQueries(['category'])
                queryClient.invalidateQueries(['layerHierarhy'])
                queryClient.invalidateQueries(['layersByParentId'])
                enqueueSnackbar(`Слой #${variables.layerId} перемещен`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (_, variables) => {
                hideMask()
                const message = `Ошибка. Слой #${variables.layerId} не перемещен`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
        })
    )
}

export const useCopyToLayerMutation = () => {
    const { showMask, hideMask } = useMask()
    const queryClient = useQueryClient()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ layerId, parentLayerId }) => copyToLayer(layerId, parentLayerId),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                hideMask()
                queryClient.invalidateQueries(['category'])
                queryClient.invalidateQueries(['layerHierarhy'])
                queryClient.invalidateQueries(['layersByParentId'])
                enqueueSnackbar(`Слой #${variables.layerId} скопирован`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (_, variables) => {
                hideMask()
                const message = `Ошибка. Слой #${variables.layerId} не скопирован`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
        })
    )
}

export const useLayerUpdateHeatMapMutation = () => {
    const { showMask, hideMask } = useMask()
    const queryClient = useQueryClient()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ layerId, heatmap }) => updateLayerHeatMap({ layerId, heatmap }),
            onMutate: () => showMask(),
            onSuccess: () => {
                hideMask()
                queryClient.invalidateQueries(['layer'])
                queryClient.invalidateQueries(['layerHierarhy'])
                queryClient.invalidateQueries(['layersByParentId'])
                enqueueSnackbar(`Настройки успешно сохранены`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (e) => {
                hideMask()
                console.log(e)
            },
        })
    )
}

export const useCopyPropsToLayerMutation = () => {
    const { showMask, hideMask } = useMask()
    const queryClient = useQueryClient()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ layerId, targetIds }) => copyPropsToLayer({ layerId, targetIds }),
            onMutate: () => showMask(),
            onSuccess: () => {
                hideMask()
                queryClient.invalidateQueries(['layer'])
                queryClient.invalidateQueries(['layerHierarhy'])
                queryClient.invalidateQueries(['layersByParentId'])
                enqueueSnackbar(`Настройки успешно скопированы`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (e) => {
                hideMask()
                console.log(e)
            },
        })
    )
}
