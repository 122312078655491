import { Box, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import GeoPortalLogo from "../../../Icons/mob/logo.png";
import { RoutePaths } from '../../../routes';
import { ReactComponent as LogoIcon } from '../../../Icons/desktop/LogoIcon.svg'


const BoxLogo = () => {

  return (
    <Stack>
      <Link to={RoutePaths.HOME}>
        <Stack direction='row' alignItems='center' spacing={'12px'} sx={{ mt: '15px', }}>
          <img src={GeoPortalLogo} alt="Геопортал" />
          <Box sx={{ pt: '12px', pl: '7px' }}><LogoIcon /></Box>
        </Stack>
      </Link>
      <Box sx={{ position: 'relative', top: '-1px' }}><Typography variant='font_10'>ГЕОПОРТАЛ ЯРОСЛАВСКОЙ ОБЛАСТИ</Typography></Box>
    </Stack>

  )
}

export default BoxLogo;