import { Box, IconButton, Stack, SvgIcon, Tooltip } from "@mui/material"
import { useContext } from "react";
import { ReactComponent as AppMenuIcon } from "../../../Icons/desktop/AppMenuIcon.svg"
import BoxLogo from "../BoxLogo/BoxLogo"
import { DrawerContext } from "../SidePanel/SideContextProvider";
import { setCookie } from "../../../service/function";
import { pxToRem } from "../../Style/desktopTheme";


const ToggleMainPanel = () => {

    const { mainDrawer, setMainDrawer } = useContext(DrawerContext)

    const wrapStackSx = { mb: pxToRem('10px'), borderBottom: '1px solid', borderColor: '#EFEFF1', }
    const innerStackSx = { mt: pxToRem('18px'), mr: pxToRem('16px'), mb: pxToRem('18px'), ml: pxToRem('16px'), justifyContent: 'center', alignItems: 'center' }
    const BoxLogoSx = { mt: pxToRem('13px') }

    const handleClickButton = () => {
        setMainDrawer((prevValue) => {
            setCookie('open_cat', !prevValue)
            return !Boolean(prevValue)
        })

    }
    return (
        <Stack direction='row' sx={wrapStackSx}>
            <Stack sx={innerStackSx}>
                <Tooltip title={mainDrawer? 'Свернуть категории' : 'Развернуть категории'} arrow placement="bottom-end">
                    <IconButton onClick={handleClickButton} style={{ padding: pxToRem('12px'), }}>
                        <SvgIcon style={{ fontSize: pxToRem('24px') }} inheritViewBox={true} ><AppMenuIcon /></SvgIcon>
                    </IconButton>
                </Tooltip>
            </Stack>
            {mainDrawer && <Box sx={BoxLogoSx}><BoxLogo /></Box>}
        </Stack >
    )
}

export default ToggleMainPanel