import { useSelector } from "react-redux"
import { editOnMapLayerSelector } from "../../../store/selectors/selectors"
import DrawControl from "./DrawControl/DrawControl"
import EditImageOverlay from "./EditImageOverlay"
import useLayerById from "../../../hooks/reactQuery/selectorCache/useLayerById"


const EditMapLayers = () => {

    const editLayerId = useSelector(editOnMapLayerSelector)
    const layer = useLayerById(editLayerId)
    console.log({layer});

    if (!layer) return null

    if (layer.typeId === 7) {
        return <EditImageOverlay layer={layer} />
    }

    if(layer.typeId === 1) {
        return <DrawControl layer={layer}/>
    }
}

export default EditMapLayers