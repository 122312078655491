import { useCallback, useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { isAuthSelector } from "../store/selectors/selectors"
import { useActions } from "./useActions"


const useAuth = () => {
    const isAuth = useSelector(isAuthSelector)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const { checkAuth, loadUser } = useCallback(useActions(), []) // используем useCallBack чтобы убрать лишние рендеры

    // Запускаем useEffect только при измении isAuth, минуя первоначальное монтирование https://stackoverflow.com/questions/55075604/react-hooks-useeffect-only-on-update
    const isInitialMount = useRef(true)
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (isAuth) {
                loadUser()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth])



    useEffect(() => {
        checkAuth()
    }, [checkAuth])

    return null
}

export default useAuth