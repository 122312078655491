import { isUpdate } from "../../service/function"

// вспомогательные функции */
export const layerByIdFn = (layerId, layers) => Object.values(layers).filter(l => l.id === layerId)[0]

export const getLayerHierarhyFn = (requestId, layers) => {
    //делаем замыкание на layers
    const getLayerById = (layerId) => {
        const fn = (layerId) => layerByIdFn(layerId, layers)
        return fn(layerId)
    }

    let arr = []
    const recursive = (layerId) => {
        if (getLayerById(layerId)) {
            arr.push(getLayerById(layerId))
            if (getLayerById(layerId).parentId !== null) {
                recursive(getLayerById(layerId).parentId)
            }
        }
        return arr
    }
    return recursive(requestId).reverse()
}

// функция, проверяющая не вышел ли их срок "годности" переданных данных из стора по свойству requestTime
export const isInCache = (selector) => {

    if(!selector) return false

    const { requestTime } = selector
    return !isUpdate(requestTime)
}
