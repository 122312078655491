import useDeviceDetect from "../../../hooks/useDeviceDetect"
import useNavigate from '../../../hooks/useNavigateWithSearchParams'
import { RoutePaths } from "../../../routes";
import { useParams } from "react-router-dom";
import { useLayersHierarhyQuery } from "../../../hooks/reactQuery/useLayer";
import { useObjectQuery } from "../../../hooks/reactQuery/useObject";
import MobTemplateHeaderToolbar from "./MobTemplateHeaderToolbar";
import DesktopTemplateHeaderToolbar from "./DesktopTemplateHeaderToolbar";



const HeadToolBarObject = ({ title, handleClose }) => {

    const objectId = Number(useParams().objectId)
    const layerId = Number(useParams().layerId)

    const navigate = useNavigate()
    const { isMobile } = useDeviceDetect()

    const { data: layerHierarhy, isLoading, error } = useLayersHierarhyQuery({ layerId })
    const { data: object, isLoading: objectIsLoading } = useObjectQuery(objectId, !Boolean(title))

    /* To GoBack and Title*/
    const previosPath = layerHierarhy && RoutePaths.LAYER.replace(":layerId", layerHierarhy[layerHierarhy.length - 2]?.id)

    const titleProp =  {
        name: title ? title.name : object?.name,
        isLoading: title? title.isLoading : objectIsLoading
    }

    return (
        isMobile
            ? <MobTemplateHeaderToolbar
                title={titleProp}
                handleClose={() => handleClose ? handleClose() : navigate(RoutePaths.HOME)}
            />
            : <DesktopTemplateHeaderToolbar
                title={titleProp}
                handleGoBack={() => navigate(previosPath)}
                breadcrumbs={{
                    isLoading,
                    error,
                    layerHierarhy
                }}
                isShowLayerMenu={layerHierarhy && layerHierarhy.length === 1}
            />
    )
}

export default HeadToolBarObject