import { Box, Button, Typography } from "@mui/material"
import { useState } from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { IMG_LAYER_PATH } from '../../../config'
import { pxToRem } from "../../Style/desktopTheme";


const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
})


const PictureField = ({ name, label, register, pictureUrl, accept }) => {

    const [file, setFile] = useState(false)

    useEffect(()=> {
        setFile(false)
    }, [pictureUrl])

    return (
        <>
            <Box>
                <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={
                        <>
                            {file &&
                                <Typography style={{ fontSize: pxToRem('16px'), marginRight: pxToRem('20px') }}>
                                    {file.name}
                                    &nbsp;&nbsp;&nbsp;
                                    {formatBytes(file.size)}
                                </Typography>
                            }

                            <CloudUploadIcon />
                        </>
                    }
                >
                    {label ? label : 'Выбрать файл'}
                    <VisuallyHiddenInput
                        type="file"
                        accept={accept? accept : 'image/*'}
                        {...register(name,{
                            onChange: (e) => {
                                setFile(e.target.files[0]) 
                            }
                        })}
                    />
                </Button>
            </Box>

            {((name === 'layerPicture' && pictureUrl) && !file) &&
                <img
                    style={{ display: pictureUrl ? 'block' : 'none' }}
                    width='200'
                    src={pictureUrl ? IMG_LAYER_PATH + pictureUrl : '#'}
                    alt={label}
                />
            }
        </>
    )
}

export default PictureField