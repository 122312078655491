import { Button, Divider, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types'
import { useModal } from '../../../hooks/useModal/useModal';
import { useLayerMetadataMutation, useUpdateLayerMetadataMutation } from '../../../hooks/reactQuery/useLayer';
import MetadataFormControllers from './components/MetadataFormControllers';
import LayerProperty from './LayerProperty';
import FormContainer from '../ForForms/FormContainer';


const NewLayer = ({ parentId }) => {

    const { openWindow } = useModal()

    const { mutate: updateLayerMetadata } = useUpdateLayerMetadataMutation()
    const { mutate: getLayerMetadata } = useLayerMetadataMutation()

    const { control, handleSubmit, watch, setValue } = useForm({
        defaultValues: {
            id: '-1',
            parentId,
            name: 'Новый слой',
            descSpatialName: '',
            descSpatialData: '',
            accessLevel: '',
            sourceSpatialData: '',
            hasOwner: true,
            ownerCompany: '',
            ownerEmail: '',
            ownerPhone: '',
            docRegulation: '',
            mapAccuracy: '',
            accessConditions: '',
            newLayerIconId: ''
        },
        mode: 'onSubmit'
    })

    /* Функционал Скопировать с Родителя */
    const inheritMetadata = async (parentId) => {
        const fields = [
            'descSpatialName',
            'descSpatialData',
            'accessLevel',
            'sourceSpatialData',
            'hasOwner',
            'ownerCompany',
            'ownerEmail',
            'ownerPhone',
            'docRegulation',
            'mapAccuracy',
            'accessConditions',
        ]

        getLayerMetadata(
            parentId,
            {
                onSuccess: (data) => {
                    fields.forEach(p => {
                        setValue(p, data[p])
                    })
                }
            }
        )
    }

    const onSubmit = async (data) => {
        updateLayerMetadata(
            data,
            {
                onSuccess: ({ data }) => {
                    const layerId = data.id

                    openWindow({
                        title: 'Свойства слоя',
                        children: <LayerProperty />,
                        layerId,
                        width: 800
                    })

                },
            }
        )
    }

    return (
        <>
            <Divider />
            <FormContainer
                action={
                    <Stack direction='row' spacing={5}  >
                        <Button
                            variant='outlined'
                            type='button'
                            onClick={() => inheritMetadata(parentId)}
                        >Скопировать с родителя</Button>

                        <Controller
                            name='send'
                            control={control}
                            render={({ field }) =>
                                <Button
                                    variant='contained'
                                    type='submit'
                                    onClick={handleSubmit(onSubmit)} {...field}
                                >Сохранить</Button>
                            }
                        />
                    </Stack>
                }
                component="form"
                method='POST'
                onSubmit={handleSubmit(onSubmit)}
                style={{ marginTop: 0 }}
            >
                <MetadataFormControllers {...{ control, editor: true, hasOwner: watch('hasOwner') }} />
            </FormContainer>
        </>
    )
}

NewLayer.propTypes = {
    parentId: PropTypes.number.isRequired
}
export default NewLayer