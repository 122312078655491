import { useSelector } from "react-redux";
import { ErrorBoundary } from 'react-error-boundary'
import { editOnMapLayerSelector, selectedLayerIdsSelector } from '../../../store/selectors/selectors';
import CreateSearchLocation from "./CreateSearchLocation";
import { ErrorFallback } from "../../desktop/ErrorBoundary/ErrorFallback";
import EditMapLayers from "../../desktop/EditMapLayers/EditMapLayers";
import ItemMapLayer from "./ItemMapLayer";
import useDeviceDetect from "../../../hooks/useDeviceDetect";

const MapLayers = () => {
    // console.log("render MapLayers");

    const {isMobile} = useDeviceDetect()
    const selectedLayerIds = useSelector(selectedLayerIdsSelector)
    const editLayerId = useSelector(editOnMapLayerSelector)

    return (
        <>
            <CreateSearchLocation />
            
            {selectedLayerIds
                .filter(layerId => layerId !== editLayerId)
                .map(layerId => <ItemMapLayer layerId={layerId} key={'l-' + layerId} />)}

            {(editLayerId && !isMobile)  &&
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <EditMapLayers/>
                </ErrorBoundary>
            }
        </>
    )

}

export default MapLayers