import { Alert, Stack } from '@mui/material'
import { useContext, useRef} from 'react'
import MyTabs from '../MyTabs/MyTabs'
import { useAllUsersRolesQuery, usePropsLayerQuery } from '../../../hooks/reactQuery/useLayer'
import Loader from '../Loader/Loader'
import { pxToRem } from '../../Style/desktopTheme'
import { LayerPropertyContext } from './LayerPropertyContext'
import PropEdit from './Tabs/Prop/PropEdit'
import HeadBoxForm from './components/HeadBoxForm'
import PropView from './Tabs/Prop/PropView'
import RequiredTags from './Tabs/RequiredTags/RequiredTags'
import Permissions from './Tabs/Permissions/Permissions'
import Metadata from './Tabs/Metadata/Metadata'
import { ModalWindowContext } from '../../../hooks/useModal/ModalWindow'
import useLayerById from '../../../hooks/reactQuery/selectorCache/useLayerById'



const LayerPropertyEditor = () => {

    const { layerId } = useContext(ModalWindowContext)
    const layer = useLayerById(layerId)

    // здесь хранится state форм из всех вкладок. 
    const formStateRef = useRef({ 'props': false, 'requiredFields': false, 'access': false, 'metadata': false, 'data': false })

    const { data: propsLayerData, isFetching: isFetchingPropsLayer, error } = usePropsLayerQuery({ layerId })

    let propsLayer = {}
    let templates = null
    let permissionLayer = null
    if (propsLayerData) {
        [propsLayer, templates, permissionLayer] = propsLayerData
    }


    // isEditor сюда включаем чтобы не было ошибок изменения состояния размонтированного компонента PropView, когда isEditor меняется на true или false
    const editor = Boolean(propsLayer.editor)
    const showInactiveTabs = propsLayer.showInactiveTabs
    const countObjects = propsLayer.spatialDataWeight?.count

    const { data: allUsersRolesData, isFetching: isFetchingAllUsersRoles } = useAllUsersRolesQuery(editor)

    let allUsers = undefined
    let allRoles = undefined
    if (allUsersRolesData) {
        [allUsers, allRoles] = allUsersRolesData
    }

    if (!layer) return null
    if (isFetchingPropsLayer || isFetchingAllUsersRoles) return <Loader />
    if (error) return <Alert severity="error">{error.message}</Alert>

    const propsHeadBoxReadonly = { layer, countObjects }

    let arrTabs = [
        {
            label: 'Свойства',
            value: editor
                ? <PropEdit />
                : <PropView />
        }
    ]

    if (showInactiveTabs) {
        arrTabs = [
            ...arrTabs,
            {
                label: 'Обязательные теги',
                value: <>
                    <HeadBoxForm {...propsHeadBoxReadonly} />
                    <RequiredTags />
                </>
            },
            {
                label: 'Права доступа',
                value: <>
                    <HeadBoxForm {...propsHeadBoxReadonly} />
                    <Permissions />
                </>
            }
        ]
    }
    arrTabs.push({
        label: 'Метаданные',
        value: <>
            <HeadBoxForm {...propsHeadBoxReadonly} />
            <Metadata />
        </>
    })

    return (
        <LayerPropertyContext.Provider value={{ formStateRef, propsLayer, templates, allUsers, allRoles, permissionLayer, layer }}>
            <Stack spacing={2} className={'overflow '} sx={{ position: 'relative', pt: pxToRem('78px') }}            >
                <MyTabs arrTabs={arrTabs} var='var1' />
            </Stack>
        </LayerPropertyContext.Provider>
    )
}

export default LayerPropertyEditor