import { Button, Divider, Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types'
import TreeListLayers from "../../../TreeListLayers/TreeListLayers";
// import { ReactComponent as AttachIcon } from '../../../../../Icons/desktop/propObject/AttachIcon.svg'
import { ReactComponent as CopyIcon } from '../../../../../Icons/desktop/propObject/CopyIcon.svg'
import { useModal } from "../../../../../hooks/useModal/useModal";
import { /*useAddingObjectFromLayerQuery, */useCloneObjectToLayerMutation } from "../../../../../hooks/reactQuery/useObject";
import { ModalWindowContext } from "../../../../../hooks/useModal/ModalWindow";


const TreeList = ({ objectId, layerIds }) => {

    const [selectedLayerId, setSelectedLayerId] = useState(false)
    // const { mutate: addingObjectFromLayer, status: addingObjectFromLayerStatus } = useAddingObjectFromLayerQuery()
    const { mutate: cloneObjectToLayer, status: cloneObjectToLayerStatus } = useCloneObjectToLayerMutation()

    const { onClose } = useContext(ModalWindowContext) // onClose получаем из контекста модального окна
    const { openAlert } = useModal()

    // при успешном добавлении объекта на слой - обновляем объекты слоя которому был привязан объект                       
    // useEffect(() => {
    //     if (addingObjectFromLayerStatus === 'success') {
    //         onClose()
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [addingObjectFromLayerStatus])

    useEffect(() => {
        if (cloneObjectToLayerStatus === 'success') {
            onClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cloneObjectToLayerStatus])

    // const addObjectFromLayer = (layerId, objectId, layerIds) => {
    //     if (!layerId) {
    //         openAlert({
    //             title: 'Сообщение',
    //             children: <>Не выбран слой</>,
    //             backdropOpacity: 0.2,
    //             width: 600
    //         })
    //         return null
    //     }

    //     if (layerIds.includes(layerId)) {
    //         openAlert({
    //             title: 'Сообщение',
    //             children: <>Объект уже привязан к выбранному слою</>,
    //             backdropOpacity: 0.2,
    //             width: 600
    //         })
    //         return null
    //     }

    //     addingObjectFromLayer({ layerId, objectId })
    // }

    const cloneObjectInLayer = (layerId, objectId) => {
        if (!layerId) {
            openAlert({
                title: 'Сообщение',
                children: <>Не выбран слой</>,
                backdropOpacity: 0.2,
                width: 600
            })
            return null
        }

        cloneObjectToLayer({ layerId, objectId })
    }

    return (
        <>
            <Stack className="overflow" spacing={3}>
                <Divider variant='var1' />

                <TreeListLayers
                    sx={{ pl: '34px' }}
                    selectedLayerIds={layerIds}
                    setSelectedLayerId = {setSelectedLayerId}
                    selectedItems={String(selectedLayerId)}
                    isDisableSelectionServiceLayer={true}
                />

                <Divider variant='var1' />
                <Stack spacing={2} direction='row' sx={{ pl: '32px', pr: '32px' }} >
                    {/* <Button variant='outlined' onClick={() => addObjectFromLayer(selectedLayerId, objectId, layerIds)}><AttachIcon />&nbsp;Привязать</Button> */}
                    <Button variant='outlined' onClick={() => cloneObjectInLayer(selectedLayerId, objectId)}><CopyIcon />&nbsp;Скопировать</Button>
                </Stack>
            </Stack>
        </>
    )
}

TreeList.propTypes = {
    objectId: PropTypes.number.isRequired,
    layerIds: PropTypes.arrayOf(PropTypes.number)
}

export default TreeList