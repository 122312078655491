import L from "leaflet";
import { Marker } from 'react-leaflet/Marker';
import { memo } from 'react';
import useIconTimeStamp from "../../../hooks/useIconTimeStamp";
import { ICON_ANCHOR_CIRCLE, ICON_ANCHOR_DROP, ICON_SIZE_CIRCLE, ICON_SIZE_DROP, MEDIUM_HEIGHT_BOTTOMSHEET, OPEN_WIDTH_SECOND_PANEL } from "../../../config";
import SelectedIcon from "../Map/SelectedIcon/SelectedIcon";
import { renderToStaticMarkup } from 'react-dom/server'
import PropTypes from 'prop-types'
import { sizeScaleFromHtml } from "../../../service/function";
import { useMap } from "react-leaflet";
import useDeviceDetect from "../../../hooks/useDeviceDetect";


const CreatePoint = ({ item, handleClick, selected, iconId, iconShape, iconUrl }) => {
    // console.log("Render CreatePoint");
    const { isMobile } = useDeviceDetect()
    const map = useMap()
    const timestamp = useIconTimeStamp(iconId)
    let zIndexOffset = 0;

    let icon = L.icon({
        iconUrl: iconUrl + timestamp,
        iconSize: (iconShape === 1 || iconShape === null || (iconShape === 0 && !iconId)) ? ICON_SIZE_DROP : ICON_SIZE_CIRCLE,
        iconAnchor: (iconShape === 1 || iconShape === null || (iconShape === 0 && !iconId)) ? ICON_ANCHOR_DROP : ICON_ANCHOR_CIRCLE,
    })

    // выделенный объект
    if (selected) {
        let selectedIcon = L.divIcon({
            className: 'custom-div-icon',
            html: renderToStaticMarkup(<SelectedIcon iconShape={iconShape} src={iconId ? iconUrl + timestamp : false} />),
            iconSize: [34, 43],
            iconAnchor: [17, 43],
        })

        icon = selectedIcon;
        zIndexOffset = 1000;

        let isContains = true
        let topLeftCorner, bottomRightCorner
        const mapSize = map.getSize()
        if (isMobile) {
            // задаем область - нижняя граница которой - шторка в значении MEDIUM_HEIGHT_BOTTOMSHEET, если находится в этой области - то вызываем flyTo
            const heightBottomSheet = Math.round(MEDIUM_HEIGHT_BOTTOMSHEET({ maxHeight: mapSize.y }))
            topLeftCorner = map.containerPointToLatLng(L.point(20, 20))
            bottomRightCorner = map.containerPointToLatLng(L.point(mapSize.x - 20, mapSize.y - heightBottomSheet - 20))
        } else {
            // задаем область - виртуальную рамку шириной 20px по границам mapContainer (+ область под Sidepanel), если находится в этой области - то вызываем flyTo
            topLeftCorner = map.containerPointToLatLng(L.point(sizeScaleFromHtml(OPEN_WIDTH_SECOND_PANEL) + 20, 20))
            bottomRightCorner = map.containerPointToLatLng(L.point(mapSize.x - 20, mapSize.y - 20))
        }
        const visibleBounds = L.latLngBounds(topLeftCorner, bottomRightCorner)
        isContains = visibleBounds.contains(L.latLng(item.lat, item.lon))

        if (!isContains) {
            const pos = [item.lat, item.lon];
            const zoom = map.getZoom();
            map.flyTo(pos, zoom, { animate: false })
        }
    }

    return <Marker
        position={[item.lat, item.lon]}
        icon={icon}
        id={item.id}
        zIndexOffset={zIndexOffset}
        eventHandlers={{
            click: (e) => handleClick && handleClick(item.id)
        }}
    />
}

export default memo(CreatePoint, (prevProps, nextProps) => {
    // обновляем только когда изменился props.selected и props.iconShape
    if (nextProps.selected === true || prevProps.selected === true
        ||
        prevProps.iconShape !== nextProps.iconShape) {
        return false
    } else {
        return true
    }
})

CreatePoint.propTypes = {
    item: PropTypes.object.isRequired,
    iconId: PropTypes.number,
    iconShape: PropTypes.number,
    iconUrl: PropTypes.string.isRequired,
    handleClick: PropTypes.func,
    selected: PropTypes.bool.isRequired,
}
