import { Box, Button, Stack } from '@mui/material'
import ModalTemplate from './ModalTemplate'


// для использования в связке с hook useModal https://dev.to/lico/react-ts-how-i-manage-modal-components-custom-modals-hook-4nmg
const ModalAlert = ({ visible = false, onClose, title, children, onOk, backdropOpacity, width }) => {

    const handleOk = () => {
        onOk?.()
        onClose?.()
    }

    const props = {
        visible,
        onClose,
        title,
        backdropOpacity,
        width,
    }
    return (
        <ModalTemplate {...props}>
            <Stack spacing={3}>
                <Box>{children}</Box>
                {handleOk && <Box><Button variant='contained' onClick={handleOk}>Ок</Button></Box>}
            </Stack>
        </ModalTemplate>
    )
}

export default ModalAlert