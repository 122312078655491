import { useLayoutEffect } from "react"
import { useMap } from "react-leaflet"
import L from "leaflet";


const useAddCornersInMap = () => {
    const map = useMap()

    useLayoutEffect(() => {
        const corners = map._controlCorners
        const l = 'leaflet-'
        const container = map._controlContainer

        corners['verticalright'] = L.DomUtil.create('div', l + 'verticalright', container);
        corners['verticalrighttop'] = L.DomUtil.create('div', l + 'verticalrighttop', corners['verticalright']);
        corners['verticalrightcenter'] = L.DomUtil.create('div', l + 'verticalrightcenter', corners['verticalright']);
        corners['verticalrightbottom'] = L.DomUtil.create('div', l + 'verticalrightbottom', corners['verticalright']);
    }, [])
    return null
}

export default useAddCornersInMap