import React, { useContext } from 'react';
import { UserContext } from '../UserContext';
import WrapFilterList from '../../WrapFilterList';


const Permissions = () => {

    const { prepareUserDialog: { user, aclList }, control, setValue } = useContext(UserContext)

    const props = {
        nameFields: 'aclIds',
        list: aclList,
        checkedIds: user.aclIds,
        control,
        setValue,
    }
    return (
        <WrapFilterList {...props}/>
    )
}

export default Permissions