import { WMSTileLayer } from 'react-leaflet/WMSTileLayer'


const CreateWMS = ({wmsUrl, layerId}) => {
   
    const url = wmsUrl.replace(/([^?]*)\?(.*)/, '$1');
    let wmsParams = Object.fromEntries(new URLSearchParams(wmsUrl.replace(url, '')));
    if (wmsParams.tileSize) {
        wmsParams.tileSize = parseInt(wmsParams.tileSize);
    }
    
    Object.assign(wmsParams, {
        layers: layerId,
        format: 'png',
        transparent: true,
        attribution: "wms",
        tileSize: (undefined === wmsParams.tileSize) ? 256 : wmsParams.tileSize
    });

    return (
        <WMSTileLayer
            url={url}
            params={wmsParams}
            eventHandlers={{
                click: (e) => {
                    console.log(e);
                },
            }}
        />
    )
};

export default CreateWMS;
