import { Box, FormLabel, Stack, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { userRoleSelector } from '../../../../store/selectors/selectors';
import { ApiPaths } from '../../../../config';
import ColorTextField from '../../ForForms/ColorTextField';
import IconBox from '../../ForForms/IconBox/IconBox';
import { useUpdateIconObjectMutation } from '../../../../hooks/reactQuery/useObject';
import FormContainer from '../../ForForms/FormContainer';


const Display = ({ reactHookForm: { watch, control, setValue }, object }) => {

    const { isGeoEditor } = useSelector(userRoleSelector)
    const { mutate: updateIconObject } = useUpdateIconObjectMutation()

    return (
        <FormContainer>
            <Stack direction='row' alignItems='center'>
                <Typography variant='font_24' component={FormLabel}>Иконка объекта точка:&nbsp;</Typography>
                <Box>
                    <IconBox
                        iconId={object.iconId}
                        src={ApiPaths.icon.FROM_OBJECT.replace(":objectId", object.id)}
                        editor={true}
                        updateIconFn={(iconId) => updateIconObject({ objectId: object.id, iconId })}
                    />
                </Box>
            </Stack>

            <Controller
                name='lineColor'
                control={control}
                render={({ field }) => <ColorTextField {...field}
                    disabled={isGeoEditor ? false : true}
                    fullWidth
                    label='Цвет границ'
                    variant="outlined"
                    margin="normal"
                    setValue={setValue}
                />}
            />

            <Controller
                name='lineWeight'
                control={control}
                render={({ field }) => <TextField {...field}
                    disabled={isGeoEditor ? false : true}
                    type='number'
                    fullWidth
                    label='Толщина линии (px)'
                    variant="outlined"
                    margin="normal"
                />}
            />

            <Controller
                name='fillColor'
                control={control}
                render={({ field }) => <ColorTextField {...field}
                    disabled={isGeoEditor ? false : true}
                    fullWidth
                    label='Цвет заливки'
                    variant="outlined"
                    margin="normal"
                    setValue={setValue}
                />}
            />

        </FormContainer>
    )
}

export default Display