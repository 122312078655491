import api from "../http";
import {ApiPaths} from "../../config"


// templates
export const loadMobileTemplates = async (mobileTmplId) => {
    const result = await api.get(ApiPaths.templates.MOBILE_TEMPLATE.replace(':mobileTmplId', mobileTmplId));
    return result.data
}


// user
export const loadUserData = async () => {
    const result = await api.get(ApiPaths.user.METADATA)
    return result.data
}


// others
export const uploadResource = async (file) => {
    const formData = new FormData()
    formData.append("file", file)
    return await api({
        url: ApiPaths.others.UPLOAD_RESOURCE,
        method: 'post',
        data: formData,
        headers: { 'contentType': false, Accept: '*/*' },
    })
}