import React, { useState } from 'react';
import { Box, Button, Container, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import api from '../../../service/http';
import { ApiPaths} from '../../../config';
import MyAlert from '../Info/MyAlert';


const Feedback = (props) => {
    // props.layerId
    // props.objectId

    const location = useLocation()
    const [sendState, setSendState] = useState({ error: false, success: false });

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        defaultValues: {
            fromName: '',
            fromMail: '',
            comment: '',
        },
        mode: 'onSubmit'
    });

    const prepareFormData = (fromName, fromMail, comment) => {
        const formData = new FormData();
        formData.append('fromName', fromName);
        formData.append('fromMail', fromMail);
        formData.append('comment', comment);
        formData.append('objectId', props.layerId ? Number(props.layerId) : Number(props.objectId));
        // eslint-disable-next-line no-restricted-globals
        formData.append('url', window.location.hostname + location.pathname + location.search);
        return formData;
    }

    const sendForm = async (formData) => {
        return api.post(
            props.layerId ? ApiPaths.feedback.LAYER : ApiPaths.feedback.OBJECT,
            formData,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    'accept': '*/*',
                }
            }
        )
    }

    const onSubmit = async (data) => {
        try {
            const myFormData = prepareFormData(data.fromName, data.fromMail, data.comment);
            let result = await sendForm(myFormData);

            if (result) {
                setSendState({ error: false, success: true })
                reset()
            }
        } catch (e) {
            console.log(e)
            setSendState({ error: true, success: false })
        }
    };

    const isValidEmail = (val) => {
        // eslint-disable-next-line no-useless-escape
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(val)) {
            return 'Введите корректный Email';
        }
    }

    // Styled
    const myAlertSx = {
        mt: '10px',
        mb: '10px',
    }

    return (
        <Container className='feed_back_form' maxWidth="sm" >

            {sendState.error && <MyAlert sx={myAlertSx} severity='error' message={'Ошибка! Замечание не было отправлено.'} />}
            {sendState.success && <MyAlert sx={myAlertSx} severity='success' message={'Замечание успешно отправлено.'} />}

            <Box component="form" method='POST' onSubmit={handleSubmit(onSubmit)} >
                <Controller
                    name="fromName"
                    control={control}
                    rules={{ required: 'Поле обязательно для заполнения' }}
                    render={({ field }) => <TextField {...field}
                        margin="normal"
                        fullWidth
                        label="Ваше имя"
                        variant="outlined"
                        autoFocus
                        error={errors?.fromName?.message ? true : false}
                        helperText={errors?.fromName?.message ? errors?.fromName?.message : false}
                    />}
                />

                <Controller
                    name="fromMail"
                    control={control}
                    rules={{
                        required: 'Поле обязательно для заполнения',
                        validate: isValidEmail,
                    }}
                    render={({ field }) => <TextField {...field}
                        margin="normal"
                        fullWidth
                        label="Email"
                        variant="outlined"
                        error={errors?.fromMail?.message ? true : false}
                        helperText={errors?.fromMail?.message ? errors?.fromMail?.message : false}
                    />}
                />

                <Controller
                    name="comment"
                    control={control}
                    rules={{ required: 'Поле обязательно для заполнения' }}
                    render={({ field }) => <TextField {...field}
                        margin="normal"
                        fullWidth
                        label="Комментарий"
                        variant="outlined"
                        multiline
                        rows={8}
                        error={errors?.comment?.message ? true : false}
                        helperText={errors?.comment?.message ? errors?.comment?.message : false}
                    />}
                />

                <Controller
                    name='send'
                    control={control}
                    render={({ field }) => <Button
                        variant="contained"
                        onClick={handleSubmit(onSubmit)} {...field}
                    >Отправить</Button>}
                />
            </Box>
        </Container>
    );
};

export default Feedback;