import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { BottomSheet } from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css';
import { Outlet } from 'react-router-dom';
import { useMatch } from 'react-router-dom';
import { CLOSE_HEIGHT_BOTTOMSHEET, HOME_HEIGHT_BOTTOMSHEET, MAX_HEIGHT_BOTTOMSHEET, MEDIUM_HEIGHT_BOTTOMSHEET, SMALL_HEIGHT_BOTTOMSHEET } from "../../../config";
import { RoutePaths } from '../../../routes';
import { useBottomSheet } from '../../../hooks/useBottomSheet';
import styles from './MobBottomSheet.module.scss'
import { createContext } from 'react';
import { Box } from '@mui/material';

export const MobBottomSheetContext = createContext({})

const MobBottomSheet = () => {

    // console.log("Render MobBottomSheet");
    const [header, setHeader] = useState()
    const [body, setBody] = useState()
    const { bottomSheetRef } = useBottomSheet()

    /* Управление MobBottomSheet */
    // points - можно передавать точки привязки
    // pointsFunc - можно передавать функции вида: ({ maxHeight }) => maxHeight*0.4;
    // defaultSnap - можно передавать как функцию, так и точки привязки
    let points = [];
    let pointsFunc = [];
    let defaultSnap;

    const isHomePage = useMatch(RoutePaths.HOME)
    const isSearchLocationPage = useMatch(RoutePaths.SEARCH_LOCATION)
    const isLayerPage = useMatch(RoutePaths.LAYER)
    const isObjectPage = useMatch(RoutePaths.OBJECT)

    /* start обработка наличия параметра layers в url - в таком случае шторку в минимум */
    const isInitialMountRef = useRef(true)
    const params = new URLSearchParams(window.location.search)
    const isLayersUrl = params.get('layers')
    useEffect(() => {
        isInitialMountRef.current = false
    }, [])
    /* end */

    if (isHomePage || isSearchLocationPage) {
        points = [HOME_HEIGHT_BOTTOMSHEET]
        defaultSnap = HOME_HEIGHT_BOTTOMSHEET
    } else if (isObjectPage) {
        points = [SMALL_HEIGHT_BOTTOMSHEET]
        pointsFunc = [MEDIUM_HEIGHT_BOTTOMSHEET]
        defaultSnap = MEDIUM_HEIGHT_BOTTOMSHEET
    } else if (isLayerPage && isLayersUrl && isInitialMountRef.current) { // если в urlParams есть параметр layers - то шторку в минимум
        defaultSnap = SMALL_HEIGHT_BOTTOMSHEET
    } else {
        points = [SMALL_HEIGHT_BOTTOMSHEET]
        defaultSnap = MAX_HEIGHT_BOTTOMSHEET
    }


    /* Функции, облегчающие работу с управлением высотой шторки*/
    const snapPoints = ({ minHeight, maxHeight }) => {
        let fn = [];
        pointsFunc.forEach(itemFunc => {
            fn.push(itemFunc({ minHeight, maxHeight }));
        });

        return [
            CLOSE_HEIGHT_BOTTOMSHEET,
            ...points,
            ...fn,
            MAX_HEIGHT_BOTTOMSHEET({ maxHeight }),
        ]
    }

    const defaultSnapFn = ({ minHeight, maxHeight/*, snapPoints*/ }) => {
        if (typeof defaultSnap === 'function') {
            return defaultSnap({ minHeight, maxHeight })
        }
        if (typeof defaultSnap === 'number') {
            return defaultSnap
        }
        return maxHeight;
    }

    useEffect(() => {
        bottomSheetRef.current?.snapTo(defaultSnap);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultSnap, points]);

    useLayoutEffect(() => {
        if (header) {
            setHeader('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHomePage])

    return (
        <BottomSheet
            ref={bottomSheetRef}
            header={header}
            className={isHomePage && styles.home}
            open={true}
            blocking={false}
            expandOnContentDrag={false} // отвечает за возможность перетаскивания за контент. 
            skipInitialTransition={true} // отвечает за то, как появляется шторка - как пружина, или мгновенно
            defaultSnap={defaultSnapFn}
            snapPoints={snapPoints}
        >
            <MobBottomSheetContext.Provider value={{ setHeader, setBody }}>
                <Box style={{ display: body ? 'none' : 'block' }}><Outlet /></Box>
                {body}
            </MobBottomSheetContext.Provider>
        </BottomSheet>
    )
}

export default MobBottomSheet