import { Polygon } from 'react-leaflet/Polygon';
import { Polyline } from 'react-leaflet/Polyline';
import { memo } from 'react';
import { rotateCoordinates, sizeScaleFromHtml } from "../../../service/function";
import { useMap } from 'react-leaflet';
import PropTypes from 'prop-types'
import L from "leaflet";
import { MEDIUM_HEIGHT_BOTTOMSHEET, OPEN_WIDTH_SECOND_PANEL } from '../../../config';
import useDeviceDetect from '../../../hooks/useDeviceDetect';


const CreateShape = ({ selected, item, handleClick, lineColor, lineWeight, fillColor, fillOpacity, isNoPaddingSelected }) => {
    // isNoPaddingSelected - если не нужно смещение выделенного объекта из под панельки, шторки ...
    // console.log("Render CreateShape");
    const SIZE_SHAPE_FROM_FITBOUNDS = 20 // если width/height в px выделенной фигуры меньше данного значения - то вызывываем fitBounds
    const map = useMap()
    const { isMobile } = useDeviceDetect()

    // eslint-disable-next-line no-eval
    let geoJsonObject = JSON.parse(item.geoJSON)
    const coordinates = rotateCoordinates(geoJsonObject.coordinates)
    const type = geoJsonObject.type

    const defaultSettings = {
        lineColor: 'blue',
        lineWeight: 2,
        fillColor: 'blue',
        fillOpacity: 0.4,
    }
    // Свойства отображения объектов есть как у слоя, так и у конкретного объекта.    
    let metadata = {
        lineColor: lineColor ? lineColor : defaultSettings.lineColor,
        lineWeight: lineWeight !== null ? lineWeight : defaultSettings.lineWeight,
        fillColor: fillColor ? fillColor : defaultSettings.fillColor,
        fillOpacity: fillOpacity !== null ? fillOpacity / 100 : defaultSettings.fillOpacity,
    };

    // выделенный объект
    if (selected) {
        metadata = {
            lineColor: '#219653',
            lineWeight: 3,
            fillColor: '#27AE60',
            fillOpacity: 0.9
        }

        let isContains = true
        let topLeftCorner, bottomRightCorner
        let heightBottomSheet
        const mapSize = map.getSize()
        if (isMobile) {
            // задаем область - рамку шириной 20px по границам mapContainer, нижняя граница которой - шторка в значении MEDIUM_HEIGHT_BOTTOMSHEET, если находится в этой области - то вызываем flyTo
            heightBottomSheet = Math.round(MEDIUM_HEIGHT_BOTTOMSHEET({ maxHeight: mapSize.y }))
            topLeftCorner = map.containerPointToLatLng(L.point(20, 20))
            bottomRightCorner = map.containerPointToLatLng(L.point(mapSize.x - 20, mapSize.y - heightBottomSheet - 20))
        } else {
            if (!isNoPaddingSelected) {
                // задаем область - рамку шириной 20px по границам mapContainer (+ область под Sidepanel), если находится в этой области - то вызываем flyTo
                topLeftCorner = map.containerPointToLatLng(L.point(sizeScaleFromHtml(OPEN_WIDTH_SECOND_PANEL) + 20, 20))
                bottomRightCorner = map.containerPointToLatLng(L.point(mapSize.x - 20, mapSize.y - 20))
            }
        }

        // 1. Определяем что объект попадает в определенную нами область
        const visibleBounds = isNoPaddingSelected
            ? map.getBounds()
            : L.latLngBounds(topLeftCorner, bottomRightCorner)

        const shapeBounds =
            (type === "Polygon" || type === "MultiPolygon")
                ? L.polygon(coordinates).getBounds()
                : L.polyline(coordinates).getBounds()

        isContains = visibleBounds.contains(shapeBounds)

        // вычисляем видимые размеры полигона в px 
        const southWestPoint = map.latLngToContainerPoint(shapeBounds._southWest) // ЮЗ
        const northEastPoint = map.latLngToContainerPoint(shapeBounds._northEast) // СВ
        let sizeShape = Math.max(northEastPoint.x - southWestPoint.x, northEastPoint.y - southWestPoint.y)

        /* Вызываем fitBounds если:
           - объект находится за пределами области, не помещается полностью в нее
           - размер объекта в px при текущем масштабе меньше SIZE_SHAPE_FROM_FITBOUNDS
           + указываем paddingTopLeft - чтобы часть объекта не оказалась secondPanel*/

        if (!isContains || sizeShape < SIZE_SHAPE_FROM_FITBOUNDS) {
            map.fitBounds([coordinates], {
                paddingTopLeft: (!isMobile && !isNoPaddingSelected) ? L.point(sizeScaleFromHtml(OPEN_WIDTH_SECOND_PANEL) + 20, 0) : L.point(0, 0),
                paddingBottomRight: (!isMobile || isNoPaddingSelected) ? L.point(0, 0) : L.point(0, heightBottomSheet)
            })
        }
    }

    if (type === "Polygon" || type === "MultiPolygon") {
        return (
            <Polygon
                positions={coordinates}
                id={item.id}
                pathOptions={{
                    color: metadata.lineColor,
                    weight: metadata.lineWeight,
                    fillColor: metadata.fillColor,
                    fillOpacity: metadata.fillOpacity,
                }}
                eventHandlers={{
                    click: () => {
                        handleClick(item.id)
                    },
                }}
                zIndexOffset={1000}
            />
        )
    }

    if (type === "LineString" || type === "MultiLineString") {
        return (
            <Polyline
                positions={coordinates}
                id={item.id}  // надо ли?
                pathOptions={{
                    color: metadata.lineColor,
                    weight: metadata.lineWeight,
                    fillColor: metadata.fillColor,
                }}
                eventHandlers={{
                    click: (/*e*/) => { handleClick && handleClick(item.id) },
                }}
            />
        )
    }
    return null
}

export default memo(CreateShape, (prevProps, nextProps) => {
    if (nextProps.selected === true || prevProps.selected === true) {
        return false
    } else {
        return true
    }
})

CreateShape.propTypes = {
    item: PropTypes.object.isRequired,
    handleClick: PropTypes.func,
    selected: PropTypes.bool.isRequired,
    lineColor: PropTypes.string,
    lineWeight: PropTypes.number,
    fillColor: PropTypes.string,
    fillOpacity: PropTypes.number,
    isNoPaddingSelected: PropTypes.bool,
}
