import { useMapEvents } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { RoutePaths } from '../../../routes';
import { isAuthSelector } from '../../../store/selectors/selectors';
import useNavigate from '../../../hooks/useNavigateWithSearchParams'


const MapEvents = () => {

    let navigate = useNavigate()
    const isAuth = useSelector(isAuthSelector)
    

    useMapEvents({
        click: (e) => {
            // здесь надо проверить на роль пользователя. Если isAnonymous - то скрываем правое меню - в нашем случае делаем переход на главную страницу
            // добавил проверку на e.originalEvent.target.localName !== 'path' - иначе срабатывал при клике на полигон,
            // и !== 'img' - иначе срабатывал на arcgis tile
            if (!isAuth && e?.originalEvent?.target?.localName !== 'path' && e?.originalEvent?.target?.localName !== 'img') {
                navigate(RoutePaths.HOME)
            }
        },
        // zoomend: (arg) => {
        //     const zoom = arg.target._zoom
        //     setSearchParams(params => {
        //         const newParams = Object.assign(params)
        //         newParams.set('z', zoom)
        //         return newParams
        //     })
        // },
        // moveend: (arg) => {
        //     const center = arg.target.getCenter()
        //     setSearchParams(params => {
        //         const newParams = Object.assign(params)
        //         if (center) {
        //             newParams.set('c', `${center.lat},${center.lng}`)
        //         }
        //         return newParams
        //     })
        // }
    })
    return null
}

export default MapEvents