
import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material"
import { useContext } from "react"
import { Controller } from "react-hook-form"
import { RoleContext } from "../RoleContext"


const General = () => {

    const { prepareRoleDialog: { role, printApps, editor }, control } = useContext(RoleContext)

    return (
        <Stack variant='mtrbl' spacing={2}>
            {role.id > 10 || !role.id
                ? <Controller
                    name='name'
                    control={control}
                    disabled={!editor}
                    rules={{ required: "Поле обязательно для заполнения" }}
                    render={({ field, fieldState: { error } }) => <TextField {...field}
                        label='Название роли'
                        variant="outlined"
                        error={Boolean(error)}
                        helperText={error && error.message}
                    />
                    }
                />
                : <Controller
                    name='name'
                    control={control}
                    disabled={!editor}
                    render={({ field }) => <input type='hidden' {...field} />
                    }
                />
            }

            <Controller
                name='comment'
                control={control}
                disabled={!editor}
                render={({ field }) => <TextField {...field}
                    label='Описание'
                    variant="outlined"
                />}
            />

            <Controller
                name='printTmpl'
                control={control}
                disabled={!editor}
                render={({ field }) => (
                    <FormControl>
                        <InputLabel id='printTmpl'>Шаблон печати</InputLabel>
                        <Select
                            label='Шаблон печати'
                            variant="outlined"
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: '250px'
                                    }
                                }
                            }}
                            {...field}
                        >
                            <MenuItem value=''>не задано</MenuItem>
                            {printApps.map(item =>
                                <MenuItem key={item} value={item}>{item}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                )}
            />

            <Controller
                name='geoEdit'
                control={control}
                disabled={!editor}
                render={({ field }) => <Box><FormControlLabel control={<Checkbox {...field} checked={field.value} />} label='Редактирует на карте' /></Box>}
            />

        </Stack>
    )
}

export default General