import { createTheme } from "@mui/material";
import { palette } from './themeSettings'


let theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 1921,
        },
    },
    palette,
    typography: {
        color: '#333333',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
    },
})

export const themeHtmlFontSize = theme.typography.htmlFontSize
export const pxToRem = (valPx) => {
    return String(valPx).replace('px', '') / themeHtmlFontSize + 'rem'
}


theme = createTheme(theme, {
    spacing: (factor) => `${pxToRem(factor * 8)}`,
    typography: {
        font_12: {
            fontSize: pxToRem('12px'),
            lineHeight: 'normal',
            letterSpacing: pxToRem('0.4px'),
            color: theme.palette.myBlack.main,
        },
        font_20: {
            fontSize: pxToRem('20px'),
            fontWeight: 600,
            lineHeight: 'normal',
            letterSpacing: pxToRem('0.15px'),
        },
        font_24: {
            lineHeight: pxToRem('24px'),
            fontWeight: 400,
        },
        font_22_28: {
            fontSize: pxToRem('22px'),
            fontWeight: 600,
            lineHeight: pxToRem('28px'),
        },
        font_12_16: {
            fontSize: pxToRem('12px'),
            lineHeight: pxToRem('16px'),
            letterSpacing: pxToRem('0.4px')
        },
        font_14_20: {
            color: 'rgba(51, 51, 51, 0.60);',
            fontSize: pxToRem('14px'),
            lineHeight: pxToRem('20px'),
            letterSpacing: pxToRem('0.25px'),
        },
        font_32_40: {
            fontSize: pxToRem('32px'),
            fontWeight: 600,
            lineHeight: pxToRem('40px'),
        },
        font_16_24: {
            fontWeight: 600,
            lineHeight: pxToRem('24px'),
            letterSpacing: pxToRem('0.15px'),
        },
        font_16_normal_008: {
            letterSpacing: pxToRem('0.08px'),
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides:
            {
                html: {
                    [theme.breakpoints.up('xxl')]: { // >1921
                        fontSize: '100%'
                    },
                    [theme.breakpoints.down('xxl')]: {  // <1921
                        fontSize: '93.75%',
                    },
                    [theme.breakpoints.down('xl')]: {
                        fontSize: '87.5%',
                    },
                    [theme.breakpoints.down('lg')]: {
                        fontSize: '81.25%',
                    },
                    [theme.breakpoints.down('md')]: {
                        fontSize: '75%',
                    },
                },
                body: {
                    //font
                    fontSize: pxToRem('16px'),
                    lineHeight: pxToRem('22px'),
                    letterSpacing: pxToRem('0.5px'),

                    // ScrollBar
                    '*::-webkit-scrollbar': {
                        width: '10px'
                    },
                    '*::-webkit-scrollbar-thumb': {
                        background: 'rgba(154, 154, 154, 0.64)',
                        backgroundClip: 'content-box',
                        border: '3px solid transparent',
                        borderRadius: '100px',
                    }
                },
                a: {
                    color: theme.palette.myBlack.main,
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'underline',
                    }
                }
            },
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: theme.palette.myGreen.main,
                }
            }
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    '& .MuiTableHead-root .MuiTableCell-root': {
                        lineHeight: pxToRem('24px'),
                        letterSpacing: pxToRem('0.15px'),
                        fontWeight: 600,
                    }
                }
            }
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                li: {
                    fontSize: pxToRem('14px'),
                    lineHeight: pxToRem('20px'),
                    color: theme.palette.myBlack.main,
                    '& a': {
                        opacity: 0.5,
                    },
                },
                separator: { 'svg': { stroke: theme.palette.myBlack.main, } }
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: pxToRem('16px'),
                    fontFamily: 'Inter',
                    lineHeight: pxToRem('19.2px'),
                    paddingTop: pxToRem('8px'),
                    paddingBottom: pxToRem('10px'),
                }
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    borderRadius: 0
                },
                option: {
                    '& .MuiBreadcrumbs-li': {
                        letterSpacing: pxToRem('0.25px'),
                    }
                }
            },
        },
        MuiStack: {
            variants: [
                {
                    props: { variant: 'mlr' },
                    style: {
                        paddingLeft: pxToRem('32px'),
                        paddingRight: pxToRem('32px'),
                    }
                },
                {
                    props: { variant: 'mtrbl' },
                    style: {
                        paddingLeft: pxToRem('32px'),
                        paddingRight: pxToRem('32px'),
                        paddingTop: pxToRem('16px'),
                        paddingBottom: pxToRem('8px'),
                    }
                },
                {
                    props: { variant: 'bottom_mlr' },
                    style: {
                        borderTop: '1px solid',
                        borderColor: 'rgba(0, 0, 0, 0.10)',
                        paddingLeft: pxToRem('32px'),
                        paddingRight: pxToRem('32px'),
                        paddingTop: pxToRem('24px'),
                        marginTop: '0px',
                    }
                },
            ]
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: { padding: 0 },
                input: {
                    padding: `${pxToRem('16.5px')} ${pxToRem('14px')}`, // for adaptive
                    lineHeight: pxToRem('24px'),
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-focused': {
                        color: theme.palette.myGreen.main,
                    },
                    transform: `translate(${pxToRem('14px')}, ${pxToRem('16px')}) scale(1)`,
                },
                shrink: {
                    transform: `translate(14px, ${pxToRem('-9px')}) scale(0.75)`,
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    '& label.Mui-focused': {
                        color: theme.palette.myGreen.main,
                    },
                    '& label.Mui-error': {
                        color: theme.palette.error.main,
                    },
                    '& .MuiOutlinedInput-root, & .MuiOutlinedInput-root.Mui-disabled': {
                        '& fieldset': {
                            borderColor: theme.palette.myGray.main,
                        },
                        '&:hover fieldset': {
                            borderColor: theme.palette.myGreen.main,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: theme.palette.myGreen.main,
                        },
                        '&.Mui-error fieldset': {
                            borderColor: theme.palette.error.main,
                        },
                    },
                },
            },
            variants: [
                {
                    props: { var: 'small' },
                    style: {
                        '.MuiOutlinedInput-input': {
                            paddingTop: pxToRem('8px'),
                            paddingBottom: pxToRem('9px'),
                        },
                        '.MuiOutlinedInput-root': {
                            paddingRight: pxToRem('4px'),
                        }
                    }
                },
            ],
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-input': {
                        lineHeight: pxToRem('24px'),
                        // paddingRight: pxToRem('32px'),
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.myGreen.main,
                    },

                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.myGray.main,
                    },

                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.myGreen.main,
                    },

                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: theme.palette.myGray.dark,
                    '&.Mui-checked': {
                        color: theme.palette.myGreen.main,
                    },
                    '&.Mui-disabled': {
                        color: 'rgba(0, 0, 0, 0.26)',
                    },
                    padding: pxToRem('8px'),
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: theme.palette.myGreen.main
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: pxToRem('16px'),
                    lineHeight: pxToRem('24px'),
                    fontWeight: 600,
                    textTransform: 'none',

                    /* */
                    paddingTop: pxToRem('6px'),
                    paddingBottom: pxToRem('6px'),
                    paddingLeft: pxToRem('8px'),
                    paddingRight: pxToRem('8px'),
                    /* */
                }
            },
            variants: [
                {
                    props: { variant: 'greenText' },
                    style: {
                        color: theme.palette.myGreen.green,
                        paddingTop: pxToRem('7px'),
                        paddingBottom: pxToRem('7px'),
                    }
                },
                {
                    props: { variant: 'contained' },
                    style: {
                        '&, &:hover': {
                            background: theme.palette.myGreen.main,
                            borderColor: theme.palette.myGreen.main,
                        },
                        '&.MuiButton-root': {
                            color: 'white',
                            paddingTop: pxToRem('6px'),
                            paddingBottom: pxToRem('8px'),
                            paddingRight: pxToRem('24px'),
                            paddingLeft: pxToRem('24px'),
                            borderRadius: pxToRem('100px'),
                        },
                    }
                },
                {
                    props: { variant: 'outlined' },
                    style: {
                        '&.MuiButton-root': {
                            color: theme.palette.myBlack.main,
                            paddingTop: pxToRem('6px'),
                            paddingBottom: pxToRem('8px'),
                            paddingRight: pxToRem('24px'),
                            paddingLeft: pxToRem('24px'),
                            borderRadius: pxToRem('100px'),
                            border: '1px solid rgba(51, 51, 51, 0.32);',
                        }
                    }
                },
                {
                    props: { variant: 'text' },
                    style: {
                        '&.MuiButton-root': {
                            color: theme.palette.myBlack.main,
                            fontWeight: 400,
                            letterSpacing: '0.005em',
                            lineHeight: pxToRem('20px'),
                            opacity: 0.5,
                            // fontSize:pxToRem('16px'),
                        }
                    }
                },
            ]
        },

        MuiTabs: {
            styleOverrides: {
                root: {
                    '& .MuiTab-root': {
                        fontSize: pxToRem('14px'),
                        lineHeight: pxToRem('20px'),
                        letterSpacing: pxToRem('0.1px'),
                        color: theme.palette.myBlack.main,
                        textTransform: 'capitalize',
                        fontWeight: 600,
                        paddingLeft: pxToRem('41px'),
                        paddingRight: pxToRem('41px'),
                        paddingBottom: pxToRem('15px'),
                        paddingTop: pxToRem('13px'),
                    },
                    '& .MuiTabs-scroller': {
                        marginLeft: pxToRem('32px'),
                        marginRight: pxToRem('32px'),
                    },
                    '& .MuiTab-root.Mui-selected': {
                        'color': theme.palette.myGreen.main
                    }
                },
                indicator: {
                    backgroundColor: theme.palette.myGreen.main
                }
            },
            variants: [
                {
                    props: { var: 'var1' },
                    style: {
                        '& .MuiTab-root': {
                            paddingLeft: pxToRem('32px'),
                            paddingRight: pxToRem('32px'),
                        },
                    }
                },
                {
                    props: { var: 'var2' },
                    style: {
                        '& .MuiTab-root': {
                            paddingLeft: pxToRem('32px'),
                            paddingRight: pxToRem('32px'),
                        },
                        '&.MuiTabs-root .MuiTabs-flexContainer': {
                            justifyContent: 'flex-start'
                        }
                    }
                }
            ]
        },
        MuiDivider: {
            variants: [
                {
                    props: { variant: 'var1' },
                    style: {
                        borderBottomColor: 'rgba(0, 0, 0, 0.10);',
                    }
                },
            ]
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    alignItems: 'start',
                    marginLeft: pxToRem('-11px'),
                    marginRight: pxToRem('16px'),
                },
                label: {
                    lineHeight: pxToRem('24px'),
                    letterSpacing: pxToRem('0.5px'),
                    paddingTop: pxToRem('8px'),
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: theme.palette.myGreen.dark,
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginTop: pxToRem('4px'),
                    marginBottom: pxToRem('4px'),
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: pxToRem('4px'),
                }
            },
            variants: [
                {
                    props: { variant: 'var1' },
                    style: {
                        padding: 0,
                        minWidth: pxToRem('24px')
                    }
                },
                {
                    props: { size: '40' },
                    style: {
                        width: pxToRem('40px'),
                        height: pxToRem('40px'),
                    }
                }
            ]
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    paddingTop: pxToRem('9px'),
                },
                paper: {
                    boxShadow: `0px ${pxToRem('4px')} ${pxToRem('24px')} 0px rgba(51, 51, 51, 0.16)`
                },
            },
            variants: [
                {
                    props: { variant: 'userMenu' },
                    style: {
                        '.MuiMenu-paper': {
                            top: `${pxToRem('75px')} !important`,
                            right: `${pxToRem('24px')} !important`,
                            borderRadius: pxToRem('12px'),
                            boxShadow: `0px ${pxToRem('4px')} ${pxToRem('24px')} 0px rgba(51, 51, 51, 0.16)`,
                        }, '& .MuiMenuItem-root': {
                            lineHeight: 'normal'
                        }
                    }
                }
            ]
        },
        MuiList: {
            styleOverrides: {
                root: {
                    '& .MuiListItem-root': {
                        paddingTop: pxToRem('8px'),
                        paddingBottom: pxToRem('8px'),
                    },
                }
            },
            variants: [
                {
                    props: { variant: 'var1' },
                    style: {
                        '& .MuiListItem-root': {
                            paddingTop: pxToRem('2px'),
                            paddingBottom: pxToRem('4px'),
                        },
                        '& .MuiListItemSecondaryAction-root': {
                            right: pxToRem('8px'),
                        },
                        '& .MuiListItemButton-root': {
                            '&:hover': {
                                cursor: 'default'
                            },
                            padding: 0
                        }
                    }
                },
            ]
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    '& .MuiPaginationItem-root': {
                        lineHeight: pxToRem('24px'),
                    }
                }
            }
        }
    }
})

export default theme