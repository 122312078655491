import { useMap } from "react-leaflet";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { mapSelector } from "../../../../store/selectors/selectors";

const MutableWmsTileLayer = () => {
    const map = useMap();
    const {wmsTileLayer} = useSelector(mapSelector)
    const [currentWmsTileLayer, setCurrentWmsTileLayer] = useState()
    
    useEffect(() => {
        if(wmsTileLayer) {
            map.addLayer(wmsTileLayer.layer)
        }

        if (currentWmsTileLayer) {
            map.removeLayer(currentWmsTileLayer)
        }
        setCurrentWmsTileLayer(wmsTileLayer?.layer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, wmsTileLayer])
    
    return null
}

export default MutableWmsTileLayer