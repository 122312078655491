import { Box, Button, CircularProgress, Divider, FormLabel, IconButton, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types'
import { userRoleSelector } from '../../../../store/selectors/selectors';
import { ReactComponent as CloseIcon } from "../../../../Icons/search/CloseIcon.svg"
import { ModalContextProvider, useModal } from '../../../../hooks/useModal/useModal';
import { useDelObjectsFromLayerMutation } from '../../../../hooks/reactQuery/useObject';
import MyLabelForm from './components/MyLabelForm';
import TreeList from './components/TreeList';
import { pxToRem } from '../../../Style/desktopTheme';
import FormContainer from '../../ForForms/FormContainer';


const MyLabelValue = ({ label, value }) => (
    <Box>
        <FormLabel><Typography variant='font_24'>{label}: &nbsp;</Typography></FormLabel>
        <Typography variant='font_24' sx={{ color: 'myGreen.dark' }}>{value}</Typography>
    </Box>
)

// layerIds - массив Id слоев, к которым привязан объект
const AddCopyObjectInLayerButton = ({ objectId, layerIds = [] }) => {
    const { openWindow } = useModal()  // второе модальное окно, поверх основного модального окна

    const openTreeListLayers = () => {
        openWindow({
            title: 'Выбор слоя',
            children: <TreeList {...{ objectId, layerIds }} />,
            backdropOpacity: 0.2,
            width: '600px'
        })
    }
    return <Button variant='outlined' onClick={openTreeListLayers}>Скопировать</Button>
}


const Information = ({ reactHookForm: { watch, control }, object, preparePropsObject }) => {

    const objectId = object.id
    const { isGeoEditor } = useSelector(userRoleSelector)

    const { mutate: delObjectsFromLayer, variables: delObjectsFromLayerVariables, status: delObjectsFromLayerStatus } = useDelObjectsFromLayerMutation()

    const handleDelObjectFromLayer = (layerId, objectIds) => {
        delObjectsFromLayer({ layerId, objectIds })
    }

    return (
        <FormContainer>
            <MyLabelValue label='Индентификатор' value={watch('id')} />
            <MyLabelValue label='Площадь' value={object.area + ' кв.м.'} />
            <Divider variant='var1' />

            <MyLabelForm label='Координаты' component={
                object.objectGeomType === 'Point'
                    ? <Stack direction='row' spacing={2}>
                        <Controller
                            name='lat'
                            control={control}
                            render={({ field }) => <TextField {...field}
                                disabled={isGeoEditor ? false : true}
                                fullWidth
                                label='Широта'
                                variant="outlined"
                                margin="normal"
                            />}
                        />

                        <Controller
                            name='lon'
                            control={control}
                            render={({ field }) => <TextField {...field}
                                disabled={isGeoEditor ? false : true}
                                fullWidth
                                label='Долгота'
                                variant="outlined"
                                margin="normal"
                            />}
                        />
                    </Stack>
                    : <Controller
                        name='wkt'
                        control={control}
                        render={({ field }) => <TextField {...field}
                            multiline
                            disabled={isGeoEditor ? false : true}
                            fullWidth
                            label='Wkt'
                            variant="outlined"
                            margin="normal"
                        />}
                    />

            } />

            {preparePropsObject.permissionObject &&
                <>
                    <Divider variant='var1' />
                    <MyLabelValue label='Используется в контроле прав!' />
                </>
            }

            <Divider variant='var1' />

            {object.layers.length > 0 &&
                <MyLabelForm
                    label='Принадлежность к слоям'
                    component={
                        <>
                            {preparePropsObject.layerBreadCrump.map(item => {
                                let breadCrumbs = ''
                                item.items.forEach((l, index) => {
                                    breadCrumbs = breadCrumbs + l.name
                                    if (index !== item.items.length - 1) {
                                        breadCrumbs = breadCrumbs + ' > '
                                    }
                                })
                                return (
                                    <TextField
                                        defaultValue={breadCrumbs}
                                        disabled
                                        multiline
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        key={`br-${item.id}`}
                                        InputProps={{
                                            endAdornment: (
                                                <>
                                                    {((delObjectsFromLayerVariables && delObjectsFromLayerVariables.layerId === item.id) && delObjectsFromLayerStatus === 'loading')
                                                        ? <CircularProgress color="inherit" size={20} />
                                                        : <IconButton onClick={() => handleDelObjectFromLayer(item.id, [object.id])} >
                                                            <SvgIcon style={{ fontSize: pxToRem('18px') }}>
                                                                <CloseIcon color="inherit" size={20} />
                                                            </SvgIcon>
                                                        </IconButton>
                                                    }
                                                </>
                                            )
                                        }}
                                    />
                                )
                            })}
                        </>
                    }
                />
            }


            {/* модальное окно с treeList layers */}
            <Stack spacing={2} direction='row'>
                <ModalContextProvider>
                    <AddCopyObjectInLayerButton objectId={objectId} layerIds={object.layers.map(l => l.id)} />
                </ModalContextProvider>
            </Stack>
        </FormContainer>
    )
}

AddCopyObjectInLayerButton.propTypes = {
    objectId: PropTypes.number.isRequired,
    layerIds: PropTypes.arrayOf(PropTypes.number)
}

export default Information