import { Alert, Box, Button, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Stack, SvgIcon, TextField } from "@mui/material"
import React, { useRef, useState } from "react"
import PropTypes from 'prop-types'
import { ReactComponent as SearchIcon } from "../../../../Icons/desktop/form/SearchIcon.svg"
import { ReactComponent as EditIcon } from "../../../../Icons/desktop/form/EditIcon.svg"
import { ReactComponent as DelIcon } from "../../../../Icons/desktop/form/DelIcon.svg"
import { ReactComponent as PlusIcon } from '../../../../Icons/desktop/propLayer/PlusIcon.svg'
import MyPagination from "../../MyPagination/MyPagination"
import { ModalContextProvider, useModal } from "../../../../hooks/useModal/useModal"
import Role from "../components/Role/Role"
import { useDelRoleMutation, useRolesQuery } from "../../../../hooks/reactQuery/useRole"
import { pxToRem } from "../../../Style/desktopTheme"
import FormContainer from "../../ForForms/FormContainer"


const EditRoleButton = ({ roleId }) => {

    const { openWindow } = useModal()
    const dataRef = useRef()

    const editRole = (roleId) => {
        openWindow({
            title: 'Роль',
            children: <Role roleId={roleId} />,
            backdropOpacity: 0.2,
            width: 600,
            dataRef, // ref для хранения данных между переключением tabs в дочернем компоненте
            isCloseBackdropClick: true,
        })
    }
    return <IconButton onClick={() => editRole(roleId)}><SvgIcon style={{ fontSize: pxToRem('16px') }}><EditIcon /></SvgIcon></IconButton>
}

EditRoleButton.propTypes = {
    roleId: PropTypes.number.isRequired,
}

const CreateRoleButton = ({ children }) => {

    const { openWindow } = useModal()
    const dataRef = useRef()

    const createRole = () => {
        openWindow({
            title: 'Роль',
            children: <Role />,
            backdropOpacity: 0.2,
            width: 600,
            dataRef, // ref для хранения данных между переключением tabs в дочернем компоненте
            isCloseBackdropClick: true,
        })
    }
    return <Button variant="outlined" style={{ paddingLeft: '16px' }} onClick={() => createRole()}>{children}</Button>
}

CreateRoleButton.propTypes = {
    children: PropTypes.node.isRequired,
}


const Roles = () => {

    const [term, setTerm] = useState('')    // фильтр
    const [pageNumber, setPageNumber] = useState(1)
    const limit = 10    // кол-во

    const inputRef = useRef()
    const { openConfirm } = useModal()

    const { mutate: delRole } = useDelRoleMutation()
    const { data: { total, roles } = {}, isLoading, error } = useRolesQuery({ term, offset: (pageNumber - 1) * limit, limit })

    const removeRole = (roleId) => {
        openConfirm({
            title: 'Сообщение',
            children: 'Вы действительно желаете удалить роль?',
            cancelText: 'Отмена',
            confirmText: 'Применить',
            onConfirm: () => delRole({ roleId }),
        })
    }

    if (isLoading) return null

    if (error) {
        return <Alert severity="error">{error.message}</Alert>
    }

    return (
        <FormContainer
            action={
                <MyPagination
                    count={Math.ceil(total / limit)}
                    page={pageNumber}
                    siblingCount={2}
                    onChange={(e, page) => {
                        setPageNumber(page)
                    }}
                />
            }
        >
            <Stack direction='row' spacing={1.5}>
                <Box>
                    <ModalContextProvider>
                        <CreateRoleButton><SvgIcon style={{ fontSize: pxToRem('18px') }}><PlusIcon /></SvgIcon>&nbsp;Добавить</CreateRoleButton>
                    </ModalContextProvider>
                </Box>

                <TextField
                    inputRef={inputRef}
                    fullWidth
                    var="small" // my attribute in desktop theme
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={() => setTerm(inputRef.current.value)} >
                                <SvgIcon style={{ fontSize: pxToRem('16px') }}><SearchIcon color="inherit" /></SvgIcon>
                            </IconButton>
                        )
                    }}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                            setTerm(inputRef.current.value)
                        }
                    }}
                />
            </Stack>

            <List variant='var1' className='overflow'>
                {roles.map((r, index) => {
                    return (
                        <React.Fragment key={r.name + r.id}>
                            <Divider />
                            <ListItemButton>
                                <ListItem
                                    secondaryAction={
                                        <Stack direction='row' spacing={1}>

                                            <ModalContextProvider>
                                                <EditRoleButton roleId={r.id} />
                                            </ModalContextProvider>

                                            <IconButton onClick={() => removeRole(r.id)}>
                                                <SvgIcon style={{ fontSize: pxToRem('16px') }}><DelIcon /></SvgIcon>
                                            </IconButton>
                                        </Stack>
                                    }
                                >
                                    <ListItemText
                                        primary={r.name}
                                        primaryTypographyProps={{ variant: 'font_24' }}
                                    />
                                </ListItem>
                            </ListItemButton>
                            {index === roles.length - 1 && <Divider />}
                        </React.Fragment>
                    )
                })}
            </List>
        </FormContainer>
    )
}

export default Roles