import { AppBar, IconButton, SvgIcon, SwipeableDrawer, Toolbar, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { ErrorBoundary } from "react-error-boundary";
import { ReactComponent as SearchIcon } from '../../../Icons/desktop/map/SearchIcon.svg'
import { ReactComponent as CloseIcon } from "../../../Icons/desktop/CloseSearchIcon.svg"
import ControlIconButton from '../MapControl/components/ControlIconButton'
import { ErrorFallback } from '../ErrorBoundary/ErrorFallback'
import { pxToRem } from '../../Style/desktopTheme';
import Search from './Search'


const SearchToolBar = () => {

    const [visibleSearchToolBar, setVisibleSearchToolBar] = useState(false)

    const toggleDrawer = () => (event) => {
        if (event && (event.type === 'keydown' || event.type === 'click')) {
            return;
        }
        setVisibleSearchToolBar(!visibleSearchToolBar)
    }

    const handleClickSearchIcon = (e) => {
        setVisibleSearchToolBar(true)
        e.preventDefault()
    }

    /* Styled */
    const sxSwipeableDrawer = {
        '& .MuiPaper-root': {
            borderRadius: '0',
            boxShadow: 'none',
        }
    }
    const sxAppBar = {
        '&.MuiAppBar-root': {
            backgroundColor: 'white'
        }
    }
    const styleToolBar = {
        height: pxToRem('136px'),
        '&.MuiToolbar-root': {
            paddingRight: pxToRem('16px')
        },
    }
    const styleIconButton = { marginLeft: pxToRem('8px') }

    return (
        <>
            <Tooltip title='Поиск слоев' arrow placement='bottom'>
                <ControlIconButton onClick={handleClickSearchIcon} >
                    <SvgIcon style={{ fontSize: pxToRem('18px') }} inheritViewBox={true}>
                        <SearchIcon />
                    </SvgIcon>
                </ControlIconButton>
            </Tooltip>

            {visibleSearchToolBar &&
                <SwipeableDrawer
                    sx={sxSwipeableDrawer}
                    anchor='top'
                    open={visibleSearchToolBar}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >

                    <AppBar position='relative' sx={sxAppBar}>
                        <Toolbar sx={styleToolBar} >
                            <ErrorBoundary FallbackComponent={ErrorFallback}>
                                <Search setVisibleSearchToolBar={setVisibleSearchToolBar} />
                            </ErrorBoundary>
                            <IconButton style={styleIconButton} onClick={() => setVisibleSearchToolBar(false)}>
                                <SvgIcon style={{ fontSize: pxToRem('44px') }} inheritViewBox={true}>
                                    <CloseIcon />
                                </SvgIcon>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </SwipeableDrawer >
            }
        </>
    )
}

export default SearchToolBar