import React, { useContext, useLayoutEffect } from 'react';
import { MapContainer, useMap } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { Box } from '@mui/material';
import { DrawerContext } from '../SidePanel/SideContextProvider';
import { CLOSE_WIDTH_MAIN_PANEL, OPEN_WIDTH_MAIN_PANEL } from '../../../config';


export function LayoutListener({ open }) {
    // Компонент для подстраивания карты под изменяющиеся размеры MapContainer - в зависимости от состояния шторки SecondPanel
    const map = useMap()

    useLayoutEffect(() => {
        map.invalidateSize()
    }, [map, open])
    return null
}

const LeafletMap = ({ control, children }) => {

    // console.log("Render LeafeltMap");

    const { mainDrawer } = useContext(DrawerContext)

    const params = new URLSearchParams(window.location.search)
    const c = params.get('c') ? params.get('c') : false;
    const z = params.get('z') ? params.get('z') : false;

    const center = c ? c.split(',') : [57.624267, 39.889667]
    const zoom = z ? z : 10

    return (
        <Box
            style={{
                width: mainDrawer ? `calc(100vw - ${OPEN_WIDTH_MAIN_PANEL}px)` : `calc(100vw - ${CLOSE_WIDTH_MAIN_PANEL}px)`,
                backgroundColor: 'pink',
                flexGrow: 1
            }}
        >
            <MapContainer
                center={center}
                zoom={zoom}
                scrollWheelZoom={true}
                style={{ height: '100vh', width: '100%', zIndex: 1, }}
                zoomControl={false}
                className="map_container"
                attributionControl={false}
            >
                <LayoutListener open={mainDrawer} />
                {control}
                {children}
            </MapContainer>
        </Box>
    )
}

export default LeafletMap