import { Box, Divider } from '@mui/material';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useActions } from '../../../hooks/useActions';
import MyAlert from '../../general/Info/MyAlert';
import AllSelectedCheckbox from '../../general/AllSelectedCheckbox/AllSelectedCheckbox';
import { contains } from '../../../service/function';
import {  selectedLayerIdsSelector, viewSelectedLayersSelector } from '../../../store/selectors/selectors';
import { RoutePaths } from '../../../routes';
import { useLayersByParentIdQuery } from '../../../hooks/reactQuery/useLayer';
import useNavigate from '../../../hooks/useNavigateWithSearchParams'
import ItemLayer from './ItemLayer';
import styles from './Layer.module.scss'
import useOnLayerIds from '../../../hooks/reactQuery/selectorCache/useOnLayerIds';


const Layer = () => {

    const layerId = Number(useParams().layerId)
    const onLayerIds = useOnLayerIds()
    const {data: layers=[], isLoading, error} = useLayersByParentIdQuery({layerId})
    const selectedLayerIds = useSelector(selectedLayerIdsSelector)
    const viewSelectedLayers = useSelector(viewSelectedLayersSelector)
    const navigate = useNavigate()

    let arrNonServiceLayerId = []              // Массив с Id НЕ сервисных слоев т.е. у которых свойство isService = false
    let objectsIsLoading = false               // флаг, отражающий что идет загрузка объектов дочернего слоя
    let allSelected = false;                   // флаг, отражающий, что выделены все слои на странице (не сервисные слои)

    const { selectLayerById, deselectLayerByIds } = useActions();

    let filterLayers = [...layers]
    if (viewSelectedLayers) {
        filterLayers = filterLayers.filter(l => onLayerIds.indexOf(l.id) >= 0)
        if (filterLayers.length === 0) {
            navigate(RoutePaths.HOME)
        }
    }

    /* Для склелетона, когда isLoading=true */
    let arrLayers = isLoading ? Array.from({ length: 7 }, (v, i) => i) : filterLayers;

    // Формируем arrNoServiseLayersId 
    if (arrLayers && arrLayers.length > 0) {
        arrLayers.forEach((item) => {
            if (item.isService === false) {
                arrNonServiceLayerId.push(item.id);
            }
        })
    }

    allSelected = contains(selectedLayerIds, arrNonServiceLayerId);

    const handleSelectItemLayer = (layerId, isLoading) => {
        if (!isLoading) {
            if (selectedLayerIds.includes(layerId)) {
                deselectLayerByIds([layerId])
            } else {
                selectLayerById(layerId);
            }
        } else {
            console.log("Дождитесь окончания загрузки данных");
        }
    }

    const handleAllSelectLayer = () => {
        if (allSelected) {
            deselectLayerByIds(arrNonServiceLayerId)
        } else {
            // получаем массив оставшихся, не выделенных элементов
            const arrRemainingSelectLayerId = arrNonServiceLayerId.filter(i => !selectedLayerIds.includes(i))
            if (arrRemainingSelectLayerId.length > 0) {
                arrRemainingSelectLayerId.forEach(id => selectLayerById(id))
            }
        }
    }

    if (error) {
        return <MyAlert severity='error' title='Ошибка' message={error.message} />
    }

    if (_.isEmpty(layers) && !isLoading) {
        return <MyAlert severity='info' message='Нет доступных слоев' />
    }
    return (
        <Box className={styles.layer}>
            <Box className={styles.block}>
                {arrNonServiceLayerId.length > 0 || isLoading
                    ? // Если присутствует НЕ сервисный слой - отображаем чекбокс "Выбрать все"
                    <>
                        <AllSelectedCheckbox
                            handleAllSelectLayer={() => handleAllSelectLayer()}
                            selected={allSelected}
                            objectsIsLoading={objectsIsLoading}
                            isLoading={isLoading}
                        />
                        <Divider variant='var1' />
                    </>
                    :
                    <div className={styles.box_layer}></div>
                }

                <Box className={styles.inner}>
                    {arrLayers &&
                        arrLayers.map((item, index) => {
                            if (isLoading) {
                                return (
                                    <Box className={styles.item} key={'l' + index}>
                                        <Box className={styles.icons}><Skeleton width={18} /></Box>
                                        <Box className={styles.name}><Skeleton width={200} count={1.5} /></Box>
                                        <Box className={styles.pos_right}></Box>
                                        <Box>
                                            <Box className={styles.icons}>
                                                <Skeleton width={20} />
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                            } else {
                                let props = {
                                    metadata: item,
                                    isOnLayer: onLayerIds.includes(item.id),
                                    handleSelectItemLayer,
                                }
                                return <ItemLayer {...props} key={'l-' + index} />
                            }
                        })
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default Layer;
