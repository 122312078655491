import React, { useState } from 'react'
import ItemObject from '../components/general/Object/ItemObject'
import CheckAccesToLayer from '../hoc/CheckAccesToLayer'
import { pxToRem } from '../components/Style/desktopTheme'
import { Box, Button, Stack } from '@mui/material'
import useCanEdit from '../hooks/useCanEdit'
import { useModal } from '../hooks/useModal/useModal'
import ObjectProperty from '../components/desktop/ObjectProperty/ObjectProperty'
import Feedback from '../components/general/Feedback/Feedback'
import { useParams } from 'react-router-dom'
import Header from '../hoc/Header'
import useDeviceDetect from '../hooks/useDeviceDetect'
import HeadToolBarObject from '../components/general/HeadToolBar/HeadToolBarObject'
import MobTemplateHeaderToolbar from '../components/general/HeadToolBar/MobTemplateHeaderToolbar'


const ObjectPage = (props) => {

    const { isMobile } = useDeviceDetect()
    return (
        <CheckAccesToLayer>
            {isMobile
                ? <MobObjectPage {...props}/>
                : <DesktopObjectPage />
            }
        </CheckAccesToLayer >
    )
}

export default ObjectPage


const MobObjectPage = ({handleClose}) => {

    const objectId = useParams().objectId
    const [modalPage, setModalPage] = useState(false)

    return (
        <>
            <Header>
                {!modalPage && <HeadToolBarObject handleClose={handleClose} />}
                {modalPage === 'feedBack' && <MobTemplateHeaderToolbar title={{ name: 'Отправить замечание' }} handleClose={() => setModalPage(false)} />}
            </Header>

            <Box sx={{ mt: '25px', mr: '15px', mb: 0, ml: '15px', height: '100%', overflow: 'auto' }}>
                {!modalPage &&
                    <>
                        <ItemObject />
                        <Box sx={{ mt: '8px', mb: '8px' }}>
                            <Box><Button variant='outlined' onClick={() => setModalPage('feedBack')}>Отправить замечание</Button></Box>
                        </Box>
                    </>
                }
                {modalPage === 'feedBack' && <Feedback objectId={objectId} />}
            </Box>
        </>
    )
}

const DesktopObjectPage = () => {

    const layerId = useParams().layerId
    const objectId = useParams().objectId
    const { openWindow } = useModal()
    const canEdit = useCanEdit(layerId)

    const openFeedback = () => {
        openWindow({
            title: 'Обратная связь',
            children: <Feedback objectId={objectId} />,
            width: 600
        })
    }

    const openProps = () => {
        openWindow({
            title: 'Свойства объекта',
            children: <ObjectProperty />,
            layerId,
            objectId,
            width: 800
        })
    }
    return (
        <>
            <Header><HeadToolBarObject /></Header>
            <Box sx={{ ml: pxToRem('12px'), mr: pxToRem('12px'), mt: pxToRem('7px'), height: '100%', overflow: 'auto' }}>
                <ItemObject />
                <Stack spacing={2} sx={{ mt: pxToRem('8px') }}>
                    <Box><Button variant='outlined' onClick={openFeedback}>Отправить замечание</Button></Box>
                    {canEdit && <Box><Button variant='outlined' onClick={openProps}>Редактировать</Button></Box>}
                </Stack>
            </Box>
        </>
    )
}