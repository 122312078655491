import api from "../http";
import {ApiPaths} from "../../config"


export const loadSearchLayers = async (term) => {
    // если term пустой - то возвращаем []
    if (!term || term === '') {
        return new Promise((resolve/*, reject*/) => resolve([])).then(result => result)
    }

    const result = await api.get(ApiPaths.search.LAYERS.replace(':term', term));
    const data = result.data
    if (data.length === 0) {
        return [{ title: 'Слои - ничего не найдено' }]
    }
    return data
}

export const loadSearchLocation = async (term) => {
    // если term пустой - то возвращаем []
    if (!term || term === '') {
        return new Promise((resolve/*, reject*/) => resolve([])).then(result => result)
    }

    const result = await api.get(
        ApiPaths.search.GEOCODER_SUGGESTIONS,
        {
            params: {
                query: term
            }
        },
    )
    // return [{ title: 'Слои - ничего не найдено' }]
    const data = result.data.filter(item => item.data.geo_lat && item.data.geo_lon); // удаляем подсказки адресов, у которых нет координат
    if (data.length === 0) {
        return [{ title: 'Поиск адресов - ничего не найдено' }]
    }
    return data
}
