import { useQueryClient, useIsFetching } from 'react-query';
import PropTypes from 'prop-types'
import { useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import { Box, Button,  SvgIcon, Typography } from '@mui/material';
import { ReactComponent as InputIcon } from "../../../Icons/layer/InputIcon.svg";
import { ReactComponent as DropMenuIcon } from "../../../Icons/layer/DropMenuIcon.svg";
import { ReactComponent as InputCheckIcon } from "../../../Icons/layer/InputCheckIcon.svg";
import { ReactComponent as ServiceLayerIcon } from "../../../Icons/layer/ServiceLayerIcon.svg";
import MyAlert from '../../general/Info/MyAlert';
import { MAX_LOAD_OBJECTS } from '../../../config';
import LoadingCheckBox from '../../general/Info/LoadingCheckBox';
import 'react-loading-skeleton/dist/skeleton.css';
import { RoutePaths } from '../../../routes';
import { selectedLayerIdsSelector } from '../../../store/selectors/selectors';
import { useActions } from "../../../hooks/useActions";
import Link from '../../../hoc/LinkWithSearchParams'
import styles from './Layer.module.scss'
import LayerMenu from './LayerMenu'




const ItemLayer = ({ metadata, isOnLayer, handleSelectItemLayer }) => {

    const isBigLayerRef = useRef(false)

    const { deselectLayerByIds } = useActions()

    const queryClient = useQueryClient()
    const selectedLayerIds = useSelector(selectedLayerIdsSelector)

    const isFetching = useIsFetching({
        queryKey: ['objectsToLayer', String(metadata.id)]
    })

    const queryState = queryClient.getQueryState({
        queryKey: ['objectsToLayer', String(metadata.id)]
    })

    let isLoading = isFetching === 0 ? false : true

    let selected = metadata.typeId === 1
        ? Boolean(queryState?.data) && selectedLayerIds.includes(metadata.id)
        : selectedLayerIds.includes(metadata.id)

    if (queryState?.data) {
        if (queryState.data.length > MAX_LOAD_OBJECTS) {
            isBigLayerRef.current = true
        }
    }

    useEffect(() => {
        if (queryState?.data) {
            if (queryState.data.length > MAX_LOAD_OBJECTS) {
                isBigLayerRef.current = true
                deselectLayerByIds([metadata.id])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryState?.data])

    return (
        <>
            <Box className={styles.item} key={'l' + metadata.id}>
                {metadata.isService ?
                    <>
                        <Box className={styles.icons}><ServiceLayerIcon /></Box>
                        <Link to={RoutePaths.LAYER.replace(':layerId', metadata.id)} className={styles.name} id={metadata.id} >
                            <Typography variant='font_16_22'>{metadata.name}</Typography>
                        </Link>
                        {isOnLayer ? <Box className={styles.on_layer + ' ' + styles.pos_right + ' ' + styles.icons} ><Box></Box></Box> : <Box className={styles.pos_right + ' ' + styles.icons}></Box>}
                    </>
                    :
                    <>
                        {isLoading
                            ?
                            <Button variant='icon40' className={styles.icons}>
                                <LoadingCheckBox />
                            </Button>
                            :
                            <Button variant='icon40' className={styles.icons} onClick={() => handleSelectItemLayer(metadata.id, isLoading)}>
                                <SvgIcon>{selected ? <InputCheckIcon /> : <InputIcon />}</SvgIcon>
                            </Button>
                        }

                        <Typography component='div' variant='font_16_22' className={styles.name} id={metadata.id} onClick={() => handleSelectItemLayer(metadata.id, isLoading)}>{metadata.name}</Typography>
                        <Box className={styles.pos_right}></Box>
                    </>
                }

                <LayerMenu metadata={metadata}>
                    <DropMenuIcon />
                </LayerMenu>
            </Box>

            {queryState?.error &&
                <Box className={styles.item}>
                    <MyAlert severity='error' title='Ошибка' message='Объекты данного слоя не были загружены!' />
                </Box>
            }

            {isBigLayerRef.current &&
                <Box className={styles.item}>
                    <MyAlert severity='info' title='Слой недоступен в мобильной версии' message={`На слое более ${MAX_LOAD_OBJECTS} объектов. Для просмотра слоя воспользуйтесь полной версией Геопортала`} />
                </Box>
            }
        </>
    )
}

ItemLayer.propTypes = {
    metadata: PropTypes.object.isRequired,
    isOnLayer: PropTypes.bool.isRequired,
    handleSelectItemLayer: PropTypes.func.isRequired,
}

export default ItemLayer