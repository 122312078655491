import useDeviceDetect from "../../../hooks/useDeviceDetect"
import useNavigate from '../../../hooks/useNavigateWithSearchParams'
import { RoutePaths } from "../../../routes";
import { useParams } from "react-router-dom";
import { useLayersHierarhyQuery } from "../../../hooks/reactQuery/useLayer";
import MobTemplateHeaderToolbar from "./MobTemplateHeaderToolbar";
import DesktopTemplateHeaderToolbar from "./DesktopTemplateHeaderToolbar";


const HeadToolBarLayers = () => {

    const navigate = useNavigate()
    const { isMobile } = useDeviceDetect()
    const layerId = Number(useParams().layerId)
    const { data: layerHierarhy, isLoading, error } = useLayersHierarhyQuery({layerId})

    /* To GoBack and Title*/
    let previosPath = RoutePaths.HOME
    let title = ''
    if (layerHierarhy && layerHierarhy.length > 0) {
        title = layerHierarhy[layerHierarhy.length - 1].name
        if (layerHierarhy.length > 1) {
            previosPath = RoutePaths.LAYER.replace(':layerId', layerHierarhy[layerHierarhy.length - 2].id)
        }
    }

    return (
        isMobile
            ? <MobTemplateHeaderToolbar
                title={{ name: title, isLoading }}
                handleGoBack={() => navigate(previosPath)}
                handleClose={() => navigate(RoutePaths.HOME)}
                breadcrumbs={{
                    isLoading,
                    error,
                    layerHierarhy
                }}
            />
            : <DesktopTemplateHeaderToolbar
                title={{ name: title, isLoading }}
                handleGoBack={() => navigate(previosPath)}
                breadcrumbs={{
                    isLoading,
                    error,
                    layerHierarhy
                }}
                isShowLayerMenu={layerHierarhy}
            />
    )
}

export default HeadToolBarLayers