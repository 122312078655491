import { useSelector } from "react-redux";
import { heatMapIdsSelector } from '../../../store/selectors/selectors';
import CreateHeatMap from "./CreateHeatMap";
import WfsGroupMapLayer from "./WfsGroupMapLayer";


const CreateWFS = ({ layer }) => {

    const heatMapIds = useSelector(heatMapIdsSelector)

    if (heatMapIds.includes(layer.id)) {
        return <CreateHeatMap layer={layer} />
    } else {
        return <WfsGroupMapLayer layerId={layer.id} />
    }
}

export default CreateWFS