import { MobBottomSheetContext } from "../components/mob/BottomSheet/MobBottomSheet"
import useDeviceDetect from "../hooks/useDeviceDetect"
import { useContext, useEffect } from "react"


const Header = ({ children }) => {
    
    const { isMobile } = useDeviceDetect()
    const {setHeader} = useContext(MobBottomSheetContext)

    useEffect(() => {
        if (isMobile && setHeader) {
            setHeader(children)
        }
    }, [isMobile, setHeader, children])
    if (!isMobile) return children
    return null
}

export default Header