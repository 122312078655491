import { useSnackbar } from "notistack";
import { useActions } from "../useActions";
import { useQueries } from "react-query";
import { loadLayerHierarhy } from "../../service/axiosFuncQuery/layerQuery";
import { TIME_UPDATE } from "../../config";
import { isAuthSelector } from "../../store/selectors/selectors";
import { useSelector } from "react-redux";
import SignIn from "../../components/desktop/SignIn/SignIn";
import { pxToRem } from "../../components/Style/desktopTheme";
import { useModal } from "../useModal/useModal";
import useDeviceDetect from '../useDeviceDetect'
import { useEffect, useState } from "react";


// Используется для предзагрузки и включения слоев 
export const usePreloadLayersQuery = () => {

    const { openConfirm, openWindow, openAlert } = useModal()
    const isAuth = useSelector(isAuthSelector)
    const { isMobile } = useDeviceDetect()

    const params = new URLSearchParams(window.location.search)
    const layerIds = params.get('layers') ? params.get('layers').split(',').map(Number) : []

    const { selectLayerById } = useActions()
    const { enqueueSnackbar } = useSnackbar()

    const successLayerIds = []
    const accessDeniedLayerIds = []
    const notLoadedLayerIds = []  // layerIds по которым запрос не прошел - в т.ч. нет доступа к этому слою

    const setSelectedLayers = () => {
        successLayerIds.forEach(layerId => {
            selectLayerById(layerId)
        })

        // Выводим сообщение о том, что не был загружен слой
        notLoadedLayerIds.forEach(id => enqueueSnackbar(
            `Слой id=${id} не загружен ${accessDeniedLayerIds.includes(id) && '. Нет доступа'}`,
            { variant: 'default', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }))
    }

    const result = useQueries(
        layerIds.map(layerId => ({
            queryKey: ['layerHierarhy', String(layerId)],
            queryFn: () => loadLayerHierarhy(layerId),
            // onError: (e) => console.log(e.message),
            staleTime: TIME_UPDATE,
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                if (error.response.data === 'Access is denied' || failureCount === 2) {
                    return false
                }
                return true
            }
        }))
    )

    result.forEach((query, index) => {
        const layerId = layerIds[index]
        if (!query.isLoading) {
            if (query.error) {
                notLoadedLayerIds.push(layerId)
                if (query.error.response.data === 'Access is denied') {
                    accessDeniedLayerIds.push(layerId)
                }
            } else {
                successLayerIds.push(layerId)
            }
        }
    })

    const [isLoad, setIsLoad] = useState()
    // устанавливаем флаг что все запросы выполнены
    useEffect(() => {
        if (result.filter(q => q.isLoading === true).length === 0 && !isLoad) {
            setIsLoad(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result])

    // сбрасываем значение при измении isAuth
    useEffect(() => {
        setIsLoad(false)
    }, [isAuth])

    useEffect(() => {
        if (isLoad) {
            if (isAuth) {
                if (accessDeniedLayerIds.length > 0) {
                    const message = `Объекты слоев #${accessDeniedLayerIds.join(", ")} не были загружены - нет доступа`
                    openAlert({
                        title: 'Нет доступа',
                        children: message,
                    })
                }
                setSelectedLayers()
            } else {
                if (accessDeniedLayerIds.length > 0 && !isMobile) {
                    const message = `Нет доступа к слоям: #${accessDeniedLayerIds.join(", ")}. Пожалуйста авторизуйтесь.`
                    let openSignIn = false

                    openConfirm({
                        title: 'Запрос доступа',
                        children: message,
                        cancelText: 'Отмена',
                        confirmText: 'Авторизоваться',
                        onConfirm: () => {
                            openSignIn = true
                            openWindow({
                                title: 'Авторизация',
                                children: <SignIn />,
                                width: pxToRem('560px'),
                                center: true,
                                onCancel: () => setSelectedLayers(),
                                onClose: () => setSelectedLayers(),
                            })
                        },
                        onCancel: () => setSelectedLayers(),
                        onClose: () => {
                            if (!openSignIn) {
                                setSelectedLayers()
                            }
                        }
                    })
                }
                else {
                    setSelectedLayers()
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad])
}