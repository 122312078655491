import { useEffect } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { useActions } from '../../../hooks/useActions';
import { RoutePaths } from '../../../routes';


const LastPages = () => {
    let isObjectPage = useMatch(RoutePaths.OBJECT)
    const { setLastPage } = useActions()
    let location = useLocation()

    // для mob - устанавливаем lastPage - адрес страницы, которая была открыта до клика на объект. Чтобы при закрытии страницы просмотра детальной инф. о объекте - вернуться на нее
    useEffect(() => {
        if (!isObjectPage) {    // for mob
            setLastPage(location.pathname)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])
    return null
}

export default LastPages