import { Alert } from "@mui/material";

function ErrorFallback({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    console.log(error.message, error.stack);

    return (
        <Alert severity="error">Ошибка. {error.message}</Alert>
    )
}

export { ErrorFallback }