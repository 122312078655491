import React, { useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import { DrawerContext } from '../SidePanel/SideContextProvider';
import { ReactComponent as KeyboardArrowLeftIcon } from '../../../Icons/desktop/KeyboardArrowLeftIcon.svg'
import { ReactComponent as KeyboardArrowRightIcon } from '../../../Icons/desktop/KeyboardArrowRightIcon.svg'
import { isBoolean } from '../../../service/function';
import { CLOSE_WIDTH_MAIN_PANEL, OPEN_WIDTH_MAIN_PANEL, OPEN_WIDTH_SECOND_PANEL } from '../../../config';
import { pxToRem } from '../../Style/desktopTheme';


const SecondDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'drawerOpen' })(
    ({ theme, drawerOpen }) => {
        let ident = drawerOpen ? OPEN_WIDTH_MAIN_PANEL : CLOSE_WIDTH_MAIN_PANEL
        return ({
            width: pxToRem(OPEN_WIDTH_SECOND_PANEL),
            left: pxToRem(ident),
            zIndex: 400,
            flexShrink: 0,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,

            }),
            ...(drawerOpen && {
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen
                })
            }),
            '.MuiDrawer-paper': {
                left: pxToRem(ident),
                width: pxToRem(OPEN_WIDTH_SECOND_PANEL),
                boxShadow: '2px 2px 4px 0px rgb(0 0 0 / 10%), 0px -1px 0px 0px rgb(0 0 0 / 2%)',
                borderRadius: '0px',
                borderLeft: `1px solid ${theme.palette.myBlack.dark}`,
                flexShrink: 0,
            }
        })
    }
)


const ToggleDrawerButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, maindraweropen, seconddraweropen }) => {
        let indentMainPanel = isBoolean(maindraweropen) ? OPEN_WIDTH_MAIN_PANEL : CLOSE_WIDTH_MAIN_PANEL
        let indentSecondPanel = isBoolean(seconddraweropen) ? OPEN_WIDTH_MAIN_PANEL : 0
        return ({
            top: pxToRem('20px'),
            position: 'absolute',
            marginLeft: pxToRem(indentMainPanel),
            // marginLeft: `calc(${indentMainPanel}px)`,
            // left: `calc(${indentSecondPanel}px - 12px)`,
            left: pxToRem(indentSecondPanel - 12),
            height: pxToRem('40px'),
            backgroundColor: 'white',
            borderRadius: '0px 2px 2px 0px',
            background: '#FFF',
            boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 0px 0px rgba(0, 0, 0, 0.02)',
            padding: '0',
            zIndex: 100,
            transition: theme.transitions.create('left', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,

            }),
            ...(isBoolean(seconddraweropen) && {
                transition: theme.transitions.create('left', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen
                })
            })
        })
    }
)


const SecondPanel = ({ children }) => {
    const { mainDrawer, secondDrawer, setSecondDrawer } = useContext(DrawerContext)

    useEffect(() => {
        if (!secondDrawer) setSecondDrawer(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <SecondDrawer
                className='second_drawer'
                open={secondDrawer}
                variant="temporary"
                hideBackdrop={true}
                disableEnforceFocus
                drawerOpen={mainDrawer}
                style={{ height: '100%', overflow: 'auto' }}
            >
                {children}
            </SecondDrawer>

            <Tooltip title={secondDrawer ? 'Свернуть панель' : 'Развернуть панель'} arrow placement='right'>
                <ToggleDrawerButton
                    maindraweropen={mainDrawer.toString()}
                    seconddraweropen={secondDrawer.toString()}
                    onClick={() => setSecondDrawer(!secondDrawer)}
                    disableRipple
                >
                    <SvgIcon style={{ width: pxToRem('36px'), height: pxToRem('40px') }} inheritViewBox>
                        {secondDrawer ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
                    </SvgIcon>
                </ToggleDrawerButton>
            </Tooltip>
        </>
    )
}

export default SecondPanel