import { alpha } from "@mui/material";

const color = {
    main: '#333333',
    grayMain: '#E0E0E0',
    grayLight1: '#F5F5F5',
    grayDark: '#CACACA',
    grayLight: '#F6F6F6',
    greenMain: '#27AE60',
    greenDark: '#219653',
}


export let palette = {
    myBlack: { main: color.main, light: alpha(color.main, 0.4), dark: color.dark, },
    myGray: { light: color.grayLight, main: color.grayMain, light1: color.grayLight1, backgr: color.backgr, dark: color.grayDark },
    myRed: { main: '#D38282' },
    myGreen: { main: color.greenMain, dark: color.greenDark },
}

