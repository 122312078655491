import { createContext, useContext, useState } from "react"

const SearchContext = createContext({})

export const useSearchContext = () => useContext(SearchContext)

export const SearchProvider = ({children}) => {
    const [resetSearch, setResetSearch] = useState(false)
    return (
        <SearchContext.Provider value={{resetSearch, setResetSearch}}>
            {children}
        </SearchContext.Provider>
    )
}