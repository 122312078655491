import { Paper, Stack, TextField, Typography } from "@mui/material"
import { useContext } from "react"
import { Controller } from "react-hook-form"
import { MapContainer, TileLayer } from 'react-leaflet';
import { AreaContext } from "../AreaContext"
import CreateShape from "../../../../../general/MapLayers/CreateShape"




const General = () => {

    const { prepareAreaDialog: { area, layerBreadCrump }, control } = useContext(AreaContext)
    // console.log(area.json);

    return (
        <Stack variant='mtrbl' spacing={2}>
            <Controller
                name='name'
                control={control}
                rules={{ required: "Поле обязательно для заполнения" }}
                render={({ field, fieldState: { error } }) => <TextField {...field}
                    label='Название правила'
                    variant="outlined"
                    error={Boolean(error)}
                    helperText={error && error.message}
                />
                }
            />

            <Paper elevation={1}>
                <MapContainer
                    center={[57.624267, 39.889667]} // не влияет, т.к. в CreateShape применяется map.fitBounds - но без него создать MapContainer не получится
                    zoom={13}
                    scrollWheelZoom={false}
                    style={{ height: '300px', zIndex: 1 }}
                    attributionControl={false}
                >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                    <CreateShape
                        item={{
                            geoJSON: area.json
                        }}
                        isNoPaddingSelected={true}
                        selected={true}
                    />
                </MapContainer>
            </Paper>


            <Stack spacing={1}>
                <Typography variant='font_16_24'>Принадлежность к слоям:</Typography>
                <Stack spacing={1}>
                    {layerBreadCrump.map(item => (
                        item.items.map((l, index) => {
                            let breadCrumbs = ''
                            breadCrumbs = breadCrumbs + l.name
                            if (index !== item.items.length - 1) {
                                breadCrumbs = breadCrumbs + ' > '
                            }
                            return breadCrumbs
                        })
                    ))}
                </Stack>
            </Stack>

        </Stack>
    )
}

export default General