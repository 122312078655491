import { Autocomplete, Box, CircularProgress, IconButton, Popper, Stack, SvgIcon, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from "use-debounce"
import { useSearchParams } from 'react-router-dom'
import { uniqueId } from 'lodash'
import PropTypes from 'prop-types'
import { ReactComponent as CloseIcon } from "../../../Icons/search/CloseIcon.svg"
import { ReactComponent as OptionsStreetIcon } from '../../../Icons/search/OptionsStreetIcon.svg'
import BreadCrumbs from '../../general/BreadCrumbs/BreadCrumbs'
import CategoryIcon from '../../general/Category/CategoryIcon'
import { RoutePaths } from '../../../routes'
import { useSearchLayerQuery, useSearchLocationQuery } from '../../../hooks/reactQuery/useSearch'
import useNavigate from '../../../hooks/useNavigateWithSearchParams'
import { pxToRem } from '../../Style/desktopTheme'
import { useSearchContext } from '../../../hooks/useSearch'


const Search = ({ setVisibleSearchToolBar }) => {
    const {resetSearch, setResetSearch} = useSearchContext() // флаг, определяющий что нужно сбросить состояние Autocomplete

    const navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams()

    const [term, setTerm] = useState('')
    const [inputValue, setInputValue] = useState('')
    const [key, setKey] = useState('autocompleteKey')

    let { data: searchLayersOptions, isFetching: layersIsLoading, isError: isLayerError } = useSearchLayerQuery(term)
    let { data: searchLocationOptions, isFetching: locationIsLoading, isError: isLocationError } = useSearchLocationQuery(term)

    // Обработка ошибок запросов
    if (isLayerError) searchLayersOptions = [{ inputValue, title: 'Поиск слоя - Server Error' }]
    if (isLocationError) searchLocationOptions = [{ inputValue, title: 'Поиск адреса - Server Error' }]

    const isLoading = layersIsLoading || locationIsLoading ? true : false

    let options = (searchLayersOptions[0]?.title === 'Слои - ничего не найдено' && searchLocationOptions[0]?.title === 'Поиск адресов - ничего не найдено')
        ? [{ title: 'Ничего не найдено' }]
        : [...searchLayersOptions, ...searchLocationOptions]

    const debouncedLoadData = useDebouncedCallback((value) => {
        setTerm(value)
    }, 750)


    // сбрасываю состояние - при клике очистить карту
    useEffect(() => {
        if (resetSearch) {
            setResetSearch(false)
            setKey(uniqueId('autocompleteKey'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetSearch])


    useEffect(() => {
        debouncedLoadData(inputValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue])


    /* Styled */
    const sxAutocomplete = {
        '& .MuiInputBase-root.MuiOutlinedInput-root': {
            paddingRight: pxToRem('9px'),
        },
    }
    const sxAutocompletePaper = {
        marginTop: '0px',
        marginLeft: pxToRem('24px'),
        marginRight: pxToRem('24px'),
        borderTop: '1px solid #E0E0E0',
    }

    const sxAutocompletePopper = {
        transform: `translate(0px, ${pxToRem('136px')}) !important`
    }

    return (
        <>
            <Autocomplete
                // open={true} // если включить, то options после отображения не убираются, что удобно при верстке
                // clearOnEscape={true}
                // openOnFocus	={true}
                key={key}
                sx={sxAutocomplete}
                fullWidth
                freeSolo
                options={options}
                clearIcon={<CloseIcon />}
                clearText='Очистить'
                PopperComponent={(props) => <Popper {...props} style={{ width: '100%' }} />}
                componentsProps={{
                    paper: {
                        sx: sxAutocompletePaper
                    },
                    popper: {
                        sx: sxAutocompletePopper,
                    }
                }}
                onInputChange={(event, inputValue, reason) => {      // срабатывает когда вводим в поле input, а также - когда выбираем значение из списка автозаполнения
                    if (reason === 'input') {
                        setInputValue(inputValue)
                    }
                }}
                onChange={(e, newValue, reason) => {       // срабатывает когда выбираем значение из списка автозаполнения
                    if (reason === 'selectOption') {
                        if ('title' in newValue) {   // - вспомогательное сообщение, типа: слои - ничего не найдено
                            return null
                        }
                        if ('value' in newValue) {  // location
                            let type = newValue.data.city_type && 'city'
                            if (newValue.data.street_type)
                                type = newValue.data.street_type && 'street'
                            else if (newValue.data.house)
                                type = newValue.data.house && 'house'

                            navigate(
                                RoutePaths.SEARCH_LOCATION
                                    .replace(':value', encodeURIComponent(newValue.value))
                                    .replace(':searchLatLon', [newValue.data.geo_lat, newValue.data.geo_lon].join(','))
                                    .replace(':type', type)
                            )
                        } else {                    // layer
                            let layerId = newValue.items.length === 1 ? newValue.items[0].id : newValue.items[newValue.items.length - 1].parent
                            navigate(RoutePaths.LAYER.replace(':layerId', layerId))
                        }
                        setVisibleSearchToolBar(false)
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        autoFocus={true}
                        fullWidth
                        margin='dense'
                        placeholder="Поиск слоев и адресов"
                        name="searchInput"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isLoading ? <CircularProgress color="inherit" size={pxToRem(20)} /> : null}
                                    {inputValue.trim() !== '' &&
                                        <IconButton onClick={() => {
                                            params.InputProps.endAdornment.props.children[0].props.onClick()
                                            setInputValue('')

                                            if (searchParams.get('ll')) {
                                                setSearchParams(searchParams.delete('ll'))
                                            }
                                        }} >
                                            <SvgIcon style={{ fontSize: pxToRem('24px') }} inheritViewBox><CloseIcon /></SvgIcon>
                                        </IconButton>
                                    }
                                </>
                            )
                        }}
                    />
                )}
                renderOption={(props, option) => {
                    delete props['key']

                    if ('title' in option) {  // обрабатываем опцию 'Ничего не найдено'
                        return (
                            <Box component='li' onClick={() => { }} className={props.className} key={props.id}
                                {...props}
                            >
                                <Box >{option.title}</Box>
                            </Box>
                        )
                    }

                    if ('value' in option) {  // обрабатываем опцию location
                        return (
                            <Stack
                                direction='row'
                                spacing={2}
                                component='li'
                                sx={{ alignItems: 'flex-start', pt: pxToRem('10px'), pb: pxToRem('10px') }}
                                className={props.className}
                                key={props.id}
                                {...props}
                            >
                                <Box><OptionsStreetIcon /></Box>
                                <Typography variant='font_24' component='div'>{option.value}</Typography>
                            </Stack>
                        )
                    }
                    if ('items' in option) {    // обрабатываем опцию layer
                        option.items.map(item => {
                            return {
                                id: item.id,
                                name: item.name
                            }
                        })

                        return (
                            <Stack
                                {...props}
                                component='li'
                                key={props.id}
                                direction='row'
                                spacing={1}
                                style={{
                                    alignItems: 'start',
                                    paddingLeft: pxToRem('4px'),
                                    paddingTop: pxToRem('8px'),
                                    paddingBottom: pxToRem('3px'),
                                }}
                            >
                                <CategoryIcon name={option.items[0].name} style={{
                                    width: '40px', height: '40px',
                                    backgroundColor: 'transparent'
                                }} />

                                <Box style={{ paddingTop: pxToRem('8px') }}>
                                    <Typography variant='font_24' component='div'>{option.items[option.items.length - 1].name}</Typography>
                                    <BreadCrumbs style={{ marginLeft: pxToRem('0px') }} variant='margin' arrBreadCrumbsData={option.items} toSearch />
                                </Box>
                            </Stack>
                        )
                    }
                }}
                getOptionLabel={(option) => {
                    if (typeof option === "string") return option
                    else return inputValue
                }}
            />
        </>
    )
}

Search.propTypes = {
    setVisibleSearchToolBar: PropTypes.func.isRequired,
}


export default Search