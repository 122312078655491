import { ScaleControl } from 'react-leaflet'
import { useSelector } from 'react-redux';
import Control from '../../cloneOutside/react-leaflet-custom-control';
import { mapTileLayerSelector } from '../../../store/selectors/selectors';
import MouseCoordinates from './components/MouseCoordinates';


const RightBottomControls = () => {

    const { id: tileId } = useSelector(mapTileLayerSelector)

    return (
        <>
            <ScaleControl imperial={false} position="verticalrightbottom" />
            {/* для Яндекс Карт поднимаем кнопки повыше - чтобы не было наложения на Яндекс-копирайт*/}
            <Control position="verticalrightbottom" style={tileId === 2 ? { marginBottom: '22px' } : { }}>
                <MouseCoordinates />
            </Control>
        </>
    )
}

export default RightBottomControls