import React, { useEffect } from 'react';
import L from "leaflet";
import { Marker } from 'react-leaflet/Marker';
import { useMap } from 'react-leaflet/hooks';
import { useParams } from 'react-router-dom';
import { FeatureGroup } from "react-leaflet";
import iconSVG from '../../../Icons/map/search/location-icon.svg';
import houseSVG from '../../../Icons/map/search/house.svg'
import streetSVG from '../../../Icons/map/search/street.svg'
import citySVG from '../../../Icons/map/search/city.svg'

const getLatlon = (str) => {
    const arrLatLon = str.split(',');
    const lat = arrLatLon[0]
    const lon = arrLatLon[1]
    return ([lat, lon])
}

const CreateSearchLocation = () => {

    const map = useMap()
    const { searchLatLon, type } = useParams();

    let innerSVG = citySVG;
    let zoom = 10;

    if (type === 'street') {
        innerSVG = streetSVG;
        zoom = 16;
    }
    else if (type === 'house') {
        innerSVG = houseSVG;
        zoom = 17;
    }

    let icon = L.divIcon({
        className: 'search-location-icon',
        html: `<img src='${iconSVG}'/><div class='marker-pin'><div class='marker-pin-after'><img src='${innerSVG}'/></div></div>`,
        iconSize: [60, 68],
        iconAnchor: [30, 68]
    })

    useEffect(() => {
        if (searchLatLon) {
            map.flyTo(
                getLatlon(searchLatLon), // returned [lat, lon]
                zoom,
                { animate: true, duration: 1 }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchLatLon, map]);

    if (!searchLatLon) return null

    return (
        <FeatureGroup>
            <Marker
                position={getLatlon(searchLatLon)}  // returned [lat, lon]
                icon={icon}
                zIndexOffset='1000'
            />
        </FeatureGroup>
    )
}

export default CreateSearchLocation;