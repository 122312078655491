import { Box, CircularProgress, Modal } from "@mui/material"


const MaskProgress = ({ open = false }) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outlineWidth: 0
    }
    return (
        <Modal
            open={open}
            slotProps={{
                backdrop: {
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    }
                }
            }}
        >
            <Box style={style}>
                <CircularProgress />
            </Box>
        </Modal>
    )
}

export default MaskProgress