import { Typography } from '@mui/material';
import HeadTitleBoxTextLayout from '../components/general/HeadToolBar/HeadTitleBoxTextLayout';


const InfoPage = () => {

    return (
        <>
            <HeadTitleBoxTextLayout title='О портале' />

            <Typography>
                Геопортал – это государственная информационная система, в которой размещено более миллиона картографических объектов. Это аптеки и медицинские учреждения, школы и детские сады, мемориальные объекты, инвестиционные площадки, предоставляемые земельные участки, схема территориального планирования Ярославской области, избирательные участки, зоны покрытия сотовой связи 2G/3G/4G и многое другое. Геопортал служит «витриной» для публикации пространственных данных для граждан из закрытых государственных информационных систем. Система активно используется Правительством Ярославской области для размещения сведений о ходе реализации национальных и региональных проектов. Информацию на Геопортал ежедневно заносят сотни сотрудников органов власти и местного самоуправления.
            </Typography>

        </>
    );
};

export default InfoPage;

