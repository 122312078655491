import { IconButton, Modal, Stack, SvgIcon, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { ReactComponent as CloseIcon } from "../../Icons/desktop/modal/CloseIcon.svg"
import { createContext } from 'react';
import { pxToRem } from '../../components/Style/desktopTheme';
import { WrapStack } from './ModalTemplate';

 
export const ModalWindowContext = createContext()

// для использования в связке с hook useModal https://dev.to/lico/react-ts-how-i-manage-modal-components-custom-modals-hook-4nmg
const ModalWindow = ({ visible = false, onClose, title, children, backdropOpacity, width, center, ...property }) => {

    // При изменении маршрута react-router - закрываем модельное окно. Это нужно например когда кликаем на ссылку в breadcrumbs
    // Запуск useEffect при монтировании компонента - пропускаем
    let location = useLocation()
    const isMounted = useRef()
    useEffect(() => {
        if (isMounted.current) {
            onClose()
        } else { // пропускаем монтирование компонента
            isMounted.current = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const opacity = backdropOpacity ? backdropOpacity : 0.6

    const props = {
        visible,
        onClose,
        title,
        backdropOpacity,
        width,
        center
    }
    return (
        <Modal
            open={visible}
            onClose={onClose}
            slotProps={{
                backdrop: {
                    style: {
                        backgroundColor: `rgba(0, 0, 0, ${opacity})`,
                    }
                }
            }}
        >
            <WrapStack
                spacing={2}
                style={{
                    width: width ? width : 'auto',
                    top: center ? '' : pxToRem('24px'),
                    transform: center ? 'translate(-50%, -50%)' : 'translate(-50%, 0px)',
                    padding: `${pxToRem('22px')} 0px ${pxToRem('24px')} 0px`,
                    minWidth: pxToRem('300px'),
                    maxHeight: `calc(100% - ${pxToRem('48px')})`,
                }}
            >

                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    style={{ padding: `0px ${pxToRem('22px')} 0px ${pxToRem('32px')}` }}
                >
                    {title && <Typography component="h1" variant='font_22_28'>{title}</Typography>}
                    {onClose && <IconButton sx={{ padding: 0, }} onClick={onClose}><SvgIcon sx={{ fontSize: pxToRem('32px') }}><CloseIcon /></SvgIcon></IconButton>}
                </Stack>

                <ModalWindowContext.Provider value={{ onClose, ...props, ...property }}>
                    {children}
                </ModalWindowContext.Provider>

            </WrapStack>
        </Modal>
    )
}

export default ModalWindow