import React, { useEffect, useContext, useRef } from 'react';
import { useSelector } from "react-redux";
import { Box, Stack, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useMatch } from 'react-router-dom'
import { DrawerContext } from '../../desktop/SidePanel/SideContextProvider';
import { isAuthSelector, viewSelectedLayersSelector } from '../../../store/selectors/selectors';
import { RoutePaths } from '../../../routes';
import CategoryIcon from './CategoryIcon';
import { useActions } from "../../../hooks/useActions";
import { useCategoryQuery } from '../../../hooks/reactQuery/useLayer';
import useNavigate from '../../../hooks/useNavigateWithSearchParams'
import { pxToRem } from '../../Style/desktopTheme';
import styles from './Category.module.scss'
import CategoryBig from './CategoryBig';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import PropTypes from 'prop-types';
import MyAlert from '../Info/MyAlert';
import useOnLayerIds from '../../../hooks/reactQuery/selectorCache/useOnLayerIds';
import useLayerHierarhy from '../../../hooks/reactQuery/selectorCache/useLayerHierarhy';


const Category = ({columns}) => {
    const { isMobile } = useDeviceDetect()

    if (isMobile) return <CategoryMobile />
    return <CategoryDesktop columns={columns} />
}


const CategoryMobile = () => {
    const navigate = useNavigate()
    const layerId = Number(useParams().layerId)
    const handleClick = (id) => {
        if (layerId !== id)
            navigate(RoutePaths.LAYER.replace(':layerId', id))
    }
    return <CategoryBase columns={4} handleClick={handleClick} />
}

const CategoryDesktop = ({columns}) => {
    const layerId = Number(useParams().layerId)
    const navigate = useNavigate()
    const { secondDrawer, setSecondDrawer } = useContext(DrawerContext)
    const lastLayerIdsRef = useRef({})  // в этот ref запоминаем layerId крайнего открытого слоя у Категории, чтобы при повторном клике на иконку данной Категории - вернуться к ней
    const layerHierarhy = useLayerHierarhy(layerId)

    let isLayerPage = useMatch(RoutePaths.LAYER)

    const handleClick = (id) => {
        if (!secondDrawer) {
            setSecondDrawer(true)
        }

        if (layerId === id || layerId === lastLayerIdsRef.current[id]) {
            if (secondDrawer) {
                navigate(RoutePaths.HOME)
            }
        } else {
            navigate(RoutePaths.LAYER.replace(':layerId', lastLayerIdsRef.current.hasOwnProperty(id) ? lastLayerIdsRef.current[id] : id))
        }
    }

    useEffect(() => {
        if (isLayerPage && layerHierarhy.length > 0) {
            lastLayerIdsRef.current = {
                ...lastLayerIdsRef.current,
                [layerHierarhy[0].id]: layerId
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layerId])

    // при login/logout - сбрасываем значение lastLayerIdsRef, минуя первичное монтирование
    const isAuth = useSelector(isAuthSelector)
    const isInitialMount = useRef(true)
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            lastLayerIdsRef.current = ({})
        }
    }, [isAuth])

    return (
            <CategoryBase columns={columns} handleClick={handleClick} />
    )
}



const CategoryBase = ({ columns = 4, handleClick }) => {

    const {isMobile} = useDeviceDetect()
    const { data = [], isLoading, error } = useCategoryQuery() 
    let category = data
    const onLayerIds = useOnLayerIds()
    const { setViewSelectedLayers } = useActions();
    const viewSelectedLayers = useSelector(viewSelectedLayersSelector)

    // функционал - Отобразить выделенные слои
    let filterData = [...category]
    if (viewSelectedLayers) {
        filterData = filterData.filter(l => onLayerIds.indexOf(l.id) >= 0)
    }

    /* Для склелетона, когда isLoading=true  - создаем массив определенной длины и заполняем значения*/
    category = isLoading ? Array.from({ length: 21 }, (v, i) => i).map((i, index) => ({ id: `cl-${index}` })) : filterData;

    useEffect(() => {
        if (onLayerIds.length === 0 && viewSelectedLayers) {
            setViewSelectedLayers(false)
        }
    }, [onLayerIds, viewSelectedLayers, setViewSelectedLayers])

    if (error) return <MyAlert severity='error' title='Ошибка' message={error.message} />

    return (
        <Stack alignItems={columns === 1 ? 'center' : 'start'} className={styles.category}
            sx={{pt: isMobile? '0': pxToRem('12px')}}
        >
            {columns === 1
                ? <>
                    <Stack spacing={1} sx={{ ml: pxToRem('8px') }}>
                        {category && category.map(item => (
                            <Box
                                onClick={(e) => isLoading ? e.preventDefault() : handleClick(item.id)}
                                style={{
                                    width: pxToRem('48px'),
                                    height: pxToRem('48px'),
                                    position: 'relative',
                                }}
                                key={`c-small-${item.id}`}
                            >
                                <Tooltip title={item.name} placement='right' arrow>
                                    <span>
                                        <CategoryIcon name={item.name}
                                            isLoading={isLoading}
                                            style={{
                                                borderRadius: pxToRem('18px'),
                                                height: '100%',
                                            }} />
                                    </span>
                                </Tooltip>
                                {(onLayerIds.includes(item.id)) && <Box className={styles.on_layer} style={{
                                    width: pxToRem('15px'),
                                    height: pxToRem('15px'),
                                }}></Box>}
                            </Box>
                        ))}
                    </Stack>
                </>
                : <CategoryBig category={category} onLayerIds={onLayerIds} isLoading={isLoading} handleClick={handleClick} />
            }
        </Stack >
    )
}

CategoryBase.propTypes = {
    columns: PropTypes.number,
    handleClick: PropTypes.func.isRequired,
}

export default Category