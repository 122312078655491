import { IconButton, SvgIcon, TableRow, TextField } from "@mui/material"
import ReactFileReader from "react-file-reader"
import { Controller } from "react-hook-form"
import PropTypes from 'prop-types'
import { ReactComponent as DragIcon } from '../../../../../Icons/desktop/propLayer/DragIcon.svg'
import { ReactComponent as DelIcon } from '../../../../../Icons/desktop/propLayer/DelIcon.svg'
import { ReactComponent as UploadIcon } from '../../../../../Icons/desktop/propLayer/UploadIcon.svg'
import { TableCellSideTags, TableCellTextFieldTags } from "../../../StyledComponents/propertyTags"
import { pxToRem } from "../../../../Style/desktopTheme"


const Row = ({ index, control, remove, isEditor, uploadFile, value, keyValue, innerRef, ...props }) => (

    <TableRow {...props} ref={innerRef} >
        <TableCellSideTags><SvgIcon sx={{fontSize: pxToRem('24px')}}><DragIcon /></SvgIcon></TableCellSideTags>
        <TableCellTextFieldTags>
            {control
                ? <Controller
                    name={`tags.${index}.key`}
                    control={control}
                    render={({ field }) => <TextField {...field} placeholder="Тег" fullWidth disabled={!isEditor} size="small"/>}
                />
                : <TextField fullWidth defaultValue={keyValue} disabled size="small" />
            }
        </TableCellTextFieldTags>
        <TableCellTextFieldTags>
            {control
                ? <>
                    <Controller
                        name={`tags.${index}.value`}
                        control={control}
                        render={({ field }) => <TextField {...field} placeholder="Значение" fullWidth disabled={!isEditor}
                            InputProps={{
                                endAdornment: uploadFile && (
                                    <ReactFileReader disabled={!isEditor} handleFiles={(file) => uploadFile(file, index)}>
                                        <IconButton variant="var1" sx={{ ml: pxToRem('8px'), mr: pxToRem('8px') }}>
                                        <SvgIcon sx={{fontSize: pxToRem('24px')}}><UploadIcon /></SvgIcon><input type='file' hidden />
                                        </IconButton>
                                    </ReactFileReader>
                                )
                            }}
                            size="small"
                        />}
                    />
                </>
                : <TextField defaultValue={value} disabled size="small"/>
            }
        </TableCellTextFieldTags>
        <TableCellSideTags>
            {isEditor && <IconButton variant="var1" onClick={() => remove && remove(index)}><SvgIcon sx={{fontSize: pxToRem('32px')}}><DelIcon fill="#FC2D29"/></SvgIcon></IconButton>}
        </TableCellSideTags>
    </TableRow>
)

Row.propTypes = {
    index: PropTypes.number.isRequired,
    innerRef: PropTypes.func.isRequired,
    isEditor: PropTypes.bool.isRequired,
    uploadFile: PropTypes.func,
    remove: PropTypes.func,
    keyValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
}
export default Row