import { useMap } from "react-leaflet";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { mapSelector } from "../../../../store/selectors/selectors";

const MutableTileLayer = () => {
    const map = useMap();
    const {tileLayer:{layer:tileLayer}} = useSelector(mapSelector)
    const [currentTileLayer, setCurrentTileLayer] = useState();    

    useEffect(() => {
        map.addLayer(tileLayer);

        if (currentTileLayer) {
            map.removeLayer(currentTileLayer);
        }
        setCurrentTileLayer(tileLayer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, tileLayer])
    
    return null;
}

export default MutableTileLayer;