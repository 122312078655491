import { useEffect } from "react"
import { useObjectQuery } from "../hooks/reactQuery/useObject"
import { RoutePaths } from "../routes"
import { useModal } from "../hooks/useModal/useModal"
import useNavigate from "../hooks/useNavigateWithSearchParams"


const OldMapPage = () => {

    // Страница для работы старых ссылок с ГП /map?......
    const navigate = useNavigate()
    const params = new URLSearchParams(window.location.search)

    const { openAlert } = useModal()

    const { data: object, error,/*isLoading: objectIsLoading, isError: objectIsError */ } = useObjectQuery(Number(params.get('o')))
    console.log({object, error});

    useEffect(() => {
        if (error) {
            if (error.response.data === 'Объект не найден') {
                openAlert({
                    title: 'Ошибка',
                    children: error.response.data,
                })
            }
            navigate(RoutePaths.HOME)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        if (params.get('o')) {
            if (object && object.layers.length > 0) {
                const newParams = new URLSearchParams()
                if (params.get('layers')) newParams.set('layers', params.get('layers'))
                if (params.get('z')) newParams.set('z', params.get('z'))
                // if (params.get('c')) newParams.set('c', params.get('c'))

                navigate(RoutePaths.OBJECT
                    .replace(':layerId', object.layers[0].id)
                    .replace(':objectId', params.get('o'))
                    + '?' + newParams.toString()
                )
            }
        } else {
            navigate(RoutePaths.HOME)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [object])
    return null
}

export default OldMapPage