import { getLayerHierarhyFn, layerByIdFn } from "../../selectors/functions"
import { editOnMapLayerSelector, heatMapIdsSelector, layerRecentSelector, selectedLayerIdsSelector } from "../../selectors/selectors"
import { MapActionCreators } from "../map/action-creators"
import { SELECTED_LAYER_IDS_ADD_DATA, SELECTED_LAYER_IDS_DEL_DATA, SELECTED_LAYER_RESET } from "./index"
import { RecentActionCreators } from "../layerRecent/action-creators"
import { queryClient } from '../../../App'
import { getCacheLayersFn } from "../../../hooks/reactQuery/selectorCache/getCacheLayersFn"


export const SelectedLayerIdsActionCreators = {
    addSelectedLayerIds: (layerIds) => {
        return {
            type: SELECTED_LAYER_IDS_ADD_DATA,
            payload: {
                layerIds
            }
        }
    },
    delSelectedLayerIds: (layerIds) => {
        return {
            type: SELECTED_LAYER_IDS_DEL_DATA,
            payload: {
                layerIds
            }
        }
    },
    resetSelectedLayerIds: () => {
        return {
            type: SELECTED_LAYER_RESET,
        }
    },
    clearSelectedLayerIds: () => {
        return async (dispatch, getState) => {
            dispatch(SelectedLayerIdsActionCreators.resetSelectedLayerIds())
            const state = getState()
            const selectedLayerIds = selectedLayerIdsSelector(state)
            selectedLayerIds.forEach(layerId => {
                dispatch(RecentActionCreators.disableRecent(layerId))
            })
        }
    },
    deselectLayerByIds: (layerIds) => {    // layerIds - array Id
        return async (dispatch, getState) => {
            const state = getState()

            // если редактируется какой либо слой - завершаем его редактирование
            const editLayerId = editOnMapLayerSelector(state)
            if (editLayerId) {
                dispatch(MapActionCreators.stopMapEdit())
            }

            // если для слоя была включена аналитическая карта - выключаем ее
            const heatMapIds = heatMapIdsSelector(state)
            layerIds.forEach(layerId => {
                if (heatMapIds.includes(layerId)) {
                    dispatch(MapActionCreators.heatMapDel(layerId))
                }
            })

            dispatch(SelectedLayerIdsActionCreators.delSelectedLayerIds(layerIds))
        }
    },
    // выделить слой и добавить его в recent
    selectLayerById: (layerId) => {
        const cacheLayers = getCacheLayersFn(queryClient)
        return async (dispatch, getState) => {
            const state = getState()
            dispatch(SelectedLayerIdsActionCreators.addSelectedLayerIds([layerId]))

            const layer = layerByIdFn(layerId, cacheLayers)
            const layerHierarhy = getLayerHierarhyFn(layerId, cacheLayers)
            const layerRecent = layerRecentSelector(state)

            // Добавляем в recent только если его там нет
            if (layer && layerHierarhy.length > 0) {
                if (layerRecent.filter(l => l.id === layer.id).length === 0) {
                    dispatch(RecentActionCreators.addRecentNew({
                        id: layer.id,
                        name: layer.name,
                        layerHierarhy
                    }))
                }
            }
        }
    },
}

