import { Box, Stack } from "@mui/material"
import { pxToRem } from "../../Style/desktopTheme"


const FormContainer = ({ children, action = false, disablePaddingRight = false, disablePaddingLeft = false, enableDividerTop = false, ...props }) => {
    return (
        <Stack spacing={2} className='overflow'
            sx={{ borderTop: enableDividerTop ? '1px solid rgba(0, 0, 0, 0.10)' : 0 }}
            {...props}>
            <Stack spacing={2} sx={{
                height: '100%',
                overflow: 'auto',
                pt: pxToRem('24px'),
                pl: pxToRem(disablePaddingLeft ? '0px' : '32px'),
                pr: pxToRem(disablePaddingRight ? '0px' : '32px'),
                pb: pxToRem('24px'),
                mr: pxToRem('32px'),
                borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
            }}>
                {children}
            </Stack>

            {action &&
                <Box style={{
                    marginLeft: pxToRem('32px'),
                    marginRight: pxToRem('32px'),
                }}>
                    {action}
                </Box>
            }
        </Stack>
    )
}

export default FormContainer