import React, { useContext } from 'react';
import { RoleContext } from '../RoleContext';
import WrapFilterList from '../../WrapFilterList';


const Users = () => {

    const { prepareRoleDialog: { role, userList, editor }, control, setValue } = useContext(RoleContext)

    const props = {
        nameFields: 'userIds',
        list: userList,
        checkedIds: role.userIds,
        control,
        setValue,
        disabled: !editor,
    }
    return (
        <WrapFilterList {...props} />)
}

export default Users