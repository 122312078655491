import {
    ADD_RECENT,
    CLEAR_RECENT,
} from "./index";


export const RecentActionCreators = {
    addRecentNew: (layer) => {
        return {
            type: ADD_RECENT,
            payload: layer
        }
    },
    clearRecentNew: () => {
        return {
            type: CLEAR_RECENT
        }
    },
}