import React from 'react'
import { Box } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
import healthIcon from '../../../Icons/category/health.svg'
import access_envIcon from '../../../Icons/category/access_env.svg'
import educatIcon from '../../../Icons/category/educat.svg'
import servicesIcon from '../../../Icons/category/services.svg'
import securityIcon from '../../../Icons/category/security.svg'
import projectIcon from '../../../Icons/category/project.svg'
import terraIcon from '../../../Icons/category/terra.svg'
import urban_planingIcon from '../../../Icons/category/urban_planing.svg'
import cultureIcon from '../../../Icons/category/culture.svg'
import relaxIcon from '../../../Icons/category/relax.svg'
import memorialsIcon from '../../../Icons/category/memorials.svg'
import natureIcon from '../../../Icons/category/nature.svg'
import economyIcon from '../../../Icons/category/economy.svg'
import connectionIcon from '../../../Icons/category/connection.svg'
import regionIcon from '../../../Icons/category/region.svg'
import municipalIcon from '../../../Icons/category/municipal.svg'
import transportIcon from '../../../Icons/category/transport.svg'
import spatialIcon from '../../../Icons/category/spatial_data.svg'
import data_earthIcon from '../../../Icons/category/data_earth.svg'
import woodsIcon from '../../../Icons/category/woods.svg'
import map_devIcon from '../../../Icons/category/map_dev.svg'
import serviceLayerIcon from '../../../Icons/category/service_layer.svg'
import archiveIcon from '../../../Icons/category/archive.svg'
import videoIcon from '../../../Icons/category/video.svg'


const CategoryIcon = ({ name = '', className, isLoading, ...props }) => {

    const CategoryName = {
        'здравоохранение': { icon: healthIcon, style: { background: '#FCE6E6' } },
        'доступная среда': { icon: access_envIcon, style: { background: '#E0ECFD' } },
        'образование': { icon: educatIcon, style: { background: '#E9F5EE' } },
        'госуслуги и учреждения': { icon: servicesIcon, style: { background: '#F0E5FB' } },
        'экстренные службы и безопасность': { icon: securityIcon, style: { background: '#FCE6E6' } },
        'проекты правительства яо': { icon: projectIcon, style: { background: '#F0E5FB' } },
        'земля и имущество': { icon: terraIcon, style: { background: '#EFEAE9' } },
        'градостроительство': { icon: urban_planingIcon, style: { background: '#E6EFFC' } },
        'культура и искусство': { icon: cultureIcon, style: { background: '#F0E5FB' } },
        'туризм, спорт и досуг': { icon: relaxIcon, style: { background: '#E9F5EE' } },
        'мемориалы': { icon: memorialsIcon, style: { background: '#EDEDED' } },
        'природа и экология': { icon: natureIcon, style: { background: '#E9F5EE' } },
        'связь': { icon: connectionIcon, style: { background: '#E0ECFD' } },
        'экономика': { icon: economyIcon, style: { background: '#FDF0E4' } },
        'территориальное деление': { icon: regionIcon, style: { background: '#E0ECFD' } },
        'муниципальная информация': { icon: municipalIcon, style: { background: '#F0E5FB' } },
        'транспорт': { icon: transportIcon, style: { background: '#FDF0E4' } },
        'пространственные сведения': { icon: spatialIcon, style: { background: '#F0E5FB' } },
        'данные дзз': { icon: data_earthIcon, style: { background: '#E0ECFD' } },
        'лесной фонд': { icon: woodsIcon, style: { background: '#E9F5EE' } },
        'карта развития': { icon: map_devIcon, style: { background: '#E9F5EE' } },
        'сервисные слои': { icon: serviceLayerIcon, style: { background: '#E0ECFD' } },
        'архив': { icon: archiveIcon, style: { background: '#F0E5FB' } },
        'камеры': { icon: videoIcon, style: { background: '#E0ECFD' } },
    }

    if (isLoading) {
        return (
            <Skeleton
                style={{
                    opacity: 0.5,
                    ...props.style
                }}
                // {...props}
                enableAnimation={true}
            />
        )
    }

    if (CategoryName[name.toLowerCase()]) {
        return (
            <Box className={className} {...props} style={{ ...CategoryName[name.toLowerCase()].style, ...props.style }} >
                <img alt={name} src={CategoryName[name.toLowerCase()].icon} />
            </Box>
        )
    } else
        return <Box className='icon_cat_bg'></Box>
}

export default CategoryIcon