import CheckAccesToLayer from '../hoc/CheckAccesToLayer'
import { pxToRem } from '../components/Style/desktopTheme'
import { Box} from '@mui/material'
import { useParams } from 'react-router-dom'
import Header from '../hoc/Header'
import useDeviceDetect from '../hooks/useDeviceDetect'
import HeadToolBarObject from '../components/general/HeadToolBar/HeadToolBarObject'
import { useSelector } from 'react-redux'
import { mapArcGisSelector } from '../store/selectors/selectors'
import ArcGisObject from '../components/general/ArcGisObjects/ArcGisObject'


const ArcGisObjectPage = (props) => {

    const { isMobile } = useDeviceDetect()
    const objectId = Number(useParams().objectId)
    const { error, isLoading, data } = useSelector(mapArcGisSelector)

    if (error) return null

    const index = data.featureCollection?.features?.findIndex(f => Number(f.id) === objectId)
    const res = data.result?.[index]
    const title = {
        name: res ? `${res?.displayFieldName} ${res?.value}` : '',
        isLoading
    }

    return (
        <CheckAccesToLayer>
            {isMobile
                ? <MobArcGisObjectPage title={title} {...props} />
                : <DesktopArcGisObjectPage title={title} />
            }
        </CheckAccesToLayer >
    )
}

export default ArcGisObjectPage


const MobArcGisObjectPage = ({ title, handleClose }) => {

    return (
        <>
            <Header>
                <HeadToolBarObject title={title} handleClose={handleClose} />
            </Header>

            <ArcGisObject />
        </>
    )
}

const DesktopArcGisObjectPage = ({ title }) => {

    return (
        <>
            <Header><HeadToolBarObject title={title} /></Header>
            <Box sx={{ ml: pxToRem('12px'), mr: pxToRem('12px'), mt: pxToRem('7px'), height: '100%', overflow: 'auto' }}>
                <ArcGisObject />
            </Box>
        </>
    )
}