import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { GalleryContext } from '../../GalleryContext';
import { ModalContextProvider, useModal } from '../../../../../hooks/useModal/useModal';
import IconItemList from './IconItemList';
import Categories from './Categories';
import EditItem from './EditItem';
import FormContainer from '../../../ForForms/FormContainer';


const style = {
    height: "calc(90vh)",
    overflow: "auto",
}


const AddIconButton = () => {
    const { openWindow } = useModal()

    const handleClick = () => {
        openWindow({
            title: 'Добавление новой иконки',
            children: <EditItem />,
            backdropOpacity: 0.2,
            width: '50%'
        })
    }

    return <Button onClick={handleClick} variant='outlined'><AddIcon />&nbsp;Добавить иконку</Button>
}


const GalleryIcons = ({ selectedIconId, setSelectedIconId }) => {

    const initialState = { oldIcon: false, term: [] }
    const [params, setParams] = useState(initialState)


    return (
        <GalleryContext.Provider value={{ params, setParams, selectedIconId, setSelectedIconId }}>
            <ModalContextProvider>
                <FormContainer>
                    <Stack direction='row' spacing={2}>
                        <Box>
                            <FormControlLabel
                                label='Старые иконки'
                                control={
                                    <Checkbox
                                        checked={params.oldIcon}
                                        onChange={e => {
                                            setParams({
                                                ...initialState,
                                                oldIcon: e.target.checked
                                            })
                                        }}
                                    />
                                }
                            />
                        </Box>

                        {!params.oldIcon && <AddIconButton />}
                    </Stack>

                    <Divider />

                    <Grid container sx={style} spacing={0} >
                        <Grid item md={3} className='overflow'>
                            <Categories />
                        </Grid>

                        <Grid item md={9} className='overflow' style={{ borderLeft: '1px solid', borderColor: 'rgba(0, 0, 0, 0.10)' }} >
                            <IconItemList />
                        </Grid>
                    </Grid>
                </FormContainer>
            </ModalContextProvider>
        </GalleryContext.Provider>
    )
}

export default GalleryIcons