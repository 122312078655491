import { useQueryClient } from "react-query";
import { getLayerHierarhyFn } from "../../../store/selectors/functions"
import { useLayersHierarhyQuery } from "../useLayer";
import useInCacheLayers from "./useInCacheLayers"

const useLayerHierarhy = (layerId) => {

    const queryClient = useQueryClient()

    const allLayersNormalized = useInCacheLayers({ normalize: true })
    const result = getLayerHierarhyFn(layerId, allLayersNormalized)
    
    const onQueryLayerIds = queryClient.getQueriesData(['layerHierarhy']).map(itemQuery => Number(itemQuery[0][1]))
    const enabled = (Object.values(result).length === 0 || onQueryLayerIds.includes(Number(layerId))) && layerId > 0

    useLayersHierarhyQuery({ layerId: Number(layerId), enabled })
    return result
}

export default useLayerHierarhy