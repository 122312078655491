import React from 'react';
import { styled } from '@mui/material/styles'
import { ReactComponent as CheckBoxAllIconSvg } from '../../../Icons/desktop/form/CheckBoxAllIconSvg.svg'


const CheckBoxAllIcon = ({ active, ...props }) => {

    const CheckBoxAllIconStyled = styled(CheckBoxAllIconSvg)(
        ({ theme, active }) => {
            return ({
                fill: active === 'true' ? theme.palette.myGreen.main : theme.palette.myGray.dark
            })
        }
    )

    return (
        <CheckBoxAllIconStyled active={active? 'true': 'false'}  {...props} />
    )
}

export default CheckBoxAllIcon;