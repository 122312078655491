import { IconButton, SvgIcon } from "@mui/material";
import { closeSnackbar } from "notistack";
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';


const CloseAction = (snackbarId) => {
    return (
        <IconButton size="small" onClick={() => closeSnackbar(snackbarId)} ><SvgIcon><CloseIcon style={{color: 'white'}}/></SvgIcon></IconButton>
    )
}

CloseAction.propTypes = PropTypes.number.isRequired

export default CloseAction