import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Skeleton from 'react-loading-skeleton'
import { SvgIcon } from '@mui/material';
import { ReactComponent as SeparatorIcon } from "../../../Icons/SeparatorIcon.svg";
import { RoutePaths } from '../../../routes';
import 'react-loading-skeleton/dist/skeleton.css'
import MyAlert from '../Info/MyAlert';
import Link from '../../../hoc/LinkWithSearchParams'
import { pxToRem } from "../../Style/desktopTheme"


const BreadCrumbs = (props) => {

    // props.className
    // props.isLoading - для скелетона
    // props.toSearch
    // props.arrBreadCrumbsData[
    //  {id:, name},
    // ]  - массив родительских слоев
    // props.isHideLastElement

    let lastCrumbs = [];
    let breadcrumbs = [];

    if (!props.toSearch)
        breadcrumbs.push(<Link to={RoutePaths.HOME} key="breadcrumb-0" >Категории</Link>);

    if (props.arrBreadCrumbsData) {
        props.arrBreadCrumbsData.forEach((item, index) => {
            if (index < props.arrBreadCrumbsData.length - 1) {
                breadcrumbs.push(
                    <Link to={RoutePaths.LAYER.replace(':layerId', item.id)} key={'breadcrumb-' + index} >
                        {item.name}
                    </Link>)
            }
        });

        if (!props.toSearch) {
            lastCrumbs = props.arrBreadCrumbsData[props.arrBreadCrumbsData.length - 1];
            breadcrumbs.push(<span key='breadcrumb-last'>{lastCrumbs?.name}</span>);
        }
    }

    // Если нет доступа к слою - выводим соответствующее предупреждение
    if (props.error) {
        return <MyAlert severity='error' title='Ошибка' message={props.error.message} />
    }

    if(props.isHideLastElement) {
        delete breadcrumbs[breadcrumbs.length - 1]
    }

    // передаем BreadCrumbs props, удаляя ненужные
    let breadCrumbsProps = { ...props }
    Object.keys(breadCrumbsProps)
        .forEach(key => {
            if (key === 'isLoading' || key === 'arrBreadCrumbsData' || key === 'error' || key === 'toSearch' || key === 'isHideLastElement') {
                delete breadCrumbsProps[key]
            }
        })

    if (breadcrumbs.length > 0) {
        return (
            <Breadcrumbs
            sx={{
                '& .MuiBreadcrumbs-separator': {
                    'svg': {stroke: '#333333',}
                }
            }}
             separator={<SvgIcon inheritViewBox={true}
                sx={{
                    width: pxToRem('5px'),
                    height: pxToRem('7px'),
                }}
            ><SeparatorIcon /></SvgIcon>} {...breadCrumbsProps} >
                {props.isLoading
                    ? <Skeleton width={pxToRem(200)} />
                    : breadcrumbs
                }
            </Breadcrumbs>
        )
    } else return null
}

export default BreadCrumbs;