import { Box, IconButton, SvgIcon } from "@mui/material"
import { ReactComponent as VectorIcon } from '../../../Icons/VectorIcon.svg';
import PropTypes from 'prop-types'


const GoBackButton = ({ handleClick }) => {
    return (
        <Box>
            <IconButton  size='40' 
            onClick={handleClick}
            >
                <SvgIcon sx={{ fontSize: '16px' }} inheritViewBox><VectorIcon /></SvgIcon>
            </IconButton>
        </Box>

    )
}

export default GoBackButton

GoBackButton.propTypes = {
    handleClick: PropTypes.func.isRequired
}