import { AppBar, Box, FormControl, Toolbar } from '@mui/material';
import Search from './Search'
import styles from './Search.module.scss'


const SearchToolBar = (props) => {
    return (
        <>
            <Box className={styles.searchToolBar}>
                <AppBar position='relative' className={styles.box_appbar}>
                    <Toolbar className={styles.box_toolbar}>
                        <FormControl fullWidth>
                            <Search {...props}/>
                        </FormControl>
                    </Toolbar>
                </AppBar>
            </Box>
        </>
    )
}

export default SearchToolBar