import { Box, Stack } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import { pxToRem } from '../../Style/desktopTheme';


const ItemLayerSkeleton = () => {
    return (
        <Box sx={{ pt: pxToRem('4px'), pb: pxToRem('4px'), pl: pxToRem('8px'), pr: pxToRem('8px') }}>
            <Stack direction='row' justifyContent='space-between' alignItems='start' sx={{ height: pxToRem('48px') }}>

                <Stack direction='row'>
                    <Skeleton width={pxToRem(18)} style={{ marginLeft: pxToRem('8px'), marginRight: pxToRem('8px') }} />
                    <Skeleton width={pxToRem(200)} count={1.5} />
                </Stack>

                <Box >
                    <Skeleton width={pxToRem(20)} />
                </Box>

            </Stack>
        </Box>
    )
}

export default ItemLayerSkeleton