export const getCacheLayersFn = (queryClient) => {

    const dataCacheLayerHierarhy = queryClient.getQueryCache().findAll('layerHierarhy')
    const dataCachelayersByParentId = queryClient.getQueryCache().findAll('layersByParentId')
    const dataCacheLayerById = queryClient.getQueryCache().findAll('layer')
    const dataCachePreloadLayerHierarhy = queryClient.getQueryCache().findAll('preloadLayerHierarhy')

    let resultNormalized = {}

    dataCacheLayerHierarhy.forEach(itemCache => {
        if (itemCache.state.data) {
            itemCache.state.data.forEach(l => {
                resultNormalized = {
                    ...resultNormalized,
                    [l.id]: l
                }
            })
        }
    })

    dataCachelayersByParentId.forEach(itemCache => {
        if (itemCache.state.data) {
            Object.values(itemCache.state.data).forEach(l => {
                resultNormalized = {
                    ...resultNormalized,
                    [l.id]: l
                }
            })
        }
    })

    dataCacheLayerById.forEach(itemCache => {
        if (itemCache.state.data) {
            Object.values(itemCache.state.data).forEach(l => {
                resultNormalized = {
                    ...resultNormalized,
                    [l.id]: l
                }
            })
        }
    })

    dataCachePreloadLayerHierarhy.forEach(itemCache => {
        if (itemCache.state.data) {
            itemCache.state.data.forEach(item => {
                Object.values(item).forEach(l => {
                    resultNormalized = {
                        ...resultNormalized,
                        [l.id]: l
                    }
                })
            })
        }
    })

    return resultNormalized
}

