import { Box, Checkbox, Divider, FormControlLabel, Grid, Stack, SvgIcon, Typography } from '@mui/material'
import { useSelector } from 'react-redux';
import { mapSelector } from '../../../../store/selectors/selectors';
import { Tiles, WmsTiles } from "../../../../store/reducers/map/index";
import { useActions } from '../../../../hooks/useActions';
import { ReactComponent as CheckboxActive } from "../../../../Icons/desktop/form/CheckboxActive.svg"
import { pxToRem } from '../../../Style/desktopTheme';


const TileItem = ({ name, icon, selected, onClick }) => {
    name = name.replace('Карта', '').replace('Спутник', '')
    return (
        <Grid item xs={3} align={"center"}>
            <Box
                onClick={onClick}
                width={pxToRem(48)}
                height={pxToRem(48)}
                border={2}
                borderColor={selected ? "#27AE60" : "transparent"}
                style={{
                    boxSizing: 'content-box',
                    borderRadius: 6, marginBottom: '4px',

                }}
            >
                <Box style={{
                    height: pxToRem(48),
                    width: pxToRem(48),
                    backgroundImage: `url(${icon})`,
                    borderRadius: pxToRem('6px')
                }}
                />
            </Box>
            <Typography width={48} variant='font_12_16' sx={{ color: selected ? 'myGreen.main' : 'inherit' }}>{name}</Typography>
        </Grid>
    )
}

const WmsTileItem = ({ name, selected, handleChange }) => {

    return (

        <FormControlLabel
            onChange={e => handleChange(e.target.checked)}
            label={<Box><Typography variant='font_12_16'>{name}</Typography></Box>}
            control={
                <Checkbox
                    checkedIcon={<SvgIcon inheritViewBox={true}><CheckboxActive /></SvgIcon>}
                    checked={selected}
                />}
        />

    )
}


const MapSettings = () => {

    const { tileLayer, wmsTileLayer } = useSelector(mapSelector)
    const { setMapTileLayer, setMapWmsTileLayer } = useActions()

    return (
        <>
            <Grid container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1.5}
                sx={{
                    width: pxToRem('252px'),
                    mt: 0,
                    ml: 0,
                    mr: pxToRem('12px'),
                    mb: pxToRem('12px'),
                }}
            >
                {Tiles.map(tile => {
                    return (<TileItem
                        key={tile.id}
                        icon={tile.icon}
                        name={tile.name}
                        selected={tile.id === tileLayer.id}
                        onClick={_ => setMapTileLayer(tile.id)}
                    />)
                }
                )}
            </Grid>

            <Divider />
            <Stack spacing={1} sx={{ ml: pxToRem('12px'), mr: pxToRem('12px') }}>
                {WmsTiles.map(tile => <WmsTileItem
                    key={tile.id}
                    name={tile.name}
                    selected={tile.id === wmsTileLayer?.id}
                    handleChange={(checked) => {
                        if (checked) {
                            setMapWmsTileLayer(tile)
                        } else {
                            setMapWmsTileLayer(null)
                        }
                    }}
                />
                )}
            </Stack>

        </>
    )
}

export default MapSettings