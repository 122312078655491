import { useQueryClient, useMutation, useQuery } from 'react-query'
import { TIME_UPDATE } from '../../config'
import { addGalleryIcon, deleteGalleryIcon, loadGalleryCategories, loadGalleryIcon, loadGalleryIcons, updateGalleryIcon, uploadGalleryIcon } from '../../service/axiosFuncQuery/galleryQuery'
import { useActions } from '../useActions'
import { useMask } from '../useMask'
import { useSnackbar } from 'notistack'
import CloseAction from '../../components/general/SnackBar/CloseAction'

// query
export const useGalleryCategoriesQuery = ({ oldIcon }) => {
    return (
        useQuery({
            queryKey: ['galleryCategories', String(oldIcon)],
            queryFn: () => loadGalleryCategories(oldIcon),
            onError: (error) => console.log(error.message),
            staleTime: TIME_UPDATE,
            // keepPreviousData: true, // показывать предыдущие данные до загрузки новых
        })
    )
}

export const useGalleryIconsQuery = ({ term, oldIcon }) => {
    return (
        useQuery({
            queryKey: ['galleryIcons', String(term), String(oldIcon)],
            queryFn: () => loadGalleryIcons(term, oldIcon),
            onError: (error) => console.log(error.message),
            staleTime: TIME_UPDATE,
        })
    )
}

export const useGalleryIconQuery = (iconId) => {
    return (
        useQuery({
            queryKey: ['galleryIconInfo', String(iconId)],
            queryFn: () => loadGalleryIcon(iconId),
            onError: (error) => console.log(error.message),
            staleTime: TIME_UPDATE,
            // enabled: Boolean(iconId)
        })
    )
}


// mutation
export const useRemoveGalleryIconMutation = () => {
    const client = useQueryClient()
    const { hideMask, showMask } = useMask()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: (iconId) => deleteGalleryIcon(iconId),
            onMutate: () => showMask(),
            onSuccess: () => {
                hideMask()
                client.invalidateQueries({ queryKey: ['galleryIcons'] })
            },
            onError: (error) => {
                hideMask()
                const message = error.response.data === 'Deleted IconPack failed - is used.' ? 'Ошибка при удалении - иконка используется' : error.response.data
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
        })
    )
}

export const useUploadGalleryIconMutation = () => {
    const queryClient = useQueryClient()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ origIcon, origTreeIcon }) => uploadGalleryIcon({ origIcon, origTreeIcon }),
            onSuccess: () => {
                queryClient.invalidateQueries(['galleryIcons'])
            },
            onError: (e) => {
                const message = `Ошибка при загрузке. ${e.message}`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
        })
    )
}

export const useAddGalleryIconMutation = () => {
    const client = useQueryClient()
    const { hideMask, showMask } = useMask()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: (tempDir) => addGalleryIcon(tempDir),
            onMutate: () => showMask(),
            onSuccess: () => {
                hideMask()
                client.invalidateQueries({ queryKey: ['galleryIcons'] })
            },
            onError: (e) => {
                hideMask()
                const message = `Ошибка при добавлении. ${e.message}`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
        })
    )
}

export const useUpdateGalleryIconMutation = () => {
    const { setChangeIcon } = useActions()
    const { hideMask, showMask } = useMask()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ tempDir, iconId }) => updateGalleryIcon(tempDir, iconId),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                setChangeIcon(variables.iconId) // помечаем в store  что иконка изменилась
                hideMask()
            },
            onError: (e) => {
                hideMask()
                const message = `Ошибка при изменении. ${e.message}`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
        })
    )
}