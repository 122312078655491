import { useQueryClient } from "react-query"
import { getCacheLayersFn } from "./getCacheLayersFn"

const useInCacheLayers = ({ normalize = false } = {}) => {
    const queryClient = useQueryClient()
    const resultNormalized = getCacheLayersFn(queryClient)
    // console.log({resultNormalized});

    if (normalize) return resultNormalized
    else return Object.values(resultNormalized)
}

export default useInCacheLayers