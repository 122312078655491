import { Stack, SvgIcon, Tooltip } from '@mui/material';
import { useMap } from 'react-leaflet';
import { ReactComponent as PlusIcon } from '../../../../Icons/map/PlusIcon.svg';
import { ReactComponent as MinusIcon } from '../../../../Icons/map/MinusIcon.svg';
import ControlIconButton from '../../../desktop/MapControl/components/ControlIconButton';
import { pxToRem } from '../../../Style/desktopTheme';


const ZoomControl = () => {
    const map = useMap();
    const zoomFunc = (e, action) => {
        e.preventDefault();
        if (action === 'in')
            map.zoomIn();
        if (action === 'out')
            map.zoomOut();
    }

    return (
        <Stack spacing={2}>
            <Tooltip title='Приблизить' arrow placement='left'>
                <span>
                <ControlIconButton onClick={(e) => zoomFunc(e, 'in')} >
                    <SvgIcon style={{ fontSize: pxToRem('14px') }} inheritViewBox>
                        <PlusIcon />
                    </SvgIcon>
                </ControlIconButton>
                </span>
            </Tooltip>

            <Tooltip title='Отдалить' arrow placement='left'>
                <span>
                <ControlIconButton onClick={(e) => zoomFunc(e, 'out')}>
                    <SvgIcon style={{ fontSize: pxToRem('14px') }} inheritViewBox>
                        <MinusIcon />
                    </SvgIcon>
                </ControlIconButton>
                </span>
            </Tooltip>
        </Stack>
    )
}

export default ZoomControl