import { applyMiddleware, combineReducers, createStore } from "redux"
import {thunk} from 'redux-thunk'
import { composeWithDevTools } from "@redux-devtools/extension"
import logger from 'redux-logger'
import reducers from './reducers'


const appReducer = combineReducers(reducers)

const rootReducer = (state, action) => {
    return appReducer(state, action)
}

let middleware = applyMiddleware(thunk, logger)
if (process.env.NODE_ENV === "development") {
    middleware = composeWithDevTools(middleware)
}

const store = createStore(rootReducer, middleware)

export default store