import { WMSTileLayer } from 'react-leaflet/WMSTileLayer'
import { GEOPORTAL_URL } from '../../../config';

const CreateWmsWfs = (props) => {
    return (
        <WMSTileLayer
            url={GEOPORTAL_URL + '/wms/'}
            params={{
                layers: props.layerId,
                transparent: true,
            }}
            eventHandlers={{
                click: (e) => {
                    console.log(e);

                },
            }}
        />
    )
};

export default CreateWmsWfs;