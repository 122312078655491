import { SystemActionCreators } from './system/action-creators';
import { RecentActionCreators } from './layerRecent/action-creators';
import { MapActionCreators } from './map/action-creators';
import {AuthActionCreators} from './auth/action-creators';
import { UserActionCreators } from './user/action-creators';
import { SelectedLayerIdsActionCreators } from './selectedLayerIds/action-creators';
import { MapArcGisActionCreators } from './mapArcGis/action-creators';


export const allActionCreators = {
    ...SystemActionCreators,
    ...RecentActionCreators,
    ...MapActionCreators,
    ...AuthActionCreators,
    ...UserActionCreators,
    ...SelectedLayerIdsActionCreators,
    ...MapArcGisActionCreators,
}