import { useSelector } from "react-redux";
import { isAuthSelector, layerRecentSelector, selectedLayerIdsSelector } from "../../../store/selectors/selectors";
import { useEffect, useMemo, useState } from "react";
import { useActions } from "../../../hooks/useActions";
import { useLoadRecentLayerQuery } from "../../../hooks/reactQuery/useLayer";
import { Box, Checkbox, List, ListItemButton, ListItemText, Stack } from "@mui/material";
import { sortByName } from "../../../service/function";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import LoadingCheckBox from "../../desktop/ForForms/LoadingCheckBox";
import { pxToRem } from "../../Style/desktopTheme";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import { useIsFetching, useQueryClient } from "react-query";
import MyAlert from "../Info/MyAlert";
import useLayerHierarhy from "../../../hooks/reactQuery/selectorCache/useLayerHierarhy";


const ItemElement = ({ layer, isChecked }) => {

    const queryClient = useQueryClient()
    const { selectLayerById, deselectLayerByIds } = useActions()
    const { refetch: enableRecentLayer, error: errorLoadRecentLayer } = useLoadRecentLayerQuery(layer.id)
    const layerHierarhy = useLayerHierarhy(layer.id)
    const isAuth = useSelector(isAuthSelector)
    const isFetchingObjectsToLayer = useIsFetching({
        queryKey: ['objectsToLayer', String(layer.id)]
    })

    /* isLoading устанавливаем на время загрузки  useLoadRecentLayerQuery и objectsToLayer. Т.к. они загружаются с некоторой паузой, что вызывает моргание, 
    поэтому устанавливаем при клике, если еще нет данных и ждем окончание загрузки objectsToLayer */
    const [isLoading, setIsLoading] = useState()

    const activateLayer = (layerId) => {
        if (isChecked) {
            deselectLayerByIds([layerId])
        } else {
            if (layerHierarhy.length > 0) {
                selectLayerById(layer.id)   // данные layerHierarhy уже есть в сторе. Включаем слой
            } else {
                enableRecentLayer()         // загружаем layerHierarhy для слоя + включаем его
            }

            if (!queryClient.getQueryData(['objectsToLayer', String(layer.id)])) {
                setIsLoading(true)
            }
        }
    }

    useEffect(() => {
        if(isFetchingObjectsToLayer) {
            setIsLoading(true)
        }
        if (!isFetchingObjectsToLayer || errorLoadRecentLayer) {
            setIsLoading(false)
        }
    }, [isFetchingObjectsToLayer, errorLoadRecentLayer])

    let errorMessage = ''
    if (errorLoadRecentLayer) {
        if (errorLoadRecentLayer?.response?.data === 'Access is denied') {
            errorMessage = 'Нет доступа. ' + (isAuth ? '' : 'Пожалуйста авторизуйтесь.')
        }
        else {
            errorMessage = errorLoadRecentLayer.message
        }
    }

    
    return (
        <Stack sx={{ mb: pxToRem('0px') }} key={layer.id}>
            <ListItemButton sx={{ pl: pxToRem('16px'), pr: pxToRem('8px') }}>
                <Stack style={{ width: '100%' }}>
                    <Stack direction='row'>
                        <ListItemText primary={`${layer.name}`} />
                        {isLoading
                            ? <LoadingCheckBox />
                            : <Box><Checkbox sx={{ mr: 0 }} checked={isChecked} edge="end" onChange={() => activateLayer(Number(layer.id))} /></Box>
                        }
                    </Stack>

                    <BreadCrumbs
                        arrBreadCrumbsData={layer.layerHierarhy}
                        style={{ marginBottom: 0, position: 'relative' }}
                        isHideLastElement={true}
                    />

                    {errorLoadRecentLayer &&
                        <MyAlert severity='error' message={errorMessage} />
                    }
                </Stack>

            </ListItemButton>
        </Stack>
    )
}


const HistoryLayer = () => {

    const recentLayers = useSelector(layerRecentSelector)
    const selectedLayerIds = useSelector(selectedLayerIdsSelector)
    const [collapsed, setCollapsed] = useState([])

    const handleCategoryClick = (id) => {
        setCollapsed(cur => {
            let i = cur.indexOf(id);
            if (i === -1) {
                cur.push(id);
            } else {
                cur.splice(i, 1);
            }
            return [...cur];
        })
    }



    const recent = useMemo(() => {
        return recentLayers.reduce((prev, v) => {
            let id = v.layerHierarhy[0].id
            let cat = prev.find(c => c.id === id)
            if (!cat) {
                cat = { ...v.layerHierarhy[0], layers: [] }
                prev.push(cat)
            }
            cat.layers.push(v)
            return prev
        }, [])
    }, [recentLayers])

    return (
        <>
            <Box sx={{ mb: pxToRem('8px'), mt: pxToRem('8px'), height: '100%', overflow: 'auto' }}>
                <List style={{ width: '100%', bgcolor: 'background.paper' }} component={"nav"}>
                    {
                        sortByName(recent).map(category => {
                            return (
                                <Box key={category.id}>
                                    <ListItemButton onClick={() => handleCategoryClick(category.id)} sx={{ pl: pxToRem('15px'), pr: pxToRem('16px') }}>
                                        <ListItemText primary={category.name} primaryTypographyProps={{ fontWeight: 600 }} />
                                        {collapsed.indexOf(category.id) === -1 ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>

                                    <Box hidden={collapsed.indexOf(category.id) !== -1} timeout="auto">
                                        <List component="div" disablePadding>
                                            {sortByName(category.layers).map(layer =>
                                                <ItemElement layer={layer} isChecked={selectedLayerIds.includes(layer.id)} key={layer.id} />
                                            )}
                                        </List>
                                    </Box>
                                </Box>
                            )
                        })
                    }
                </List>
            </Box>
        </>
    )
}

export default HistoryLayer