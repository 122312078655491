import { Box, IconButton, SvgIcon } from '@mui/material';
import React from 'react';
import Link from '../../../hoc/LinkWithSearchParams'
import { ReactComponent as VectorIcon } from '../../../Icons/VectorIcon.svg';
import { pxToRem } from '../../Style/desktopTheme';


const GoBack = (props) => {
   // props.path

   return (
      <Box>
         <IconButton component={Link} to={props.path} {...props}>
            <SvgIcon style={{ fontSize: pxToRem('16px') }} inheritViewBox><VectorIcon /></SvgIcon>
         </IconButton>
      </Box>
   )
}

export default GoBack; 