import { Box, Stack } from "@mui/material"
import { pxToRem } from "../../Style/desktopTheme"
import LoadingIcon from '../../../Icons/LoadingIcon.gif';


const LoadingCheckBox = () => {
    return <Box>
        <Stack alignItems='center' justifyContent='center' sx={{ padding: pxToRem('11px')}}>
            <img style={{ width: pxToRem('18px'), height: pxToRem('18px') }} align='center' src={LoadingIcon} alt='loading' />
        </Stack>
    </Box>
}

export default LoadingCheckBox