import { Alert, AlertTitle } from '@mui/material'


const MyAlert = ({title, message,severity, ...props}) => {
    return (
        <Alert severity={severity} {...props}>
            {title && <AlertTitle><strong>{title}</strong></AlertTitle>}
            {message}
        </Alert>
    );
};

export default MyAlert