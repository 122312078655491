import api from "../http";
import {ApiPaths} from "../../config"


export const loadGalleryCategories = async (oldIcon) => {
    const result = await api.get(ApiPaths.gallery.LOAD_CATEGORIES.replace(':oldIcon', oldIcon))
    return result.data
}

export const loadGalleryIcons = async (term, oldIcon) => {
    const result = await api.get(ApiPaths.gallery.LOAD_ICONS.replace(':term', term).replace(':oldIcon', oldIcon))
    return result.data
}

export const loadGalleryIcon = async (iconId) => {
    const result = await api.get(ApiPaths.gallery.LOAD_ICON.replace(':iconId', iconId))
    return result.data
}


export const deleteGalleryIcon = async (iconId) => {
    return await api({
        url: ApiPaths.gallery.REMOVE_ICON.replace(':iconId', iconId),
        method: 'GET',
    })
}

export const uploadGalleryIcon = async ({ origIcon, origTreeIcon }) => {
    let formData = new FormData()
    formData.append("origTreeIcon", origTreeIcon)
    formData.append("origIcon", origIcon)

    return await api({
        url: ApiPaths.gallery.UPLOAD_ICON,
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
}

export const addGalleryIcon = async (tempDir) => {
    let formData = new FormData()
    formData.append("tempDir", tempDir)
    return await api({
        method: 'POST',
        url: ApiPaths.gallery.ADD_ICON,
        data: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
}

export const updateGalleryIcon = async (tempDir, iconId) => {
    let formData = new FormData()
    formData.append("tempDir", tempDir)
    formData.append("iconId", iconId)
    return await api({
        method: 'POST',
        url: ApiPaths.gallery.UPDATE_ICON,
        data: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
}

export const updateTreeIconLayer = async (layerId, iconId) => {
    let formData = new FormData()
    formData.append("layerId", layerId)
    formData.append("treeiconId", iconId)
    return await api({
        url: ApiPaths.icon.UPDATE_TREEICON_LAYER,
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },

    })
}

export const updateIconLayer = async (layerId, iconId) => {
    let formData = new FormData()
    formData.append("layerId", layerId)
    formData.append("iconId", iconId)
    return await api({
        url: ApiPaths.icon.UPDATE_ICON_LAYER,
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },

    })
}

export const updateIconObject = async (objectId, iconId) => {
    let formData = new FormData()
    formData.append("objectId", objectId)
    formData.append("iconId", iconId)
    return await api({
        url: ApiPaths.icon.UPDATE_FLAGICON_OBJECT,
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },

    })
}

export const updateIconShapeLayer = async (layerId, iconShape) => {
    let formData = new FormData()
    formData.append("layerId", layerId)
    formData.append("iconShape", iconShape)
    return await api({
        url: ApiPaths.icon.UPDATE_ICON_SHAPE,
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
}