import React, { useContext } from 'react';
import { UserContext } from '../UserContext';
import WrapFilterList from '../../WrapFilterList';



const Roles = () => {

    const { prepareUserDialog: { user, roleList }, control, setValue } = useContext(UserContext)

    const props = {
        nameFields: 'roleIds',
        list: roleList,
        checkedIds: user.roleIds,
        control,
        setValue,
    }

    return ( 
        <WrapFilterList {...props}/>
    )
}

export default Roles