import { Alert, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Stack, SvgIcon, TextField } from "@mui/material"
import React, { useRef, useState } from "react"
import PropTypes from 'prop-types'
import { ReactComponent as SearchIcon } from "../../../../Icons/desktop/form/SearchIcon.svg"
import { ReactComponent as EditIcon } from "../../../../Icons/desktop/form/EditIcon.svg"
import { ReactComponent as DelIcon } from "../../../../Icons/desktop/form/DelIcon.svg"
import MyPagination from "../../MyPagination/MyPagination"
import { ModalContextProvider, useModal } from "../../../../hooks/useModal/useModal"
import Area from "../components/Area/Area"
import { useAreasQuery, useDelAreaMutation } from "../../../../hooks/reactQuery/useArea"
import { pxToRem } from "../../../Style/desktopTheme"
import FormContainer from "../../ForForms/FormContainer"

const EditAreaButton = ({ areaId }) => {

    const { openWindow } = useModal()

    const editArea = (areaId) => {
        openWindow({
            title: 'Территориальное ограничение',
            children: <Area areaId={areaId} />,
            backdropOpacity: 0.2,
            width: 600,
            isCloseBackdropClick: true,
        })
    }
    return <IconButton onClick={() => editArea(areaId)}><SvgIcon style={{ fontSize: pxToRem('16px') }}><EditIcon /></SvgIcon></IconButton>
}

EditAreaButton.propTypes = {
    areaId: PropTypes.number.isRequired,
}


const Areas = () => {

    const [term, setTerm] = useState('')    // фильтр
    const [pageNumber, setPageNumber] = useState(1)
    const limit = 10    // кол-во

    const inputRef = useRef()
    const { openConfirm } = useModal()

    const { data: { total, areas } = {}, isLoading, error } = useAreasQuery({ term, offset: (pageNumber - 1) * limit, limit })
    const { mutate: delArea } = useDelAreaMutation()

    const removeArea = (areaId) => {
        openConfirm({
            title: 'Подтверждение',
            children: 'Вы действительно желаете удалить ограничение?',
            cancelText: 'Отмена',
            confirmText: 'Применить',
            onConfirm: () => delArea({ areaId }),
        })
    }

    if (isLoading) return null

    if (error) {
        return <Alert severity="error">{error.message}</Alert>
    }

    return (
        <FormContainer
            action={<MyPagination
                count={Math.ceil(total / limit)}
                page={pageNumber}
                siblingCount={2}
                onChange={(e, page) => {
                    setPageNumber(page)
                }}
            />}

        >
            <TextField
                inputRef={inputRef}
                fullWidth
                var="small" // my attribute in desktop theme
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={() => setTerm(inputRef.current.value)} >
                            <SvgIcon style={{ fontSize: pxToRem('16px') }}><SearchIcon color="inherit" /></SvgIcon>
                        </IconButton>
                    )
                }}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        setTerm(inputRef.current.value)
                    }
                }}
            />

            <List variant='var1' className='overflow'>
                {areas.map((a, index) => {
                    return (
                        <React.Fragment key={a.name + a.id}>
                            <Divider />
                            <ListItemButton>
                                <ListItem
                                    secondaryAction={
                                        <Stack direction='row' spacing={1}>

                                            <ModalContextProvider>
                                                <EditAreaButton areaId={a.id} />
                                            </ModalContextProvider>

                                            <IconButton onClick={() => removeArea(a.id)}><SvgIcon style={{ fontSize: pxToRem('16px') }}><DelIcon /></SvgIcon></IconButton>
                                        </Stack>
                                    }
                                >
                                    <ListItemText
                                        primary={a.name}
                                        primaryTypographyProps={{ variant: 'font_24' }}
                                    />
                                </ListItem>
                            </ListItemButton>
                            {index === areas.length - 1 && <Divider />}
                        </React.Fragment>
                    )
                })}
            </List>
        </FormContainer>
    )
}

export default Areas