import { Stack } from '@mui/material';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { userRoleSelector } from '../../../store/selectors/selectors';
import MyTabs from '../MyTabs/MyTabs';
import GalleryIcons from './Tabs/Icons/GalleryIcons';
import { ModalWindowContext } from '../../../hooks/useModal/ModalWindow';


const Gallery = () => {

    const { isAdministrator } = useSelector(userRoleSelector)
    const { onClose } = useContext(ModalWindowContext)

    if (!isAdministrator) {
        onClose()
    }

    const arrTabs = [
        {
            label: 'Иконки',
            value: <GalleryIcons/>
        },
        {
            label: 'Категории',
            value: <></>
        },
    ]

    return (
        <>
            <Stack spacing={2} className='overflow'>
                <MyTabs arrTabs={arrTabs} var='var1' />
            </Stack>
        </>
    )
}

export default Gallery