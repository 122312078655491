const initialState = {
    login: null,
    isAuth: false,
    isLoading: false,
    error: ""
}

export const AUTH_SET_IS_AUTH = "AUTH_SET_IS_AUTH";
export const AUTH_SET_LOADING = "AUTH_SET_LOADING";
export const AUTH_SET_ERROR = "AUTH_SET_ERROR";

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case AUTH_SET_IS_AUTH:
            return {
                ...state,
                login: action.payload.username ? action.payload.username : state.login, 
                isAuth: action.payload.isAuth,
                isLoading: false
            };
        case AUTH_SET_LOADING:
            return { ...state, isLoading: action.payload };
        case AUTH_SET_ERROR:
            return { ...state, error: action.payload, isLoading: false };
        default:
            return state;
    }
}