import { Box, Button, Stack } from '@mui/material'
import ModalTemplate from './ModalTemplate'



// для использования в связке с hook useModal https://dev.to/lico/react-ts-how-i-manage-modal-components-custom-modals-hook-4nmg
const ModalConfirm = ({ visible = false, onClose, title, children, cancelText, onCancel, confirmText, onConfirm }) => {
    // console.log({onClose});

    const handleCancel = () => {
        onCancel?.()
        onClose?.()
    }

    const handleConfirm = () => {
        onConfirm?.()
        onClose?.()
    }

    const props = {
        visible,
        onClose,
        title,
    }
    return (
        <ModalTemplate {...props}>
            <Stack spacing={3}>
                <Box>{children}</Box>

                <Stack direction='row' spacing={3} justifyContent='center'>
                    <Button variant='contained' onClick={handleConfirm}>{confirmText}</Button>
                    <Button variant='outlined' onClick={handleCancel}>{cancelText}</Button>
                </Stack>
            </Stack>


        </ModalTemplate>
    )
}

export default ModalConfirm