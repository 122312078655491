export const SYSTEM_SET_LAST_PAGE = "SYSTEM_SET_LAST_PAGE"
export const SYSTEM_SHOW_MASK = "SYSTEM_SHOW_MASK"
export const SYSTEM_SET_VIEW_SELECTED_LAYERS = "SYSTEM_SET_VIEW_SELECTED_LAYERS"
export const SYSTEM_RESET = "SYSTEM_RESET"

const initialState = {
    lastPage: '',       // for mobVersion крайняя открытая страница до клика на объект
    viewSelectedLayers: false // состояние определяемой кнопкой Отобразить выделенные слои
}

export default function systemReducer(state = initialState, action) {
    switch (action.type) {
        case SYSTEM_SET_LAST_PAGE:
            return { ...state, lastPage: action.payload }

        case SYSTEM_SET_VIEW_SELECTED_LAYERS:
            return { ...state, viewSelectedLayers: action.payload }

        case SYSTEM_RESET:
            return initialState

        default:
            return state
    }
}
