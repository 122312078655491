import React, { useContext, useEffect, useRef } from 'react';
import { Box, Button, Paper, SvgIcon, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import { useForm, Controller, useFieldArray } from "react-hook-form"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { trim } from 'lodash';
import MaskProgress from '../../../../general/Info/MaskProgress';
import { DOWNLOAD_RESOURCE_PATH } from '../../../../../config';
import { ReactComponent as PlusIcon } from '../../../../../Icons/desktop/propLayer/PlusIcon.svg'
import { reorderByPosition, sortByPosition } from '../../../../../service/function';
import { TableCellHeadTags, TableContainerTags } from '../../../StyledComponents/propertyTags';
import { LayerPropertyContext } from '../../LayerPropertyContext';
import { useModal } from '../../../../../hooks/useModal/useModal';
import { useUpdateLayerTagsMutation } from "../../../../../hooks/reactQuery/useLayer"
import { uploadResource } from '../../../../../service/axiosFuncQuery/othersQuery';
import { pxToRem } from '../../../../Style/desktopTheme';
import Row from './Row';
import FormContainer from '../../../ForForms/FormContainer';



const RequiredTags = () => {

    const bottomRef = useRef() // для скроллинга вниз после добавления нового элемента

    const { propsLayer: { editor }, layer, formStateRef } = useContext(LayerPropertyContext)
    const { openAlert } = useModal()
    const { mutate: updateLayerTags } = useUpdateLayerTagsMutation()

    const layerTags = sortByPosition(layer.tags)
    /* used react-hook-form dynamical fields */
    const { control, handleSubmit, watch, getValues, setValue, formState: { isSubmitting } } = useForm({
        defaultValues: {
            tags: formStateRef.current['requiredFields'] ? formStateRef.current['requiredFields'].tags : layerTags,
        },
        mode: 'onSubmit'
    })

    const { append, remove } = useFieldArray({ name: 'tags', control })
    const tags = watch('tags')

    const isMounted = useRef() // пропускаем монтирование компонента
    useEffect(() => {
        if (isMounted.current) {
            // при изменении layerTags - обновляем tags формы и formStateRef
            formStateRef.current['requiredFields'] = layerTags
            setValue('tags', layerTags)
            console.log({ layerTags });
        } else {
            isMounted.current = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layerTags])

    const onSubmit = async (data) => {
        // убираем незаполненные.Если не заполнен только тег - то устанавливаем ему дефолтное значение
        const newTags = [...data.tags
            .filter(tag => {
                if (tag.id) return true
                if (trim(tag.key) === '' && trim(tag.value) === '') return false
                return true
            })
            .map(tag => {
                if (tag.key === '') tag.key = 'Тег'
                return tag
            })
        ]
        updateLayerTags({ layerId: layer.id, data: newTags })
    }

    const addTag = () => {
        // для position находим максимальное значение position у имеющихся тегов и к нему добавляем 1
        let maxPosition = 1
        if (tags.length > 0) {
            tags.forEach(itemTag => {
                maxPosition = maxPosition > itemTag.position ? maxPosition : itemTag.position
            })
        }

        const newTag = { key: '', value: '', position: maxPosition + 1, type: 0, draggableId: `new${tags.length}` }
        append(newTag)
    }

    const uploadFile = (file, index) => {
        uploadResource(file[0])
            .then(response => {
                setValue(`tags.${index}.value`, DOWNLOAD_RESOURCE_PATH + response.data)

            })
            .catch(e => {
                openAlert({
                    title: 'Ошибка при загрузке файла',
                    children: e.message,
                    backdropOpacity: 0.2,
                    width: 600
                })
            })
    }

    const onDragEnd = (result) => {
        if (result.destination) {
            const startIndex = result.source.index
            const endIndex = result.destination.index
            setValue('tags', reorderByPosition(tags, startIndex, endIndex))
        }
    }

    // сохраняем значения полей формы при размонтировании компонента (при переходе на другую вкладку) 
    // Установку при монтировании - см. defaultValues
    useEffect(() => {
        return () => {
            formStateRef.current = {
                ...formStateRef.current,
                'requiredFields': getValues(),
            }
        }
    }, [formStateRef, getValues])

    const isInitialMount = useRef(true)
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            // scroll вниз после добавления элемента
            bottomRef.current?.scrollIntoView({ behavior: "smooth" })
        }
    }, [tags.length])


    return (
        <FormContainer
            action={
                editor && <>
                    <Controller
                        name='send'
                        control={control}
                        disabled={isSubmitting}
                        render={({ field }) =>
                            <Button
                                type='submit'
                                variant='contained'
                                onClick={handleSubmit(onSubmit)} {...field}
                            >Сохранить</Button>
                        }
                    />
                </>}
            disablePaddingRight={true}
            component="form"
            method='POST'
            onSubmit={handleSubmit(onSubmit)}
        >
            {editor && <Box><Button style={{ paddingLeft: '16px' }} variant='outlined' onClick={addTag}>
                <SvgIcon sx={{ fontSize: pxToRem('18px') }}><PlusIcon /></SvgIcon>
                &nbsp;Добавить тег</Button></Box>}

            <Box sx={{ overflow: 'auto', pr: pxToRem('32px') }}>
                <TableContainerTags component={Paper} >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCellHeadTags></TableCellHeadTags>
                                <TableCellHeadTags><Typography variant='font_16_24'>Тег</Typography></TableCellHeadTags>
                                <TableCellHeadTags><Typography variant='font_16_24'>Значение</Typography></TableCellHeadTags>
                                <TableCellHeadTags></TableCellHeadTags>
                            </TableRow>
                        </TableHead>

                        <DragDropContext onDragEnd={onDragEnd} >
                            <Droppable droppableId="droppable">
                                {(provided) => (
                                    <TableBody  {...provided.droppableProps} ref={provided.innerRef} >
                                        {tags.map((item, index) => {
                                            const props = {
                                                control,
                                                index,
                                                isEditor: editor,
                                                remove,
                                                uploadFile,
                                            }
                                            const draggableId = item.id ? item.id.toString() : item.draggableId
                                            return (
                                                <Draggable key={draggableId} draggableId={draggableId} index={index}>
                                                    {(provided) => {
                                                        // Запрещаем горизонтальное перемещение draggable объекта
                                                        var transform = provided.draggableProps.style.transform
                                                        if (transform) {
                                                            var t = transform.split(",")[1]
                                                            provided.draggableProps.style.transform = "translate(0px," + t
                                                            provided.draggableProps.style.left = 78
                                                        }
                                                        return (
                                                            <Row
                                                                key={item.id}
                                                                {...props}
                                                                innerRef={provided.innerRef} // ref приходится прокидывать отдельным свойством и устанавливать его у React.DomElement
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            />
                                                        )
                                                    }}
                                                </Draggable >
                                            )
                                        })}
                                        {provided.placeholder}
                                    </TableBody>
                                )}
                            </Droppable >
                        </DragDropContext >
                    </Table >
                </TableContainerTags>
                <Box ref={bottomRef} />
            </Box >
            {isSubmitting && <MaskProgress />}
        </FormContainer >
    )
}

export default RequiredTags