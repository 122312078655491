import { Box, Button, Divider, List, ListItem, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import BreadCrumbs from '../../general/BreadCrumbs/BreadCrumbs';
import { getChangeObjectsDate, getFormatDate } from '../../../service/function';
import { ApiPaths } from '../../../config';
import Link from '../../../hoc/LinkWithSearchParams'
import { usePreparePropsLayerQuery } from '../../../hooks/reactQuery/useLayer';
import { ModalWindowContext } from '../../../hooks/useModal/ModalWindow';
import useLayerById from '../../../hooks/reactQuery/selectorCache/useLayerById';
import useLayerHierarhy from '../../../hooks/reactQuery/selectorCache/useLayerHierarhy';


const NotAuthorized = () => {

    const { layerId } = useContext(ModalWindowContext)
    const layer = useLayerById(layerId)
    const layerHierarhy = useLayerHierarhy(layerId)
    const { data: preparePropsLayerData } = usePreparePropsLayerQuery({ layerId })

    if (!layer) return null

    return (
        <>
            <Stack variant='bottom_mlr'>
                <Typography component='h2' variant='font_32_40'>{layer.name}</Typography>
                <BreadCrumbs variant='margin' arrBreadCrumbsData={layerHierarhy} />

                <Stack spacing={2}>
                    <Divider variant='var1' />

                    <List style={{ width: '70%' }} >
                        <ListItem disableGutters
                            secondaryAction={<Typography variant='font_16_normal_008' >{getFormatDate(layer['createdDateTime'])}</Typography>}
                        >
                            <Typography variant='font_24'>Дата создания:</Typography>
                        </ListItem>

                        <ListItem disableGutters
                            secondaryAction={<Typography variant='font_16_normal_008' >{getFormatDate(layer['changedDateTime'])}</Typography>}
                        >
                            <Typography variant='font_24'>Дата изменения свойств слоя:</Typography>
                        </ListItem>

                        <ListItem disableGutters
                            secondaryAction={<Typography variant='font_16_normal_008' >{getChangeObjectsDate(preparePropsLayerData?.spatialDataWeight)}</Typography>}
                        >
                            <Typography variant='font_24'>Дата изменения объектов:</Typography>
                        </ListItem>
                    </List>

                    <Divider variant='var1' />

                    <Typography component='h4' variant='font_16_24'>Название ПД</Typography>
                    <Typography variant='font_24' component='p'>{layer.descSpatialName}</Typography>

                    <Typography component='h4' variant='font_16_24'>Описание ПД</Typography>
                    <Typography variant='font_24' component='p'>{layer.descSpatialData}</Typography>

                    <Typography component='h4' variant='font_16_24'>Источник получения ПД</Typography>
                    <Typography variant='font_24' component='p'>{layer.sourceSpatialData}</Typography>

                    <Box><Button variant='outlined' component={Link} target='_blank' to={ApiPaths.others.EDITOR_LAYERS.replace(':layerId', layerId)}>Редакторы слоя</Button></Box>
                </Stack>
            </Stack>
        </>
    )
}

export default NotAuthorized