import React, { useContext } from 'react';
import { AreaContext } from '../AreaContext';
import WrapFilterList from '../../WrapFilterList';


const Roles = () => {

    const { prepareAreaDialog: { area, roleList }, control, setValue } = useContext(AreaContext)

    const props = {
        nameFields: 'roleIds',
        list: roleList,
        checkedIds: area.roleIds,
        control,
        setValue,
    }
    return (
        <WrapFilterList {...props}/>
    )
}

export default Roles