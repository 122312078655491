import {
    Box, IconButton, Modal, Stack, SvgIcon, Typography,
    styled
} from '@mui/material'
import React from 'react'
import { ReactComponent as CloseIcon } from "../../Icons/CloseIcon.svg"
import { pxToRem } from '../../components/Style/desktopTheme'


export const WrapStack = styled(Stack)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    outlineColor: 'white',
    // -webkit-box-sizing: border-box;
    // -moz-box-sizing: border-box;
    boxSizing: 'border-box',
    border: 0,
    borderRadius: pxToRem('28px'),
})

const ModalTemplate = ({ title, visible, onClose, backdropOpacity, width, children }) => {
    const opacity = backdropOpacity ? backdropOpacity : 0.6

    const paddingStyle = {
        paddingLeft: pxToRem('32px'),
        paddingRight: pxToRem('22px'),
    }

    return (
        <Modal
            open={visible}
            onClose={onClose}
            slotProps={{
                backdrop: {
                    style: {
                        backgroundColor: `rgba(0, 0, 0, ${opacity})`,
                    }
                }
            }}
        >
            <WrapStack
                style={{
                    width: width ? width : 'auto',
                    paddingTop: pxToRem('24px'),
                    paddingBottom: pxToRem('32px'),
                    minWidth: pxToRem('300px'),
                    maxHeight: `calc(100% - ${pxToRem('48px')})`,
                }}
                spacing={3}
            >

                <Stack style={paddingStyle} direction='row' alignItems='center' justifyContent='space-between' >
                    {title && <Typography component="h1" variant='font_22_28'>{title}</Typography>}
                    {onClose && <IconButton sx={{ padding: 0 }} onClick={onClose}><SvgIcon sx={{ fontSize: pxToRem('32px') }}><CloseIcon /></SvgIcon></IconButton>}
                </Stack>

                <Box style={paddingStyle}>
                    {children}
                </Box>
            </WrapStack>
        </Modal>
    )
}

export default ModalTemplate