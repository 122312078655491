import { Checkbox, CircularProgress, Stack, styled, Typography } from '@mui/material';
import React, { createContext, useContext } from 'react';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import PropTypes from 'prop-types'
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { ReactComponent as CollapseIcon } from '../../../Icons/desktop/treeListLayers/CollapseIcon.svg'
import { ReactComponent as ExpandIcon } from '../../../Icons/desktop/treeListLayers/ExpandIcon.svg'
import { ReactComponent as LayerIcon } from "../../../Icons/layer/ServiceLayerIcon.svg";
import { useTreeListLayersQuery } from '../../../hooks/reactQuery/useTreeListLayers'
import { SimpleTreeView } from '@mui/x-tree-view';
import { Controller } from 'react-hook-form';

const TreeListLayersCheckedContext = createContext()

const StyledCheckbox = styled(Checkbox)({
    padding: '0px',
    '& .MuiSvgIcon-root': { fontSize: 20 }
})


const ItemLabel = ({ id, name, selected, isService, parentId, disabledLayerIds }) => {

    const { field } = useContext(TreeListLayersCheckedContext)
    return (
        <Stack direction='row' alignItems='center' spacing={1.5} >

            {parentId && !isService &&
                <StyledCheckbox
                    {...field}
                    onChange={(e, checked) => {
                        let resultValue = field.value
                        if (checked) {
                            resultValue = [...resultValue, id]
                        } else {
                            resultValue = resultValue.filter(v => v !== id)
                        }
                        field.onChange(resultValue)
                        e.stopPropagation()
                    }}
                    disabled={disabledLayerIds.includes(id)}
                    checked={field.value.includes(id)}
                    onClick={(e) => e.stopPropagation()}
                />}

            {(parentId && !isService)
                ? <WysiwygIcon />
                : <LayerIcon />
            }
            <Typography
                style={selected ? { fontWeight: 600 } : {}}
                var={12}>{name}</Typography>
        </Stack>
    )
}

const StyledTreeItem = styled(TreeItem)({
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px solid #D9D9D9;`,
    },
})


const ItemLayer = ({ treeList, disabledLayerIds }) => {
    return treeList.map(l => {
        const key = 'layer-' + l.id
        return (
            <StyledTreeItem
                key={key}
                itemId={l.id.toString()}
                label={
                    <ItemLabel
                        id={l.id}
                        name={l.text}
                        disabledLayerIds={disabledLayerIds}
                        isService={l.isService}
                        parentId={l.parentId} />
                }
                disabled={disabledLayerIds.includes(l.id)}
            >
                {l.children.length > 0 && <ItemLayer treeList={l.children} disabledLayerIds={disabledLayerIds} />}
            </StyledTreeItem>
        )
    })
}


const TreeListLayersChecked = ({ control, disabledLayerIds = [], ...props }) => {
    const { data: treeList, isLoading } = useTreeListLayersQuery()

    if (isLoading) {
        return (
            <Stack alignItems='center' justifyContent='center'>
                <CircularProgress />
            </Stack>
        )
    }

    return (
        <Stack className='overflow' variant='mlr'>
            <SimpleTreeView
                {...props}
                slots={{
                    collapseIcon: CollapseIcon,
                    expandIcon: ExpandIcon,
                }}
            >
                <Controller
                    name='targetIds'
                    control={control}
                    render={({ field }) => (
                        <TreeListLayersCheckedContext.Provider value={{ field }}>
                            <ItemLayer {...{ treeList, disabledLayerIds }} />
                        </TreeListLayersCheckedContext.Provider>
                    )}
                />
            </SimpleTreeView>
        </Stack>
    )
}

TreeListLayersChecked.propTypes = {
    control: PropTypes.object.isRequired,
    disabledLayerIds: PropTypes.arrayOf(PropTypes.number)
}

ItemLayer.propTypes = {
    treeList: PropTypes.array.isRequired,
    disabledLayerIds: PropTypes.array,
}

ItemLabel.propTypes = {
    id:PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    isService:PropTypes.bool.isRequired,
    parentId: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
    disabledLayerIds: PropTypes.arrayOf(PropTypes.number)
}

export default TreeListLayersChecked