import { useSelector } from "react-redux"
import { mapArcGisSelector } from "../../../store/selectors/selectors"
import Loader from "../../desktop/Loader/Loader";
import MyAlert from "../Info/MyAlert";
import { Button, IconButton, List, ListItem, ListItemButton, ListItemText, Stack, SvgIcon, TextField } from "@mui/material";
import { pxToRem } from "../../Style/desktopTheme";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as FilterIcon } from '../../../Icons/desktop/filterObjects/FilterObjectsIcon.svg'
import { ReactComponent as CloseIcon } from "../../../Icons/search/CloseIcon.svg"


const sxTextField = {
    '& .MuiOutlinedInput-root': {
        pr: '4px'
    }
}

const StackTextFieldSx = { mt: pxToRem('16px'), ml: pxToRem('16px'), mr: pxToRem('16px'), mb: pxToRem('26px') }


const ArcGisObjects = ({ handleClickObject }) => {

    const objectId = Number(useParams().objectId)
    const { error, isLoading, data } = useSelector(mapArcGisSelector)
    const lat = Number(useParams().lat)
    const lng = Number(useParams().lng)

    // filter
    const inputRef = useRef()
    const [term, setTerm] = useState('')

    const initialCount = 50
    const [itemCount, setItemCount] = useState(initialCount)

    useEffect(() => {
        setItemCount(initialCount)
        setTerm('')
    }, [lat, lng])

    if (isLoading) return <Loader />
    if (error) return <MyAlert severity='error' title='Ошибка' message={error} />

    /* для отображения данных используются два набора данных в data:
    - data.featureCollection.features
    - data.results
    По сути это одни и те же данные, но скомпонованные по разному. Например data.results содержит поля displayFieldName и value
    - их мы выводим в название. А вот в id в attributes имеют разные ключи у разных слоев (где-то OBJECT_ID, FID и др)
    Зато data.featureCollection.features содержит уникальный id c key=id
    Поэтому и приходится их использовать совместно. И фильтровать тоже.
    Фильтровать буду data.result. Получаю индексы элементов, что соответствуют условию фильтра, и по этим индексам 
    уже фильтрую data.featureCollection.features - чтобы оба эти массива соответствовали друг другу
    */
    let features = data.featureCollection?.features
    let results = data.result

    if (term !== '') {
        let filteredIndex = []
        results = results.filter((o, index) => {
            const str = o.displayFieldName + ' ' + o.value
            if (str.toLowerCase().indexOf(term.toLowerCase()) >= 0) {
                filteredIndex.push(index)
                return true
            } else return false
        })

        features = features.filter((_, index) => filteredIndex.includes(index))
    }

    return (
        <>
            <Stack sx={StackTextFieldSx}>
                <TextField
                    inputRef={inputRef}
                    sx={sxTextField}
                    placeholder="Ключевые слова..."
                    InputProps={{
                        endAdornment: (
                            <>
                                {term.trim() !== '' &&
                                    <IconButton size={'40px'} onClick={() => {
                                        setTerm('')
                                        inputRef.current.value = ''
                                    }}>
                                        <SvgIcon inheritViewBox><CloseIcon fill={'black'} /></SvgIcon>
                                    </IconButton>
                                }

                                <IconButton onClick={() => setTerm(inputRef.current.value)}><FilterIcon /></IconButton>
                            </>
                        )
                    }}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                            setTerm(inputRef.current.value)
                        }
                    }}
                />
            </Stack>

            <Stack sx={{ overflow: 'auto', }}>
                <List
                    sx={{
                        backgroundColor: 'myGray.light1',
                        padding: pxToRem('16px')
                    }}
                >
                    {results
                        ?.slice(0, itemCount)
                        ?.map((o, index) => {
                            const itemId = features[index].id
                            const isSelected = Number(itemId) === objectId
                            return (
                                <ListItem key={itemId}
                                    disableGutters
                                    disablePadding
                                    sx={{
                                        backgroundColor: isSelected ? 'myGreen.main' : 'white',
                                        borderRadius: pxToRem('12px'),
                                        mt: pxToRem('8px'),
                                        mb: pxToRem('8px'),
                                        '&:hover': {
                                            backgroundColor: isSelected ? 'myGreen.main' : 'myGray.main',
                                        }
                                    }}
                                    style={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                    }}
                                >
                                    <ListItemButton
                                        sx={{
                                            pt: pxToRem('12px'),
                                            pb: pxToRem('12px'),
                                            borderRadius: pxToRem('12px'),
                                        }}
                                        onClick={() => handleClickObject(itemId)}
                                    >
                                        <ListItemText primary={`${o.displayFieldName} ${o.value}`} sx={{
                                            color: isSelected ? 'white' : 'inherit',
                                            userSelect: 'text'
                                        }} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                </List>

                {results?.length > itemCount &&
                    <Button
                        onClick={() => setItemCount(v => v + initialCount)}
                        fullWidth variant='greenText' >
                        ({itemCount} из {results.length})
                        Показать еще
                    </Button>
                }

            </Stack>
        </>
    )
}

export default ArcGisObjects