export const USER_SET_DATA = "USER_SET_DATA"
export const USER_SET_LOADING = "USER_SET_LOADING"
export const USER_SET_ERROR = "USER_SET_ERROR"
export const USER_RESET = "USER_RESET"

const initialState = {
    data: {
        fullName: '',
        avatarUrl: '',
        role: {
            isAdministrator: undefined,
            isAnonymous: undefined,
            isEditor: undefined,
            isProjectEditor: undefined,
        },
        menu: []
    },
    isLoading: false,
    error: false,
}

const setData = (state, payload) => {    
    let newState = {
        ...state,
        isLoading: false,
        error: false,
        data: payload
    }
    return newState;
}


export default function systemReducer(state = initialState, action) {
    switch (action.type) {
        case USER_SET_LOADING:
            return { ...state, isLoading: action.payload, error: false }
        case USER_SET_ERROR:
            return { ...state, isLoading: false, error: action.payload }
        case USER_SET_DATA:
            return setData(state, action.payload)
        case USER_RESET:
            return initialState
        default:
            return state
    }
}
