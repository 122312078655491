import { Stack } from "@mui/material"
import MainPanel from "./MainPanel"
import SecondPanel from "./SecondPanel"
import FilterObjectsPage from "../../../pages/FilterObjectsPage"
import ToggleMainPanel from "./ToggleMainPanel"
import Category from '../../general/Category/Category'
import { DrawerContext } from "../SidePanel/SideContextProvider"
import { useContext } from "react"
import { Outlet, useMatch } from "react-router-dom"
import { RoutePaths } from "../../../routes"
import ArcGisObjectsPage from "../../../pages/ArcGisObjectsPage"


const SidePanel = () => {

    const { mainDrawer } = useContext(DrawerContext)

    let isFilterObjectPage = useMatch(RoutePaths.FILTER_OBJECTS)
    let isFilterObjectDetailPage = useMatch(RoutePaths.FILTER_OBJECTS_DETAIL)
    const isFilterObject = isFilterObjectPage || isFilterObjectDetailPage

    const isHomePage = useMatch(RoutePaths.HOME)
    const isArcGisPage = useMatch(RoutePaths.ARCGIS)

    if (isFilterObject) return (
        <>
            <MainPanel>
                <FilterObjectsPage />
            </MainPanel>

            {isFilterObjectDetailPage && <SecondPanel><Outlet /></SecondPanel>}
        </>
    )

    if (isArcGisPage) return (
        <>
            <MainPanel>
                <ArcGisObjectsPage />
            </MainPanel>

            <SecondPanel><Outlet /></SecondPanel>
        </>
    )

    return (
        <>
            <MainPanel>
                <ToggleMainPanel />
                <Category columns={mainDrawer ? 4 : 1} />
            </MainPanel>

            {!isHomePage && <SecondPanel><Outlet /></SecondPanel>}
        </>
    )
}

const SidePanelWrap = () => <Stack direction='row'><SidePanel /></Stack>

export default SidePanelWrap