import useDeviceDetect from "../hooks/useDeviceDetect"
import { Box, Grid, IconButton, Stack, SvgIcon } from "@mui/material"
import Title from "../components/general/Title/Title"
import { ReactComponent as CloseIcon } from "../Icons/desktop/modal/CloseIcon.svg"
import { pxToRem } from "../components/Style/desktopTheme"
import Link from '../hoc/LinkWithSearchParams'
import { DrawerContext } from "../components/desktop/SidePanel/SideContextProvider"
import { useContext, useState } from "react"
import { getCookie, isBoolean } from "../service/function"
import { RoutePaths } from "../routes"
import { ErrorFallback } from "../components/desktop/ErrorBoundary/ErrorFallback"
import ArcGisObjects from "../components/general/ArcGisObjects/ArcGisObjects"
import { ErrorBoundary } from "react-error-boundary"
import { useParams } from "react-router-dom"
import useNavigate from '../hooks/useNavigateWithSearchParams'
import Header from "../hoc/Header"
import MobTemplateHeaderToolbar from "../components/general/HeadToolBar/MobTemplateHeaderToolbar"
import ArcGisObjectPage from "./ArcGisObjectPage"


const ArcGisObjectsPage = () => {

    const { isMobile } = useDeviceDetect()
    return (
        isMobile
            ? <MobArcGisObjectsPage />
            : <DesktopArcGisObjectsPage />
    )
}


const MobArcGisObjectsPage = () => {
    const navigate = useNavigate()

    const layerId = Number(useParams().layerId)
    const lat = Number(useParams().lat)
    const lng = Number(useParams().lng)

    const [isObjectPage, setIsObjectPage] = useState(false)

    const handleClickObject = (objectId) => {
        navigate(RoutePaths.ARCGIS
            .replace(':layerId', layerId)
            .replace(':objectId', objectId)
            .replace(':lat', lat)
            .replace(':lng', lng)
        )
        setIsObjectPage(true)
    }

    return (
        <>
            {isObjectPage && <ArcGisObjectPage handleClose={() => setIsObjectPage(null)} />}

            <Box style={{ display: isObjectPage ? 'none' : 'block' }}>

                {!isObjectPage &&
                    <Header>
                        <MobTemplateHeaderToolbar title={{ name: 'Список объектов ArcGis' }} handleClose={() => navigate(RoutePaths.HOME)} />
                    </Header>
                }

                <ArcGisObjects handleClickObject={handleClickObject} />
            </Box>
        </>
    )
}


const DesktopArcGisObjectsPage = () => {

    const navigate = useNavigate()
    const layerId = Number(useParams().layerId)
    const lat = Number(useParams().lat)
    const lng = Number(useParams().lng)
    const { mainDrawer, setMainDrawer } = useContext(DrawerContext)

    const handleClose = () => {
        const open_cat = isBoolean(getCookie('open_cat'))
        if (mainDrawer !== open_cat) {
            setMainDrawer(Boolean(open_cat))
        }
    }

    const handleClickObject = (objectId) => {
        navigate(RoutePaths.ARCGIS
            .replace(':layerId', layerId)
            .replace(':objectId', objectId)
            .replace(':lat', lat)
            .replace(':lng', lng)
        )
    }

    return (
        <>
            <Stack sx={{ mt: pxToRem('24px'), ml: pxToRem('16px'), mr: pxToRem('16px'), mb: pxToRem('16px') }} spacing={2}>
                <Grid container direction='row' justifyContent='space-between'>
                    <Grid item><Title title='Список объектов ArcGis' /></Grid>
                    <Grid item><IconButton component={Link} onClick={handleClose} to={RoutePaths.HOME} sx={{ p: 0 }}  >
                        <SvgIcon style={{ fontSize: pxToRem('32px') }} inheritViewBox><CloseIcon /></SvgIcon>
                    </IconButton></Grid>
                </Grid>
            </Stack>

            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <ArcGisObjects handleClickObject={handleClickObject} />
            </ErrorBoundary>
        </>
    )
}

export default ArcGisObjectsPage