import { Typography } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
import { pxToRem } from '../../Style/desktopTheme'
import styles from './CategoryName.module.scss'
import useDeviceDetect from '../../../hooks/useDeviceDetect'


// Обрезаем строки по дизайну
const MyTypography = (props) => {
    const { isMobile } = useDeviceDetect()
    return <Typography variant={isMobile ? 'font_11' : 'font_12'} component='div' {...props} />
}

const CategoryName = ({ name = '', isLoading }) => {

    if (isLoading) {
        return (
            <Skeleton width={pxToRem('80')} enableAnimation={true} />
        )
    }

    if (name.toLowerCase() === "здравоохранение") {
        return <MyTypography className={styles.name + ' ' + styles.ellipsis} style={{ minWidth: '79px', }}>{name}</MyTypography>
    }
    if (name.toLowerCase() === "доступная среда") {
        return <MyTypography className={styles.name}>Доступная<wbr /> <span>среда</span></MyTypography>
    }
    if (name.toLowerCase() === "образование") {
        return <MyTypography className={styles.name}><span>{name}</span></MyTypography>
    }
    if (name.toLowerCase() === "госуслуги и учреждения") {
        return <MyTypography className={styles.name}>Госуслуги<wbr /> <span>и учреждения</span></MyTypography>
    }
    if (name.toLowerCase() === "экстренные службы и безопасность") {
        return <MyTypography className={styles.name} style={{ minWidth: '83px', }}>Экстренные<wbr /> <span>службы</span><wbr /> <span className={styles.ellipsis} style={{ display: 'block', }}>и безопасность</span></MyTypography>
    }
    if (name.toLowerCase() === "проекты правительства яо") {
        return <MyTypography className={styles.name}>Проекты<wbr /> <span className={styles.ellipsis} style={{ display: 'block', }}>Правительства</span> ЯО</MyTypography>
    }
    if (name.toLowerCase() === "земля и имущество") {
        return <MyTypography className={styles.name}>Земля <span>и имущество</span></MyTypography>
    }
    if (name.toLowerCase() === "градостроительство") {
        return <MyTypography className={styles.name + ' ' + styles.ellipsis}>{name}</MyTypography>
    }
    if (name.toLowerCase() === "культура и искусство") {
        return <MyTypography className={styles.name}>Культура <span>и искусство</span></MyTypography>
    }
    if (name.toLowerCase() === "туризм, спорт и досуг") {
        return <MyTypography className={styles.name}><span>Туризм, спорт</span><wbr /> и досуг</MyTypography>
    }
    if (name.toLowerCase() === "природа и экология") {
        return <MyTypography className={styles.name}>Природа <span>и экология</span></MyTypography>
    }
    if (name.toLowerCase() === "территориальное деление") {
        return <MyTypography className={styles.name + ' ' + styles.ellipsis} style={{ minWidth: '77px' }}><span>Территориальное</span><wbr /> <span>деление</span></MyTypography>
    }
    if (name.toLowerCase() === "пространственные сведения") {
        return <MyTypography className={styles.name + ' ' + styles.ellipsis} style={{ minWidth: '77px' }}>Пространственные<wbr /> сведения</MyTypography>
    }
    if (name.toLowerCase() === "карта развития") {
        return <MyTypography className={styles.name + ' ' + styles.ellipsis} style={{ minWidth: '77px' }}>Карта<wbr /> развития</MyTypography>
    }
    if (name.toLowerCase() === "муниципальная информация") {
        return <MyTypography className={styles.name + ' ' + styles.ellipsis} style={{ minWidth: '82px' }}><span>Муниципальная</span><wbr /> информация</MyTypography>
    }
    else {
        return <MyTypography className={styles.name}>{name}</MyTypography>
    }
}

export default CategoryName