export const GEOPORTAL_URL = process.env.REACT_APP_GEOPORTAL_URL;

export const MOBILE_WIDTH = 600

// react Query staleTime
export const TIME_UPDATE = 5 * 60 * 1000;
export const SEARCH_TIME_UPDATE = 30 * 1000;    // Время обновления кеша поиска
export const OBJECT_TIME_UPDATE = 'Infinity'
export const MOB_TEMPLATES_TIME_UPDATE = 5 * 60 * 1000

// Map
export const CLUSTERING_AMOUNT = 50;    // если объектов на карте больше данного кол-ва, то применяется кластеризация

/***  Mobile  ***/
export const MAX_LOAD_OBJECTS = 2000; // limit max load objects on Layer

// BottomSheet - высота шторки
export const CLOSE_HEIGHT_BOTTOMSHEET = 30;
export const HOME_HEIGHT_BOTTOMSHEET = 154;
export const SMALL_HEIGHT_BOTTOMSHEET = 100;
export const MEDIUM_HEIGHT_BOTTOMSHEET = ({ maxHeight }) => maxHeight * 0.4;
export const MAX_HEIGHT_BOTTOMSHEET = ({ maxHeight }) => maxHeight - 86;


/***  Desktop  ***/
export const IMG_LAYER_PATH = '/img_layer/';
export const DOWNLOAD_RESOURCE_PATH = '/integration/export/download_resource/';

export const OPEN_WIDTH_MAIN_PANEL = 416
export const CLOSE_WIDTH_MAIN_PANEL = 72
export const OPEN_WIDTH_SECOND_PANEL = 416

/*** Icons Size ***/
export const ICON_SIZE_CIRCLE = [28, 28]
export const ICON_ANCHOR_CIRCLE = [14, 14]
export const ICON_SIZE_DROP = [34, 43]
export const ICON_ANCHOR_DROP = [17, 43]

// gallery icons
export const ORIG_TREE_ICON_SIZE = 34
export const ORIG_ICON_SIZE = 24


/*** REST PATH ***/
export const ApiPaths = {
    layer: {
        LAYER: '/layer/get/:layerId',
        LAYERS: '/layer/level/:layerId',
        CATEGORIES: '/layer/categories',
        PARENT_METADATA: '/layer/metadataparent/:parentId',     // для функционала Скопировать с родителя
        PERMISSIONS: '/layer/get/permissions/:layerId',
        PREPARE_PROPS: '/layer/prop/additional_data/:layerId',   // доп. данные для отображения формы свойств Слоя
        UPDATE: '/layer/update',
        METADATA_UPDATE: '/layer/metadataupdate',
        PERMISSIONS_UPDATE: '/permission/layer/update/:layerId',
        TAGS_UPDATE: '/layer/updatetags/:layerId',
        PICTURE_BOUNDS_UPDATE: '/layer/update_picture_bounds',
        DELETE: '/layer/remove/:layerId',
        MOVE_TO: '/layer/move_to_layer',
        COPY_TO: '/layer/copy_to_layer',
        LAYER_HIERARHY: '/layer/selected_hierarhy/:layerId',
        TREE_LIST: '/layer/tree_list',
        HEATMAP_UPDATE: '/layer/update_heatmap',
        COPY_PROPS_TO_LAYER: '/layer/copy_props_to_layer',
    },
    object: {
        OBJECT: '/geo/get/:objectId',
        OBJECTS_TO_LAYER: '/geo/list_by_layer_id?layerId=:layerId', // Список объектов
        FILTER_OBJECTS_TO_LAYER: '/geo/list_by_layer_id_filtered',   // filter objects
        PREPARE_PROPS_OBJECT: '/geo/prepare_props_object/:objectId',     // доп. данные для отображения формы свойств объекта
        HISTORY: '/geo/get/history/:objectId?revId=:revId',
        REVERT: '/geo/revert',
        PROPERTIES: '/geo/getpropertiesbyid',
        CREATE: '/geo/gis_objects_create',
        UPDATE: '/geo/update',
        UPDATE_OBJECTS_GIS: '/geo/gis_objects_update',
        DELETE_OBJECTS_FROM_LAYER: '/geo/gis_objects_delete',
        DELETE_ALL_OBJECTS_FROM_LAYER: '/geo/gis_objects_delete_by_layer',
        ADD_OBJECT_IN_LAYER: '/geo/add_to_layer?objId=:objectId&layerId=:layerId',
        CLONE_OBJECT_TO_LAYER: '/geo/clone_to_layer?objId=:objectId&layerId=:layerId',
        OBJECT_TAG_KEYS_BY_LAYER_ID: '/geo/object_tag_keys/:layerId',
    },
    search: {
        LAYERS: '/search/layers?term=:term',
        GEOCODER_SUGGESTIONS: '/geocoder/suggestions',
    },
    user: {
        METADATA: '/user/metadata',
    },
    icon: {
        FROM_LAYER: '/geo/flagicon/:layerId',
        FROM_OBJECT: '/geo/flagicon/object/:objectId',
        UPDATE_FLAGICON_OBJECT: '/geo/flagicon/updateIcon',
        TREEICON: '/layer/treeicon/:layerId',
        UPDATE_TREEICON_LAYER: '/layer/updateTreeIcon',
        UPDATE_ICON_LAYER: '/layer/updateIcon',
        UPDATE_ICON_SHAPE: '/layer/updateIconShape',
    },
    gallery: {
        LOAD_ICONS: '/gallery/load?term=:term&oldIcon=:oldIcon',
        LOAD_CATEGORIES: '/gallery/categories?oldIcon=:oldIcon',
        TREEICON: '/gallery/:iconId/treeicon.png',
        OLD_ICON: '/gallery/:iconId/icon.png',
        CIRCLE_ICON: '/gallery/:iconId/circle_icon.png',
        DROP_ICON: '/gallery/:iconId/drop_icon.png',
        ORIG_TREEICON: '/gallery/:iconId/orig_treeicon.svg',
        ORIG_ICON: '/gallery/:iconId/orig_icon.svg',
        UPLOAD_ICON: '/gallery/upload',
        REMOVE_ICON: '/gallery/delete?iconId=:iconId',
        LOAD_ICON: '/gallery/get?id=:iconId',
        TEMP_DIR_TREEICON: '/gallery/temp/:tempDir/treeicon.png',
        TEMP_DIR_DROPICON: '/gallery/temp/:tempDir/drop_icon.png',
        TEMP_DIR_CIRCLEICON: '/gallery/temp/:tempDir/circle_icon.png',
        TEMP_DIR_ORIG_TREEICON: '/gallery/temp/:tempDir/orig_treeicon.svg',
        TEMP_DIR_ORIG_ICON: '/gallery/temp/:tempDir/orig_icon.svg',
        ADD_ICON: '/gallery/add',
        UPDATE_ICON: '/gallery/update',
    },
    feedback: {
        LAYER: '/layer/feedback_send',
        OBJECT: '/geo/feedback_send',
    },
    templates: {
        MOBILE_TEMPLATE: '/templates/mobile/get/:mobileTmplId',
        MOBILE_TEMPLATES: '/templates/mobile/list',
    },
    auth: {
        LOGIN: '/auth/login',
        REFRESH: '/auth/refresh',
        LOGOUT: '/logout'
    },
    admin: {
        user: {
            USERS: '/admin/users/sorted_list?term=:term&offset=:offset&limit=:limit',
            REMOVE_USER: '/admin/user/:userId',
            PREPARE_USER_DIALOG: '/admin/rest/user/:userId',
            PREPARE_NEW_USER_DIALOG: '/admin/rest/user/new',
            SAVE_USER: '/admin/rest/user/:userId',
        },
        role: {
            ROLES: '/admin/role/sorted_list?term=:term&offset=:offset&limit=:limit',
            REMOVE_ROLE: '/admin/role/:roleId',
            PREPARE_ROLE_DIALOG: '/admin/rest/role/:roleId',
            PREPARE_NEW_ROLE_DIALOG: '/admin/rest/role/new',
            SAVE_ROLE: '/admin/rest/role/:roleId',
        },
        area: {
            AREAS: '/admin/areas/sorted_list?term=:term&offset=:offset&limit=:limit',
            REMOVE_AREA: '/admin/area/:areaId',
            PREPARE_AREA_DIALOG: '/admin/rest/area/:areaId',
            SAVE_AREA: '/admin/rest/area/:areaId'
        }
    },
    others: {
        EDITOR_LAYERS: '/layer/metadata/:layerId/view/editors', // редакторы слоя (ведет на desktop)
        PERMISSION_ALL_USERS: '/permission/all_users',
        PERMISSION_ALL_ROLES: '/permission/all_roles',
        UPLOAD_RESOURCE: '/integration/import/upload_resource/',
    },
}