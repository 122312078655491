import { getLayerHierarhyFn } from "../../../store/selectors/functions"
import useInCacheLayers from "./useInCacheLayers"

const useLayerHierarhyList = (layerIds) => {
    const allLayersNormalized = useInCacheLayers({ normalize: true })
    let results = {}
    
    layerIds.forEach(id => {
        results = {
            ...results,
            [id]: getLayerHierarhyFn(id, allLayersNormalized)
        }
    })
    return results
}

export default useLayerHierarhyList