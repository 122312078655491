import { Box, Button, Divider, FormLabel, Grid, Link, Stack, Typography } from '@mui/material';
import { getChangeObjectsDate, getFormatDate } from '../../../service/function';
import { ApiPaths } from '../../../config';
import { usePreparePropsLayerQuery } from '../../../hooks/reactQuery/useLayer';
import useLayerById from '../../../hooks/reactQuery/selectorCache/useLayerById';


const Property = (props) => {

    const { layerId } = props;
    const layer = useLayerById(layerId)
    const { data: preparePropsLayerData } = usePreparePropsLayerQuery({ layerId })

    if(!layer) return null
    
    return (
        <Grid container columns={1} rowSpacing={2} sx={{pl:'15px', pr:'15px', pt:'25px'}}>
                <Grid item xs={1}>
                    <Stack direction='row'><Typography variant='font_16_22' component={FormLabel}>Дата создания: </Typography>&nbsp;{getFormatDate(layer['createdDateTime'])}</Stack>
                </Grid>

                <Grid item xs={1}>
                    <Stack direction='row'><Typography variant='font_16_22' component={FormLabel}>Дата изменения свойств слоя:</Typography>&nbsp;{getFormatDate(layer['changedDateTime'])}</Stack>
                </Grid>

                <Grid item xs={1}>
                    <Stack direction='row'><Typography variant='font_16_22' component={FormLabel}>Дата изменения объектов:</Typography>&nbsp;{getChangeObjectsDate(preparePropsLayerData?.spatialDataWeight)}</Stack>
                </Grid>

                <Grid item xs={1}  >
                    <Divider variant='var1' />
                </Grid>

                <Grid item xs={1}  >
                    <Typography component='h4' variant='font_16_normal_008' sx={{pb:'7px', bold: 600}}>Название ПД</Typography>
                    <Typography variant='font_16_22' component='p'>{layer.descSpatialName}</Typography>
                </Grid>

                <Grid item xs={1}  >
                    <Typography component='h4' variant='font_16_normal_008' sx={{pb:'7px', bold: 600}}>Описание ПД</Typography>
                    <Typography variant='font_16_22' component='p'>{layer.descSpatialData}</Typography>
                </Grid>

                <Grid item xs={1}  >
                    <Typography component='h4' variant='font_16_normal_008' sx={{pb:'7px', bold: 600}}>Источник получения ПД</Typography>
                    <Typography variant='font_16_22' component='p'>{layer.sourceSpatialData}</Typography>
                </Grid>

                <Grid item xs={1}  >
                    <Box><Button variant='outlined' component={Link} target='_blank' href={ApiPaths.others.EDITOR_LAYERS.replace(':layerId', layerId)}>Редакторы слоя</Button></Box>
                </Grid>
            </Grid>
    )
}

export default Property