import { loadUserData } from "../../../service/axiosFuncQuery/othersQuery"
import { USER_SET_DATA, USER_SET_LOADING, USER_SET_ERROR, USER_RESET } from "./index"


export const UserActionCreators = {
    setIsLoading: (isLoading) => {
        return {
            type: USER_SET_LOADING,
            payload: {
                isLoading
            }
        }
    },
    setError: (error) => {
        return {
            type: USER_SET_ERROR,
            payload: {
                error
            },
        }
    },
    setData: (data) => {
        return {
            type: USER_SET_DATA,
            payload: data,
        }
    },
    reset: () => {
        return {
            type: USER_RESET,
        }
    },
    loadUser: () => {
        return async (dispatch, getState) => {
            if(!getState().auth.isAuth) 
                return false
            
            try {
                dispatch(UserActionCreators.setIsLoading(true))
                const data = await loadUserData()
                dispatch(UserActionCreators.setData(data))
                
            } catch (e) {
                dispatch(UserActionCreators.setError(e))
            }
        }
    }
}
