import { useQuery } from 'react-query'
import { MOB_TEMPLATES_TIME_UPDATE } from '../../config'
import { loadMobileTemplates } from '../../service/axiosFuncQuery/othersQuery'


export const useMobileTemplatesQuery = (mobileTmplId) => {    
    return (
        useQuery({
            queryKey: ['mobileTemplate', mobileTmplId],
            queryFn: () => loadMobileTemplates(mobileTmplId),
            onError: (error) => console.log(error.message),
            staleTime: MOB_TEMPLATES_TIME_UPDATE,
            enabled: !!mobileTmplId // Запрос не будет выполняться до получения mobileTmplId
        })
    )
}