import { useQueryClient, useMutation, useQuery } from 'react-query'
import { TIME_UPDATE } from '../../config'
import { delRole, loadPrepareRoleDialog, loadRoles, saveRole } from '../../service/axiosFuncQuery/adminQuery'
import { useMask } from '../useMask'
import { useSnackbar } from 'notistack'
import CloseAction from '../../components/general/SnackBar/CloseAction'


// query
export const useRolesQuery = ({ term, offset, limit }) => {
    return (
        useQuery({
            queryKey: ['roles', String(term), String(offset), String(limit)],
            queryFn: () => loadRoles({ term, offset, limit }),
            onError: (error) => console.log(error.message),
            staleTime: TIME_UPDATE,
            keepPreviousData: true, // показывать предыдущие данные до загрузки новых
        })
    )
}

export const usePrepareRoleDialogQuery = (roleId) => {
    return (
        useQuery({
            queryKey: ['prepareRoleDialog', String(roleId)],
            queryFn: () => loadPrepareRoleDialog(roleId),
            onError: (error) => console.log(error.message),
            staleTime: TIME_UPDATE,
        })
    )
}

// mutation
export const useSaveRoleMutation = () => {
    const client = useQueryClient()
    const { hideMask, showMask } = useMask()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ roleId, data }) => saveRole(roleId, data),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                hideMask()
                client.invalidateQueries({ queryKey: ['roles'] })

                if (variables.roleId > 0) {
                    client.invalidateQueries({ queryKey: ['prepareRoleDialog', String(variables.roleId)] })
                }
                const message = `Роль '${variables.data.name}' ${variables.roleId > 0 ? 'сохранена': 'добавлена'}`
                enqueueSnackbar(message, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (e, variables) => {
                hideMask()
                const message = `Ошибка. Роль '${variables.data.name}' не ${variables.roleId > 0 ? 'сохранена': 'добавлена'}. ${e.response.data}`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
        })
    )
}

export const useDelRoleMutation = () => {
    const client = useQueryClient()
    const { hideMask, showMask } = useMask()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ roleId }) => delRole(roleId),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                hideMask()
                client.invalidateQueries({ queryKey: ['roles'] })
                enqueueSnackbar(`Роль id=${variables.roleId} удалена`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (_, variables) => {
                hideMask()
                const message = `Ошибка. Роль id=${variables.roleId} не удалена`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            }
        })
    )
}