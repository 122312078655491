import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import BoxLogo from '../../mob/BoxLogo/BoxLogo';
import { RoutePaths } from '../../../routes/index'
import { ReactComponent as Category } from "../../../Icons/menu/Category.svg"
import { ReactComponent as Help } from "../../../Icons/menu/Help.svg";
import { ReactComponent as Info } from "../../../Icons/menu/Info.svg";
import styles from './AppMenu.module.scss'
import MenuListItem from './MenuListItem';
import { Fragment } from 'react';
// import { ReactComponent as FullVersion } from "../../../Icons/menu/FullVersion.svg";


function AppMenu() {

    const MenuItems = [
        [
            {
                title: "Категории",
                path: RoutePaths.HOME,
                icons: Category
            }],
        [
            {
                title: "Помощь",
                path: RoutePaths.HELP,
                icons: Help
            },
            {
                title: "О портале",
                path: RoutePaths.INFO,
                icons: Info
            },
        ],
    ]

    return (
        <div className='menu' >
            <Box className={styles.wrap_menu}><BoxLogo /></Box>
            <List className='list'>
                {MenuItems.map((item_block, i) => (
                    <Fragment key={"item_block_" + i}>
                        {item_block.map((item) => (

                            <MenuListItem
                                className={i > 0 ? 'indent list_item' : 'list_item'}
                                path={item.path}
                                key={item.title}
                                text={item.title}
                                callback={item.callback}
                                icons={<item.icons />}
                            />
                        ))}
                        {(i !== MenuItems.length - 1) ? <Divider className={i === 1 ? 'divider ind' : 'divider'} /> : ""}
                    </Fragment>
                ))}
                <Box style={{ flexGrow: 1 }} />
            </List>
        </div>
    );
}

export default AppMenu
