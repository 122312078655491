import { useState, createContext } from 'react'
import PropTypes from 'prop-types';
import MyTabs from '../MyTabs/MyTabs';
import ImportTab from "./Tabs/ImportTab";
import ExportTab from "./Tabs/ExportTab";


export const ImportExportDialogContext = createContext({ gcs: "WGS84", format: "MIFMID" });

export const ImportExport = ({ layerId, tabs }) => {

    const arrTabs = []

    if (tabs.includes('import')) {
        arrTabs.push({
            label: 'Импорт',
            value: <ImportTab layerId={layerId} />
        })
    }

    if (tabs.includes('export')) {
        arrTabs.push({
            label: 'Экспорт',
            value: <ExportTab layerId={layerId} />
        })
    }

    const [dialogContext/*, setDialogContext*/] = useState({ gcs: "WGS84", format: "MIFMID" });
    return (
        <ImportExportDialogContext.Provider value={dialogContext}>
            <MyTabs arrTabs={arrTabs} var='var2' />
        </ImportExportDialogContext.Provider>
    )
}

ImportExport.propTypes = {
    layerId: PropTypes.number.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.string).isRequired
}
