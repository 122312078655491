import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom';
import { selectedLayerIdsSelector } from '../../../store/selectors/selectors'
import { useObjectQuery, usePropertiesObjectQuery } from '../../../hooks/reactQuery/useObject'
import { useMobileTemplatesQuery } from '../../../hooks/reactQuery/useMobileTemplates'
import ObjectTemplate from './ObjectTemplate'


const ItemObject = () => {

    const objectId = useParams().objectId
    const location = useLocation();
    let pattern, mobileTmplId;

    const { data: object, error: objectError, isLoading: objectIsLoading } = useObjectQuery(objectId)
    const { data: properties, error: propertiesError, isLoading: propertiesIsLoading } = usePropertiesObjectQuery(objectId)

    const selectedLayerIds = useSelector(selectedLayerIdsSelector)

    /* для того чтобы избежать повторных ререндеров в дочернем компоненте (где может быть открыто видео) - запоминаем значение model (используется в props дочернего компонента)
     и изменяем его только если изменились object и properties */

    // т.к. в зависимостях useMemo желательны примитивы - преобразуем объекты в строку
    const objectStringified = object ? JSON.stringify(object) : false
    const propertiesStringified = properties ? JSON.stringify(properties) : false

    const model = useMemo(() => {
        const objectLocal = JSON.parse(objectStringified)
        const propertiesLocal = JSON.parse(propertiesStringified)
        let modelLocal = {}
        if (objectLocal) {
            for (var p in propertiesLocal) {
                if (propertiesLocal[p].key) {
                    modelLocal[propertiesLocal[p].key] = propertiesLocal[p].value;
                } else {
                    for (var pp in propertiesLocal[p]) {
                        if (pp === 'layersData') {
                            modelLocal[pp] = {};
                            for (var l in propertiesLocal[p][pp]) {
                                modelLocal[pp][propertiesLocal[p][pp][l].id] = propertiesLocal[p][pp][l];
                            }
                        } else {
                            modelLocal[pp] = propertiesLocal[p][pp];
                        }
                    }
                }
            }

            modelLocal = {
                ...modelLocal,
                name: objectLocal.name,
                oid: objectLocal.id,
                lat: objectLocal.lat,
                lon: objectLocal.lon,
            }

            return modelLocal
        } else return ({})
    }, [objectStringified, propertiesStringified])


    if (object && properties) {
        // слой, которому принадлежит объект
        const itemLayer = selectedLayerIds.length > 0
            ? object.layers.find(i => selectedLayerIds.indexOf(i.id) >= 0)
            : object.layers.find(i => (i.tmpl || i.mobileTmplId))

        if (itemLayer?.mobileTmplId) {
            mobileTmplId = itemLayer.mobileTmplId;
        }
        else if (itemLayer?.tmpl) {
            pattern = itemLayer.tmpl;
        }
        // else pattern = 'У слоя, которому принадлежит объект не найдено шаблона';
        else pattern = '';
    }

    const { data: mobileTemplates, isLoading: mobileTemplatesIsLoading/*, isError: mobileTemplatesIsError*/ } = useMobileTemplatesQuery(mobileTmplId)


    // если загружен мобильный шаблон - устанавливаем его
    if (mobileTmplId && mobileTemplates) {
        pattern = mobileTemplates.template;
    }

    let params = new URLSearchParams(location.search);
    params.append('feedback', 'true');


    const props = {
        model,
        pattern,
        isLoading: objectIsLoading || propertiesIsLoading || mobileTemplatesIsLoading,
        objectError: objectError ? objectError.response.data ? objectError.response.data : objectError.message : false,
        propertiesError: propertiesError ? propertiesError.response.data ? propertiesError.response.data : propertiesError.message : false
    }
    return <ObjectTemplate {...props} />
}

export default ItemObject