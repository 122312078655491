import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom';
import { ApiPaths, GEOPORTAL_URL } from '../../../config';
import CreateShape from './CreateShape';
import CreatePoint from './CreatePoint';
import useLayerById from '../../../hooks/reactQuery/selectorCache/useLayerById';


const CreateObjectsWFS = ({ objects, layerId, handleClick, clusterGroupRef }) => {

    const selectedObjectId = Number(useParams().objectId)
    const layer = useLayerById(layerId)

    // !!!! обрати внимание что за loop?
    const handleClickLoop = (objectId) => {
        const layerId = layer.id
        const loop = (objectId) => {
            handleClick(layerId, objectId)
        }
        loop(objectId)
    }

    // устанавливаем значения из слоя
    let layerIconUrl = GEOPORTAL_URL + ApiPaths.icon.FROM_LAYER.replace(':layerId', layer.id);
    let layerLineColor = layer.lineColor ? layer.lineColor : null;
    let layerLineWeight = layer.lineWeight !== null ? layer.lineWeight : null;
    let layerFillColor = layer.fillColor ? layer.fillColor : null;
    let layerFillOpacity = layer.fillOpacity !== null ? layer.fillOpacity : null;

    return (
        objects.map(item => {
            let iconUrl = layerIconUrl
            let lineColor = layerLineColor
            let lineWeight = layerLineWeight
            let fillColor = layerFillColor
            let fillOpacity = layerFillOpacity
            if (layer.viewByObject) {   // если установлена опция 'Отображение по объектам' - берем значения из объекта, если они там есть
                if (item.iconId) iconUrl = GEOPORTAL_URL + ApiPaths.icon.FROM_OBJECT.replace(':objectId', item.id);
                if (item.lineColor) lineColor = item.lineColor;
                if (item.lineWeight) lineWeight = item.lineWeight;
                if (item.fillColor) fillColor = item.fillColor;
                if (item.fillOpacity) fillOpacity = item.fillOpacity;
            }
            const selected = selectedObjectId === item.id

            return (
                item.objectGeomType === 'Point'
                    ?
                    <CreatePoint
                        clusterGroupRef={clusterGroupRef}
                        item={item} // {id, lat, lon...} - property object
                        iconUrl={iconUrl}
                        key={'o_' + item.id}
                        selected={selected}
                        handleClick={handleClickLoop}
                        iconShape={layer.iconShape}
                        iconId={layer.viewByObject && item.iconId ? item.iconId : layer.iconId}
                    />
                    :
                    <CreateShape
                        lineColor={lineColor}
                        lineWeight={lineWeight}
                        fillColor={fillColor}
                        fillOpacity={fillOpacity}

                        item={item}
                        key={'o_' + item.id}
                        selected={selected}
                        handleClick={handleClickLoop}
                    />
            )
        })
    )
}

CreateObjectsWFS.propTypes = {
    layerId: PropTypes.number.isRequired,
    objects: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleClick: PropTypes.func.isRequired,
}

export default CreateObjectsWFS