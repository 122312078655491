import { Button, Stack, Typography } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import PropTypes from 'prop-types'
import { trim } from "lodash"
import { getFormatDateMs, prepareAssignedlayers, replaceNullValuesFromObject } from "../../../../../service/function"
import MyTabs from "../../../MyTabs/MyTabs"
import { usePrepareUserDialogQuery, useSaveUserMutation } from "../../../../../hooks/reactQuery/useUser"
import { useModal } from "../../../../../hooks/useModal/useModal"
import Loader from '../../../Loader/Loader'
import { BoxMarginLeftRight } from "../../../StyledComponents/BoxMarginLeftRight"
import General from "./Tabs/General"
import { UserContext } from './UserContext'
import Roles from "./Tabs/Roles"
import Permissions from "./Tabs/Permissions"
import Layers from "./Tabs/Layers"
import { ModalWindowContext } from "../../../../../hooks/useModal/ModalWindow"
import FormContainer from "../../../ForForms/FormContainer"


const User = ({ userId }) => {

    const setTabsRef = useRef()
    const { openAlert } = useModal()
    const { onClose } = useContext(ModalWindowContext)
    const [uId, setUId] = useState(userId)

    const { mutate: saveUser, data: responseSaveUser, isSuccess: isSaveUser } = useSaveUserMutation()
    const { data: prepareUserDialog = {}, isLoading, isFetching, error } = usePrepareUserDialogQuery(uId)
    const { user, command, layerPerms = [] } = prepareUserDialog //command здесь играет роль userId. Равен "-1" для нового пользователя 

    const { control, handleSubmit, watch, setValue, reset, trigger, clearErrors, formState: { errors }, setError } = useForm({
        defaultValues: {
            'id': '',
            'geoEdit': true,
            'login': '',
            'lastName': '',
            'firstName': '',
            'middleName': '',
            'email': '',
            'phone': '',
            'organization': '',
            'printTmpl': '',
            'password': '',
            'confirmPassword': '',
            'roleIds': [],
            'aclIds': [],
            'assignedlayers': []
        },
        mode: 'onSubmit'
    })

    const arrTabs = [
        {
            label: 'Общие',
            value: <General />
        },
        {
            label: 'Роли',
            value: <Roles />
        },
        {
            label: 'Терр. права',
            value: <Permissions />
        },
        {
            label: 'Слои',
            value: <Layers />
        },
    ]

    useEffect(() => {
        if (!uId && isSaveUser && responseSaveUser) {
            setUId(responseSaveUser.data)
        }
    }, [isSaveUser, responseSaveUser, uId])

    useEffect(() => {
        if (error) {
            onClose()
            openAlert({
                title: 'Ошибка при загрузке страницы',
                children: error.response.data,
                backdropOpacity: 0.2,
                width: 600
            })
        }
    }, [error, onClose, openAlert])


    // После загрузки данных - устанавливаем defaultValues через reset
    useEffect(() => {
        if (user) {
            reset({
                'geoEdit': user.geoEdit ? true : true,
                ...replaceNullValuesFromObject({ // заменяем значения полей null и undefined на '' - требование react-hook-form
                    'id': user.id,
                    'login': user.login,
                    'lastName': user.lastName,
                    'firstName': user.firstName,
                    'middleName': user.middleName,
                    'email': user.email,
                    'phone': user.phone,
                    'organization': user.organization,
                    'printTmpl': user.printTmpl,
                }),
                'roleIds': user.roleIds,
                'aclIds': user.aclIds,
                'assignedlayers': prepareAssignedlayers(layerPerms),
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching])

    // Если была ошибка несовпадения введенных паролей - при изменении соотв. полей - убираем ее
    useEffect(() => {
        if ((watch('password') !== '' && watch('confirmPassword') !== '') && (errors.password || errors.confirmPassword)) {
            clearErrors('password')
            clearErrors('confirmPassword')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('password'), watch('confirmPassword')])


    const onSubmit = async (data) => {
        // Проверка login
        if (trim(data.login) === '') {
            setTabsRef.current(0) // переключаемся на 0 вкладку
            trigger()
            return null
        }

        // Проверка паролей
        if (data.password !== data.confirmPassword) {
            setTabsRef.current(0)
            setError('password', { type: 'password verification', message: 'Пароли не совпадают' })
            setError('confirmPassword', { type: 'password verification' })
            return null
        }

        data.assignedlayers = JSON.stringify(data.assignedlayers)
        saveUser({ userId: command, data })
    }

    if (error) return null
    if (isLoading) return <Loader />

    return (
        <>
            {user.creationDate &&
                <BoxMarginLeftRight style={{ marginTop: '0px' }}>
                    <Typography variant='font_12'>Создан: {getFormatDateMs(user.creationDate)}</Typography>
                </BoxMarginLeftRight>
            }

            <Stack component="form" method='POST'
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault() }}
                spacing={2}
                className='overflow'
                style={{ marginTop: '0px' }}
            >
                <FormContainer
                    action={<Button
                        type="submit"
                        variant='contained'>Сохранить</Button>}
                    disablePaddingRight
                    disablePaddingLeft
                >
                    <UserContext.Provider value={{ prepareUserDialog, control, setValue }}>
                        <MyTabs arrTabs={arrTabs} var='var1' setTabsRef={setTabsRef} />
                    </UserContext.Provider>
                </FormContainer>
            </Stack>
        </>
    )
}

User.propTypes = {
    userId: PropTypes.number,
}

export default User 