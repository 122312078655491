import { Stack } from "@mui/material"
import PropTypes from 'prop-types'
import { RoutePaths } from "../../../routes"
import GoBack from "../../general/GoBack"
import Title from "../../general/Title/Title"

const HeadTitleBoxTextLayout = ({title}) => {

    return (
        <Stack direction='row' alignItems='center' style={{ position: 'relative', left: '-10px' }} >
            <GoBack path={RoutePaths.HOME} style={{ width: '40px', height: '40px' }} />
            <Title title={title} path={RoutePaths.HOME} />
        </Stack>
    )
}

HeadTitleBoxTextLayout.propTypes = {
    title: PropTypes.string.isRequired
}

export default HeadTitleBoxTextLayout