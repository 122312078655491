import { Avatar, Box, Stack } from '@mui/material';
import React from 'react';
import { pxToRem } from '../../../Style/desktopTheme';


const BoxImg = ({ src, ...props }) => {

    return (
        <Stack
            {...props}
            style={{
                ...props.style,
                width: pxToRem('56px'),
                height: pxToRem('56px'),
                border: '1px dashed rgba(51, 51, 51, 0.60)',
                position: 'relative',
            }}
            alignItems='center'
            justifyContent='center'
        >

            {src
                ? <Avatar
                    src={src}
                    style={{
                        width: 'auto',
                        height: 'auto',
                    }}
                />
                :
                <Box></Box>
            }
        </Stack>
    )
}

export default BoxImg;