import L from "leaflet";
import TileSCKO from '../../../Icons/map/tile-scko.jpg';
import TileYandexSat from "../../../Icons/map/tile-yandex-sat.jpg";
import TileESRI from "../../../Icons/map/tile-esri.jpg";
import Tile2GIS from "../../../Icons/map/tile-2gis.jpg";
import TileOSM from "../../../Icons/map/tile-osm.jpg";
import TileRelief from "../../../Icons/map/tile-relief.jpg";
import TileEEKO from "../../../Icons/map/tile-eeko.jpg";
// import TileGoogleSat from "../../../Icons/map/tile-google-sat.jpg";
import YandexTileLayer from "./../../../components/general/Map/Leaflet/YandexTileLayer";


const EgrnTileLayer = () => {
    const wmsUrlLayer = 'https://gis76.ru/storage_system/api/mapImage/getImage?service=WMS&layers=LandRecord&format=image/png&transparent=true&version=1&width=512&height=512&srs=EPSG:3857'
    const wmsUrl = wmsUrlLayer.replace(/([^?]*)\?(.*)/, '$1')
    const wmsParams = Object.fromEntries(new URLSearchParams(wmsUrlLayer.replace(wmsUrl, '')))

    if (wmsParams.tileSize) {
        wmsParams.tileSize = parseInt(wmsParams.tileSize)
    }
    var wmsLayer = L.tileLayer.wms(wmsUrl + '?f=image', Object.assign(wmsParams, {
        format: 'png',
        transparent: true,
        attribution: "wms",
        zIndex: 2, 
        pane: 'overlayPane'
    }))
    return wmsLayer
}

export const MAP_SET_TILE_LAYER = "MAP_SET_TILE";
export const MAP_SET_EDIT_ON_MAP = "MAP_SET_EDIT_ON_MAP";
export const MAP_HEATMAP_ADD_ID = "MAP_HEATMAP_ADD_ID"
export const MAP_HEATMAP_DEL_ID = "MAP_HEATMAP_DEL_ID"
export const MAP_SET_WMS_TILE_LAYER = "MAP_SET_WMS_TILE_LAYER"
export const MAP_SET_CHANGE_ICON = "MAP_SET_CHANGE_ICON"


const TILE_SCKO = 1;
const TILE_YANDEX_SAT = 2;
const TILE_ESRI = 3;
const TILE_2GIS = 4;
const TILE_OSM = 5;
const TILE_RELIEF = 6;
const TILE_EEKO = 7;
// const TILE_GOOGLE_SAT = 8;

export const Tiles = [
    {
        id: TILE_SCKO,
        name: "СЦКО",
        icon: TileSCKO,
        layer: L.tileLayer("https://maps.yarcloud.ru/yomap/{z}/{y}/{x}")
    },
    {
        id: TILE_YANDEX_SAT,
        name: "Карта Яндекс",
        icon: TileYandexSat,
        layer: YandexTileLayer()
    },
    {
        id: TILE_ESRI,
        name: "Спутник ESRI",
        icon: TileESRI,
        layer: L.tileLayer("https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}")
    },
    {
        id: TILE_2GIS,
        name: "Карта 2GIS",
        icon: Tile2GIS,
        layer: L.tileLayer("https://tile0.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=41")
    },
    {
        id: TILE_OSM,
        name: "Карта OSM",
        icon: TileOSM,
        layer: L.tileLayer("https://maps.yarcloud.ru/enk/{z}/{x}/{y}.png")
    },
    {
        id: TILE_RELIEF,
        name: "Рельеф",
        icon: TileRelief,
        layer: L.tileLayer("https://c.tile.opentopomap.org/{z}/{x}/{y}.png")
    },
    {
        id: TILE_EEKO,
        name: "ЕЭКО",
        icon: TileEEKO,
        layer: L.tileLayer("https://ngw.fppd.cgkipd.ru/tile/56/{z}/{x}/{y}.png")
    },
    /* {
         id: TILE_GOOGLE_SAT,
         name: "Спутник Google",
         icon: TileGoogleSat,
         layer: L.tileLayer("https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",{maxZoom: 20, subdomains:['mt0','mt1','mt2','mt3'] })
     }*/
];

export const WmsTiles = [
    {
        id: 'egrn',
        name: 'Земельные участки ЯО',
        layer: EgrnTileLayer()
    }
]

const getTileById = (id) => Tiles.find(t => t.id === id);


const initialState = {
    analiticalMapIds: {
        heatmap: [],
        // diagram: [],
        // analysys: []
    },
    editOnMapLayer: undefined,
    tileLayer: getTileById(JSON.parse(localStorage.getItem("tile"))) || getTileById(TILE_YANDEX_SAT),
    wmsTileLayer: null,
    changeIcon: {},
    // changeIcon: {
    //     iconId: timeStamp
    // }
}

const heatMapAdd = (state, layerId) => {
    return {
        ...state,
        analiticalMapIds: {
            ...state.analiticalMapIds,
            heatmap: [
                ...state.analiticalMapIds.heatmap,
                layerId
            ]
        }
    }
}

const heatMapDel = (state, layerId) => {
    return {
        ...state,
        analiticalMapIds: {
            ...state.analiticalMapIds,
            heatmap: [
                ...state.analiticalMapIds.heatmap.filter(id => id !== layerId),
            ]
        }
    }
}

export default function MapReducer(state = initialState, action) {
    switch (action.type) {
        case MAP_SET_TILE_LAYER:
            localStorage.setItem("tile", JSON.stringify(action.payload));
            return {
                ...state,
                tileLayer: getTileById(action.payload),
            }
        case MAP_SET_EDIT_ON_MAP:
            return {
                ...state,
                editOnMapLayer: action.payload,
            }
        case MAP_HEATMAP_ADD_ID:
            return heatMapAdd(state, action.payload)
        case MAP_HEATMAP_DEL_ID:
            return heatMapDel(state, action.payload)

        case MAP_SET_WMS_TILE_LAYER:
            return {
                ...state,
                wmsTileLayer: action.payload
            }
        
        case MAP_SET_CHANGE_ICON: 
            return {
                ...state,
                changeIcon: {
                    ...state.changeIcon,
                    [Number(action.payload.iconId)]: action.payload.timestamp
                }
            }
        default:
            return state
    }
}