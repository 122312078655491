import { useQueryClient, useMutation, useQuery } from 'react-query'
import { TIME_UPDATE } from '../../config'
import { delArea, loadPrepareAreaDialog, loadAreas, saveArea } from '../../service/axiosFuncQuery/adminQuery'
import { useMask } from '../useMask'
import { useSnackbar } from 'notistack'
import CloseAction from '../../components/general/SnackBar/CloseAction'


// query
export const useAreasQuery = ({ term, offset, limit }) => {
    return (
        useQuery({
            queryKey: ['areas', String(term), String(offset), String(limit)],
            queryFn: () => loadAreas({ term, offset, limit }),
            onError: (error) => console.log(error.message),
            staleTime: TIME_UPDATE,
            keepPreviousData: true, // показывать предыдущие данные до загрузки новых
        })
    )
}

export const usePrepareAreaDialogQuery = (areaId) => {
    return (
        useQuery({
            queryKey: ['prepareAreaDialog', String(areaId)],
            queryFn: () => loadPrepareAreaDialog(areaId),
            onError: (error) => console.log(error.message),
            staleTime: TIME_UPDATE,
        })
    )
}


// mutation
export const useSaveAreaMutation = () => {
    const client = useQueryClient()
    const { hideMask, showMask } = useMask()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ areaId, data }) => saveArea(areaId, data),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                hideMask()
                client.invalidateQueries({ queryKey: ['areas'] })

                if (variables.areaId > 0) {
                    client.invalidateQueries({ queryKey: ['prepareAreaDialog', String(variables.areaId)] })
                }
                enqueueSnackbar(`Территориальное ограничение '${variables.data.name}' сохранено`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (_, variables) => {
                hideMask()
                const message = `Ошибка. Территориальное ограничение '${variables.data.name}' не сохранено`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
        })
    )
}

export const useDelAreaMutation = () => {
    const client = useQueryClient()
    const { hideMask, showMask } = useMask()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ areaId }) => delArea(areaId),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                hideMask()
                client.invalidateQueries({ queryKey: ['areas'] })
                enqueueSnackbar(`Территориальное ограничение id=${variables.areaId} удалено`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (_, variables) => {
                hideMask()
                const message = `Ошибка. Территориальное ограничение id=${variables.areaId} не удалено`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
        })
    )
}