import { isEqual } from "lodash";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom"
import { selectedLayerIdsSelector } from "../store/selectors/selectors";



const useAppSearchParams = () => {

    const isInitialMount = useRef(true)
    let [searchParams, setSearchParams] = useSearchParams()

    const selectedLayerIds = useSelector(selectedLayerIdsSelector)

    // Вызываем setSearchParams только когда новые layers и те что уже в url - не совпадают
    const isUrlLayersUpdate = searchParams.get('layers') ? (!isEqual(selectedLayerIds, searchParams.get('layers').split(',').map(Number))) : true

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false
        } else {
            if (isUrlLayersUpdate) {
                setSearchParams(params => {
                    const newParams = Object.assign(params)
                    if (selectedLayerIds.length > 0) {
                        newParams.set("layers", selectedLayerIds)
                    } else {
                        if (newParams.has('layers')) {
                            newParams.delete('layers')
                        }
                    }
                    return newParams
                },
                    { replace: true }
                )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLayerIds, isUrlLayersUpdate])

    return null
}

export default useAppSearchParams