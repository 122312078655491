import { PropTypes } from 'prop-types';
import Draw from 'leaflet-draw';
import isEqual from 'fast-deep-equal';
import React, { useRef } from 'react';
import { useLeafletContext } from '@react-leaflet/core';
import leaflet, { Map, Control } from 'leaflet';


const eventHandlers = {
    onEdited: 'draw:edited',
    onDrawStart: 'draw:drawstart',
    onDrawStop: 'draw:drawstop',
    onDrawVertex: 'draw:drawvertex',
    onEditStart: 'draw:editstart',
    onEditMove: 'draw:editmove',
    onEditResize: 'draw:editresize',
    onEditVertex: 'draw:editvertex',
    onEditStop: 'draw:editstop',
    onDeleted: 'draw:deleted',
    onDeleteStart: 'draw:deletestart',
    onDeleteStop: 'draw:deletestop',
};

function EditControl({ controlRef, ...props }) {

    // console.log("Render EditControl");
    
    const context = useLeafletContext();
    const drawRef = useRef();
    const propsRef = useRef(props);

    const onDrawCreate = (e) => {
        const { onCreated } = props;
        const container = context.layerContainer || context.map;
        container.addLayer(e.layer);
        onCreated && onCreated(e);
    };

    React.useEffect(() => {
        const { map } = context;
        const { onMounted } = props;

        for (const key in eventHandlers) {
            map.on(eventHandlers[key], (evt) => {
                let handlers = Object.keys(eventHandlers).filter(
                    (handler) => eventHandlers[handler] === evt.type
                );
                if (handlers.length === 1) {
                    let handler = handlers[0];
                    props[handler] && props[handler](evt);
                }
            });
        }
        map.on(leaflet.Draw.Event.CREATED, onDrawCreate);
        drawRef.current = createDrawElement(props, context, controlRef);
        map.addControl(drawRef.current);
        onMounted && onMounted(drawRef.current);

        return () => {
            map.off(leaflet.Draw.Event.CREATED, onDrawCreate);

            for (const key in eventHandlers) {
                if (props[key]) {
                    //!!! внимание!здесь удаляю все Handler-ы, т.к. видимо в DrawControls они меняются, и этот момент пока не пофиксил
                    // map.off(eventHandlers[key], props[key]);
                    map.off(eventHandlers[key]);
                    
                }
            }
            drawRef.current.remove(map);
        };
    }, [])


    React.useEffect(() => {
        if (
            isEqual(props.draw, propsRef.current.draw) &&
            isEqual(props.edit, propsRef.current.edit) &&
            props.position === propsRef.current.position
        ) {
            return;
        }
        const { map } = context;

        drawRef.current.remove(map);
        drawRef.current = createDrawElement(props, context, controlRef);
        drawRef.current.addTo(map);
        const { onMounted } = props;
        onMounted && onMounted(drawRef.current);

        return () => {
            drawRef.current.remove(map);
        };
    }, [props.draw, props.edit, props.position]);
    return null;
}

function createDrawElement(props, context, controlDrawRef) {

    const { layerContainer } = context;
    const { draw, edit, position } = props;

    const options = {
        edit: {
            featureGroup: edit?.edit?.featureGroup ? edit.edit.featureGroup : layerContainer,
            ...edit,
        },
    };

    if (draw) {
        options.draw = { ...draw };
    }

    if (position) {
        options.position = position;
    }

    L.EditToolbar.Delete.include({
        removeAllLayers: false
    });
    
    let drawControl = new Control.Draw(options);
    if(controlDrawRef) {
        controlDrawRef.current = drawControl
    }    
    return drawControl
}

EditControl.propTypes = {
    ...Object.keys(eventHandlers).reduce((acc, val) => {
        acc[val] = PropTypes.func;
        return acc;
    }, {}),
    onCreated: PropTypes.func,
    onMounted: PropTypes.func,
    draw: PropTypes.shape({
        polyline: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        polygon: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        rectangle: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        circle: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        marker: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    }),
    edit: PropTypes.shape({
        edit: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        remove: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        poly: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        allowIntersection: PropTypes.bool,
    }),
    position: PropTypes.oneOf([
        'topright',
        'topleft',
        'bottomright',
        'bottomleft',
        'verticalrighttop',
        'verticalrightcenter',
        'verticalrightbottom',
    ]),
    leaflet: PropTypes.shape({
        map: PropTypes.instanceOf(Map),
        layerContainer: PropTypes.shape({
            addLayer: PropTypes.func.isRequired,
            removeLayer: PropTypes.func.isRequired,
        }),
    }),
};

export default EditControl;
