import { useSelector } from "react-redux"
import { changeIconSelector } from "../store/selectors/selectors"


const useIconTimeStamp = (iconId) => {
    const changeIcon = useSelector(changeIconSelector)
    const timestamp = changeIcon.hasOwnProperty(iconId) ? '?timestamp=' + changeIcon[iconId] : ''

    return timestamp
}

export default useIconTimeStamp