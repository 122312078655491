import { Box, Stack, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types'
import { morph } from '../../../../service/function';
import { ApiPaths } from '../../../../config';
import IconBox from '../../ForForms/IconBox/IconBox';
import { BoxMarginLeftRight } from '../../StyledComponents/BoxMarginLeftRight';

const HeadBoxForm = ({ layer, countObjects, control, updateIconFn }) => {
    
    const StackSx = {
        position: 'absolute',
        top: '0px',
        width: 'calc(100% - 64px)'
    }
    
    return (
        <BoxMarginLeftRight>
        <Stack direction='row' spacing={2} alignItems='start' sx={StackSx}>
            <Box>
                <IconBox
                    iconId={layer.treeiconId}
                    src={layer.id ? ApiPaths.icon.TREEICON.replace(":layerId", layer.id) : '#'}
                    editor={Boolean(control)}
                    updateIconFn={updateIconFn}
                />
            </Box>

            {control
                ? <Controller
                    name='name'
                    control={control}
                    rules={{ required: "Поле обязательно для заполнения" }}
                    render={({ field, fieldState: { error } }) => <TextField {...field}
                        fullWidth
                        label='Название слоя'
                        variant="outlined"
                        margin="normal"
                        style={{
                            marginTop: '5px'
                        }}
                        error={Boolean(error)}
                        helperText={error && error.message}
                    />}
                />
                : <TextField
                    disabled={true}
                    defaultValue={layer.name}
                    fullWidth
                    label='Название слоя'
                    variant="outlined"
                    margin="normal"
                    style={{
                        marginTop: '5px'
                    }}
                />
            }

            {countObjects &&
                <Typography variant='font_24' sx={{ pt: '18px' }}>
                    {countObjects}&nbsp;{countObjects && morph(countObjects)}
                </Typography>
            }

        </Stack>
        </BoxMarginLeftRight>
    )
}

HeadBoxForm.propTypes = {
    countObjects: PropTypes.number,
    layerId: PropTypes.number,
    control: PropTypes.object, //  если компонент контролируемый (можем редактировать поля)
    name: PropTypes.string,  // если компонент не контролируемый
}

export default HeadBoxForm