export const layerType = {
    1: 'Векторный слой (WFS)',
    2: 'WMS сервис',
    3: 'Тайловый сервис',
    5: 'Сервис ArcGIS',
    6: 'Векторный слой (WMS)',
    7: 'Картинка',
    9: 'Динамический WFS',
}

export const sortedLayerType = (() => {
    const arrLayerType = Object.entries(layerType)
    return ([arrLayerType[0], arrLayerType[4], arrLayerType[1], arrLayerType[2], arrLayerType[3], arrLayerType[5], arrLayerType[6]])
})()

/* Отображение набора свойств в зависимости от выбранного TypeId */
export const layerPropVisiblityTypeId = {
    1: [
        "wsdl",
        "viewByObject",
        "feedbackByObject",
        "iconBgColor",
        "iconColor",
        "iconId",
        "iconShape",
        "lineColor",
        "lineWeight",
        "fillColor",
        "fillOpacity",
        "tmplId",
        "mobileTmplId",
    ],
    2: [
        "wmsUrl"
    ],
    3: [
        "tileServiceUrl",
        "url"
    ],
    5: [
        "esriUrl", // вместо arcGisUrl
        "isViewObjectsOnArcgis",
        // "esriTmpl" // на gis76.ru он также реализован как то странно, значения в форме по факту - пустые. Уточнить, нужно ли это свойство
        // arcGisTmpl - пока не реализован
    ],
    6: [
        "wsdl",
        "viewByObject",
        "feedbackByObject",
        "iconId",
        "iconShape",
        "lineColor",
        "lineWeight",
        // "borderFileName",        // временно не реализовано
        "fillColor",    

        
        // start for "fillImageDimention"
        // "fillFileName",     // временно не реализовано
        // "fillImageWidth",   // временно не реализовано
        // "fillImageHeight",  // временно не реализовано
        // end for "fillImageDimention"

        "fillOpacity",
        "captionTag",

        // start for "layerFont"
        "captionSize",
        "fontColor",
        "fontBold",
        // end for "layerFont"

        // start for "halo"
        "haloSize",
        "haloColor",
        // end for "halo"

        "tmplId",
    ],
    7: [
        "layerPicture",
        "pictureOpacity",
        "pictureBounds"
    ],
    9: [
        "rnisApiKey",
        "vehicleTailLength",
        "vehicleTailColor",
        "vehicleInactivityPeriod",
        "iconId",
        "iconShape",
        "tmplId",
    ]
}
