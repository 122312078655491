import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types'


const MetadataFormControllers = ({ control, editor, hasOwner }) => {

    return (
        <>
            <Controller
                disabled={!editor}
                name='descSpatialName'
                control={control}
                rules={{ required: "Поле обязательно для заполнения" }}
                render={({ field, fieldState: { error } }) => <TextField {...field}
                    multiline
                    fullWidth
                    label='Название ПД'
                    placeholder="Название данных в свободном стиле"
                    error={Boolean(error)}
                    helperText={error && error.message}
                />}
            />

            <Controller
                disabled={!editor}
                name='descSpatialData'
                control={control}
                rules={{ required: "Поле обязательно для заполнения" }}
                render={({ field, fieldState: { error } }) => <TextField {...field}
                    multiline
                    fullWidth
                    label='Описание ПД'
                    placeholder="Описание данных в свободном стиле"
                    error={Boolean(error)}
                    helperText={error && error.message}
                />}
            />

            <Controller
                disabled={!editor}
                name='accessLevel'
                control={control}
                render={({ field }) => (
                    <FormControl fullWidth>
                        <InputLabel>Уровень доступа</InputLabel>
                        <Select
                            label='Уровень доступа'
                            variant="outlined"
                            {...field}
                        >
                            <MenuItem value='без ограничений'>без ограничений</MenuItem>
                            <MenuItem value='ограниченный доступ'>ограниченный доступ</MenuItem>
                            <MenuItem value='для служебного пользования'>для служебного пользования</MenuItem>
                        </Select>
                    </FormControl>
                )}
            />

            <Controller
                disabled={!editor}
                name='sourceSpatialData'
                control={control}
                rules={{ required: "Поле обязательно для заполнения" }}
                render={({ field, fieldState: { error } }) => <TextField {...field}
                    multiline
                    fullWidth
                    label='Источник получения ПД'
                    placeholder="Документ источник ПД, информационный ресурс"
                    error={Boolean(error)}
                    helperText={error && error.message}
                />}
            />

            <Controller
                disabled={!editor}
                name='hasOwner'
                control={control}
                render={({ field }) => <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label='Есть ответственное лицо' />}
            />

            {hasOwner &&
                <>
                    <Controller
                        disabled={!editor}
                        name='ownerCompany'
                        control={control}
                        rules={hasOwner ? { required: "Поле обязательно для заполнения" } : {}}
                        render={({ field, fieldState: { error } }) => <TextField {...field}
                            multiline
                            fullWidth
                            label='Название организации'
                            placeholder="Организация, осуществляющая ведение данных"
                            error={Boolean(error)}
                            helperText={error && error.message}
                        />}
                    />

                    <Controller
                        disabled={!editor}
                        name='ownerEmail'
                        control={control}
                        rules={hasOwner ? { required: "Поле обязательно для заполнения" } : {}}
                        render={({ field, fieldState: { error } }) => <TextField {...field}
                            multiline
                            fullWidth
                            label='Email'
                            placeholder="Почтовый адрес, на который будут приходить замечания с объектов от пользователей"
                            error={Boolean(error)}
                            helperText={error && error.message}
                        />}
                    />

                    <Controller
                        disabled={!editor}
                        name='ownerPhone'
                        control={control}
                        render={({ field }) => <TextField {...field}
                            multiline
                            fullWidth
                            label='Телефон ответственного лица'
                        />}
                    />
                </>}

            <Controller
                disabled={!editor}
                name='docRegulation'
                control={control}
                render={({ field }) => <TextField {...field}
                    multiline
                    fullWidth
                    label='Документ - регламент'
                    placeholder='Документ, регламентирующий работу с этими ПД'
                />}
            />

            <Controller
                disabled={!editor}
                name='mapAccuracy'
                control={control}
                render={({ field }) => (
                    <FormControl fullWidth>
                        <InputLabel>Масштаб (точность)</InputLabel>
                        <Select
                            label='Масштаб (точность)'
                            {...field}
                        >
                            <MenuItem value='Не важно '>Не важно</MenuItem>
                            <MenuItem value='Геодезическая'>Геодезическая</MenuItem>
                            <MenuItem value='1:2 000'>1:2 000</MenuItem>
                            <MenuItem value='1:5 000'>1:5 000</MenuItem>
                            <MenuItem value='1:10 000'>1:10 000</MenuItem>
                            <MenuItem value='1:25 000'>1:25 000</MenuItem>
                            <MenuItem value='1:50 000'>1:50 000</MenuItem>
                            <MenuItem value='1:100 000'>1:100 000</MenuItem>
                            <MenuItem value='1:200 000'>1:200 000</MenuItem>
                            <MenuItem value='1:300 000'>1:300 000</MenuItem>
                            <MenuItem value='1:500 000'>1:500 000</MenuItem>
                            <MenuItem value='1:1 000 000'>1:1 000 000</MenuItem>
                        </Select>
                    </FormControl>
                )}
            />

            <Controller
                disabled={!editor}
                name='accessConditions'
                control={control}
                render={({ field }) => <TextField {...field}
                    multiline
                    fullWidth
                    label='Информация об условиях доступа к набору данных'
                    placeholder='Описание условий распространения данных, название лицензии (в свободном стиле)'
                />}
            />

        </>
    )
}

MetadataFormControllers.propTypes = {
    control: PropTypes.object.isRequired,
    editor: PropTypes.bool.isRequired,
    hasOwner: PropTypes.bool
}

export default MetadataFormControllers