import { checkAuth, login, logout} from "../../../service/auth";
import { SelectedLayerIdsActionCreators } from "../selectedLayerIds/action-creators";
import { UserActionCreators } from "../user/action-creators";
import { AUTH_SET_ERROR, AUTH_SET_IS_AUTH, AUTH_SET_LOADING } from "./index";


export const AuthActionCreators = {
    setIsAuth: (isAuth, username = null) => {
        return {
            type: AUTH_SET_IS_AUTH,
            payload: { isAuth, username }
        }
    },
    setIsLoading: (isLoading) => {
        return {
            type: AUTH_SET_LOADING,
            payload: isLoading
        }
    },
    setError: (error) => {
        return {
            type: AUTH_SET_ERROR,
            payload: error
        }
    },
    login: (username, password, resetQuery) => {
        return async (dispatch) => {
            try {
                dispatch(AuthActionCreators.setIsLoading(true));
                const response = await login(username, password);
                if (response.data.success === true) {
                    localStorage.setItem("username", username);
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem("refresh_token", response.data.refresh_token);
                    dispatch(AuthActionCreators.setIsAuth(true, username));
                    resetQuery()    // инвалидируем все запросы
                } else {
                    dispatch(AuthActionCreators.setError("Ошибка! Имя или пароль не верны"));
                }
            } catch (e) {
                dispatch(AuthActionCreators.setError("Ошибка! Имя или пароль не верны"));
            }
        }
    },
    checkAuth: () => {
        return async (dispatch) => {
            try {
                const token = localStorage.getItem('refresh_token');
                if (!token) {
                    dispatch(AuthActionCreators.setIsAuth(false));
                    return;
                }
                dispatch(AuthActionCreators.setIsLoading(true));
                const isAuth = await checkAuth();
                dispatch(AuthActionCreators.setIsAuth(isAuth));
            } catch (e) {
                dispatch(AuthActionCreators.setIsAuth(false));
            }
        }
    },
    logout: (resetQuery) => {
        return async (dispatch) => {
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            await logout()
            resetQuery()    // инвалидация всех запросов
            dispatch(SelectedLayerIdsActionCreators.clearSelectedLayerIds())
            dispatch(AuthActionCreators.setIsAuth(false))
            dispatch(UserActionCreators.reset())
        }
    },
}