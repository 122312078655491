import { useMutation, useQuery, useQueryClient } from 'react-query'
import { createGeometryObject, deleteAllObjectsFromLayer, deleteObjectsFromLayer, loadObject, loadPreparePropsObject, loadPropertiesObject, objectRevertTo, updateGeometryObject, updateObject, loadObjectRevisions, loadObjectTagKeysByLayerId } from '../../service/axiosFuncQuery/objectQuery'
import { useSnackbar } from 'notistack'
import { useActions } from '../useActions'
import { useMask } from '../useMask'
import { TIME_UPDATE } from '../../config'
import CloseAction from '../../components/general/SnackBar/CloseAction'
import { cloneObjectToLayer } from '../../service/axiosFuncQuery/adminQuery'
import { updateIconObject } from '../../service/axiosFuncQuery/galleryQuery'

// query
export const useObjectQuery = (objectId, enabled) => {
    return (
        useQuery({
            queryKey: ['object', String(objectId)],
            queryFn: () => loadObject(objectId),
            onError: (error) => console.log(error.message),
            staleTime: TIME_UPDATE,
            refetchOnWindowFocus: false,
            keepPreviousData: true, // показывать предыдущие данные до загрузки новых
            enabled: Boolean(objectId) && enabled,
            retry: (failureCount, error) => {
                if (error.response.data === 'Объект не найден' || failureCount === 2) {
                    return false
                }
                return true
            }
        })
    )
}

export const usePreparePropsObjectQuery = (objectId) => {
    return (
        useQuery({
            queryKey: ['preparePropsObject', String(objectId)],
            queryFn: () => loadPreparePropsObject(objectId),
            onError: (error) => console.log(error.message),
            staleTime: TIME_UPDATE,
        })
    )
}

export const usePropertiesObjectQuery = (objectId) => {
    return (
        useQuery({
            queryKey: ['propertiesObject', String(objectId)],
            queryFn: () => loadPropertiesObject(objectId),
            onError: (error) => console.log(error.message),
            staleTime: TIME_UPDATE,
            refetchOnWindowFocus: false,
            keepPreviousData: false, // показывать предыдущие данные до загрузки новых
            enabled: Boolean(objectId)
        })
    )
}

export const useObjectRevisionsQuery = ({objectId, revId}) => {
    return (
        useQuery({
            queryKey: ['objectRevisions', String(objectId), String(revId)],
            queryFn: () => loadObjectRevisions(objectId, revId),
            onError: (error) => console.log(error.message),
            staleTime: TIME_UPDATE,
            keepPreviousData: true,
        })
    )
}

export const useObjectTagKeysByLayerId = (layerId) => {
    return(
        useQuery({
            queryKey: ['objectTagKeysByLayerId', String(layerId)],
            queryFn: () => loadObjectTagKeysByLayerId(layerId),
            onError: (error) => console.log(error.message),
            staleTime: TIME_UPDATE,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        })
    )
}

// mutation
export const useUpdateObjectMutation = () => {
    const { showMask, hideMask } = useMask()
    const client = useQueryClient()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ data }) => updateObject(data),
            onMutate: () => showMask(),
            onSuccess: (_, variables,) => {
                hideMask()
                client.invalidateQueries({ queryKey: ['object', String(variables.data.id)] })
                client.invalidateQueries({ queryKey: ['propertiesObject', String(variables.data.id)] })
                client.invalidateQueries({ queryKey: ['preparePropsObject', String(variables.data.id)] })
                variables.layerIds.forEach(layerId => client.invalidateQueries({ queryKey: ['objectsToLayer', String(layerId)] }))
                enqueueSnackbar(`Свойства объекта #${variables.data.id} сохранены`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (_, variables) => {
                const message = `Ошибка. Свойства объекта #${variables.data.id} не сохранены`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
                hideMask()
            }
        })
    )
}

export const useRevertObjectMutation = () => {
    const client = useQueryClient()
    const { showMask, hideMask } = useMask()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ objectId, revId }) => objectRevertTo(objectId, revId),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                hideMask()
                client.invalidateQueries({ queryKey: ['object', String(variables.objectId)] })
                client.invalidateQueries({ queryKey: ['preparePropsObject', String(variables.objectId)] })
                variables.layerIds.forEach(layerId => client.invalidateQueries({ queryKey: ['objectsToLayer', String(layerId)] }))
                enqueueSnackbar(`Свойства объекта #${variables.objectId} изменены до ревизии revId=${variables.revId}`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (_, variables) => {
                const message = `Ошибка. Свойства объекта #${variables.objectId} не изменены`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
                hideMask()
            }
        })
    )
}

// удаление объекта из слоя
export const useDelObjectsFromLayerMutation = () => {
    const client = useQueryClient()
    const { showMask, hideMask } = useMask()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ layerId, objectIds }) => deleteObjectsFromLayer(layerId, objectIds), // !!! objectIds - array objectIds
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                hideMask()
                variables.objectIds.forEach(id => {
                    client.invalidateQueries({ queryKey: ['object', String(id)] })
                    client.invalidateQueries({ queryKey: ['preparePropsObject', String(id)] })
                })
                client.invalidateQueries({ queryKey: ['objectsToLayer', String(variables.layerId)] })
                client.invalidateQueries(['layerHierarhy'])
                client.invalidateQueries(['layersByParentId'])
                client.invalidateQueries(['layer'])
                enqueueSnackbar(`Объект${variables.objectIds.length > 1 ? 'ы' : ''} слоя #${variables.layerId} удален${variables.objectIds.length > 1 ? 'ы' : ''}`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (_, variables) => {
                const message = `Ошибка. Объект${variables.objectIds.length > 1 ? 'ы' : ''} слоя #${variables.layerId} не удален${variables.objectIds.length > 1 ? 'ы' : ''}`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
                hideMask()
            }
        })
    )
}

// добавление объекта на слой
// export const useAddingObjectFromLayerMutation = () => {
//     const client = useQueryClient()
//     const { showMask, hideMask } = useMask()
//     return (
//         useMutation({
//             mutationFn: ({ layerId, objectId }) => addingObjectFromLayer(layerId, objectId),
//             onMutate: () => showMask(),
//             onSuccess: (_, variables) => {
//                 hideMask()
//                 client.invalidateQueries({ queryKey: ['objectsToLayer', String(variables.layerId)] })
//                 client.invalidateQueries({ queryKey: ['object', String(variables.objectId)] })
//                 client.invalidateQueries({ queryKey: ['preparePropsObject', String(variables.objectId)] })
//                 client.invalidateQueries(['layerHierarhy'])
//                 client.invalidateQueries(['layersByParentId'])
//                 client.invalidateQueries(['layer'])
//             },
//             onError: (_, variables) => {
//                 const message = `Ошибка. Свойства объекта #${variables.objectId} не изменены`
//             }
//         })
//     )
// }

// удаление всех объектов из слоя
export const useDelAllObjectsFromLayerMutation = () => {
    const client = useQueryClient()
    const { showMask, hideMask } = useMask()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ layerId }) => deleteAllObjectsFromLayer(layerId),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                hideMask()
                client.invalidateQueries({ queryKey: ['objectsToLayer', String(variables.layerId)] })
                client.invalidateQueries(['layerHierarhy'])
                client.invalidateQueries(['layersByParentId'])
                client.invalidateQueries(['layer'])
                enqueueSnackbar(`Объекты слоя #${variables.layerId} удалены`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (_, variables) => {
                const message = `Ошибка. Объекты слоя #${variables.layerId} не удалены`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })                
                hideMask()
            }
        })
    )
}

// копирование объекта на другой слой
export const useCloneObjectToLayerMutation = () => {
    const { showMask, hideMask } = useMask()
    const client = useQueryClient()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ layerId, objectId }) => cloneObjectToLayer(layerId, objectId),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                hideMask()
                client.invalidateQueries({ queryKey: ['objectsToLayer', String(variables.layerId)] })
                client.invalidateQueries(['layerHierarhy'])
                client.invalidateQueries(['layersByParentId'])
                client.invalidateQueries(['layer'])
                enqueueSnackbar(`Объект #${variables.objectId} скопирован на слой layerId=${variables.layerId}`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (_, variables) => {
                const message = `Ошибка. Объект #${variables.objectId} не скопирован на слой`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })                
                hideMask()
            }
        })
    )
}


// создание объекта
export const useCreateGeometryObjectMutation = () => {
    const { showMask, hideMask } = useMask()
    const client = useQueryClient()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ type, layerId, name, geoJson }) => createGeometryObject(type, layerId, name, geoJson),
            onMutate: () => showMask(),
            onSuccess: (data, variables) => {
                hideMask()
                if (data) {
                    client.invalidateQueries({ queryKey: ['objectsToLayer', String(variables.layerId)] })
                    client.invalidateQueries(['layerHierarhy'])
                    client.invalidateQueries(['layersByParentId'])
                    client.invalidateQueries(['layer'])
                }
            },
            onError: (e) => {
                const message = `Ошибка. Объект не создан`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })                
                hideMask()
            }
        })
    )
}

// обновление геометрии объекта
export const useUpdateGeometryObjectMutation = () => {
    const { showMask, hideMask } = useMask()
    const client = useQueryClient()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({/*layerId,*/ data }) => updateGeometryObject(data),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                hideMask()
                client.invalidateQueries({ queryKey: ['objectsToLayer', String(variables.layerId)] })
            },
            onError: (e) => {
                const message = `Ошибка. Объект не обновлен`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })                
                hideMask()
            }
        })
    )
}

export const useUpdateIconObjectMutation = () => {
    const { setChangeIcon } = useActions()
    const queryClient = useQueryClient()
    const { hideMask, showMask } = useMask()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ objectId, iconId }) => updateIconObject(objectId, iconId),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                setChangeIcon(variables.iconId)
                queryClient.invalidateQueries({ queryKey: ['object', String(variables.objectId)] })
                queryClient.invalidateQueries({ queryKey: ['objectsToLayer'] }) // можно уточнить очистку - до конкретного слоя
                queryClient.invalidateQueries({ queryKey: ['galleryIcons'] })
                queryClient.invalidateQueries({ queryKey: ['galleryCategories'] })
                queryClient.invalidateQueries({ queryKey: ['galleryIconInfo'] })
                hideMask()
                enqueueSnackbar(`Иконка объекта #${variables.objectId} изменена`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (_, variables) => {
                hideMask()
                const message = `Иконка объекта #${variables.objectId} не изменена`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })                
            },
        })
    )
}