import { Button, Stack } from "@mui/material"
import { useContext, useEffect } from "react"
import { useForm } from "react-hook-form"
import PropTypes from 'prop-types'
import { prepareAssignedlayers, replaceNullValuesFromObject } from "../../../../../service/function"
import MyTabs from "../../../MyTabs/MyTabs"
import { useModal } from "../../../../../hooks/useModal/useModal"
import { usePrepareRoleDialogQuery, useSaveRoleMutation } from "../../../../../hooks/reactQuery/useRole"
import Loader from "../../../Loader/Loader"
import { RoleContext } from './RoleContext'
import General from "./Tabs/General"
import Users from "./Tabs/Users"
import Permissions from "./Tabs/Permissions"
import Layers from "./Tabs/Layers"
import { ModalWindowContext } from "../../../../../hooks/useModal/ModalWindow"
import FormContainer from "../../../ForForms/FormContainer"


const Role = ({ roleId }) => {
    const { openAlert } = useModal()
    const { onClose } = useContext(ModalWindowContext)

    const { mutate: saveRole, status: saveStatus } = useSaveRoleMutation()
    const { data: prepareRoleDialog = {}, isLoading, isFetching, error } = usePrepareRoleDialogQuery(roleId)

    const { role, editor, command, layerPerms = [] } = prepareRoleDialog //command здесь играет роль roleId. Равен "-1" для новой роли

    /* !!! Внимание! Если editor === false - то все поля делаем disabled. 
    Это сделано так, чтобы мы не могли отредактировать ограниченный определенный набор ролей, например: 'Администратор геопортала' и др. */


    const { control, handleSubmit, setValue, reset } = useForm({
        defaultValues: {
            'id': '',
            'name': '',
            'comment': '',
            'geoEdit': false,
            'printTmpl': '',
            'userIds': [],
            'aclIds': [],
            'layerRead': [],
            'layerWrite': [],
            'assignedlayers': []
        },
        mode: 'onSubmit'
    })


    const arrTabs = [
        {
            label: 'Общие',
            value: <General />
        },
        {
            label: 'Пользователи',
            value: <Users />
        },
        {
            label: 'Терр. права',
            value: <Permissions />
        },
        {
            label: 'Слои',
            value: <Layers />
        },
    ]

    // После загрузки данных - устанавливаем defaultValues через reset
    useEffect(() => {
        if (role) {
            reset({
                'geoEdit': role.geoEdit ? true : false,
                ...replaceNullValuesFromObject({ // заменяем значения полей null и undefined на '' - требование react-hook-form
                    'id': role.id,
                    'name': role.name,
                    'comment': role.comment,
                    'printTmpl': role.printTmpl,
                    'assignedlayers': role.assignedlayers,
                }),
                'userIds': role.userIds,
                'aclIds': role.aclIds,
                'layerRead': role.layerRead,
                'layerWrite': role.layerWrite,
                'assignedlayers': prepareAssignedlayers(layerPerms),
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching])

    useEffect(() => {
        if (error) {
            onClose()
            openAlert({
                title: 'Ошибка при загрузке страницы',
                children: error.response.data,
                backdropOpacity: 0.2,
                width: 600
            })
        }
    }, [error, onClose, openAlert])



    const onSubmit = async (data) => {
        data.assignedlayers = JSON.stringify(data.assignedlayers) // преобразуем данные assignedlayers в строку - так требует backEnd

        if (editor) {
            saveRole({ roleId: command, data })
        }
    }


    useEffect(() => {
        if (saveStatus === 'success') {
            onClose()
        }
        // eslint-disable-next-line
    }, [saveStatus])

    if (error) return null
    if (isLoading) return <Loader />

    return (
        <>
            <Stack component="form" method='POST'
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault() }}
                spacing={2} className='overflow'>

                <FormContainer
                    action={editor && <Button type='submit' variant='contained'>Сохранить</Button>}
                    disablePaddingRight
                    disablePaddingLeft
                >
                    <RoleContext.Provider value={{ prepareRoleDialog, control, setValue }}>
                        <MyTabs arrTabs={arrTabs} var='var1' />
                    </RoleContext.Provider>
                </FormContainer>
            </Stack>
        </>
    )
}

Role.propTypes = {
    roleId: PropTypes.number,
}

export default Role 