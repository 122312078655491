import $ from "jquery"
import L from "leaflet";
import { useDebouncedCallback } from 'use-debounce';
import { PropTypes } from 'prop-types';
import { useMap } from 'react-leaflet';
import { useUpdatePictureBoundsLayerMutation } from '../../../hooks/reactQuery/useLayer';
import { IMG_LAYER_PATH } from '../../../config';
import LayerDistortableImage from '../../general/MapLayers/LayerDistortableImage';


const EditImageOverlay = ({ layer }) => {
    // console.log({layer});
    
    const map = useMap()
    const { mutate: updatePictureBounds } = useUpdatePictureBoundsLayerMutation()

    const debouncedUpdateImageCorners = useDebouncedCallback((corners) => {
        const bounds = [
            [corners[0].lat, corners[0].lng],
            [corners[1].lat, corners[1].lng],
            [corners[3].lat, corners[3].lng],
            [corners[2].lat, corners[2].lng],
        ]

        updatePictureBounds({ layerId: layer.id, bounds: JSON.stringify(bounds) })
    }, 750)

    // edit ImageLayer
    const imageUrl = IMG_LAYER_PATH + layer.pictureUrl
    const b = map.getBounds()
    let imageBounds = [
        [b.getNorthWest().lat, b.getNorthWest().lng],
        [b.getNorthEast().lat, b.getNorthEast().lng],
        [b.getSouthEast().lat, b.getSouthEast().lng],
        [b.getSouthWest().lat, b.getSouthWest().lng]
    ]

    if (layer.pictureBounds) {
        try {
            let bounds = $.parseJSON(layer.pictureBounds)
            if (bounds != null) imageBounds = bounds
        } catch (e) {
            console.log(e)
        }
    }
        return (
            <LayerDistortableImage
                url={imageUrl}
                corners={[
                    new L.latLng(imageBounds[0][0], imageBounds[0][1]),
                    new L.latLng(imageBounds[1][0], imageBounds[1][1]),
                    new L.latLng(imageBounds[3][0], imageBounds[3][1]),
                    new L.latLng(imageBounds[2][0], imageBounds[2][1]),
                ]}
                mode={"distort"}
                actions={[L.DragAction, L.RotateAction, L.ScaleAction, L.DistortAction, L.FreeRotateAction, L.LockAction]}
                selected={true}
                suppressToolbar={false}
                zIndex={200}
                onUpdate={(props) => {
                    debouncedUpdateImageCorners(props)
                }}
                opacity={Number(layer.pictureOpacity) > 0 ? layer.pictureOpacity / 100 : 1}
            />
        )
}

EditImageOverlay.propTypes = {
    layer: PropTypes.object
}

export default EditImageOverlay