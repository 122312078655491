import api from "../http";
import {ApiPaths} from "../../config"


// users
export const loadUsers = async ({ term, offset, limit }) => {
    const result = await api.get(
        ApiPaths.admin.user.USERS
            .replace(':term', term)
            .replace(':offset', offset)
            .replace(':limit', limit)
    )
    return result.data
}

export const delUser = async (userId) => {
    return await api({
        url: ApiPaths.admin.user.REMOVE_USER.replace(':userId', userId),
        method: 'DELETE',
    })
}

export const saveUser = async (userId, data) => {
    return await api({
        url: ApiPaths.admin.user.SAVE_USER.replace(':userId', userId),
        method: 'post',
        data: data,
    })
}

export const loadPrepareUserDialog = async (userId) => {
    const result = await api.get(
        userId ? ApiPaths.admin.user.PREPARE_USER_DIALOG.replace(':userId', userId) : ApiPaths.admin.user.PREPARE_NEW_USER_DIALOG, // создание или редактирование
    )
    return result.data
}


// roles
export const loadRoles = async ({ term, offset, limit }) => {
    const result = await api.get(
        ApiPaths.admin.role.ROLES
            .replace(':term', term)
            .replace(':offset', offset)
            .replace(':limit', limit)
    )
    return result.data
}

export const delRole = async (roleId) => {
    return await api({
        url: ApiPaths.admin.role.REMOVE_ROLE.replace(':roleId', roleId),
        method: 'DELETE',
    })
}

export const saveRole = async (roleId, data) => {
    return await api({
        url: ApiPaths.admin.role.SAVE_ROLE.replace(':roleId', roleId),
        method: 'post',
        data: data,
    })
}

export const loadPrepareRoleDialog = async (roleId) => {
    const result = await api.get(
        roleId ? ApiPaths.admin.role.PREPARE_ROLE_DIALOG.replace(':roleId', roleId) : ApiPaths.admin.role.PREPARE_NEW_ROLE_DIALOG, // создание или редактирование
    )
    return result.data
}

// areas
export const loadAreas = async ({ term, offset, limit }) => {
    const result = await api.get(
        ApiPaths.admin.area.AREAS
            .replace(':term', term)
            .replace(':offset', offset)
            .replace(':limit', limit)
    )
    return result.data
}

export const delArea = async (areaId) => {
    return await api({
        url: ApiPaths.admin.area.REMOVE_AREA.replace(':areaId', areaId),
        method: 'DELETE',
    })
}

export const saveArea = async (areaId, data) => {
    return await api({
        url: ApiPaths.admin.area.SAVE_AREA.replace(':areaId', areaId),
        method: 'post',
        data: data,
    })
}

export const loadPrepareAreaDialog = async (areaId) => {
    const result = await api.get(ApiPaths.admin.area.PREPARE_AREA_DIALOG.replace(':areaId', areaId))
    return result.data
}

export const cloneObjectToLayer = async (layerId, objectId) => {
    const url = ApiPaths.object.CLONE_OBJECT_TO_LAYER
        .replace(":objectId", objectId)
        .replace(":layerId", layerId)
    return await api.get(url)
}
