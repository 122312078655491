import { Button, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import useLayerById from "../../../hooks/reactQuery/selectorCache/useLayerById";
import { useLayerUpdateHeatMapMutation } from "../../../hooks/reactQuery/useLayer";
import PropTypes from 'prop-types'
import FormContainer from "../ForForms/FormContainer";


const HeatMap = ({ layerId }) => {

    const layer = useLayerById(layerId)
    const heatmap = JSON.parse(layer.heatmap)

    const { mutate: updateHeatMap } = useLayerUpdateHeatMapMutation()
    const { control, handleSubmit } = useForm({
        defaultValues: {
            gradient: heatmap ? heatmap.gradient : '',
            heatradius: heatmap ? heatmap.heatradius : '',
            layerId: layerId,
            parentId: layer.parentId
        },
        mode: 'onSubmit'
    })

    const onSubmit = async (data) => {
        updateHeatMap({
            layerId,
            heatmap: JSON.stringify({ gradient: data.gradient, heatradius: data.heatradius })
        })
    }

    return (
        <FormContainer
            action={<Button type='submit' variant='contained'>Сохранить</Button>}
            component="form"
            method='POST'
            onSubmit={handleSubmit(onSubmit)}
        >
            <Controller
                name='gradient'
                control={control}
                render={({ field }) => <TextField {...field}
                    type='text'
                    label='Настройки градиента'
                />}
            />

            <Controller
                name='heatradius'
                control={control}
                render={({ field }) => <TextField {...field}
                    type='number'
                    label='Радиус'
                />}
            />
        </FormContainer>
    )
}

export default HeatMap

HeatMap.propTypes = {
    layerId: PropTypes.number.isRequired,
}