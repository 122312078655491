import { Box, } from '@mui/material'
import MapControl from '../MapControl/MapControl'
import MapLayers from '../../general/MapLayers/MapLayers'
import MapEvents from '../../general/Events/MapEvents'
import MutableTileLayer from "../../general/Map/Leaflet/MutableTileLayer"
import useAuth from '../../../hooks/useAuth'
import MutableWmsTileLayer from '../../general/Map/Leaflet/MutableWmsTileLayer'
import LeafletMap from '../Map/LeafletMap'
import SidePanel from '../SidePanel/SidePanel'
import SideContextProvider from '../SidePanel/SideContextProvider'


// Проверяем авторизацию. Выносим отдельно, чтобы не было rerendera всего MapLayout
const Auth = () => {
    useAuth()
    return null
}


const MapLayout = () => {
    // console.log("Render MapLayout");

    return (
        <>
            <SideContextProvider>
                <Auth />
                <Box sx={{ display: 'flex' }}>
                    <SidePanel />

                    <LeafletMap>
                        <MutableTileLayer />
                        <MutableWmsTileLayer />
                        <MapEvents />
                        <MapControl />
                        <MapLayers />
                    </LeafletMap>
                </Box>
            </SideContextProvider>
        </>
    )
}

export default MapLayout
