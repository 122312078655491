import Category from "../components/general/Category/Category"
import useDeviceDetect from "../hooks/useDeviceDetect"

const HomePage = () => {
    const { isMobile } = useDeviceDetect()

    return (
        isMobile ? <Category /> : null
    )
}

export default HomePage