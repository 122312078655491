// import { Box, Button, IconButton, SvgIcon } from "@mui/material"
// import CloseIcon from '@mui/icons-material/Close';
// import { BoxMarginLeftRight } from "../components/desktop/StyledComponents/BoxMarginLeftRight";


const TestPage = () => {

    // const factorial = (n) => {
    //     return (n === 1) ? 1 : n * factorial(n - 1)
    // }

    // const testFn = (n) => {
    //     if (n >= 5000) {
    //         return factorial (n)
    //     }

    //     const result = 2*testFn(n+1)/(n+1)
    //     return result
    // }

    // const res = 1000*testFn(7)/testFn(4)
    // console.log({res});

    return (
        <>
            {/* <BoxMarginLeftRight><Button type='submit' variant='contained'
            // disabled
            >Скопировать</Button></BoxMarginLeftRight> */}
        </>
    )
}

export default TestPage