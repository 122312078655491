export const SELECTED_LAYER_IDS_ADD_DATA = "SELECTED_LAYER_IDS_ADD_DATA"
export const SELECTED_LAYER_IDS_DEL_DATA = "SELECTED_LAYER_IDS_DEL_DATA"
export const SELECTED_LAYER_RESET = "SELECTED_LAYER_RESET"


const initialState = [] // массивы Id выделенных слоев

const addSelectedLayer = (state, payload) => { // добавляет массив элементов
    let newState = [
        ...state,
        ...payload.layerIds,
    ]
    newState = [...new Set(newState)] // удаляем дубликаты, если они есть
    return newState
}

const delSelectedLayers = (state, payload) => { // удаляет массив элементов
    let newState = state.filter(id => payload.layerIds.indexOf(id) === -1)
    return newState
}

export default function SelelectedLayersReducer(state = initialState, action) {
    switch (action.type) {
        case SELECTED_LAYER_IDS_ADD_DATA: 
            return addSelectedLayer(state, action.payload)
        case SELECTED_LAYER_IDS_DEL_DATA:
            return delSelectedLayers(state, action.payload)
        case SELECTED_LAYER_RESET:
            return initialState
        default:
            return state
    }
}