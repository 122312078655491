import { memo } from "react"
import React, { useEffect, useRef } from 'react'
import { Box, ImageList, ImageListItem, ImageListItemBar } from '@mui/material'
import jsRender from "jsrender"
import $ from "jquery"
import MyAlert from '../Info/MyAlert'
import Loader from '../../desktop/Loader/Loader'


/* start Подключаем функции для JsRendera */
const loadJsRenderFunc = async () => {
    const { jsRenderFunc } = await import('../../../service/jsrender-func');
    jsRenderFunc();
}

loadJsRenderFunc();
const render = jsRender($);

const currentBlockImg = (res) => {
    let listObjImg = []
    let jqRes = $(res).find('.imgList')

    if (jqRes.length > 0) {
        $.each(jqRes, (i, elem) => {
            let list = []
            let listImg = $(elem).find('img')
            $.each(listImg, (j, el) => {
                list.push({
                    title: $(el).attr('title'),
                    src: $(el).attr('src')
                })
            })
            listObjImg = [
                ...listObjImg,
                {
                    title: $(elem).attr('data-title'),
                    columns: Number($(elem).attr('data-columns')),
                    list,
                }
            ]
        })
    }
    return listObjImg;
}
/* end */


const ObjectTemplate = ({ model, pattern, isLoading, objectError, propertiesError }) => {

    const resultRef = useRef()
    let imgData;

    const renderTemplate = (t, m) => {
        try {
            const func = render.templates(t)
            let res = func(JSON.parse(m))
            imgData = currentBlockImg(res)

            if (resultRef.current) {
                resultRef.current.innerHTML = res
            }
        } catch (e) {
            resultRef.current.innerHTML = e
        }
    }

    useEffect(() => {
        if (model && pattern) {
            /* добавляем данные в модель для подстраивания размеров видео */
            if (resultRef.current) {
                const w = resultRef.current.offsetWidth
                model.zoom = (w / 640)
                model.video_h = (w / 640) * 360
                renderTemplate(pattern, JSON.stringify(model, null, 4))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pattern, model])

    if (objectError) {
        return <MyAlert severity='error' title='Ошибка' message={objectError} />
    }

    if (propertiesError) {
        return <MyAlert severity='error' title='Ошибка' message={propertiesError} />
    }

    if (isLoading) return <Loader />

    return (
        <>
            <Box className='template' ref={resultRef}></Box>
            {imgData &&
                <Box>
                    {imgData.map(itemBlock => (
                        <React.Fragment key={itemBlock.title}>
                            <h4>{itemBlock.title}</h4>
                            {itemBlock.list.length > 0 &&
                                <ImageList cols={itemBlock.columns ? itemBlock.columns : 2} rowHeight={164}>
                                    {itemBlock.list.map(item => (
                                        <ImageListItem
                                            key={item.src}
                                            onClick={() => window.open(item.src, '_blank').focus()}
                                        >
                                            <img
                                                src={`${item.src}`}
                                                alt={item.title ? item.title : item.src}
                                                loading="lazy"
                                            />
                                            {item.title &&
                                                <ImageListItemBar
                                                    subtitle={item.title}
                                                />
                                            }
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            }
                        </React.Fragment>
                    ))}
                </Box>
            }
        </>
    )
}

export default memo(ObjectTemplate)