import { useQuery } from 'react-query'
import { TIME_UPDATE } from "../../config"
import { loadObjectsToLayer } from "../../service/axiosFuncQuery/objectsToLayerQuery"


export const useObjectsToLayerQuery = (layerId) => {
    return (
        useQuery({
            queryKey: ['objectsToLayer', String(layerId)],
            queryFn: () => loadObjectsToLayer(layerId),
            onError: (error) => console.log(error.message),
            staleTime: TIME_UPDATE,
            enabled: layerId > 0,
            refetchOnWindowFocus: false,  // не делать повторный запрос при возвращении на страницу
        })
    )
}