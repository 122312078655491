import { IconButton, FormControl, MenuItem, Select, TableCell as MuiTableCell, TableRow, styled, SvgIcon } from '@mui/material';
import { ReactComponent as DelIcon } from '../../../../../Icons/desktop/propLayer/DelIcon.svg'
import { pxToRem } from '../../../../Style/desktopTheme';


const TableCell = styled(MuiTableCell)({
    paddingTop: '8px',
    paddingBottom: '8px',
})

const Row = ({ index, name, comment, permission, setValue, removePermission, permissionMode, editor }) => (
    <TableRow>
        <TableCell>{name}</TableCell>
        <TableCell>
            <FormControl fullWidth>
                <Select
                    disabled={!editor || !setValue}
                    variant="outlined"
                    value={permission}
                    onChange={(e) => setValue(`permission.${index}.permission`, e.target.value)}
                    size='small'
                >
                    <MenuItem value={permissionMode.read}>Чтение</MenuItem>
                    <MenuItem value={permissionMode.write}>Запись</MenuItem>
                </Select>
            </FormControl>
        </TableCell>
        <TableCell>{comment}</TableCell>
        <TableCell sx={{ padding: 0 }}>
            {(removePermission && editor) && <IconButton onClick={() => removePermission(index)}>
                <SvgIcon style={{ fontSize: pxToRem('32px') }} inheritViewBox><DelIcon fill="rgba(51, 51, 51, 0.4)" /></SvgIcon>
            </IconButton>}
        </TableCell>
    </TableRow>
)

export default Row