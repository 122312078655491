import { Box, IconButton } from "@mui/material"
import PropTypes from 'prop-types'
import { ReactComponent as CloseIcon } from "../../../Icons/CloseIcon.svg";

const CloseButton = ({ handleClick }) => {
    return (
        <Box>
            <IconButton size='40' onClick={ handleClick } >
                <CloseIcon />
            </IconButton>
        </Box>
    )

}

export default CloseButton

CloseButton.propTypes = {
    handleClick: PropTypes.func.isRequired
}