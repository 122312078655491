import { useSelector } from "react-redux"
import { mapArcGisSelector } from "../../../store/selectors/selectors"
import Loader from "../../desktop/Loader/Loader"
import MyAlert from "../Info/MyAlert"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { useParams } from "react-router-dom"


const ArcGisObject = () => {

    const { error, isLoading, data } = useSelector(mapArcGisSelector)
    const objectId = Number(useParams().objectId)
    const index = data.featureCollection?.features?.findIndex(f => Number(f.id) === objectId)
    const properties = data.result?.[index]?.attributes || {}

    if (isLoading) return <Loader />
    if (error) return <MyAlert severity='error' title='Ошибка' message={error} />

    return (
        <>
            <Table padding='normal' size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Наименование</TableCell>
                        <TableCell>Значение</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {Object.keys(properties).map(key => (
                        <TableRow key={key}>
                            <TableCell>{key}</TableCell>
                            <TableCell>{properties[key]}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    )
}

export default ArcGisObject