import { Button, FormLabel, Stack } from "@mui/material"
import { useContext, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { getChangeObjectsDate, getFormatDate, replaceNullValuesFromObject } from "../../../../../service/function"
import { LayerPropertyContext } from "../../LayerPropertyContext"
import MetadataFormControllers from "../../components/MetadataFormControllers"
import { useLayerMetadataMutation, useUpdateLayerMetadataMutation } from "../../../../../hooks/reactQuery/useLayer"
import Link from '../../../../../hoc/LinkWithSearchParams'
import FormContainer from "../../../ForForms/FormContainer"


const Metadata = () => {

    const { propsLayer: { editor, spatialDataWeight }, layer, formStateRef } = useContext(LayerPropertyContext)
    // console.log("spatialDataWeight", spatialDataWeight);
    // return null

    const { mutate: updateLayerMetadata } = useUpdateLayerMetadataMutation()
    const { mutate: getLayerMetadata } = useLayerMetadataMutation()

    const propVisible = [
        'id',
        'createdDateTime',
        'changedDateTime',
        'descSpatialName',
        'descSpatialData',
        'accessLevel',
        'sourceSpatialData',
        'hasOwner',
        'ownerCompany',
        'ownerEmail',
        'ownerPhone',
        'docRegulation',
        'mapAccuracy',
        'accessConditions'
    ]

    const prepareDefaultValues = () => {
        let defaultValues = {}
        propVisible.forEach(p => {
            defaultValues[p] = layer[p]
        })
        return replaceNullValuesFromObject(defaultValues)
    }

    const { control, handleSubmit, watch, setValue, getValues } = useForm({
        defaultValues: formStateRef.current['metadata'] ? formStateRef.current['metadata'] : prepareDefaultValues(),
        mode: 'onSubmit'
    })

    let hasOwner = editor ? watch('hasOwner') : layer.hasOwner

    /* Функционал Скопировать с Родителя */
    const inheritMetadata = async (parentId) => {
        const fields = [
            'descSpatialName',
            'descSpatialData',
            'accessLevel',
            'sourceSpatialData',
            'hasOwner',
            'ownerCompany',
            'ownerEmail',
            'ownerPhone',
            'docRegulation',
            'mapAccuracy',
            'accessConditions',
        ]

        getLayerMetadata(
            parentId,
            {
                onSuccess: (data) => {
                    fields.forEach(p => {
                        setValue(p, data[p])
                    })
                }
            }
        )
    }

    const onSubmit = async (data) => {
        updateLayerMetadata(data)
    }

    // сохраняем значения полей формы при размонтировании компонента (при переходе на другую вкладку) 
    // Установку при монтировании - см. defaultValues
    useEffect(() => {
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            formStateRef.current['metadata'] = getValues()
        }
    }, [formStateRef, getValues])

    return (
        <FormContainer
            action={
                editor &&
                <Stack direction='row' spacing={5}  >
                    <Button
                        variant='outlined'
                        type='button'
                        onClick={() => inheritMetadata(layer.parentId)}
                    >Скопировать с родителя</Button>

                    <Controller
                        name='send'
                        control={control}
                        render={({ field }) =>
                            <Button
                                variant='contained'
                                type='submit'
                                onClick={handleSubmit(onSubmit)} {...field}
                            >Сохранить</Button>
                        }
                    />
                </Stack>
            }
            component="form"
            method='POST'
            onSubmit={handleSubmit(onSubmit)}
        >
            <Stack direction='row'><FormLabel>Дата создания слоя: &nbsp;</FormLabel>{getFormatDate(layer?.createdDateTime)}</Stack>
            <Stack direction='row'><FormLabel>Дата изменения свойств слоя: &nbsp;</FormLabel>{getFormatDate(layer?.changedDateTime)}</Stack>
            <Stack direction='row'><FormLabel>Дата изменения объектов: &nbsp;</FormLabel>{getChangeObjectsDate(spatialDataWeight)}</Stack>

            <MetadataFormControllers {...{ control, editor, hasOwner }} />

            <Link target='_blank' href={`/layer/metadata/${layer?.id}/view/editors`}>Посмотреть редакторов слоя</Link>
        </FormContainer>
    )
}

export default Metadata