import { ARCGIS_SET_ERROR, ARCGIS_SET_IS_LOADING, ARCGIS_SET_DATA } from "./index"


export const MapArcGisActionCreators = {
    ArcGisSetError: (error) => {
        return{
            type: ARCGIS_SET_ERROR,
            payload: error
        }
    },
    ArcGisSetIsLoading: (isLoading) => {
        return {
            type: ARCGIS_SET_IS_LOADING,
            payload: isLoading
        }
    },
    ArcGisSetData: (featureCollection, result) => {
        return {
            type: ARCGIS_SET_DATA,
            payload: {featureCollection, result}
        }
    },
}