import { createContext, useCallback, useContext, useState } from "react";
import MaskProgress from "../components/general/Info/MaskProgress";


const MaskContext = createContext({})

export const useMask = () => useContext(MaskContext)

export const MaskContextProvider = ({children}) => {

    const [visible, setVisible] = useState(false)

    const showMask = useCallback(() => {
        setVisible(true)
    }, [])

    const hideMask = useCallback(() => {
        setVisible(false)
    }, [])

    const maskContextValue = {
        showMask,
        hideMask,
    }

    return (
        <MaskContext.Provider value={maskContextValue}>
            <MaskProgress open={visible} />
            {children}
        </MaskContext.Provider>
    )
}