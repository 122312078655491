import system from './system';
import layerRecent from './layerRecent';
import map from './map';
import auth from './auth';
import user from './user';
import selectedLayerIds from './selectedLayerIds';
import mapArcGis from './mapArcGis';


const reducers = {
    system,
    layerRecent,
    map,
    auth,
    user,
    selectedLayerIds,
    mapArcGis,
}

export default reducers;