import api from "../http";
import {ApiPaths} from "../../config"


export const loadObject = async (objectId) => {
    const result = await api.get(ApiPaths.object.OBJECT.replace(':objectId', objectId))
    return result.data
}

export const loadPreparePropsObject = async (objectId) => {
    const result = await api.get(ApiPaths.object.PREPARE_PROPS_OBJECT.replace(':objectId', objectId))
    return result.data
}

export const loadPropertiesObject = async (id) => {
    const result = await api({
        url: ApiPaths.object.PROPERTIES,
        method: 'post',
        data: { id },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
    return result.data
}


export const objectRevertTo = async (objectId, revId) => {
    return await api({
        url: ApiPaths.object.REVERT,
        method: 'post',
        data: { objectId, revId },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

export const loadObjectRevisions = async (objectId, revId = '') => {
    const result = await api.get(ApiPaths.object.HISTORY
        .replace(':objectId', objectId)
        .replace(
            revId ? ':revId' : '?revId=:revId',
            revId)
    )
    return result.data
}

export const deleteObjectsFromLayer = async (layerId, objectIds) => {       // objectIds - array objectId
    return await api({
        url: ApiPaths.object.DELETE_OBJECTS_FROM_LAYER,
        method: 'post',
        data: JSON.stringify({ objIds: objectIds, layerId }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export const addingObjectFromLayer = async (layerId, objectId) => {
    const url = ApiPaths.object.ADD_OBJECT_IN_LAYER
        .replace(":objectId", objectId)
        .replace(":layerId", layerId)
    return await api.get(url)
}

export const deleteAllObjectsFromLayer = async (layerId) => {
    const formData = new FormData()
    formData.append('layerId', layerId)
    return await api({
        url: ApiPaths.object.DELETE_ALL_OBJECTS_FROM_LAYER,
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': '"multipart/form-data'
        },
    })
}

export const loadFilteredObjectsToLayer = async (layerId, term, start, amount) => {

    const result = await api.post(ApiPaths.object.FILTER_OBJECTS_TO_LAYER,
        `layerId=${layerId}&start=${start}&amount=${amount}&filter[]=${term}`
    )
    return result.data
}

export const updateObject = async (data) => {
    return await api({
        url: ApiPaths.object.UPDATE,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export const createGeometryObject = async (type, layerId, name, geoJson) => {
    const obj = JSON.stringify(geoJson)
    const body = { type, layerId, obj, name }

    const result = await api.post(
        ApiPaths.object.CREATE,
        body,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
    )

    if (result.status === 200) {
        return true
    }
    return false
}

export const updateGeometryObject = async (data) => {
    return await api({
        url: ApiPaths.object.UPDATE_OBJECTS_GIS,
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'application/json' }
    })
}

export const loadObjectTagKeysByLayerId = async (layerId) => {
    const result = await api.get(ApiPaths.object.OBJECT_TAG_KEYS_BY_LAYER_ID.replace(':layerId', layerId))
    return result.data
}
