import React, { useContext } from 'react';
import { Box, Checkbox, FormControl, FormControlLabel, FormLabel, InputLabel, Link, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { LayerPropertyContext } from '../../LayerPropertyContext';
import HeadBoxForm from '../../components/HeadBoxForm';
import { ApiPaths, IMG_LAYER_PATH } from '../../../../../config';
import IconBox from '../../../ForForms/IconBox/IconBox';
import { layerPropVisiblityTypeId, sortedLayerType } from './fieldData';
import FormContainer from '../../../ForForms/FormContainer';


const PropView = () => {

    const { propsLayer, layer, templates } = useContext(LayerPropertyContext)

    // функция, определяющая отображать или нет поле в зависимости от выбранного typeId
    const isVisibleField = (name) => {
        return layerPropVisiblityTypeId[layer.typeId].includes(name)
    }

    return (
        <>
            <HeadBoxForm layer={layer} countObjects={propsLayer.spatialDataWeight?.count} />

            <FormContainer>
                <Stack direction='row'><Typography variant='font_24' component={FormLabel}>Идентификатор слоя:&nbsp;</Typography>#{layer.id}</Stack>

                <Box><FormControlLabel control={<Checkbox disabled checked={layer.isService} />} label='Сервисный слой' /></Box>


                <TextField disabled label='Номер для сортировки' defaultValue={layer.position} />

                <FormControl fullWidth>
                    <InputLabel>Тип слоя</InputLabel>
                    <Select label='Тип слоя' disabled value={layer.typeId}>
                        {sortedLayerType.map(item => (
                            <MenuItem key={item[1]} value={item[0]}>{item[1]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {isVisibleField('wsdl') &&
                    <Stack direction='row'><Typography variant='font_24' component={FormLabel}>Веб сервис:&nbsp;</Typography><Link href="ws?wsdl" target="_blank">WSDL</Link></Stack>
                }

                {isVisibleField('viewByObject') &&
                    <Box><FormControlLabel control={<Checkbox disabled checked={layer.viewByObject} />} label='Отображение по объектам' /></Box>
                }

                {isVisibleField('feedbackByObject') &&
                    <Box><FormControlLabel control={<Checkbox disabled checked={layer.feedbackByObject} />} label='Обратная связь с автором объектов' /></Box>
                }

                {(isVisibleField('iconId') && layer.parentId) &&
                    <Stack direction='row' alignItems='center'>
                        <Typography variant='font_24' component={FormLabel}>Иконка объекта точка:&nbsp;</Typography>
                        <Box>
                            <IconBox
                                iconId={layer.iconId}
                                src={ApiPaths.icon.FROM_LAYER.replace(":layerId", layer.id)}
                                editor={false}
                            />
                        </Box>
                    </Stack>
                }

                {(isVisibleField('iconShape') && layer.parentId) &&
                    <FormControl fullWidth>
                        <InputLabel>Тип иконки объекта точка</InputLabel>
                        <Select value={layer.iconShape} disabled label='Тип иконки объекта точка'>
                            <MenuItem key='circle' value={0}>Круг</MenuItem>
                            <MenuItem key='drop' value={1}>Капля</MenuItem>
                        </Select>
                    </FormControl>
                }

                {isVisibleField('lineColor') &&
                    <TextField disabled defaultValue={layer.lineColor} label='Цвет границ' />
                }

                {isVisibleField('lineWeight') &&
                    <TextField disabled defaultValue={layer.lineWeight} label='Толщина линии (px)' />
                }

                {isVisibleField('fillColor') &&
                    <TextField disabled defaultValue={layer.fillColor} label='Цвет заливки' />
                }

                {(isVisibleField('fillImageWidth') && isVisibleField('fillImageHeight')) &&
                    <Stack direction='row' spacing={2}>
                        <TextField disabled defaultValue={layer.fillImageWidth} label='Ширина изображения (px)' />
                        <TextField disabled defaultValue={layer.fillImageHeight} label='Высота изображения (px)' />
                    </Stack>
                }

                {isVisibleField('fillOpacity') &&
                    <TextField disabled defaultValue={layer.fillOpacity} label='Прозрачность заливки (от 0% до 100%)' />
                }

                {isVisibleField('captionTag') &&
                    <TextField
                        disabled
                        defaultValue={layer.captionTag}
                        multiline
                        rows={2}
                        label='Шаблон для отображения в названии'
                        helperText={`Используйте названия тегов в скобках: {{name}}`}
                    />
                }

                {(isVisibleField('captionSize') && isVisibleField('fontColor') && isVisibleField('fontBold')) &&
                    <Stack direction='row' spacing={2}>
                        <TextField disabled defaultValue={layer.captionSize} label='Размер шрифта (px)' />
                        <TextField disabled defaultValue={layer.fontColor} label='Цвет шрифта' />
                        <Box><FormControlLabel control={<Checkbox disabled checked={layer.fontBold} />} label='Жирный' /></Box>
                    </Stack>
                }

                {(isVisibleField('haloSize') && isVisibleField('haloColor')) &&
                    <Stack direction='row' spacing={2}>
                        <TextField disabled defaultValue={layer.haloSize} label='Размер HALO (px)' />
                        <TextField disabled defaultValue={layer.haloColor} label='Размер HALO (px)' />
                    </Stack>
                }

                {isVisibleField('tmplId') &&
                    <FormControl fullWidth>
                        <InputLabel>Шаблон для &apos;облака&apos;</InputLabel>
                        <Select disabled value={layer.tmplId}
                            label="Шаблон для 'облака'"
                            variant="outlined"
                        >
                            <MenuItem key='none' value={false}>Не выбран</MenuItem>
                            {templates.map(t => [t.id, t.name]).map(item => (
                                <MenuItem key={item[1]} value={item[0]}>{item[1]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }

                {isVisibleField('wmsUrl') &&
                    <Stack direction='row' alignItems='center'>
                        <Typography variant='font_24' component={FormLabel}><>Адрес <Link href="http://www.opengeospatial.org/standards/wfs" target="_blank">WMS</Link> сервиса</>:&nbsp;</Typography>
                        <TextField disabled defaultValue={layer.wmsUrl} fullWidth />
                    </Stack>
                }

                {isVisibleField('tileServiceUrl') &&
                    <TextField disabled defaultValue={layer.url}
                        label='Адрес тайлового* сервиса'
                        helperText={` *Пример строки подключения:<br/>
            http://tile.host.ru/TileService.ashx?Request=GetTile&LayerName={layername}&apikey={apikey}&z={z}&x={x}&y={y}<br/>
            Обязательные параметры запроса<br/>
            {layername} – цифробуквенный идентификатор слоя. Берется из значения тега {layername} в ответе на запрос
            Request=GetCapabilities, совпадает со служебным полем ID слоя в “личной карте пользователя”.<br/>
            {apikey} – ключ доступа<br/>
            {x}, {y}, {z} – номер тайла`}
                    />
                }

                {isVisibleField('esriUrl') &&
                    <TextField disabled defaultValue={layer.esriUrl} label='Адрес сервиса ArcGis' />
                }

                {isVisibleField('layerPicture') &&
                    <Stack direction='row' spacing={1}>
                        <FormLabel>Скан карты (картинка в формате jpg):</FormLabel>
                        {layer.pictureUrl && <img width={200} src={IMG_LAYER_PATH + layer.pictureUrl} alt='Скан карты' />}
                    </Stack>
                }

                {isVisibleField('pictureOpacity') &&
                    <TextField disabled defaultValue={layer.pictureOpacity} label='Прозрачность заливки (от 0% до 100%)' />
                }

                {isVisibleField('pictureBounds') &&
                    <TextField disabled defaultValue={layer.pictureBounds} multiline label='Координаты углов картинки (WGS84)' />
                }

                {isVisibleField('rnisApiKey') &&
                    <TextField disabled defaultValue={layer.rnisApiKey} label='RNIS API KEY' />
                }

                {isVisibleField('vehicleTailLength') &&
                    <TextField disabled defaultValue={layer.vehicleTailLength} label='Длина хвоста(точки)' />
                }

                {isVisibleField('vehicleTailColor') &&
                    <TextField disabled defaultValue={layer.vehicleTailColor} label='Цвет хвоста' />
                }

                {isVisibleField('vehicleInactivityPeriod') &&
                    <TextField disabled defaultValue={layer.vehicleInactivityPeriod} label='Период неактивности(часы)' />
                }
            </FormContainer>
        </>
    )
}

export default PropView