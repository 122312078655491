import {HeatmapLayerFactory}  from "@vgrid/react-leaflet-heatmap-layer";
import { useObjectsToLayerQuery } from "../../../hooks/reactQuery/useObjectsToLayer"


const CreateHeatMap = ({ layer }) => {

    const HeatmapLayer = HeatmapLayerFactory()

    const heatmeta = JSON.parse(layer.heatmap)
    const gradient = JSON.parse(heatmeta.gradient)
    const radius = Number(heatmeta.heatradius)

    const { data: objects, isFetching } = useObjectsToLayerQuery(layer.id)

    if (isFetching) return null

    const points = objects.map(o => [o.lat, o.lon, 0.1]);    

    return (
        <>
            <HeatmapLayer
                points={points}
                longitudeExtractor={m => m[1]}
                latitudeExtractor={m => m[0]}
                intensityExtractor={m => m[2]}
                gradient={gradient}
                radius={radius}
                opacity={0.5}
            />
        </>
    )
}

export default CreateHeatMap