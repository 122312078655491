// import isEqual from 'fast-deep-equal';
import { useRef, useEffect} from 'react';
import { useLeafletContext } from '@react-leaflet/core';

import { Control, DomUtil, DomEvent} from 'leaflet';


function CustomLeafletButton(props) {
    const context = useLeafletContext();
    const btnRef = useRef();
    const propsRef = useRef(props);


    useEffect(() => {
        const { map } = context;
        const { onMounted } = props;

        btnRef.current = createDrawElement(props, context);
        map.addControl(btnRef.current);
        onMounted && onMounted(btnRef.current);

        return () => {
            btnRef.current.remove(map);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            props.position === propsRef.current.position
        ) {
            return;
        }
        const { map } = context;

        btnRef.current.remove(map);
        btnRef.current = createDrawElement(props, context);
        btnRef.current.addTo(map);

        return () => {
            btnRef.current.remove(map);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.position]);

    return null;
}

const ButtonControl = Control.extend({
    options: { position: "topleft", title: "" },
    onAdd: function (map) {
        const container = DomUtil.create("div", "leaflet-draw");
        //const section = DomUtil.create("div", "leaflet-draw-section", container);
        const toolbar = DomUtil.create("div", "leaflet-draw-toolbar leaflet-bar", container);
        toolbar.setAttribute('style', "margin-top:0;")
        const link = DomUtil.create("a", this.options.class, toolbar);
        const linkAttrs = {
            title: this.options.title,
            href: "#",
        };
        Object.entries(linkAttrs).forEach(([k, v]) => {
            link.setAttribute(k, v);
        });
        // link.innerHTML = icon;
        // if (RegExp(/url\(.+\)/).test(icon)) {
        //     link.innerHTML = "";
        //     link.style.backgroundImage = icon;
        // }
        DomEvent.on(link, "mousedown dblclick", DomEvent.stopPropagation)
            .on(link, "click", DomEvent.stop)
            .on(link, "click", this.options.onClickEventHandler, this);
        return container;
    },
});

function createDrawElement(props, context) {
    // const { layerContainer } = context;
    const options = {

    };
    if (props.position) {
        options.position = props.position;
    }
    if (props.class) {
        options.class = props.class;
    }
    if (props.title) {
        options.title = props.title;
    }
    if (props.onClick) {
        options.onClickEventHandler = props.onClick;
    }

    return new ButtonControl(options);
}


export default CustomLeafletButton;