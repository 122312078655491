// auth
export const authSelector = state => state.auth
export const isAuthSelector = state => state.auth.isAuth
export const userLoginSelector = state => state.auth.login

// layerRecent
export const layerRecentSelector = state => state.layerRecent

// map
export const mapSelector = state => state.map
export const mapTileLayerSelector = state => state.map.tileLayer
export const editOnMapLayerSelector = state => mapSelector(state).editOnMapLayer
export const heatMapIdsSelector = state => mapSelector(state).analiticalMapIds.heatmap
export const changeIconSelector = state => mapSelector(state).changeIcon

// mapArcGis
export const  mapArcGisSelector = state => state.mapArcGis

// selectedLayerIds
export const selectedLayerIdsSelector = state => state.selectedLayerIds

// system
export const systemSelector = state => state.system
export const viewSelectedLayersSelector = state => systemSelector(state).viewSelectedLayers
export const lastPageSelector = state => systemSelector(state).lastPage

// user 
export const userSelector = state => state.user.data
export const userRoleSelector = state => userSelector(state).role