import { createContext, useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { RoutePaths } from '../../../routes';
import { getCookie, isBoolean } from '../../../service/function';


export const DrawerContext = createContext()

// выносим отдельно stackoverflow.com/questions/63894878/is-there-a-way-to-make-context-only-re-render-if-its-data-changes
const SideContextProvider = ({ children }) => {

    let isFilterObjectPage = useMatch(RoutePaths.FILTER_OBJECTS)
    let isFilterObjectDetailPage = useMatch(RoutePaths.FILTER_OBJECTS_DETAIL)
    const isFilterObject = isFilterObjectPage || isFilterObjectDetailPage
    const isArcGisPage = useMatch(RoutePaths.ARCGIS)


    const open_cat = isBoolean(getCookie('open_cat'))
    const [mainDrawer, setMainDrawer] = useState(
        (isFilterObject || isArcGisPage)
            ? true
            : Boolean(open_cat === undefined ? true : open_cat)
    )
    const [secondDrawer, setSecondDrawer] = useState(false)

    useEffect(() => {
        setMainDrawer(true)
    }, [isFilterObject, isArcGisPage])

    return (
        <DrawerContext.Provider value={{ mainDrawer, setMainDrawer, secondDrawer, setSecondDrawer }}>
            {children}
        </DrawerContext.Provider>
    )
}


export default SideContextProvider