import { useQueryClient, useMutation, useQuery } from 'react-query'
import { TIME_UPDATE } from '../../config'
import { delUser, loadPrepareUserDialog, loadUsers, saveUser } from '../../service/axiosFuncQuery/adminQuery'
import { useMask } from '../useMask'
import { useSnackbar } from 'notistack'
import CloseAction from '../../components/general/SnackBar/CloseAction'


// query
export const useUsersQuery = ({ term, offset, limit }) => {
    return (
        useQuery({
            queryKey: ['users', String(term), String(offset), String(limit)],
            queryFn: () => loadUsers({ term, offset, limit }),
            onError: (error) => console.log(error.message),
            staleTime: TIME_UPDATE,
            keepPreviousData: true, // показывать предыдущие данные до загрузки новых
        })
    )
}

export const usePrepareUserDialogQuery = (userId) => {
    return (
        useQuery({
            queryKey: ['prepareUserDialog', String(userId)],
            queryFn: () => loadPrepareUserDialog(userId),
            onError: (error) => console.log(error.message),
            staleTime: TIME_UPDATE,
        })
    )
}


// mutation
export const useSaveUserMutation = () => {

    const client = useQueryClient()
    const { hideMask, showMask } = useMask()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ userId, data }) => saveUser(userId, data),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                hideMask()
                client.invalidateQueries({ queryKey: ['users'] })
                if (variables.userId > 0) {
                    client.invalidateQueries({ queryKey: ['prepareUserDialog', String(variables.userId)] })
                }
                const message = `Пользователь '${variables.data.login}' ${variables.userId > 0 ? 'сохранен' : 'добавлен'}`
                enqueueSnackbar(message, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (e, variables) => {
                const message = `Ошибка. Пользователь id=${variables.userId} не сохранен. ${e.response.data}`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
                hideMask()
            },
        })
    )
}

export const useDelUserMutation = () => {
    const client = useQueryClient()
    const { hideMask, showMask } = useMask()
    const { enqueueSnackbar } = useSnackbar()
    return (
        useMutation({
            mutationFn: ({ userId }) => delUser(userId),
            onMutate: () => showMask(),
            onSuccess: (_, variables) => {
                hideMask()
                client.invalidateQueries({ queryKey: ['users'] })
                enqueueSnackbar(`Пользователь id=${variables.userId} удален`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
            onError: (_, variables) => {
                hideMask()
                const message = `Ошибка. Пользователь id=${variables.userId} не удален`
                enqueueSnackbar(message, { variant: 'error', action: CloseAction, persist: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } })
            },
        })
    )
}