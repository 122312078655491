import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Button, List, ListItem, Skeleton, SvgIcon, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import PropTypes from 'prop-types'
import { ReactComponent as ArrowForwarSharpIcon } from '../../../Icons/desktop/filterObjects/ArrowIcon.svg'
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs'
import Loader from '../../desktop/Loader/Loader'
import { pxToRem } from '../../Style/desktopTheme'
import { sxAccordion, sxAccordionDetails, sxAccordionSummary, sxListItem, } from './sxFilterObjects'
import useDeviceDetect from '../../../hooks/useDeviceDetect'
import { useParams } from 'react-router-dom'
import { useObjectsToLayerQuery } from '../../../hooks/reactQuery/useObjectsToLayer'


const ItemObjectsToLayer = ({ layerId, name, term = '', handleClickObject = () => { }, layerHierarhyList }) => {

    const { isMobile } = useDeviceDetect()
    const selectedLayerId = Number(useParams().layerId)
    const selectedObjectId = Number(useParams().objectId)

    const amount = 10
    const [count, setCount] = useState(5)
    const { data, isLoading } = useObjectsToLayerQuery(layerId)

    const [expanded, setExpanded] = useState()

    let objects = data ? [...data] : []

    if (term !== '') {
        objects = objects.filter(o => {
            if (o.name.toLowerCase().indexOf(term.toLowerCase()) >= 0) return true
            if (String(o.id).indexOf(term) >= 0) return true
            return false
        })
    }

    const viewObjects = objects?.slice(0, count)
    const total = objects?.length

    const handleNext = () => {
        setCount(v => v + amount)
    }

    const handleChangeAccordion = (panel) => (e, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    }

    return (
        <Accordion
            expanded={expanded === `panel${layerId}`}
            onChange={handleChangeAccordion(`panel${layerId}`)}
            key={`accordion_key_${layerId}`}
            sx={sxAccordion}
        >
            <AccordionSummary
                expandIcon={<SvgIcon style={{ fontSize: pxToRem('24px') }} inheritViewBox><ArrowForwarSharpIcon /></SvgIcon>}
                sx={sxAccordionSummary}
            >
                <Stack spacing={1} sx={{ml:pxToRem('8px')}}>
                    <Typography
                        sx={{ ml: pxToRem('8px'), bold: 600 }}
                        variant={isMobile ? 'font_16_normal_008' : 'font_16_24'}
                        component={'h5'}
                    >
                        {name}&nbsp;
                        <Typography variant={isMobile ? 'font_16_24' : 'font_16_24'} style={{ color: 'rgba(51, 51, 51, 0.50)' }}>
                            {isLoading
                                ? <Skeleton style={{ display: 'inline-block' }} width={30} />
                                : <>({total})</>
                            }
                        </Typography>
                    </Typography>

                    <BreadCrumbs arrBreadCrumbsData={layerHierarhyList} isLoading={false} isHideLastElement />
                </Stack>
            </AccordionSummary>


            <AccordionDetails sx={sxAccordionDetails}>
                {isLoading
                    ? <Loader />
                    : <>
                        <List>
                            {viewObjects?.map(o => {
                                return (
                                    <ListItem
                                        className={(selectedLayerId === layerId && selectedObjectId === o.id) ? 'selected' : ''}
                                        key={o.id}
                                        sx={sxListItem}
                                    >
                                        <Stack spacing={1} onClick={() => handleClickObject({ layerId, objectId: o.id })} >
                                            <Typography variant={isMobile ? 'font_16_22' : 'font_24'}>{o.name}</Typography>
                                        </Stack>
                                    </ListItem>
                                )
                            })}
                        </List>

                        {(total > 5 && (viewObjects.length < total)) &&
                            <Button onClick={() => handleNext()} fullWidth variant='greenText' >
                                Показать еще
                            </Button>
                        }
                    </>
                }
            </AccordionDetails>
        </Accordion>
    )
}

export default ItemObjectsToLayer

ItemObjectsToLayer.propTypes = {
    layerId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    term: PropTypes.string.isRequired,
    handleClickObject: PropTypes.func,
    layerHierarhyList: PropTypes.arrayOf(PropTypes.object).isRequired,
}