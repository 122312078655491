import React, { useEffect, useState } from 'react';
import { Box, Button, SvgIcon } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import LoadingCheckBox from '../Info/LoadingCheckBox';
import 'react-loading-skeleton/dist/skeleton.css';
import CheckBoxAllIcon from '../../desktop/ForForms/CheckBoxAllIcon';
import styles from './AllSelectedCheckbox.module.scss'


const AllSelectedCheckbox = (props) => {


    // props.selected
    // props.isLoading - загрузка самого компонента(страницы) - для отображения Skeleton
    // props.objectsIsLoading - загрузка объектов слоя
    // props.handleAllSelectLayer()

    const [checkAll, setCheckAll] = useState(props.selected);

    const handleCheckAll = () => {
        if (!props.objectsIsLoading) {
            setCheckAll(!checkAll);
            props.handleAllSelectLayer();
        }
    }

    useEffect(() => {
        setCheckAll(props.selected);
    }, [props.selected]);


    return (
        <>
            <Box className={styles.selected_all} onClick={handleCheckAll}>
                <Button variant='icon40'>
                    {props.isLoading
                        ? <Skeleton width={18} />
                        : <SvgIcon>
                            {props.objectsIsLoading
                                ? <LoadingCheckBox />
                                : checkAll ? <CheckBoxAllIcon active /> : <CheckBoxAllIcon />}
                        </SvgIcon>
                    }
                </Button>
                <Box>{props.isLoading ? <Skeleton width={100} /> : 'Выбрать все'}</Box>
            </Box>

        </>
    );
};

export default AllSelectedCheckbox;