import React, { useContext } from 'react';
import { AreaContext } from '../AreaContext';
import WrapFilterList from '../../WrapFilterList';


const Users = () => {

    const { prepareAreaDialog: { area, userList }, control, setValue } = useContext(AreaContext)

    const props = {
        nameFields: 'userIds',
        list: userList,
        checkedIds: area.userIds,
        control,
        setValue,
    }
    return (
        <WrapFilterList {...props}/>
    )
}

export default Users