import React from 'react';
import { Box } from '@mui/material';
import Control from 'react-leaflet-custom-control';
import { RoutePaths } from '../../../../routes';
import { ReactComponent as MapSettings } from "../../../../Icons/map/LayerSettings.svg";
import { ReactComponent as ListObjectsIcon } from "../../../../Icons/map/ListObjectsIcon.svg";
import { ReactComponent as ClearIcon } from "../../../../Icons/map/ClearIcon.svg";
import ZoomControl from '../../../general/Map/Control/ZoomControl';
import Link from '../../../../hoc/LinkWithSearchParams'
import styles from './MapControl.module.scss';
import LocationControl from './LocationControl';
import { useClearMap } from '../../../../hooks/useClearMap';



const MapControl = () => {

    const clearMap = useClearMap()
    
    return (
        <>
            <Control position="topright">
                <Box className={styles.controlPanel + ' ' + styles.toolkitControlPanel} >
                    <Link to={RoutePaths.HISTORY_LAYER}><MapSettings /></Link>
                    <Link to={RoutePaths.FILTER_OBJECTS}><ListObjectsIcon /></Link>
                    <Box component='a' href='' onClick={(e) => { clearMap(e); e.preventDefault() }}><ClearIcon /></Box>
                </Box>
            </Control>

            <Control position="topright">
                <Box className={styles.controlPanel + ' ' + styles.zoomControlPanel}>
                    <ZoomControl />
                </Box>

            </Control>

            <Control position="topright">
                <Box className={styles.controlPanel + ' ' + styles.locationControlPanel} >
                    <LocationControl />
                </Box>
            </Control>
        </>
    )
}

export default MapControl;