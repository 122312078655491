import React, { useContext } from 'react';
import { RoleContext } from '../RoleContext';
import WrapFilterList from '../../WrapFilterList';


const Permissions = () => {

    const { prepareRoleDialog: { role, aclList, editor }, control, setValue } = useContext(RoleContext)

    const props = {
        nameFields: 'aclIds',
        list: aclList,
        checkedIds: role.aclIds,
        control,
        setValue,
        disabled:!editor,
    }
    return (
        <WrapFilterList {...props}/>
    )
}

export default Permissions