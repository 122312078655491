import { useSelector } from "react-redux"
import { useLocation, useNavigate } from 'react-router-dom'
import { Avatar, Box, Button, Divider, IconButton, List, ListItem, ListItemAvatar, MenuItem, MenuList, Stack, SvgIcon, Typography, Link as MuiLink } from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description';
import { styled } from '@mui/material/styles'
import { useActions } from '../../../hooks/useActions'
import { authSelector, userRoleSelector, userSelector } from '../../../store/selectors/selectors'
import { ReactComponent as LogInIcon } from '../../../Icons/desktop/LogInIcon.svg'
import { ReactComponent as UserAvatar } from '../../../Icons/desktop/UserAvatar.svg'
import { useModal } from '../../../hooks/useModal/useModal'
import Admin from '../Admin/Admin'
import Gallery from '../Gallery/Gallery'
import Link from '../../../hoc/LinkWithSearchParams'
import { pxToRem } from '../../Style/desktopTheme'
import SignIn from '../SignIn/SignIn'
import { RoutePaths } from '../../../routes';
import { useQueryClient } from "react-query";


const Doc = () => {
    // Styled
    const MyLink = styled(MuiLink)(
        ({ theme }) => ({ color: theme.palette.myBlack.main })
    )
    return (
        <List dense={true}>
                <ListItem>
                    <ListItemAvatar><Avatar><DescriptionIcon /></Avatar></ListItemAvatar>
                    <MyLink href='/unsec/docs/user_manual.docx'>Руководство пользователя</MyLink>
                </ListItem>

                <ListItem>
                    <ListItemAvatar><Avatar><DescriptionIcon /></Avatar></ListItemAvatar>
                    <MyLink href='/unsec/docs/admin.docx'>Руководство администратора</MyLink>
                </ListItem>

                <ListItem>
                    <ListItemAvatar><Avatar><DescriptionIcon /></Avatar></ListItemAvatar>
                    <MyLink href='/unsec/docs/dev_manual.docx'>Руководство программиста</MyLink>
                </ListItem>
                <ListItem>
                    <ListItemAvatar><Avatar><DescriptionIcon /></Avatar></ListItemAvatar>
                    <MyLink href='/unsec/docs/memo.docx'>Инструкция редактора мемориальных объектов Ярославской области</MyLink>
                </ListItem>
                <ListItem>
                    <ListItemAvatar><Avatar><DescriptionIcon /></Avatar></ListItemAvatar>
                    <MyLink href='/media/instruction1.mp4'>Видеоинструкция</MyLink>
                </ListItem>

            </List>
    )
}

const UserMenu = ({ popoverClose }) => {

    const queryClient = useQueryClient()
    const location = useLocation()
    const { logout } = useActions()
    const { isAuth, login: userLogin } = useSelector(authSelector)
    const { isAdministrator, /*isAnonymous,*/ isEditor } = useSelector(userRoleSelector)
    const { fullName } = useSelector(userSelector)
    const navigate = useNavigate()
    const { openWindow } = useModal()

    let userName = ''
    if (fullName.trim() === '') {
        userName = userLogin
    } else {
        const arrFullName = fullName?.split(" ")
        userName = arrFullName?.length === 3
            ? `${arrFullName[0]} ${arrFullName[1][0]}. ${arrFullName[2][0]}.`
            : fullName
    }

    const openSign = () => {
        openWindow({
            title: 'Авторизация',
            children: <SignIn />,
            width: pxToRem('560px'),
            center: true,
        })

        popoverClose()
    }

    const handleLogout = () => {
        navigate(RoutePaths.HOME)
        const resetQuery = () => queryClient.resetQueries() // инвалидация всех запросов
        logout(resetQuery)
        popoverClose()
    }

    const openAdmin = () => {
        openWindow({
            title: 'Управление пользователями и ролями',
            children: <Admin />,
            width: 800
        })
        popoverClose()
    }

    const openDoc = () => {
        openWindow({
            title: 'Документация',
            children: <Doc />,
            width: 'auto',
            center: true,
        })

        popoverClose()
    }
    const openGallery = () => {
        openWindow({
            title: 'Галерея иконок',
            children: <Gallery />,
            width: '80%'
        })
        popoverClose()
    }

    return (
        <>
            <MenuList>
                {!isAuth &&
                    <MenuItem state={{ previousLocation: location }} onClick={openSign}>
                        <Stack direction='row' alignItems='center' spacing={1.5}>
                            <IconButton sx={{
                                width: pxToRem(64),
                                height: pxToRem(64),
                                bgcolor: 'myGray.main',
                            }}>
                                <SvgIcon style={{ width: pxToRem('28px'), height: pxToRem('32px') }} inheritViewBox={true}>
                                    <LogInIcon />
                                </SvgIcon>
                            </IconButton>
                            <Typography variant='font_20'>Авторизация</Typography>
                        </Stack>
                    </MenuItem>
                }

                {isAuth &&
                    <MenuItem>
                        <Stack direction='row' alignItems='center' spacing={1.5}>
                            <Avatar style={{width: pxToRem(64), height: pxToRem(64), background: 'rgba(196, 196, 196, 0.3)'}}>
                                <SvgIcon style={{fontSize: pxToRem('36px')}} inheritViewBox={true}>
                                    <UserAvatar />
                                </SvgIcon>
                            </Avatar>
                            <Stack>
                                <Typography variant='font_22_28' >{userName}</Typography>
                                <Button sx={{
                                    textTransform: 'none',
                                    justifyContent: 'start',
                                    pl: '0px'
                                }}
                                    onClick={handleLogout}
                                >
                                    <Typography variant='font_24' sx={{ color: 'myRed.main' }}>Выйти</Typography>
                                </Button>
                            </Stack>
                        </Stack>
                    </MenuItem>
                }

                {isAuth &&
                    <Box>
                        {isAdministrator &&
                            <>
                                <MenuItem onClick={openAdmin}>Администрирование</MenuItem>

                                {/* <MenuItem>Отчеты</MenuItem> */}
                                {/* <MenuItem>Настройки системы</MenuItem> */}
                                {/* <MenuItem>Добавить категорию</MenuItem> */}
                                <MenuItem onClick={openGallery}>Галерея иконок</MenuItem>
                            </>
                        }

                        {isEditor &&
                            <>
                                {/* <MenuItem>Пространственные операции</MenuItem> */}
                                <MenuItem component={Link} sx={{ color: 'myBlack.main' }} href='https://gis76.ru/tmpl/login' target='_blank'>Редактор шаблонов</MenuItem>
                            </>}

                        {/* {!isAnonymous && 
                            <MenuItem>Сменить пароль</MenuItem>
                        } */}

                        <Divider style={{ margin: `${pxToRem('8px')} ${pxToRem('20px')} ${pxToRem('8px')} ${pxToRem('16px')}` }} />
                    </Box>
                }

                <MenuItem state={{ previousLocation: location }} onClick={openDoc}>Документация</MenuItem>
                {/* <MenuItem><LinkMui sx={{ color: 'myBlack.main' }} href={RoutePaths.METADATA_LAYER} target='_blank'>Метаданные слоев</LinkMui></MenuItem> */}

                {/* <MenuItem>Виджет</MenuItem> */}
                <MenuItem component='a' sx={{ color: 'myBlack.main' }} href='https://gtc76.ru/' target='_blank'>О портале</MenuItem>
                <MenuItem component='a' sx={{ color: 'myBlack.main' }} href='https://gtc76.ru/contacts/' target='_blank'>Контакты</MenuItem>
            </MenuList>
        </>
    )
}

export default UserMenu